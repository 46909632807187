import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const pencapaianNota = JSON.parse(localStorage.getItem("pencapaianNota"));

// Mendapatkan tanggal pertama bulan ini
const firstDateOfMonth = dayjs().startOf("month");

// Mendapatkan tanggal terakhir bulan ini
const lastDateOfMonth = dayjs().endOf("month");

const initialState = {
  tanggalPencapaianFilter: [firstDateOfMonth, lastDateOfMonth],
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: { jenis_order: false, source_order: false, alamat: false },
  rowGroup: pencapaianNota ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actions"] },
  printModal: { id: null, open: false, title: null },
  detailModal: { id: null, open: false, title: null },
  detailProgressModal: { id: null, open: false, title: null },
  pembayaranModal: { id: null, open: false, title: null },
};

const PencapaianNota = createSlice({
  name: "pencapaianNota",
  initialState,
  reducers: {
    SET_TANGGAL_PENCAPAIAN_FILTER: (state, action) => {
      state.tanggalPencapaianFilter = action.payload.tanggal;
    },
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "pencapaianNota",
        JSON.stringify(action.payload.group)
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_PRINT_MODAL: (state, action) => {
      state.printModal = action.payload.modal;
    },
    SET_DETAIL_MODAL: (state, action) => {
      state.detailModal = action.payload.modal;
    },
    SET_DETAIL_PROGRESS_MODAL: (state, action) => {
      state.detailProgressModal = action.payload.modal;
    },
    SET_PEMBAYARAN_MODAL: (state, action) => {
      state.pembayaranModal = action.payload.modal;
    },
  },
});

export const {
  SET_TANGGAL_PENCAPAIAN_FILTER,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_PRINT_MODAL,
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_PEMBAYARAN_MODAL,
} = PencapaianNota.actions;

export default PencapaianNota.reducer;
