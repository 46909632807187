import React from "react";
import { Grid } from "@mui/material";
import { Api, EventHandlers } from "../repository";
import { useDispatch, useSelector } from "react-redux";
import { NumericText } from "../../../../components/common";
import { dateFormatter } from "../../../../components/controls";
import { DeleteDialog } from "../../../../components/Layout/Content";

const ModalDeleteRiwayat = () => {
  const dispatch = useDispatch();

  const { tanggalRiwayatBayarFilter, hapusRiwayat } = useSelector(
    (state) => state.riwayatBayar
  );

  const handleClose = () => {
    EventHandlers.handleHapusRiwayat({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
        data: null,
      },
    });
  };

  const { mutate, isPending } = Api.deletePembayaran({
    id: hapusRiwayat.id,
    handleClose,
    startDate: tanggalRiwayatBayarFilter[0],
    endDate: tanggalRiwayatBayarFilter[1],
  });

  const onDelete = () => {
    mutate(hapusRiwayat.id);
  };

  return (
    <DeleteDialog
      handleClose={handleClose}
      onDelete={onDelete}
      title={"Delete Riwayat Pembayaran"}
      open={hapusRiwayat.open}
      isLoading={isPending}
    >
      <Grid
        container
        borderRadius="5px"
        sx={{ backgroundColor: "#F3F7F8", padding: "0.5rem" }}
      >
        <Grid item xs={3}>
          <strong>Nota</strong>
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          <strong>{hapusRiwayat.data?.nota}</strong>
        </Grid>
        <Grid item xs={3}>
          Tanggal Bayar
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          {hapusRiwayat.title
            ? dateFormatter({
                date: new Date(hapusRiwayat.data?.tanggal),
                withHari: true,
                withMonth: "simple",
                withTimes: true,
                yearLength: "full",
              })
            : "-"}
        </Grid>
        <Grid item xs={3}>
          Nominal
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          <NumericText value={hapusRiwayat.data?.nominal ?? 0} prefix={true} />
        </Grid>
        <Grid item xs={3}>
          Metode bayar
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          {hapusRiwayat.data?.metode_pembayaran}
        </Grid>
        <Grid item xs={3}>
          Keterangan
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          {hapusRiwayat.data?.keterangan ?? "-"}
        </Grid>
      </Grid>
    </DeleteDialog>
  );
};

export default ModalDeleteRiwayat;
