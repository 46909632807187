import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { CheckboxInput, Input, CustomButton } from "../../../controls";
import { ActionGrid } from "../../../common";

const SelesaiAntrianLayout = ({ onSubmit, handleClose, title, isPending }) => {
  const { control, watch, handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            name="keterangan"
            control={control}
            label="Keterangan"
            rules={{ required: false }}
            defaultValue=""
            rows={5}
          />
        </Grid>
      </Grid>
      {/* Validation Check */}
      <Grid
        container
        justifyContent={"end"}
        alignItems={"center"}
        marginTop={1}
        marginBottom={"-1rem"}
      >
        <CheckboxInput
          name={"confirmation"}
          control={control}
          label={"Pastikan data sudah benar!"}
          rules={{ required: false }}
          defaultValue={false}
        />
      </Grid>

      {/* Action Button */}
      <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
      <ActionGrid
        isLoading={isPending}
        actionStart={
          <CustomButton onClick={handleClose} warna={"clear"} widthbtn={"100%"}>
            Batal
          </CustomButton>
        }
        actionEnd={
          <CustomButton
            loading={isPending}
            warna={"next"}
            widthbtn={"100%"}
            disabled={watch("confirmation") ? false : true}
            type="submit"
          >
            Simpan
          </CustomButton>
        }
      />
    </form>
  );
};

SelesaiAntrianLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isPending: PropTypes.bool,
};
export default SelesaiAntrianLayout;
