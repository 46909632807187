import axios from "axios";
import config from "../config";

export const axiosInstance = axios.create({
  baseURL: `${config.apiUrl}/api`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let response;

    if (
      error.response &&
      error.response.headers["content-type"]?.includes("text/html")
    ) {
      response = {
        type: "error",
        message: "Terjadi Kesalahan Pada Server. Format response tidak sesuai.",
        code: error.response?.status || 500,
      };
    } else if (error.response?.status === 401) {
      localStorage.removeItem("token");
      response = error.response?.data || {};
      response.type = "error";
      response.message = response.message || "Unauthorized";
      response.code = 401;
    } else if (error.response?.data) {
      response = error.response?.data || {};
      response.type = "error";
      response.code = error.response?.status || 500;
    } else {
      response = {
        type: "error",
        message: "Terjadi Kesalahan Pada Server.",
        code: 500,
      };
    }

    if (!response.data) {
      response.data = [];
    }

    return Promise.reject(response);
  }
);
