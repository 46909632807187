import { createSlice } from "@reduxjs/toolkit";

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const pendapatanBulanan = JSON.parse(localStorage.getItem("pendapatanBulanan"));

const initialState = {
  bulan: currentMonth,
  tahun: currentYear,
  page: 0,
  pageSize: 31,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {},
  rowGroup: pendapatanBulanan ?? [],
  aggregationModel: {
    nota: "sum",
    jumlah_order: "sum",
    reject: "sum",
    gross_profit: "sum",
    brutto_jual: "sum",
    brutto_hpp: "sum",
    retur_jual: "sum",
    retur_hpp: "sum",
    netto_jual: "sum",
    netto_hpp: "sum",
    profit: "sum",
    profit_percent: "sum",
  },
  pinnedModel: { left: [], right: ["profit", "profit_percent"] },
};

const PendapatanBulanan = createSlice({
  name: "pendapatanBulanan",
  initialState,
  reducers: {
    SET_BULAN_PENDAPATAN: (state, action) => {
      state.bulan = action.payload;
    },
    SET_TAHUN_PENDAPATAN: (state, action) => {
      state.tahun = action.payload;
    },
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "pendapatanBulanan",
        JSON.stringify(action.payload.group)
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
  },
});

export const {
  SET_BULAN_PENDAPATAN,
  SET_TAHUN_PENDAPATAN,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} = PendapatanBulanan.actions;

export default PendapatanBulanan.reducer;
