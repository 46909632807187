import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { BlankDialog } from "../../../../components/Layout/Content";
import { Divider, Grid } from "@mui/material";
import { CheckboxInput } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { FormProduksiGroup } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";
import { ActionGrid } from "../../../../components/common";

const ModalProduksiGroup = ({ form }) => {
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.data);
  const { produksiGroup } = useSelector((state) => state.antrianGroup);
  const handleClose = () => {
    EventHandlers.handleProduksiGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
        data: {
          nota: null,
          keterangan: null,
          antrian: 0,
          size: 0,
          additional: 0,
          list: [],
        },
      },
    });
  };

  const { mutate, isPending } = Api.produksiAntrainGroup({
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    const data = {
      keterangan: values.keterangan,
    };

    mutate({ body: data, id: produksiGroup.id });
  };
  return (
    <BlankDialog
      maxWidth={"sm"}
      open={produksiGroup.open}
      handleClose={handleClose}
      title={produksiGroup.title}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormProduksiGroup form={form} data={produksiGroup.data} />

        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              type="submit"
              disabled={form.watch("confirmation") ? false : true}
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalProduksiGroup;
