import React from "react";
import { useSelector } from "react-redux";
import { NumberInput, Select } from "../../../../components/controls";
import { BlankDialog } from "../../../../components/Layout/Content";
import { Divider, Grid } from "@mui/material";
import CustomButton from "../../../../components/controls/CustomButton";
import { CheckboxInput } from "../../../../components/controls";
import { ActionGrid } from "../../../../components/common";

const ModalCreateUpdateTarget = ({
  form,
  onSubmit,
  handleClose,
  isPendingCreate,
  isPendingUpdate,
  isPendingDelete,
}) => {
  const { createUpdateModal, hapusTarget } = useSelector(
    (state) => state.pengaturanTarget
  );

  const { default_bulan, default_tahun } = useSelector((state) => state.data);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={createUpdateModal.open || hapusTarget.open}
      handleClose={handleClose}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 1rem",
          }}
        >
          <span style={{ color: hapusTarget.open ? "#FF0707" : "" }}>
            {createUpdateModal.open
              ? createUpdateModal.title
              : hapusTarget.title}
          </span>
        </div>
      }
      hideCloseIcon={true}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Select
              name={"bulan"}
              control={form.control}
              label={"Bulan"}
              options={default_bulan}
              rules={{ required: false }}
              defaultValue={""}
              disabled={hapusTarget.open || createUpdateModal.id ? true : false}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              name={"tahun"}
              control={form.control}
              label={"Tahun"}
              options={default_tahun}
              rules={{ required: false }}
              defaultValue={""}
              disabled={hapusTarget.open || createUpdateModal.id ? true : false}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberInput
              rules={{
                required: "Nominal Harus di isi!",
                min: {
                  value: 1,
                  message: "Nominal Harus di isi!",
                },
              }}
              name={`jumlah`}
              control={form.control}
              label={"Nominal"}
              defaultValue={0}
              disabled={hapusTarget.open}
            />
          </Grid>
        </Grid>
        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPendingCreate || isPendingUpdate || isPendingDelete}
          actionStart={
            <CustomButton
              onClick={handleClose}
              warna={"clear"}
              widthbtn={"100%"}
            >
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPendingCreate || isPendingUpdate || isPendingDelete}
              warna={"next"}
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalCreateUpdateTarget;
