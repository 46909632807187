import React from "react";
import { useSelector } from "react-redux";
import { TabelPencapaian, DetailAntrian, DetailProgress } from "./components";
import { Api, useFormNotaPencapaian } from "./repository";

const PencapaianNotaPage = () => {
  const useForm = useFormNotaPencapaian();
  const { detailModal, detailProgressModal, tanggalPencapaianFilter } =
    useSelector((state) => state.pencapaianNota);
  const getDataAntrian = Api.read({ tanggal: tanggalPencapaianFilter });
  const getAntrianDetail = Api.detailAntrian({ modal: detailModal });
  const getProgressDetail = Api.detailProgress({ modal: detailProgressModal });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelPencapaian
        data={getDataAntrian.data ? getDataAntrian.data.data : []}
        isLoading={getDataAntrian.isLoading}
      />

      {/* Modal Popup */}
      <DetailAntrian
        data={getAntrianDetail.data ? getAntrianDetail.data.data : {}}
        isLoading={getAntrianDetail.isLoading}
        isError={getAntrianDetail.isError}
      />

      <DetailProgress
        data={getProgressDetail.data ? getProgressDetail.data.data : {}}
        isLoading={getProgressDetail.isLoading}
        isError={getProgressDetail.isError}
        {...{ form: useForm }}
      />
    </>
  );
};

export default PencapaianNotaPage;
