import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
};

const Dikerjakan = createSlice({
  name: "dikerjakan",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { SET_TAB } = Dikerjakan.actions;

export default Dikerjakan.reducer;
