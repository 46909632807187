import { dateFormat } from "../../../../components/controls";
import { NumericText } from "../../../../components/common";

export class Config {
  static column = [
    {
      field: "nota",
      flex: 1,
      minWidth: 70,
      hideable: false,
      headerName: "No. Nota",
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#007FF3",
              fontWeight: 700,
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "produksi",
      flex: 1,
      minWidth: 150,
      headerName: "Admin Produksi",
    },
    {
      field: "tanggal_reject",
      type: "date",
      flex: 1,
      minWidth: 150,
      headerName: "Tanggal Reject",
      valueGetter: (params) => new Date(params),
      renderCell: (params) => {
        if (params.value instanceof Date && !isNaN(params.value)) {
          return (
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {dateFormat(params.value, "", "-")}
            </span>
          );
        }
        return "";
      },
      groupingValueGetter: (params) => {
        var date = new Date(params);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "nominal_reject",
      flex: 1,
      minWidth: 150,
      headerName: "Nominal Reject",
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "keterangan_reject",
      flex: 1,
      minWidth: 150,
      headerName: "Keterangan Reject",
    },
    {
      headerName: "Opsi",
      disableClickEventBubbling: true,
      field: "actions",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      disableColumnResize: true,
      disableColumnReorder: true,
      width: 60,
    },
  ];

  static variable = {
    nama: "Reject",
    endpoint: "reject",
  };
}
