import {
  SET_BULAN_CASHFLOW,
  SET_TAHUN_CASHFLOW,
} from "../domain/cashflowBulananSlice";

export class EventHandlers {
  static handleBulan = ({ dispatch, bulan }) => {
    dispatch(SET_BULAN_CASHFLOW(bulan));
  };
  static handleTahun = ({ dispatch, tahun }) => {
    dispatch(SET_TAHUN_CASHFLOW(tahun));
  };
}
