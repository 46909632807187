import React from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import { Select } from "../../../../components/controls";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { useQueryClient } from "@tanstack/react-query";

const FilterBulanTahun = ({ form, tab }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { default_bulan, default_tahun } = useSelector((state) => state.data);

  const {
    harianBulan,
    harianTahun,
    bulananTahun,
    tahunanTahun1,
    tahunanTahun2,
  } = useSelector((state) => state.dashboardCardSourceOrder);
  return (
    default_bulan.length > 0 &&
    default_tahun.length > 0 && (
      <>
        {tab === 0 && (
          <>
            <Grid item>
              <Select
                name="harianBulan"
                control={form.control}
                label="Bulan"
                options={default_bulan}
                rules={{ required: false }}
                defaultValue={harianBulan}
                size="small"
                noshrinklabel={true}
                onChange={(_, value) => {
                  form.setValue("harianBulan", value.props.value);
                  EventHandlers.handleHarianBulan({
                    dispatch,
                    harianBulan: value.props.value,
                    harianTahun,
                    oldHarianBulan: harianBulan,
                    queryClient,
                  });
                }}
                wave
              />
            </Grid>
            <Grid item>
              <Select
                name="harianTahun"
                control={form.control}
                label="Tahun"
                options={default_tahun}
                rules={{ required: false }}
                defaultValue={harianTahun}
                size="small"
                noshrinklabel={true}
                onChange={(_, value) => {
                  form.setValue("harianTahun", value.props.value);
                  EventHandlers.handleHarianTahun({
                    dispatch,
                    harianTahun: value.props.value,
                    harianBulan: harianBulan,
                    oldHarianTahun: harianTahun,
                    queryClient,
                  });
                }}
                wave
              />
            </Grid>
          </>
        )}
        {tab === 1 && (
          <>
            <Grid item>
              <Select
                name="bulananTahun"
                control={form.control}
                label="Tahun"
                options={default_tahun}
                rules={{ required: false }}
                defaultValue={bulananTahun}
                size="small"
                noshrinklabel={true}
                onChange={(_, value) => {
                  form.setValue("bulananTahun", value.props.value);
                  EventHandlers.handleBulananBulan({
                    dispatch,
                    bulananTahun: value.props.value,
                    oldBulananTahun: bulananTahun,
                    queryClient,
                  });
                }}
                wave
              />
            </Grid>
          </>
        )}
        {tab === 2 && (
          <>
            <Grid item>
              <Select
                name="tahunan1"
                control={form.control}
                label="Tahun"
                options={default_tahun}
                rules={{ required: false }}
                defaultValue={tahunanTahun1}
                size="small"
                noshrinklabel={true}
                onChange={(_, value) => {
                  form.setValue("tahunan1", value.props.value);
                  EventHandlers.handleStartTahun({
                    dispatch,
                    tahunanTahun1: value.props.value,
                    oldTahunanTahun1: tahunanTahun1,
                    tahunanTahun2: tahunanTahun2,
                    queryClient,
                  });
                }}
                wave
              />
            </Grid>
            <Grid item alignSelf={"center"}>
              <OpenInFullIcon
                sx={{ rotate: "45deg", color: "#797979", mt: "4px" }}
              />
            </Grid>
            <Grid item>
              <Select
                name="tahunan2"
                control={form.control}
                label="Tahun"
                options={default_tahun}
                rules={{ required: false }}
                defaultValue={tahunanTahun2}
                size="small"
                noshrinklabel={true}
                onChange={(_, value) => {
                  form.setValue("tahunan2", value.props.value);
                  EventHandlers.handleEndBulan({
                    dispatch,
                    tahunanTahun2: value.props.value,
                    tahunanTahun1: tahunanTahun1,
                    oldTahunanTahun2: tahunanTahun2,
                    queryClient,
                  });
                }}
                wave
              />
            </Grid>
          </>
        )}
      </>
    )
  );
};

export default FilterBulanTahun;
