import { createSlice } from "@reduxjs/toolkit";
const tabelUser = JSON.parse(localStorage.getItem("tabelUser"));

const initialState = {
  page: 0,
  pageSize: 10,
  search: "",
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {},
  rowGroup: tabelUser ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actions"] },
  toggleView: 1,
  createUpdateUser: { id: null, open: false, title: null },
  deleteUser: { id: null, open: false, title: null },
  filterRole: "All",
  filterStatus: "All",
  pageSizeGrid: 30,
  pageGrid: 1,
};

const User = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_PAGE_USER: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_GRID_USER: (state, action) => {
      state.pageGrid = action.payload.pageGrid;
    },
    SET_PAGE_SIZE_USER: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_PAGE_SIZE_GRID_USER: (state, action) => {
      state.pageSizeGrid = action.payload.pageSizeGrid;
      state.pageGrid = 1;
    },
    SET_SEARCH: (state, action) => {
      state.search = action.payload.search;
      state.pageGrid = 1;
    },
    SET_FILTER_USER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT_USER: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem("tabelUser", JSON.stringify(action.payload.group));
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_TOGGLE_VIEW: (state, action) => {
      state.toggleView = action.payload;
    },
    SET_CREATE_UPDATE_USER: (state, action) => {
      state.createUpdateUser = action.payload.modal;
    },
    SET_DELETE_USER: (state, action) => {
      state.deleteUser = action.payload.modal;
    },
    SET_ROLE_USER: (state, action) => {
      state.filterRole = action.payload;
      state.pageGrid = 1;
    },
    SET_STATUS_USER: (state, action) => {
      state.filterStatus = action.payload;
      state.pageGrid = 1;
    },
  },
});

export const {
  SET_PAGE_USER,
  SET_PAGE_GRID_USER,
  SET_PAGE_SIZE_USER,
  SET_PAGE_SIZE_GRID_USER,
  SET_SEARCH,
  SET_FILTER_USER,
  SET_SORT_USER,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_TOGGLE_VIEW,
  SET_CREATE_UPDATE_USER,
  SET_DELETE_USER,
  SET_ROLE_USER,
  SET_STATUS_USER,
} = User.actions;

export default User.reducer;
