import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: null,
  title: "",
};
const imageDetailSlice = createSlice({
  name: "imageDetailSlice",
  initialState,

  reducers: {
    UPDATE_IMAGE: (state, action) => {
      state.url = action.payload.url;
      state.title = action.payload.title;
    },
    RESET_IMAGE: () => initialState,
  },
});

export const { UPDATE_IMAGE, RESET_IMAGE } = imageDetailSlice.actions;
export default imageDetailSlice.reducer;
