import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { PembayaranLayout } from "../../../../components/Layout/Content/Antrian";
import { useForm } from "react-hook-form";
import ModalDeletePembayaran from "./PembayaranAntrian/ModalDeletePembayaran";

const DetailPembayaran = () => {
  const dispatch = useDispatch();
  const formAntrian = useForm();
  const { tanggalRejectFilter, pembayaranModal } = useSelector(
    (state) => state.rekapReject
  );

  const { data, isLoading, isError } = Api.detailPembayaran({
    modal: pembayaranModal,
  });

  const handleClose = useCallback(() => {
    EventHandlers.handlePembayaranModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });

    formAntrian.reset();
  }, [dispatch, formAntrian]);

  const { mutate, isPending } = Api.updatePembayaran({
    id: pembayaranModal.id,
    onSuccess: () => {
      formAntrian.reset();
      handleClose();
    },
    tanggal: tanggalRejectFilter,
  });

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  const onSubmit = (values) => {
    if (pembayaranModal.id) {
      mutate({ body: values, id: pembayaranModal.id });
    }
  };

  const handleDeletePembayaran = (data) => {
    EventHandlers.handleDeletePembayaran({
      dispatch,
      modal: {
        id: data.id,
        open: true,
        title: data,
      },
    });
  };

  return (
    <>
      <PembayaranLayout
        title={pembayaranModal.title}
        handleClose={handleClose}
        open={pembayaranModal.open}
        data={data ? data.data : {}}
        onSubmit={onSubmit}
        isLoading={isLoading || isPending}
        formAntrian={formAntrian}
        handleDeletePembayaran={handleDeletePembayaran}
      />
      <ModalDeletePembayaran />
    </>
  );
};

export default DetailPembayaran;
