import { Config } from ".";
import { useFetchingData } from "../../../../hooks";

export class Api {
  // READ DATA STATUS PEMBAYARAN
  static pendapatanBulanan = ({ bulan, tahun }) => {
    const endpoint = `${Config.variable.endpoint}?bulan=${bulan}&tahun=${tahun}`;
    return useFetchingData({
      endpoint,
    });
  };
}
