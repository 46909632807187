import React from "react";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { ChipItems } from "../../../common";

const TotalOrder = ({ size }) => {
  const totalOrderAntrian = size.reduce(
    (acc, currentValue) => acc + currentValue.qty,
    0
  );

  return (
    <Card
      elevation={3}
      sx={{
        width: "100%",
        borderRadius: "5px",
        borderBottom: "0.3rem solid #008DD2",
        color: "#797979",
      }}
    >
      <CardContent sx={{ padding: "1rem !important", color: "#797979" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#797979",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            Total Order
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {totalOrderAntrian} Pcs
          </Typography>
        </div>
        <Divider sx={{ margin: "0.7rem 0", borderWidth: "1px" }} />
        <Grid container spacing={1}>
          {size.map((sizeValue, index) => (
            <Grid item key={index}>
              <ChipItems
                size="small"
                label={`${sizeValue.nama} : ${sizeValue.qty}`}
                backgroundcolor="#008DD2"
                borderradius={"5px"}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalOrder;
