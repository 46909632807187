import React from "react";
import { Grid } from "@mui/material";

const FormSourceOrder = (props) => {
  const { form, Input, handleFocus, SwitchInput, dataSourceOrder, isLoading } =
    props;
  const source_order = Array.from(new Set(dataSourceOrder));

  // Calculate mid-point
  const midPoint = Math.ceil(source_order.length / 2);

  return (
    <Grid container spacing={2} marginBottom={3} sx={{ color: "#797979" }}>
      {/* First half of the inputs */}
      <Grid container item xs={6} spacing={2}>
        {source_order.slice(0, midPoint).map((source, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              {...form.register(`source_order.${index}.id`, {
                valueAsNumber: true,
              })}
              value={source.id}
            />
            <Grid item xs={6} textAlign="right">
              <SwitchInput
                name={`source_order.${index}.status`}
                control={form.control}
                label={`${index + 1}`}
                defaultValue={source ? source.status : false}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                rules={{
                  required: form.watch(`source_order.${index}.status`)
                    ? true
                    : false,
                }}
                name={`source_order.${index}.name`}
                control={form.control}
                label={`Source Order ${index + 1}`}
                onFocus={handleFocus}
                disabled={
                  !form.watch(`source_order.${index}.status`, source.status) ||
                  isLoading
                }
                defaultValue={source.name}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {/* Second half of the inputs */}
      <Grid container item xs={6} spacing={2}>
        {source_order.slice(midPoint).map((source, index) => {
          const adjustedIndex = index + midPoint;
          return (
            <React.Fragment key={adjustedIndex}>
              <input
                type="hidden"
                {...form.register(`source_order.${adjustedIndex}.id`, {
                  valueAsNumber: true,
                })}
                value={source.id}
              />
              <Grid item xs={6} textAlign="right">
                <SwitchInput
                  name={`source_order.${adjustedIndex}.status`}
                  control={form.control}
                  label={`${adjustedIndex + 1}`}
                  defaultValue={source ? source.status : false}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rules={{
                    required: form.watch(`source_order.${adjustedIndex}.status`)
                      ? true
                      : false,
                  }}
                  name={`source_order.${adjustedIndex}.name`}
                  control={form.control}
                  label={`Source Order ${adjustedIndex + 1}`}
                  onFocus={handleFocus}
                  disabled={
                    !form.watch(
                      `source_order.${adjustedIndex}.status`,
                      source ? source.status : false
                    ) || isLoading
                  }
                  defaultValue={source.name}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FormSourceOrder;
