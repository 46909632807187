import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CleaningServices as ClearIcon,
  ForwardOutlined as ArrowRight,
  DoneOutlineOutlined as CheckIcon,
} from "@mui/icons-material";
import { SwitchInput } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { BlankDialog } from "../../../../components/Layout/Content";
import FormTambahAntrian from "./ModalAntrian/FormTambahAntrian";
import FormOrderDetail from "./ModalAntrian/FormDetailOrder";
import FormConfirmation from "./ModalAntrian/FormConfirmation";
import { Config, EventHandlers } from "../repository";
import { ActionGrid } from "../../../../components/common";

const ModalAntrian = (props) => {
  const {
    isLoading,
    formAntrian,
    onSubmit,
    sourceOrder,
    jenisOrder,
    size,
    additional,
    ekspedisi,
    keterangan,
    metodePembayaran,
  } = props;
  const [isLoadingClear, setIsLoadingClear] = useState(false);

  const { createUpdateModal, createModalPage } = useSelector(
    (state) => state.antrianNota
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    EventHandlers.handleCreateUpdateModal({
      dispatch,
      modal: { id: null, open: false, title: null },
      clearErrors: formAntrian.clearErrors,
      reset: formAntrian.reset,
    });
  };

  const { size: Sizes } = formAntrian.getValues();
  const isAtLeastOneActiveSize = () => {
    return Sizes && Sizes.some((size) => size.isActive);
  };

  const isAtLeastOneDetailOrder = () => {
    const getSize = formAntrian.watch("size");
    const activeSizes = getSize.filter((size) => size.isActive); // Filter size yang aktif
    return activeSizes.every((size) => size.detail_order.length > 0); // Periksa setiap size aktif, apakah ada detail_order
  };

  return (
    <BlankDialog
      maxWidth={createModalPage === 3 ? "sm" : "xl"}
      open={createUpdateModal.open}
      handleClose={handleClose}
      outsideClose={false}
      isLoading={isLoading}
      hideCloseIcon={createModalPage === 3 && true}
      title={
        <>
          {createModalPage === 1 ? (
            <>
              <SwitchInput
                name={"antrian.prioritas"}
                control={formAntrian.control}
                label={"Antrian Prioritas"}
                defaultValue={false}
                sx={{
                  position: "absolute",
                  left: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
              <span style={{ fontSize: "14px" }}>Tambah Antrian</span>
            </>
          ) : createModalPage === 1 ? (
            <span style={{ fontSize: "14px" }}>Detail Order</span>
          ) : (
            <span style={{ fontSize: "14px" }}>Tambah Antrian</span>
          )}
        </>
      }
      action={
        createModalPage === 1 ? (
          <Grid
            container
            spacing={2}
            justifyContent={createModalPage === 3 ? "end" : "space-between"}
            padding={"1rem"}
          >
            <Grid item xs={3}>
              <CustomButton
                loading={isLoadingClear}
                starticon={
                  !isLoadingClear && <ClearIcon sx={{ rotate: "45deg" }} />
                }
                disabled={isLoading ? true : false}
                onClick={() => {
                  setIsLoadingClear(true);
                  setTimeout(() => {
                    const defaultValues = Config.defaultValue;
                    defaultValues["size"] = size.map((sizeItem) => ({
                      size_id: sizeItem.id,
                      label: sizeItem.name,
                      isActive: false,
                      harga: 0,
                      detail_order: [],
                      status: sizeItem.status,
                    }));
                    formAntrian.reset(defaultValues);
                    setIsLoadingClear(false);
                  }, 500);
                }}
                warna={"clear"}
              >
                Clear
              </CustomButton>
            </Grid>
            <Grid item xs={3}>
              <CustomButton
                endicon={<ArrowRight />}
                onClick={() => {
                  EventHandlers.handleCreatePageModal({
                    dispatch,
                    page: 2,
                  });
                }}
                warna={"next"}
                disabled={
                  !formAntrian.formState.isValid || !isAtLeastOneActiveSize()
                }
              >
                Lanjutkan
              </CustomButton>
            </Grid>
          </Grid>
        ) : createModalPage === 2 ? (
          <Grid
            container
            spacing={2}
            justifyContent={createModalPage === 3 ? "end" : "space-between"}
            padding={"1rem"}
          >
            <Grid item xs={3}>
              <CustomButton
                starticon={<ArrowRight sx={{ rotate: "180deg" }} />}
                disabled={isLoading ? true : false}
                onClick={() =>
                  EventHandlers.handleCreatePageModal({ dispatch, page: 1 })
                }
                warna={"back"}
              >
                Kembali
              </CustomButton>
            </Grid>
            <Grid item xs={3}>
              <CustomButton
                endicon={<CheckIcon />}
                disabled={
                  !formAntrian.formState.isValid || !isAtLeastOneDetailOrder()
                }
                warna={"simpan"}
                onClick={() => {
                  EventHandlers.handleCreatePageModal({
                    dispatch,
                    page: 3,
                  });
                }}
              >
                Simpan
              </CustomButton>
            </Grid>
          </Grid>
        ) : (
          <ActionGrid
            padding={"1rem"}
            isLoading={isLoading}
            actionStart={
              <CustomButton
                disabled={isLoading ? true : false}
                onClick={() =>
                  EventHandlers.handleCreatePageModal({ dispatch, page: 2 })
                }
                warna={"clear"}
                widthbtn={"100%"}
              >
                Batal
              </CustomButton>
            }
            actionEnd={
              <CustomButton
                loading={isLoading}
                disabled={!formAntrian.watch("confirmation")}
                warna={"next"}
                widthbtn={"100%"}
                onClick={onSubmit}
              >
                Simpan
              </CustomButton>
            }
          />
        )
      }
    >
      <form onSubmit={onSubmit}>
        {createModalPage === 1 ? (
          <FormTambahAntrian
            {...{
              formAntrian,
              size,
              jenisOrder,
              sourceOrder,
              ekspedisi,
            }}
          />
        ) : createModalPage === 2 ? (
          <FormOrderDetail
            {...{
              formAntrian,
              additional,
              keterangan,
            }}
          />
        ) : (
          <FormConfirmation
            {...{
              formAntrian,
              ekspedisi,
              jenisOrder,
              sourceOrder,
              metodePembayaran,
              additional,
            }}
          />
        )}
      </form>
    </BlankDialog>
  );
};

export default ModalAntrian;
