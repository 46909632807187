import React from "react";
import {
  Card,
  CardContent,
  Divider,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { ChipItems } from "../../../common";
import { Info as InfoIcon } from "@mui/icons-material";

const TotalAntrian = ({ nota, onClickInfoAntrian, status }) => {
  return (
    <Card
      elevation={3}
      sx={{
        width: "100%",
        borderRadius: "5px",
        borderBottom: "0.3rem solid #787878",
        color: "#797979",
      }}
    >
      <CardContent sx={{ padding: "1rem !important", color: "#797979" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#797979",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            Total Antrian
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              {nota.length} Nota
            </Typography>
            <IconButton
              onClick={onClickInfoAntrian}
              size="small"
              aria-label="info"
              sx={{
                padding: "0",
                marginLeft: "5px",
                color:
                  status !== "berbeda" ? "rgba(47, 169, 238, 1)" : "#EE5D2F",
              }}
            >
              <InfoIcon sx={{ fontSize: "18px !important" }} />
            </IconButton>
          </div>
        </div>
        <Divider sx={{ margin: "0.7rem 0", borderWidth: "1px" }} />
        <Grid container spacing={1}>
          {nota.map((item, index) => (
            <Grid item key={index}>
              <ChipItems
                size="small"
                label={item.nota}
                backgroundcolor={item.status.warna}
                borderradius={"5px"}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalAntrian;
