import React from "react";
import { Api } from "./repository";
import { useSelector } from "react-redux";
import { TabelCashflowTahunan } from "./components";
import { PrintCashflowTahunan } from "./components/PrintCashflowTahunan";

const TahunanPage = (props) => {
  const { componentRefPT } = props;
  const { tahun } = useSelector((state) => state.cashflowTahunan);
  const getCashflowTahunan = Api.pendapatanTahunan({ tahun: tahun });

  return (
    <>
      <TabelCashflowTahunan
        data={getCashflowTahunan.data ? getCashflowTahunan.data.data : []}
        isLoading={getCashflowTahunan.isLoading}
      />
      <div style={{ display: "none" }}>
        <PrintCashflowTahunan
          ref={componentRefPT}
          data={getCashflowTahunan.data ? getCashflowTahunan.data.data : []}
          tahun={tahun}
        />
      </div>
    </>
  );
};

export default TahunanPage;
