import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { SwitchInput } from "../../../../components/controls";

const PengaturanSwitch = ({ form, dataPengaturan }) => {
  const pengaturan = Array.from(new Set(dataPengaturan));

  const renderInputField = (
    index,
    id,
    label,
    nameInput,
    defaultValueInput,
    xsInput,
    xsLabel
  ) => (
    <React.Fragment key={index}>
      {id}
      <Grid item xs={xsInput} sx={{ textAlign: "end" }}>
        <SwitchInput
          name={nameInput}
          control={form.control}
          sx={{ marginLeft: "0.5rem" }}
          defaultValue={defaultValueInput === "false" ? false : true}
        />
      </Grid>
      <Grid item xs={xsLabel} textAlign={"start"}>
        <Box
          sx={{
            borderRadius: "5px",
            backgroundColor: "#ECECEC",
            border: "0.5px solid #B4B4B4",
            boxShadow: "0px 2px 4px 0px #00000040 inset",
            padding: "8.5px 14px",
          }}
        >
          {label}
        </Box>
      </Grid>
    </React.Fragment>
  );

  return (
    <Grid container spacing={2} alignItems={"center"} marginBottom={2}>
      <Grid item xs={12}>
        <Typography fontWeight={700} color={"#797979"}>
          Akses Customer Service
        </Typography>
        <Divider />
      </Grid>
      {!pengaturan.length > 0 && (
        <>
          {renderInputField(0, "", "Skip Desainer", "", "false", 3, 3)}
          {renderInputField(1, "", "Edit Antrian", "", "false", 3, 3)}
          {renderInputField(2, "", "Edit Size", "", "false", 3, 3)}
          {renderInputField(3, "", "Delete Antrian", "", "false", 3, 3)}
          {renderInputField(4, "", "Delete Pembayaran", "", "false", 3, 3)}
        </>
      )}
      {pengaturan.length > 0 &&
        pengaturan.map((e, index) => {
          if (e.type === "bool") {
            return renderInputField(
              index,
              <input
                type="hidden"
                {...form.register(`setting.${index}.id`, {
                  valueAsNumber: true,
                })}
                value={e.id}
              />,
              <div style={{ textTransform: "capitalize" }}>
                {e.rule.replaceAll("_", " ").toLowerCase()}
              </div>,
              `setting.${index}.value`,
              e.value,
              3,
              3
            );
          }
          return null;
        })}
    </Grid>
  );
};

PengaturanSwitch.propTypes = {
  form: PropTypes.object.isRequired,
  dataPengaturan: PropTypes.array.isRequired,
};

export default PengaturanSwitch;
