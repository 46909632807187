import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper } from "@mui/material";
import { EventHandlers } from "../repository";
import CardTabel from "../../../../components/common/CardTabel";
import ModalEditProduk from "./ModalEditProduk";

const PengaturanProduk = ({
  form,
  dispatch,
  onSubmit,
  getDataJenisOrder,
  getDataSize,
  getDataAdditional,
  isLoading,
}) => {
  const dataJenisOrder = Array.from(new Set(getDataJenisOrder));
  const dataSize = Array.from(new Set(getDataSize));
  const dataAdditional = Array.from(new Set(getDataAdditional));

  return (
    <>
      <Paper sx={{ p: 2.25 }}>
        <Grid container columnSpacing={3} rowSpacing={4}>
          {dataJenisOrder
            .filter((product) => product.status)
            .map((product, index) => {
              return (
                <Grid item xs={6} xl={4} key={product.id}>
                  <CardTabel
                    isLoading={false}
                    produkID={product.id}
                    produkTitle={product.name}
                    rowsSize={dataSize}
                    rowsAdditional={dataAdditional}
                    onEdit={() => {
                      EventHandlers.handleEditProduct({
                        dispatch,
                        modal: {
                          id: product.id,
                          open: true,
                          title: "Edit Harga",
                          jenis: product.name,
                        },
                      });
                    }}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Paper>
      <ModalEditProduk
        {...{ form, dispatch, dataSize, dataAdditional, onSubmit, isLoading }}
      />
    </>
  );
};

PengaturanProduk.propTypes = {
  form: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PengaturanProduk;
