import React, { useCallback, useEffect, useRef } from "react";
import { PrintAntrianLayout } from "../../../../components/Layout/Content/Antrian";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { updateLoading } from "../../../themeSlice";

const PrintAntrian = () => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { printModal } = useSelector((state) => state.rekapNota);
  const { data, isLoading, isError } = Api.printAntrian({
    modal: printModal,
  });

  const handleClose = useCallback(() => {
    EventHandlers.handlePrintModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleClose(),
  });

  useEffect(() => {
    if (data) {
      setTimeout(() => handlePrint(), 500);
    }
  }, [data, handlePrint]);

  useEffect(() => {
    dispatch(updateLoading(isLoading));
  }, [dispatch, isLoading]);

  return (
    <div style={{ display: "none" }}>
      <PrintAntrianLayout data={data ? data.data : {}} ref={componentRef} />
    </div>
  );
};

export default PrintAntrian;
