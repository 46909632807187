import { createSlice } from "@reduxjs/toolkit";
const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const initialState = {
  tab: 0,
  toggleView: false,
  harianBulan: currentMonth,
  harianTahun: currentYear,
  bulananTahun: currentYear,
  tahunanTahun1: currentYear,
  tahunanTahun2: currentYear + 1,
};

const DashboardCardJenisOrder = createSlice({
  name: "dashboardCardJenisOrder",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
    SET_TOGGLE_VIEW: (state, action) => {
      state.toggleView = action.payload;
    },
    SET_BULAN_HARIAN: (state, action) => {
      state.harianBulan = action.payload;
    },
    SET_TAHUN_HARIAN: (state, action) => {
      state.harianTahun = action.payload;
    },
    SET_TAHUN_BULANAN: (state, action) => {
      state.bulananTahun = action.payload;
    },
    SET_TAHUN_START: (state, action) => {
      state.tahunanTahun1 = action.payload;
    },
    SET_TAHUN_END: (state, action) => {
      state.tahunanTahun2 = action.payload;
    },
  },
});

export const {
  SET_TAB,
  SET_TOGGLE_VIEW,
  SET_BULAN_HARIAN,
  SET_TAHUN_HARIAN,
  SET_TAHUN_BULANAN,
  SET_TAHUN_START,
  SET_TAHUN_END,
} = DashboardCardJenisOrder.actions;

export default DashboardCardJenisOrder.reducer;
