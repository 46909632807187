import { createSlice } from "@reduxjs/toolkit";

const scannerSlice = createSlice({
  name: "scanner",
  initialState: {
    scanOrderGroup: { id: null, open: false, title: null },
  },

  reducers: {
    SCAN_BARCODE_ORDER_GROUP: (state, action) => {
      state.scanOrderGroup = action.payload;
    },
  },
});

export const { SCAN_BARCODE_ORDER_GROUP } = scannerSlice.actions;
export default scannerSlice.reducer;
