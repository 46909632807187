import React from "react";
import {
  SET_PAGE_USER,
  SET_PAGE_SIZE_USER,
  SET_FILTER_USER,
  SET_SORT_USER,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/userSlice";
import { Config } from "../repository";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import CustomDatagrid from "../../../components/Layout/Content/CustomDatagrid";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import BlockIcon from "@mui/icons-material/Block";

const TabelUser = (props) => {
  const { form, data, isLoading } = props;
  const dispatch = useDispatch();
  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.user);
  const { username, role } = useSelector((state) => state.auth);

  const columns = Config.column.map((e) => {
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (username) {
            if (role === "root") {
              if (params.row.role === "root") {
                return [<BlockIcon />];
              } else {
                return [
                  <GridActionsCellItem
                    icon={
                      <EditIcon
                        sx={{
                          fontSize: "21px",
                          color: "#fff",
                          backgroundColor: "rgba(255, 143, 40, 1)",
                          padding: "4px",
                          borderRadius: "50%",
                        }}
                      />
                    }
                    label="Edit"
                    onClick={() => {
                      form.setValue("name", params.row.name);
                      form.setValue("username", params.row.username);
                      form.setValue("role", params.row.role);
                      form.setValue("color", params.row.color);
                      form.setValue(
                        "status",
                        params.row.status === "active" ? true : false
                      );
                      EventHandlers.handleCreateUpdate({
                        dispatch,
                        modal: {
                          id: params.row.id,
                          photo: params.row.photo,
                          open: true,
                          title: `Edit User !`,
                        },
                      });
                    }}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    icon={
                      <DeleteIcon
                        sx={{
                          fontSize: "21px",
                          color: "#fff",
                          backgroundColor: "rgba(255, 0, 0, 1)",
                          padding: "4px",
                          borderRadius: "50%",
                        }}
                      />
                    }
                    label="Delete"
                    onClick={() => {
                      EventHandlers.handleDelete({
                        dispatch,
                        modal: {
                          id: params.row.id,
                          usercode: params.row.usercode,
                          name: params.row.name,
                          role: params.row.role,
                          photo: params.row.photo,
                          open: true,
                          title: `Delete User !`,
                        },
                      });
                    }}
                    showInMenu
                  />,
                ];
              }
            }
          } else {
            return [];
          }
        },
        sortable: false,
      };
    }
    return e;
  });

  return (
    <CustomDatagrid
      rows={data}
      columns={columns}
      isLoading={isLoading}
      page={page}
      pageSize={pageSize}
      filterModel={filterModel}
      sortModel={sortModel}
      columnModel={columnModel}
      rowGroup={rowGroup}
      aggregationModel={aggregationModel}
      pinnedModel={pinnedModel}
      SET_COLUMN={SET_COLUMN}
      SET_PAGE={SET_PAGE_USER}
      SET_PAGE_SIZE={SET_PAGE_SIZE_USER}
      SET_FILTER={SET_FILTER_USER}
      SET_SORT={SET_SORT_USER}
      SET_ROW_GROUP={SET_ROW_GROUP}
      SET_AGGREGATION={SET_AGGREGATION}
      SET_PINNED_COLUMN={SET_PINNED_COLUMN}
      withNumberColumn={true}
    />
  );
};

export default TabelUser;
