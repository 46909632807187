import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Grid, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog, DataForm } from "../../../../components/Layout/Content";
import {
  CheckboxInput,
  CustomButton,
  Input,
  Select,
} from "../../../../components/controls";
import { ActionGrid, ChipItems } from "../../../../components/common";
import { useGetCachedData } from "../../../../hooks";
import { Api, EventHandlers } from "../repository";

const ModalProgress = () => {
  const dispatch = useDispatch();
  const { control, watch, handleSubmit, reset } = useForm();
  const getDataStatus = useGetCachedData({ queryKey: "status" });
  const [listStatus, setListStatus] = useState([]);
  const { socket } = useSelector((state) => state.data);
  const { role } = useSelector((state) => state.auth);
  const { progressModal } = useSelector((state) => state.checkoutSiapKirim);

  const { data, isError, isLoading } = Api.detailAntrian({
    modal: progressModal,
  });

  const handleClose = useCallback(() => {
    reset({ status_id: "", keterangan: "", confirmation: false });
    EventHandlers.handleProgressModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch, reset]);

  useEffect(() => {
    if (getDataStatus) {
      setListStatus(
        getDataStatus.data.produksi
          .filter((status) => status.status)
          .map((status) => ({
            id: status.id,
            name: status.nama,
          }))
      );
    }
  }, [getDataStatus]);

  const { mutate: mutateProgress, isPending } = Api.progressAntrian({
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    if (role === "root") {
      mutateProgress({ body: values, id: progressModal.id });
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={progressModal.open}
      handleClose={handleClose}
      title={progressModal.title}
    >
      <DataForm
        data={data ? data.data : {}}
        isLoading={isLoading}
        withStatus={false}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          sx={{ paddingBottom: 1 }}
        >
          <Grid item xs={6}>
            <ChipItems
              label={data?.data?.progress?.status?.nama || "-"}
              variant="filled"
              borderradius={"4px"}
              sx={{
                fontSize: 12,
                width: "100%",
                height: "100%",
                padding: "8.7px 12px",
                color: data?.data?.progress?.status?.warna
                  ? "white"
                  : "#797979",
                backgroundColor:
                  data?.data?.progress?.status?.warna || "#dddddd",
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Select
              disabled={listStatus.length ? false : true}
              name={"status_id"}
              control={control}
              label={"Status"}
              options={
                data?.data?.progress?.status?.id
                  ? listStatus.filter(
                      (status) => status.id !== data?.data?.progress?.status?.id
                    )
                  : listStatus
              }
              rules={{ required: "Status Harus Dipilih!" }}
              defaultValue={""}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"} fullWidth>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              fullWidth
              disabled={watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalProgress;
