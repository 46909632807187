import React from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Stack,
  Tab,
  Tabs,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  InputSearch,
  SortingLength,
  TabPanel,
  a11yProps,
} from "../components/common";
import { EventHandlers } from "../features/dikerjakan/page/repository";
import NotaPage from "../features/dikerjakan/nota/NotaPage";
import GroupPage from "../features/dikerjakan/group/GroupPage";
import {
  Api,
  EventHandlers as EventHandlersGroup,
} from "../features/dikerjakan/group/repository";

const SedangDikerjakan = () => {
  const dispatch = useDispatch();

  const { tab } = useSelector((state) => state.dikerjakan);

  const { role } = useSelector((state) => state.auth);

  const { setting } = useSelector((state) => state.data);

  const { pageSize, search, filterStatus, filterPriority } = useSelector(
    (state) => state.dikerjakanGroup
  );

  const { data } = Api.read();

  const statusOptions = Array.from(
    new Map(
      data
        ? data.data
            .filter((item) => item.status.id)
            .map((item) => [item.status.id, item.status])
        : []
    ).values()
  );

  // CHANGE TITLE
  React.useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Dikerjakan`;
      } else {
        document.title = `Dikerjakan`;
      }
    } else {
      document.title = "Dikerjakan";
    }
  }, [setting]);

  React.useEffect(() => {
    if (role === "desainer" && tab !== 0) {
      EventHandlers.handleTab({ dispatch, tab: 0 });
    }
  }, [role, tab, dispatch]);

  return (
    <Layout>
      {/* Title Container */}
      {role === "admin_produksi" && (
        <Stack
          sx={{ pb: 1.5 }}
          direction="row"
          justifyContent={tab === 1 ? "space-between" : "end"}
          alignItems="center"
        >
          {tab === 1 && (
            <>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Grid item>
                  <InputSearch
                    value={search}
                    handleChange={(search) =>
                      EventHandlersGroup.handleSearch({ dispatch, search })
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth sx={SelectStyle}>
                    <InputLabel id="demo-simple-select-label">
                      Filter Status
                    </InputLabel>
                    <Select
                      value={filterStatus}
                      label="Filter Status"
                      onChange={(event) =>
                        EventHandlersGroup.handleFilterStatus({
                          dispatch,
                          filterStatus: event.target.value,
                        })
                      }
                      size="small"
                    >
                      <MenuItem value="All">Semua Status</MenuItem>
                      {statusOptions.map((status, index) => (
                        <MenuItem value={status.id} key={index}>
                          {status.status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth sx={SelectStyle}>
                    <InputLabel id="demo-simple-select-label">
                      Filter Priority
                    </InputLabel>
                    <Select
                      value={filterPriority}
                      label="Filter Priority"
                      onChange={(event) =>
                        EventHandlersGroup.handleFilterPriority({
                          dispatch,
                          filterPriority: event.target.value,
                        })
                      }
                      size="small"
                    >
                      <MenuItem value="All">Semua Priority</MenuItem>
                      <MenuItem value={false}>Regular</MenuItem>
                      <MenuItem value={true}>Prioritas</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <SortingLength
                value={pageSize}
                handleChange={(pageSize) =>
                  EventHandlersGroup.handlePageSize({ dispatch, pageSize })
                }
              />
            </>
          )}
          <AppBar
            position="static"
            elevation={0}
            sx={{ backgroundColor: "#fff", width: "250px" }}
          >
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, value) =>
                EventHandlers.handleTab({ dispatch, tab: value })
              }
              centered
            >
              <Tab {...a11yProps(0)} label="BY NOTA" />
              <Tab {...a11yProps(1)} label="BY GROUP" />
            </Tabs>
          </AppBar>
        </Stack>
      )}

      {/* Content */}
      {role === "admin_produksi" ? (
        <>
          <TabPanel value={tab} index={0}>
            <NotaPage />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <GroupPage />
          </TabPanel>
        </>
      ) : (
        <TabPanel value={tab} index={0}>
          <NotaPage />
        </TabPanel>
      )}
    </Layout>
  );
};

const SelectStyle = {
  "& .MuiInputBase-root": {
    color: "#1976d2",
    borderRadius: "5px",
    fontSize: "13px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& .Mui-disabled": {
      borderRadius: "6px",
      backgroundColor: "#ECECEC",
      fontWeight: 700,
    },
    "& .MuiSvgIcon-root": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(25, 118, 210, 0.5)",
    marginTop: "5px",
  },
  "& .MuiInputLabel-shrink": {
    display: "none",
    color: "#1976d2",
    fontWeight: 500,
    top: "-5px",
  },
  "& legend": {
    display: "none",
  },
  "& .Mui-focused + .MuiOutlinedInput-root": {
    "& .MuiInputLabel-shrink": {
      color: "#1976d2",
    },
  },
  "& .Mui-disabled": {
    color: "#bdbdbd",
  },
  "& .MuiFormLabel-root": {
    color: "#1976d2",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 500,
    backgroundColor: "#fff",
    paddingX: "5px",
    position: "absolute",
    top: 23,
    right: 0,
  },
  "& .MuiSvgIcon-root": {
    color: "#1976d2",
  },
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
};

export default SedangDikerjakan;
