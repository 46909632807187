import {
  gridPaginationRowRangeSelector,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
} from "@mui/x-data-grid-premium";

function NumberCell(props) {
  const { id, api } = props;
  const apiRef = { current: api };
  const range = gridPaginationRowRangeSelector(apiRef);
  const rows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
  const index = rows.findIndex((r) => r.id === id);

  return index === -1 ? "-" : range.firstRowIndex + index + 1;
}

export default NumberCell;
