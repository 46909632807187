import { useCreatingData } from "../../../../hooks";

export class Api {
  static updateHargaProduct = ({ onSuccess, socket }) => {
    return useCreatingData({
      endpoint: "/pengaturan/harga_product",
      invalidated: ["size", "additional"],
      onSuccess: () => {
        onSuccess();
        socket.emit("setting_produk");
      },
    });
  };
}
