import React from "react";
import { WaveChart } from "../../../../components/common";

const ChartPencapaianJenisOrder = ({
  toggleView,
  tab,
  nominalJenisOrderHarian,
  nominalJenisOrderBulan,
  nominalJenisOrderTahun,
  qtyJenisOrderHarian,
  qtyJenisOrderBulan,
  qtyJenisOrderTahun,
  tanggal,
  bulan,
  tahun,
}) => {
  return (
    <WaveChart
      key={toggleView}
      type={"bar"}
      stacked={true}
      series={
        toggleView === false && tab === 0
          ? nominalJenisOrderHarian
          : toggleView === true && tab === 0
          ? qtyJenisOrderHarian
          : toggleView === false && tab === 1
          ? nominalJenisOrderBulan
          : toggleView === true && tab === 1
          ? qtyJenisOrderBulan
          : toggleView === false && tab === 2
          ? nominalJenisOrderTahun
          : toggleView === true && tab === 2
          ? qtyJenisOrderTahun
          : null
      }
      color={["#35C1ED", "#ED5635", "#15D14A"]}
      xaxis={tab === 0 ? tanggal : tab === 1 ? bulan : tab === 2 ? tahun : null}
      nominal={toggleView === false && true}
      pcs={toggleView === true && true}
    />
  );
};

export default ChartPencapaianJenisOrder;
