import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

// Mendapatkan tanggal pertama bulan ini
const firstDateOfMonth = dayjs().startOf("month");

// Mendapatkan tanggal terakhir bulan ini
const lastDateOfMonth = dayjs().endOf("month");

const groupRiwayatBayar = JSON.parse(localStorage.getItem("riwayatBayar"));

const initialState = {
  tanggalRiwayatBayarFilter: [firstDateOfMonth, lastDateOfMonth],
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {
    jenis_order: false,
    source_order: false,
    alamat: false,
    desainer: false,
    produksi: false,
  },
  rowGroup: groupRiwayatBayar ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actionDelete"] },
  hapusRiwayat: { id: null, open: false, title: null },
};

const RiwayatBayar = createSlice({
  name: "riwayatBayar",
  initialState,
  reducers: {
    SET_TANGGAL_RIWAYAT_BAYAR_FILTER: (state, action) => {
      state.tanggalRiwayatBayarFilter = action.payload.tanggal;
    },
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "riwayatBayar",
        JSON.stringify(action.payload.group)
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_HAPUS_RIWAYAT: (state, action) => {
      state.hapusRiwayat = action.payload.modal;
    },
  },
});

export const {
  SET_TANGGAL_RIWAYAT_BAYAR_FILTER,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_HAPUS_RIWAYAT,
} = RiwayatBayar.actions;

export default RiwayatBayar.reducer;
