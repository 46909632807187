import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Grid,
  Divider,
} from "@mui/material";
import { CheckboxInput, CustomButton } from "../../controls";
import { useForm } from "react-hook-form";
import { ActionGrid } from "../../common";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "15px",
        color: "#797979",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteDialog = ({
  open,
  handleClose,
  children,
  title,
  outsideClose = true,
  isLoading = false,
  onDelete,
}) => {
  const { control, watch, reset } = useForm();

  const onClose = (_, reason) => {
    if (!isLoading) {
      if (reason && reason === "backdropClick" && !outsideClose) return;
      if (reason && reason === "escapeKeyDown" && !outsideClose) return;
      handleClose();
    }
  };

  useEffect(() => {
    reset({ confirmation: false });
  }, [open, reset]);

  return (
    <BootstrapDialog
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      open={open}
      scroll={"body"}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers={true} sx={{ padding: "1rem !important" }}>
        {children}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Data yang dihapus tidak dapat dikembalikan !"}
            rules={{ required: true }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          // padding={"0.5rem 1rem"}
          isLoading={isLoading}
          actionStart={
            <CustomButton onClick={onClose} warna={"next"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isLoading}
              warna={"clear"}
              onClick={onDelete}
              disabled={watch("confirmation") ? false : true}
            >
              Delete
            </CustomButton>
          }
        />
      </DialogContent>
    </BootstrapDialog>
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
};

export default DeleteDialog;
