import React, { useRef } from "react";
import { styled } from "@mui/system";
import { Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { InputAdornment, IconButton } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";

const CustomDateRangePicker = styled(DateRangePicker)(() => ({
  "& .MuiInputBase-root": {
    paddingRight: 0.5,
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: 13,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    border: "1px solid rgba(25, 118, 210, 0.5)",
  },
  "& .MuiInputBase-input": {
    color: "#6C6C6C",
  },
  "& .MuiTouchRipple-root": {
    borderRadius: 0,
  },
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
}));

const WaveDateRange = ({ name, control, rules, defaultValue, onChange }) => {
  // for daterange
  const dateRangePickerRef = useRef(null);

  const handleIconClick = () => {
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.focus();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={() => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDateRangePicker
            slots={{ field: SingleInputDateRangeField }}
            defaultValue={defaultValue}
            onChange={onChange}
            slotProps={{
              textField: {
                size: "small",
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton size="small" onClick={handleIconClick}>
                        <DateRangeIcon sx={{ color: "#1976d2" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default WaveDateRange;
