import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const firstDateOfMonth = dayjs().startOf("month");
const lastDateOfMonth = dayjs().endOf("month");
const groupRekapReject = JSON.parse(localStorage.getItem("rekapReject"));

const initialState = {
  tanggalRejectFilter: [firstDateOfMonth, lastDateOfMonth],
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {},
  rowGroup: groupRekapReject ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actions"] },
  printModal: { id: null, open: false, title: null },
  detailModal: { id: null, open: false, title: null },
  detailProgressModal: { id: null, open: false, title: null },
  pembayaranModal: { id: null, open: false, title: null },
  deletePembayaranModal: { id: null, open: false, title: null },
  editReject: { id: null, open: false, title: null },
  hapusReject: { id: null, open: false, title: null },
  refetch_status: false,
};

const RekapReject = createSlice({
  name: "rekapReject",
  initialState,
  reducers: {
    SET_TANGGAL_REJECT_FILTER: (state, action) => {
      state.tanggalRejectFilter = action.payload.tanggal;
    },
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem("rekapReject", JSON.stringify(action.payload.group));
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_PRINT_MODAL: (state, action) => {
      state.printModal = action.payload.modal;
    },
    SET_DETAIL_MODAL: (state, action) => {
      state.detailModal = action.payload.modal;
    },
    SET_DETAIL_PROGRESS_MODAL: (state, action) => {
      state.detailProgressModal = action.payload.modal;
    },
    SET_PEMBAYARAN_MODAL: (state, action) => {
      state.pembayaranModal = action.payload.modal;
    },
    SET_DELETE_PEMBAYARAN_MODAL: (state, action) => {
      state.deletePembayaranModal = action.payload.modal;
    },
    SET_EDIT_REJECT: (state, action) => {
      state.editReject = action.payload.modal;
    },
    SET_HAPUS_REJECT: (state, action) => {
      state.hapusReject = action.payload.modal;
    },
    SET_REFETCH_STATUS: (state, action) => {
      state.refetch_status = action.payload.refetch_status;
    },
  },
});

export const {
  SET_TANGGAL_REJECT_FILTER,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_PRINT_MODAL,
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_DELETE_PEMBAYARAN_MODAL,
  SET_EDIT_REJECT,
  SET_HAPUS_REJECT,
  SET_REFETCH_STATUS,
} = RekapReject.actions;

export default RekapReject.reducer;
