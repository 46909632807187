import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Paper } from "@mui/material";

const Sortinglength = ({
  handleChange,
  value,
  options = [10, 25, 50, 100],
}) => {
  return (
    <Paper elevation={0} sx={{ margin: "0 0.5rem" }}>
      <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          IconComponent={() => null}
          sx={{
            "& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall":
              {
                paddingRight: "14px",
                color: "#808080",
                textAlign: "center",
              },
            ".MuiOutlinedInput-notchedOutline": {
              borderRadius: "5px",
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiMenuItem-root": {
              paddingX: "0.5rem",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              sx={{ paddingX: "0.5rem", justifyContent: "center" }}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

export default Sortinglength;
