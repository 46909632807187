import { createSlice } from "@reduxjs/toolkit";
const pembayaranTerbaru = JSON.parse(localStorage.getItem("lastPembayaran"));

const initialState = {
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {},
  rowGroup: pembayaranTerbaru ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: [] },
};

const TabelGrafikPembayaran = createSlice({
  name: "tabelGrafikPembayaran",
  initialState,
  reducers: {
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "lastPembayaran",
        JSON.stringify(action.payload.group)
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
  },
});

export const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} = TabelGrafikPembayaran.actions;

export default TabelGrafikPembayaran.reducer;
