import {
  SET_PAGE_USER,
  SET_PAGE_GRID_USER,
  SET_SEARCH,
  SET_PAGE_SIZE_USER,
  SET_PAGE_SIZE_GRID_USER,
  SET_TOGGLE_VIEW,
  SET_CREATE_UPDATE_USER,
  SET_DELETE_USER,
  SET_ROLE_USER,
  SET_STATUS_USER,
} from "../domain/userSlice";

export class EventHandlers {
  static handleToggleView = ({ dispatch, view }) => {
    dispatch(SET_TOGGLE_VIEW(view));
  };
  static handleCreateUpdate = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_CREATE_UPDATE_USER({ modal }));
  };
  static handleDelete = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_DELETE_USER({ modal }));
  };
  static handleRole = ({ dispatch, filterRole }) => {
    dispatch(SET_ROLE_USER(filterRole));
  };
  static handleStatus = ({ dispatch, filterStatus }) => {
    dispatch(SET_STATUS_USER(filterStatus));
  };

  static handleChangePage = ({ dispatch, page }) => {
    dispatch(SET_PAGE_USER({ page }));
  };

  static handlePageGridChange = ({ dispatch, pageGrid }) => {
    dispatch(SET_PAGE_GRID_USER({ pageGrid }));
  };

  static handleSearch = ({ dispatch, search }) => {
    dispatch(SET_SEARCH({ search }));
  };

  static handlePageSize = ({ dispatch, pageSize }) => {
    dispatch(SET_PAGE_SIZE_USER({ pageSize }));
  };

  static handlePageSizeGrid = ({ dispatch, pageSizeGrid }) => {
    dispatch(SET_PAGE_SIZE_GRID_USER({ pageSizeGrid }));
  };
}
