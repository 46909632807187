const dateFormat = (props, type, separator = "/") => {
  const tgl = new Date(props);
  const yyyy = tgl.getFullYear();
  let mm = tgl.getMonth() + 1;
  let dd = tgl.getDate();
  let hour = tgl.getHours();
  let minute = tgl.getMinutes();
  let second = tgl.getSeconds();
  let milli = ((tgl.getMilliseconds() / 10) | 0) % 100;

  if (hour < 10) hour = "0" + hour;
  if (minute < 10) minute = "0" + minute;
  if (second < 10) second = "0" + second;
  if (milli < 10) milli = "0" + milli;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  let formatted = "";
  if (type === "full") {
    formatted =
      dd +
      separator +
      mm +
      separator +
      yyyy +
      " " +
      hour +
      ":" +
      minute +
      ":" +
      second;
  } else {
    formatted = dd + separator + mm + separator + yyyy;
  }
  return formatted;
};

export default dateFormat;
