import { Config } from ".";
import {
  useCreatingData,
  useFetchingData,
  useDeletingData,
  useUpdatingData,
} from "../../../../hooks";
export class Api {
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}`,
    });
  };

  static create = ({ form, handleClose }) => {
    return useCreatingData({
      endpoint: Config.variable.endpoint,
      invalidated: [`${Config.variable.endpoint}`],
      onSuccess: () => {
        handleClose();
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static update = ({ form, handleClose }) => {
    return useUpdatingData({
      endpoint: Config.variable.endpoint,
      invalidated: [`${Config.variable.endpoint}`],
      onSuccess: () => {
        handleClose();
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static delete = ({ handleClose }) => {
    return useDeletingData({
      endpoint: Config.variable.endpoint,
      onSuccess: () => {
        handleClose();
      },
      invalidated: [`${Config.variable.endpoint}`],
    });
  };
}
