import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
};

const ManageGroupPage = createSlice({
  name: "manageGroupPage",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { SET_TAB } = ManageGroupPage.actions;

export default ManageGroupPage.reducer;
