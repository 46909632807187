import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const Select = (props) => {
  const {
    name,
    control,
    label,
    options,
    rules,
    defaultValue,
    disabled = false,
    size = "small",
    fullwidth = true,
    width,
    wave,
    withIcon,
    noshrinklabel,
    onReset,
    ...rest
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          disabled={disabled}
          fullWidth={fullwidth}
          size={size}
          {...(error && { error: true })}
          sx={{
            width: width,
            minWidth: 90,
            "& .MuiInputBase-root": {
              color: wave ? "#1976d2" : "#797979",
              borderRadius: "5px",
              boxShadow: wave ? "" : "0px 2px 4px 0px #00000040 inset",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& .Mui-disabled": {
                borderRadius: "6px",
                backgroundColor: "#ECECEC",
                boxShadow: wave ? "" : "0px 2px 4px 0px #00000040 inset",
                fontWeight: 700,
              },
              "& .MuiSvgIcon-root": {
                boxShadow: "none",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: wave ? "1px solid rgba(25, 118, 210, 0.5)" : "",
              marginTop: "5px",
            },
            "& .MuiInputLabel-shrink": {
              display: withIcon || noshrinklabel ? "none" : "",
              color: wave ? "#1976d2" : "",
              fontWeight: 500,
              top: "-5px",
            },
            "& legend": {
              display: "none",
            },
            "& .Mui-focused + .MuiOutlinedInput-root": {
              "& .MuiInputLabel-shrink": {
                color: wave ? "#1976d2" : "",
              },
            },
            "& .Mui-disabled": {
              color: "#bdbdbd",
            },
            "& .MuiFormLabel-root": {
              color: wave ? "#1976d2" : "",
            },
            "& .MuiFormHelperText-root": {
              fontWeight: 500,
              backgroundColor: "#fff",
              paddingX: "5px",
              position: "absolute",
              top: 23,
              right: 0,
            },
            "& .MuiSvgIcon-root": {
              color: wave ? "#1976d2" : "#797979",
            },
            "&:hover": {
              backgroundColor: "rgba(25, 118, 210, 0.04)",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: wave ? "#1976d2 !important" : "",
              },
            },
          }}
        >
          {withIcon ? (
            <InputAdornment
              position="start"
              sx={{
                height: 1,
                position: withIcon && "absolute",
                top: withIcon && 5,
                left: withIcon && 9,
                "& .MuiSvgIcon-root": {
                  color: wave ? "#1976d2 !important" : "#797979",
                },
              }}
            >
              {withIcon}
            </InputAdornment>
          ) : (
            <InputLabel sx={{ display: "flex" }}>
              {`${label} ${rules["required"] ? "*" : ""}`}
            </InputLabel>
          )}

          {field.value !== "" && onReset && (
            <InputAdornment
              position="end"
              sx={{
                zIndex: 1,
                height: 1,
                position: onReset && "absolute",
                top: onReset && 5,
                right: onReset && 5,
                "& .MuiSvgIcon-root": {
                  color: "#FF1E1E !important",
                },
              }}
            >
              <IconButton
                onClick={onReset}
                sx={{
                  backgroundColor: "#fff",
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "#fff",

                    "&:hover": {
                      "& .MuiSvgIcon-root": {
                        color: "rgba(255, 30, 30, 0.8) !important",
                      },
                    },
                  },
                }}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          )}

          <MuiSelect
            {...field}
            {...rest}
            label={`${label} ${rules["required"] ? "*" : ""}`}
            displayEmpty={withIcon ? true : false}
            inputProps={withIcon && { "aria-label": "Without label" }}
            sx={{
              "& .MuiSelect-select": {
                paddingLeft: withIcon ? "2rem" : "",
              },
            }}
          >
            <MenuItem value={withIcon ? "" : null} disabled>
              {withIcon
                ? `${label} ${rules["required"] ? "*" : ""}`
                : `Pilih ${label}`}
            </MenuItem>
            {options.length > 0 &&
              options.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  disabled={item.disabled ?? false}
                >
                  {item.name}
                </MenuItem>
              ))}
          </MuiSelect>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default Select;
