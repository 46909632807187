import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import { BlankDialog } from "../../../../components/Layout/Content";
import { TotalAntrianCard } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";

const PopupTotalAntrian = () => {
  const dispatch = useDispatch();
  const { cardTotalAntrian } = useSelector((state) => state.manageGroup);
  const handleClose = () => {
    EventHandlers.handleCardTotalAntrian({
      dispatch,
      modal: { id: null, open: false, title: null, data: [] },
    });
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={cardTotalAntrian.open}
      handleClose={handleClose}
      title={cardTotalAntrian.title}
    >
      <TotalAntrianCard data={cardTotalAntrian.data} />
    </BlankDialog>
  );
};

export default PopupTotalAntrian;
