import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/tabelGrafikPembayaranSlice";
import { Config } from "../repository";

const TabelPembayaranTerbaru = ({ data, isLoading }) => {
  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.tabelGrafikPembayaran);

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns: Config.column,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      setDensity={"compact"}
      showQuickFilter={false}
      withNumberColumn={true}
      sx={{
        borderWidth: "1px 0 0 0",
        borderColor: "#ABABAB",
        borderStyle: "solid",
        boxShadow: "none",
        borderRadius: 0,
      }}
    />
  );
};

TabelPembayaranTerbaru.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
  statusList: PropTypes.array,
};

export default TabelPembayaranTerbaru;
