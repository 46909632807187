import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Slide,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, hideCloseIcon, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "600",
        lineHeight: "15px",
        color: "#797979",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {!hideCloseIcon && onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translateY(-50%)",
            color: "#343434",
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BlankDialog = (props) => {
  const {
    open,
    handleClose,
    maxWidth = "sm",
    children,
    title = "Detail",
    action,
    outsideClose = true,
    isLoading = false,
    hideCloseIcon,
  } = props;
  const onClose = (_, reason) => {
    if (!isLoading) {
      if (reason && reason === "backdropClick" && !outsideClose) return;
      if (reason && reason === "escapeKeyDown" && !outsideClose) return;
      handleClose();
    }
  };
  return (
    <BootstrapDialog
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      scroll={"body"}
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={onClose}
        hideCloseIcon={hideCloseIcon}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers={true} sx={{ padding: "1rem !important" }}>
        {children}
      </DialogContent>
      {action}
    </BootstrapDialog>
  );
};

BlankDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  action: PropTypes.node,
};

export default BlankDialog;
