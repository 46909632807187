import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { axiosInstance } from "../config/api";
import { setNotification } from "../features/notificationSlice";

const useFetchingData = (props) => {
  const {
    endpoint,
    enabled = true,
    staleTime = config.defaultStaleTime,
    gcTime = config.defaultGcTime,
    params,
    type = "protected",
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useQuery({
    queryKey: params ? [endpoint, params] : [endpoint],
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(endpoint, params);
        return response.data || [];
      } catch (error) {
        dispatch({
          type: setNotification,
          payload: {
            snackbarOpen: true,
            snackbarType: error?.type,
            snackbarMessage: error?.message,
          },
        });
        if (error?.code === 401) {
          navigate("/login", { replace: true });
        }
      }
    },
    refetchOnWindowFocus: false,
    staleTime,
    gcTime,
    enabled: useEnabled(type, enabled),
  });
};

const useEnabled = (type, enabled) => {
  const { username } = useSelector((state) => state.auth);
  if (type === "protected") {
    if (username) {
      return enabled;
    } else {
      return false;
    }
  }
  return enabled;
};

export default useFetchingData;
