import React, { useState, useEffect } from "react";
import { Collapse, Divider, Grid, Typography } from "@mui/material";
import CollapseButton from "../../../../components/common/CollapseButton";
import { BlankDialog } from "../../../../components/Layout/Content";
import CustomButton from "../../../../components/controls/CustomButton";
import { useSelector } from "react-redux";
import { NumberInput, handleFocus } from "../../../../components/controls";
import { CheckboxInput } from "../../../../components/controls";
import { EventHandlers } from "../repository";
import { ActionGrid } from "../../../../components/common";

const ModalEditProduk = (props) => {
  const { form, dispatch, dataSize, dataAdditional, onSubmit, isLoading } =
    props;

  const [size, setSize] = useState(true);
  const [additional, setAdditional] = useState(true);
  const { editProduct } = useSelector((state) => state.pengaturanProduk);

  useEffect(() => {
    if (!editProduct.open) {
      form.reset();
    }
  }, [editProduct.open, form]);

  const handleClose = () => {
    dataSize.forEach((sizeItem, index) => {
      sizeItem.harga.forEach((harga) => {
        if (harga.jenisorder_id === editProduct.id) {
          form.setValue(`size.${index}.size_id`, null);
          form.setValue(`size.${index}.hpp`, 0);
          form.setValue(`size.${index}.harga`, 0);
          form.setValue(`size.${index}.jenisorder_id`, null);
        }
      });
    });
    dataAdditional.forEach((additionalItem, index) => {
      additionalItem.harga.forEach((harga) => {
        if (harga.jenisorder_id === editProduct.id) {
          form.setValue(`additional.${index}.additional_id`, null);
          form.setValue(`additional.${index}.hpp`, 0);
          form.setValue(`additional.${index}.harga`, 0);
          form.setValue(`additional.${index}.jenisorder_id`, null);
        }
      });
    });
    form.setValue("confirmation", false);
    EventHandlers.handleEditProduct({
      dispatch,
      modal: { id: null, open: false, title: null },
    });
  };

  const handleFormSize = () => {
    setSize(!size);
  };
  const handleFormAdditional = () => {
    setAdditional(!additional);
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={editProduct.open}
      handleClose={handleClose}
      isLoading={isLoading}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 1rem",
          }}
        >
          <span>{editProduct.title}</span>
          <span>{editProduct.jenis}</span>
        </div>
      }
      hideCloseIcon={true}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* Form Size */}
        <CollapseButton
          onClick={handleFormSize}
          checked={size}
          expandLabel="Size"
          collapseLabel="Size"
          sx={{
            position: "absolute",
            marginLeft: "-2rem",
            left: "50%",
            top: 34,
          }}
        />
        <Collapse in={size} sx={{ marginY: size ? "1rem" : "0.5rem" }}>
          <Grid container spacing={2} alignItems={"center"}>
            {dataSize
              .filter((sizeItem) => sizeItem.status)
              .map((sizeItem, index) => (
                <React.Fragment key={index}>
                  {sizeItem.harga
                    .filter((harga) => harga.jenisorder_id === editProduct.id)
                    .map((harga, hargaIndex) => (
                      <React.Fragment key={hargaIndex}>
                        <input
                          type="hidden"
                          {...form.register(`size.${index}.size_id`)}
                          defaultValue={sizeItem.id}
                        />
                        <input
                          type="hidden"
                          {...form.register(`size.${index}.jenisorder_id`)}
                          defaultValue={harga.jenisorder_id}
                        />
                        <Grid item xs={4} textAlign={"end"}>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: "#797979",
                            }}
                          >
                            {sizeItem.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            rules={{ required: false }}
                            name={`size.${index}.hpp`}
                            control={form.control}
                            label={"HPP"}
                            defaultValue={harga.hpp}
                            onFocus={handleFocus}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            rules={{ required: false }}
                            name={`size.${index}.harga`}
                            control={form.control}
                            label={"Harga Jual"}
                            defaultValue={harga.harga}
                            onFocus={handleFocus}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </Grid>
        </Collapse>

        {/* Form Additional */}
        <Divider sx={{ marginBottom: additional ? "1.5rem" : 0 }}>
          <CollapseButton
            onClick={handleFormAdditional}
            checked={additional}
            expandLabel="Additional"
            collapseLabel="Additional"
          />
        </Divider>
        <Collapse in={additional}>
          <Grid container spacing={2} alignItems={"center"}>
            {dataAdditional
              .filter((additionalItem) => additionalItem.status)
              .map((additionalItem, index) => (
                <React.Fragment key={index}>
                  {additionalItem.harga
                    .filter((harga) => harga.jenisorder_id === editProduct.id)
                    .map((harga, hargaIndex) => (
                      <React.Fragment key={hargaIndex}>
                        <input
                          type="hidden"
                          {...form.register(
                            `additional.${index}.additional_id`
                          )}
                          value={additionalItem.id}
                        />
                        <input
                          type="hidden"
                          {...form.register(
                            `additional.${index}.jenisorder_id`
                          )}
                          value={harga.jenisorder_id}
                        />
                        <Grid item xs={4} textAlign={"end"}>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 700,
                              color: "#797979",
                            }}
                          >
                            {additionalItem.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            rules={{ required: false }}
                            name={`additional.${index}.hpp`}
                            control={form.control}
                            label={"HPP"}
                            defaultValue={harga.hpp}
                            onFocus={handleFocus}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput
                            rules={{ required: false }}
                            name={`additional.${index}.harga`}
                            control={form.control}
                            label={"Harga Jual"}
                            defaultValue={harga.harga}
                            onFocus={handleFocus}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </Grid>
        </Collapse>

        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isLoading}
          actionStart={
            <CustomButton
              onClick={handleClose}
              warna={"clear"}
              widthbtn={"100%"}
            >
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isLoading}
              warna={"next"}
              widthbtn={"100%"}
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalEditProduk;
