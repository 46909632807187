import React, { useEffect, useState } from "react";
import { Collapse, Grid, IconButton, Typography } from "@mui/material";
import TabelAntrianGroup from "../../DataGroup/TabelAntrianGroup";
import { useSelector } from "react-redux";
import { CallToAction, Delete } from "@mui/icons-material";
import { dateFormatter } from "../../../../controls";
import { NumericText } from "../../../../common";

const colRiwayat = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    type: "actions",
    filterable: false,
    display: "flex",
    renderHeader: () => <strong>No</strong>,
    renderCell: (params) => (
      <>
        <Typography fontSize={"10px"} textAlign={"center"}>
          {params.value}
        </Typography>
      </>
    ),
  },
  {
    field: "tanggal",
    flex: 1,
    minWidth: 140,
    hideable: false,
    headerName: "Tanggal Bayar",
    display: "flex",
    renderHeader: () => <strong>Tanggal Bayar</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography fontSize={"10px"}>
            {dateFormatter({
              date: new Date(params.value),
              withHari: true,
              withMonth: "simple",
              withTimes: true,
              yearLength: "full",
            })}
          </Typography>
        </>
      );
    },
  },
  {
    field: "user",
    flex: 1,
    sortable: false,
    minWidth: 110,
    display: "flex",
    renderHeader: () => <strong>Customer Service</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography fontSize={"10px"}>{params.value}</Typography>
        </>
      );
    },
  },
  {
    field: "jumlah_bayar",
    flex: 1,
    type: "number",
    sortable: false,
    minWidth: 70,
    display: "flex",
    renderHeader: () => <strong>Nominal</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography fontSize={"10px"}>
            <NumericText value={params.value} prefix={false} />
          </Typography>
        </>
      );
    },
  },
  {
    field: "metode_pembayaran",
    flex: 1,
    sortable: false,
    minWidth: 60,
    display: "flex",
    renderHeader: () => <strong>Metode</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography fontSize={"10px"}>{params.value}</Typography>
        </>
      );
    },
  },
  {
    field: "keterangan",
    flex: 1,
    sortable: false,
    minWidth: 100,
    display: "flex",
    renderHeader: () => <strong>Keterangan</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography fontSize={"10px"}>{params.value ?? "-"}</Typography>
        </>
      );
    },
  },
];

const RiwayatPembayaran = ({
  in: open,
  dataPembayaran,
  handleDeletePembayaran,
}) => {
  const { role } = useSelector((state) => state.auth);
  const { setting } = useSelector((state) => state.data);

  const [statusDeletePembayaran, setStatusDeletePembayaran] = useState(false);
  const [columns, setColumns] = useState(colRiwayat);

  useEffect(() => {
    const getStatusDeletePembayaran = setting.find(
      (item) => item.rule === "DELETE_PEMBAYARAN"
    );
    setStatusDeletePembayaran(getStatusDeletePembayaran.value === "false");
  }, [setting]);

  useEffect(() => {
    const updatedColumns = [...colRiwayat];
    if (role === "customer_service" || role === "root") {
      const indexToInsertAfter = updatedColumns.findIndex(
        (e) => e.field === "keterangan"
      );
      if (
        indexToInsertAfter !== -1 &&
        !updatedColumns.some((e) => e.field === "actions")
      ) {
        updatedColumns.splice(indexToInsertAfter + 1, 0, {
          field: "actions",
          headerName: "Aksi Proses",
          hideable: false,
          filterable: false,
          sortable: false,
          align: "center",
          headerAlign: "center",
          minWidth: 50,
          width: 50,
          maxWidth: 50,
          renderHeader: () => <CallToAction />,
          renderCell: (params) => (
            <div>
              <IconButton
                size="small"
                variant="outlined"
                color="error"
                onClick={() => handleDeletePembayaran(params.row)}
                disabled={role !== "root" ? statusDeletePembayaran : false}
              >
                <Delete />
              </IconButton>
            </div>
          ),
        });
      }
    }
    setColumns(updatedColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, statusDeletePembayaran]);

  const rowsRiwayatData = dataPembayaran.map((row, index) => ({
    ...row,
    no: index + 1, // Menambahkan properti id untuk mendapatkan nomor otomatis
  }));

  return (
    <Collapse in={open}>
      <Grid container mt={1.5} sx={{ backgroundColor: "#F3F7F8" }}>
        <TabelAntrianGroup
          rows={rowsRiwayatData}
          column={columns}
          fontSize={"10px"}
        />
      </Grid>
    </Collapse>
  );
};

export default RiwayatPembayaran;
