import dayjs from "dayjs";
import * as React from "react";
import { useSelector } from "react-redux";
import { Grid, Stack, Typography } from "@mui/material";
import { NumericText } from "../../../../components/common";
import logoWave from "../../../../assets/icon/logo/waveprint.svg";
import config from "../../../../config";

const DatePrint = dayjs();
const formattedDate = DatePrint.format("DD-MM-YYYY HH:mm");

const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const PrintReject = React.forwardRef((props, ref) => {
  const { data, tanggal } = props;
  const { setting } = useSelector((state) => state.data);
  const { name, usercode } = useSelector((state) => state.auth);
  const [pengaturan, setPengaturan] = React.useState({
    nama: "",
    alamat: "",
    logo: "",
  });

  const formattedStartDate =
    dayjs(tanggal[0]).format("DD") +
    " " +
    monthNames[dayjs(tanggal[0]).month()] +
    " " +
    dayjs(tanggal[0]).format("YYYY");
  const formattedEndDate =
    dayjs(tanggal[1]).format("DD") +
    " " +
    monthNames[dayjs(tanggal[1]).month()] +
    " " +
    dayjs(tanggal[1]).format("YYYY");

  const customHead = {
    padding: "0.7rem",
  };

  const customBody = {
    padding: "0.5rem 0.7rem",
  };

  // Hitung total pembayaran
  const totalReject = data.length;
  const totalNominal = data.reduce(
    (total, item) => total + (item.nominal_reject || 0),
    0
  );

  React.useEffect(() => {
    if (setting.length) {
      const nama = setting.find((e) => e.rule === "NAMA_TOKO");
      const alamat = setting.find((e) => e.rule === "ALAMAT");
      const logo = setting.find((e) => e.rule === "LOGO_NOTA");
      if (nama) {
        setPengaturan((old) => ({ ...old, nama: nama.value }));
      }
      if (alamat) {
        setPengaturan((old) => ({ ...old, alamat: alamat.value }));
      }
      if (logo) {
        setPengaturan((old) => ({ ...old, logo: logo.value }));
      }
    }
  }, [setting]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={1}
      >
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <div style={{ marginRight: "1rem" }}>
            <img
              src={config.apiUrl + "/storage/logo/" + pengaturan.logo}
              alt="Logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
              Rekap Antrian
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{pengaturan.nama}</Typography>
            <Typography sx={{ fontSize: 12 }}>{pengaturan.alamat}</Typography>
          </div>
        </Grid>
        <Grid item sx={{ fontSize: 12 }}>
          <Stack direction="row" spacing={1}>
            <Stack>
              <div style={{ fontWeight: 700 }}>Rekap</div>
              <div>Admin</div>
              <div>Jangka Waktu</div>
            </Stack>
            <Stack>
              <div>:</div>
              <div>:</div>
              <div>:</div>
            </Stack>
            <Stack>
              <div style={{ fontWeight: 700 }}>Laporan Reject</div>
              <div>
                {usercode} {name}
                {/* {name.slice(0, 8)}
                {name.length > 8 && '...'} */}
              </div>
              <div>
                {formattedStartDate} - {formattedEndDate}
              </div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <table
        width={"100%"}
        style={{ paddingTop: 5, paddingBottom: 8, borderCollapse: "collapse" }}
      >
        <thead
          style={{
            backgroundColor: "#EEF2F7",
            borderBottom: "2px solid #ADA4A4",
            paddingTop: 10,
          }}
        >
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <td align="center" width={"5%"} style={customHead}>
              No
            </td>
            <td align="left" width={"13%"} style={customHead}>
              Nota
            </td>
            <td align="left" width={"17%"} style={customHead}>
              Admin Produksi
            </td>
            <td align="left" width={"20%"} style={customHead}>
              Tanggal Checkout
            </td>
            <td align="right" width={"15%"} style={customHead}>
              Nominal Reject
            </td>
            <td align="left" width={"30%"} style={customHead}>
              Keterangan Reject
            </td>
          </tr>
        </thead>

        <tbody>
          {data.length > 0 ? (
            data.map((e, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "0.1px solid #cfcfcf",
                }}
              >
                <td align="center" style={customBody}>
                  {index + 1}
                </td>
                <td align="left" style={customBody}>
                  <b>{e.nota}</b>
                </td>
                <td align="left" style={customBody}>
                  {e.produksi}
                </td>
                <td align="left" style={customBody}>
                  {e.tanggal_reject}
                </td>
                <td align="right" style={customBody}>
                  <NumericText
                    thousandSeparator
                    value={e.nominal_reject}
                    prefix={true}
                    displayType="text"
                  />
                </td>
                <td align="left" style={customBody}>
                  {e.keterangan_reject.length > 35
                    ? e.keterangan_reject.substring(0, 35) + "..."
                    : e.keterangan_reject}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}></td>
            </tr>
          )}
        </tbody>
      </table>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "e",
          justifyContent: "center",
          backgroundColor: "#EEF2F7",
          padding: "0.5rem 1rem",
          fontWeight: 700,
          borderBottom: "2px solid #ADA4A4",
          textAlign: "center",
        }}
      >
        <Grid item xs={4}>
          Total Pembayaran :{" "}
          <NumericText
            thousandSeparator
            value={totalReject}
            suffix=" Nota"
            displayType="text"
          />
        </Grid>
        <Grid item xs={4}>
          Total Nominal :{" "}
          <NumericText
            thousandSeparator
            value={totalNominal}
            prefix={true}
            displayType="text"
          />
        </Grid>
      </Grid>

      <Grid container justifyContent={"space-between"} padding={"1rem 0 0 0"}>
        <Typography sx={{ fontSize: 8 }}>
          Printed on : {formattedDate}
        </Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </Grid>

      {/* Mengatur pemisahan halaman setelah tabel */}
      <div style={{ pageBreakAfter: "always", marginTop: "2rem" }}></div>
    </div>
  );
});
