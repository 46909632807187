import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import { CustomDateRange } from "../../../../components/controls";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/riwayarBayarSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardRiwayatBayar from "./TabelRiwayatBayar/FooterCardRiwayatBayar";

const TabelRiwayatBayar = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    tanggalRiwayatBayarFilter,
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.riwayatBayar);

  const { role } = useSelector((state) => state.auth);

  const columns = Config.column.map((e) => {
    return e;
  });

  if (role === "root") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "keterangan"
    );
    if (indexToInsertAfter !== -1) {
      columns.splice(indexToInsertAfter + 1, 0, {
        field: "actionDelete",
        width: 60,
        maxWidth: 60,
        hideable: false,
        filterable: false,
        sortable: false,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        groupable: false,
        renderHeader: () => <DeleteIcon sx={{ color: "#797979" }} />,
        renderCell: (params) => {
          if (typeof params.id === "number") {
            return (
              <IconButton
                onClick={() =>
                  EventHandlers.handleHapusRiwayat({
                    dispatch,
                    modal: {
                      id: params.id,
                      open: true,
                      title: "Delete Antrian !",
                      data: params.row,
                    },
                  })
                }
                sx={{
                  "&:hover svg": {
                    backgroundColor: "rgba(255, 0, 0, 1)",
                  },
                }}
              >
                <DeleteIcon
                  sx={{
                    fontSize: "21px",
                    color: "#fff",
                    backgroundColor: "rgba(159, 159, 159, 1)",
                    padding: "4px",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            );
          }
        },
      });
    }
  }

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
      getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
      footerComponent={({ finalData }) => {
        return (
          <FooterCardRiwayatBayar data={finalData} isLoading={isLoading} />
        );
      }}
      customToolbar={
        <Box sx={{ p: 1 }}>
          <CustomDateRange
            title="Filter Tanggal Bayar"
            defaultValue={tanggalRiwayatBayarFilter}
            onChange={(values) =>
              EventHandlers.handleFilterTanggal({
                dispatch,
                queryClient,
                tanggal: values,
              })
            }
          />
        </Box>
      }
    />
  );
};

TabelRiwayatBayar.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TabelRiwayatBayar;
