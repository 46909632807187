import { createSlice } from "@reduxjs/toolkit";

const pengaturanTarget = JSON.parse(localStorage.getItem("pengaturanTarget"));

const initialState = {
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {},
  rowGroup: pengaturanTarget ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actions"] },
  createUpdateModal: { id: null, open: false, title: null },
  hapusTarget: { id: null, open: false, title: null },
};

const PengaturanTarget = createSlice({
  name: "pengaturanTarget",
  initialState,
  reducers: {
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "pengaturanTarget",
        JSON.stringify(action.payload.group),
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_CREATE_UPDATE_TARGET: (state, action) => {
      state.createUpdateModal = action.payload.modal;
    },
    SET_HAPUS_TARGET: (state, action) => {
      state.hapusTarget = action.payload.modal;
    },
  },
});

export const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_CREATE_UPDATE_TARGET,
  SET_HAPUS_TARGET,
} = PengaturanTarget.actions;

export default PengaturanTarget.reducer;
