import { Typography } from "@mui/material";

const Footer = () => {
  return (
    <footer>
      <Typography
        sx={{
          paddingTop: "30px",
          fontWeight: 600,
          color: "gray",
          textDecoration: "none",
          fontSize: 10,
          textAlign: "center",
        }}
      >
        Copyright TenTackle © 2024 - All Right Reserveds
      </Typography>
    </footer>
  );
};

export default Footer;
