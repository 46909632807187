import { useCreatingData, useFetchingData } from "../../../../hooks";

export class Api {
  static updateSetting = ({ socket }) => {
    return useCreatingData({
      endpoint: "/pengaturan/setting",
      invalidated: ["setting"],
      onSuccess: () => {
        socket.emit("setting_toko");
        return;
      },
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    });
  };

  static setting = () => {
    return useFetchingData({
      endpoint: "setting",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
      type: "non_protected",
    });
  };
}
