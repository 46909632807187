import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/pencapaianNotaSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardPencapaian from "./TabelPencapaian/FooterCardPencapaian";
import ActionButton from "./TabelPencapaian/ActionButton";
import { Box } from "@mui/material";
import { CustomDateRange } from "../../../../components/controls";
import { useQueryClient } from "@tanstack/react-query";

const TabelPencapaian = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
    tanggalPencapaianFilter,
  } = useSelector((state) => state.pencapaianNota);

  const { role } = useSelector((state) => state.auth);

  const statusOptions = Array.from(new Set(data.map((item) => item.status)));

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "preview") {
      return {
        ...e,
        getActions: (params) =>
          ActionButton.getActionPreview({
            role,
            params,
            dispatch,
          }),
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            return ActionButton.getActionOpsi({ role, params, dispatch });
          }
          return [];
        },
      };
    }
    return e;
  });

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
      getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
      footerComponent={({ finalData }) => {
        return <FooterCardPencapaian data={finalData} isLoading={isLoading} />;
      }}
      customToolbar={
        <Box sx={{ p: 1 }}>
          <CustomDateRange
            title={
              role === "desainer"
                ? "Filter Tanggal Selesai Desain"
                : role === "admin_produksi"
                ? "Filter Tanggal Selesai Produksi"
                : "Filter Tanggal Selesai"
            }
            defaultValue={tanggalPencapaianFilter}
            onChange={(values) =>
              EventHandlers.handleChangeFilterTanggal({
                dispatch,
                queryClient,
                tanggal: values,
              })
            }
          />
        </Box>
      }
    />
  );
};

TabelPencapaian.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TabelPencapaian;
