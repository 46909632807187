import { Config, EventHandlers } from ".";
import { EventHandlers as EventHandlersNota } from "../../nota/repository";
import { useFetchingData, useUpdatingData } from "../../../../hooks";
export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/dikerjakan`,
    });
  };

  static readSedangDikerjakan = () => {
    return useFetchingData({
      endpoint: `dikerjakan`,
    });
  };

  static progressAntrainGroup = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/dikerjakan/progress`,
      invalidated: [
        `${Config.variable.endpoint}/dikerjakan`,
        `dikerjakan`,
        `antrian`,
        `group/antrian`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static selesaiAntrainGroup = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/dikerjakan/selesai`,
      invalidated: [
        `${Config.variable.endpoint}/dikerjakan`,
        `dikerjakan`,
        `pencapaian`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        EventHandlersNota.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
          socket.emit("checkout_siap_kirim");
          socket.emit("dikerjakan_group");
          socket.emit("dashboard_last_reject");
        }
      },
      onError: () => {
        return;
      },
    });
  };
}
