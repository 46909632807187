import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Grid, Stack, Tab, Tabs } from "@mui/material";
import Layout from "../layout/Layout";
import { TabPanel, a11yProps } from "../components/common";
import { AddButton } from "../components/controls";
import { EventHandlers } from "../features/pengaturan/page/repository/EventHandlers";
import { EventHandlers as EventHandlersTarget } from "../features/pengaturan/target/repository";

import TokoPage from "../features/pengaturan/toko/TokoPage";
import SystemPage from "../features/pengaturan/system/SystemPage";
import ProdukPage from "../features/pengaturan/produk/ProdukPage";
import TargetPage from "../features/pengaturan/target/TargetPage";

const Pengaturan = (props) => {
  const dispatch = useDispatch();
  const { tab } = useSelector((state) => state.pengaturan);
  const { role } = useSelector((state) => state.auth);

  return (
    <Layout>
      {/* Title Container */}
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent={tab === 3 ? "space-between" : "end"}
        alignItems="center"
      >
        {tab === 3 ? (
          <Grid
            container
            columnSpacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Grid item>
              <AddButton
                title="Tambah Target"
                onClick={() =>
                  EventHandlersTarget.handleCreateUpdate({
                    dispatch,
                    modal: { id: null, open: true, title: "Tambah Target" },
                  })
                }
              />
            </Grid>
          </Grid>
        ) : null}
        <AppBar
          position="static"
          elevation={0}
          sx={{
            backgroundColor: "#fff",
            width: role === "root" ? "500px" : "325px",
          }}
        >
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(event, value) =>
              EventHandlers.handleTab({ dispatch, tab: value })
            }
            centered
          >
            <Tab {...a11yProps(0)} label="TOKO" />
            <Tab
              {...a11yProps(1)}
              label="SYSTEM"
              sx={{ display: role !== "root" && "none" }}
            />
            <Tab {...a11yProps(2)} label="PRODUK" />
            <Tab {...a11yProps(3)} label="TARGET" />
          </Tabs>
        </AppBar>
      </Stack>

      {/* Content */}
      <TabPanel value={tab} index={0}>
        <TokoPage />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <SystemPage />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ProdukPage />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <TargetPage />
      </TabPanel>
    </Layout>
  );
};

export default Pengaturan;
