import * as React from "react";
import { useSelector } from "react-redux";
import { Grid, Stack, Typography } from "@mui/material";
import { NumericText } from "../../../../components/common";
import logoWave from "../../../../assets/icon/logo/waveprint.svg";
import config from "../../../../config";

const DatePrint = new Date();
const formattedDate = `${DatePrint.getDate()}-${
  DatePrint.getMonth() + 1
}-${DatePrint.getFullYear()} ${DatePrint.getHours()}:${DatePrint.getMinutes()}`;

const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const getMonthName = (monthNumber) => {
  if (monthNumber < 1 || monthNumber > 12) {
    return ""; // Handle invalid month numbers
  }
  return monthNames[monthNumber - 1];
};

export const PrintPendapatanBulanan = React.forwardRef((props, ref) => {
  const { Data, bulan, tahun } = props;
  const { setting } = useSelector((state) => state.data);
  const { name, usercode, role } = useSelector((state) => state.auth); // Get role from Redux state
  const [pengaturan, setPengaturan] = React.useState({
    nama: "",
    alamat: "",
    logo: "",
  });

  const customHead = {
    padding: "0.1rem 0.7rem 0.1rem 0.7rem",
    borderRadius: 5,
  };

  const groupBorder = {
    borderBottom: "1px solid #ADA4A4",
  };

  const customBody = {
    padding: "0.2rem 0.7rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  // Define headers conditionally based on role
  const mainHeaders = [
    { label: "Tanggal", align: "center", rowSpan: 2, width: "4%" },
    { label: "Nota Order", align: "right", rowSpan: 2 },
    { label: "Jumlah Order", align: "right", rowSpan: 2 },
    {
      label: "Bruto",
      align: role === "owner" ? "center" : "right",
      colSpan: role === "owner" ? 2 : 0,
      width: "16%",
    },
    {
      label: "Retur",
      align: role === "owner" ? "center" : "right",
      colSpan: role === "owner" ? 2 : 0,
      width: "16%",
    },
    {
      label: role === "owner" ? "Netto" : "Netto (Omset)",
      align: role === "owner" ? "center" : "right",
      colSpan: role === "owner" ? 2 : 0,
      width: "16%",
    },
    {
      label: "Gross Profit",
      align: "right",
      rowSpan: 2,
      hide: role !== "owner",
    },
    { label: "Reject", align: "right", rowSpan: 2 },
    { label: "Profit", align: "right", rowSpan: 2, hide: role !== "owner" },
    {
      label: "%",
      align: "right",
      rowSpan: 2,
      hide: role !== "owner",
    },
  ].filter((header) => !header.hide);

  const subHeaders = [
    { label: "jual", align: "center" },
    { label: "hpp", align: "center" },
    { label: "jual", align: "center" },
    { label: "hpp", align: "center" },
    { label: "jual", align: "center" },
    { label: "hpp", align: "center" },
  ].filter((header) => !header.hide);

  // Calculate totals conditionally based on role
  const totalNota = Data.reduce((total, item) => total + (item.nota || 0), 0);
  const totalOrder = Data.reduce(
    (total, item) => total + (item.jumlah_order || 0),
    0
  );
  const totalBrutoJual = Data.reduce(
    (total, item) => total + (item.brutto.harga_jual || 0),
    0
  );
  const totalBrutoHpp =
    role === "owner"
      ? Data.reduce((total, item) => total + (item.brutto.hpp || 0), 0)
      : null;
  const totalReturJual = Data.reduce(
    (total, item) => total + (item.retur.harga_jual || 0),
    0
  );
  const totalReturHpp =
    role === "owner"
      ? Data.reduce((total, item) => total + (item.retur.hpp || 0), 0)
      : null;
  const totalNettoJual = Data.reduce(
    (total, item) => total + (item.netto.harga_jual || 0),
    0
  );
  const totalNettoHpp =
    role === "owner"
      ? Data.reduce((total, item) => total + (item.netto.hpp || 0), 0)
      : null;
  const totalGrossProfit =
    role === "owner"
      ? Data.reduce((total, item) => total + (item.gross_profit || 0), 0)
      : null;
  const totalReject = Data.reduce(
    (total, item) => total + (item.reject || 0),
    0
  );
  const totalProfit =
    role === "owner"
      ? Data.reduce((total, item) => total + (item.profit || 0), 0)
      : null;
  const totalProfitPercent =
    role === "owner"
      ? Data.reduce((total, item) => total + (item.profit_percent || 0), 0)
      : null;

  React.useEffect(() => {
    if (setting.length) {
      const nama = setting.find((e) => e.rule === "NAMA_TOKO");
      const alamat = setting.find((e) => e.rule === "ALAMAT");
      const logo = setting.find((e) => e.rule === "LOGO_NOTA");
      if (nama) {
        setPengaturan((old) => ({ ...old, nama: nama.value }));
      }
      if (alamat) {
        setPengaturan((old) => ({ ...old, alamat: alamat.value }));
      }
      if (logo) {
        setPengaturan((old) => ({ ...old, logo: logo.value }));
      }
    }
  }, [setting]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={1}
      >
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <div style={{ marginRight: "1rem" }}>
            <img
              src={config.apiUrl + "/storage/logo/" + pengaturan.logo}
              alt="Logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
              Laporan Pendapatan
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{pengaturan.nama}</Typography>
            <Typography sx={{ fontSize: 12 }}>{pengaturan.alamat}</Typography>
          </div>
        </Grid>
        <Grid item sx={{ fontSize: 12 }}>
          <Stack direction="row" spacing={1}>
            <Stack>
              <div style={{ fontWeight: 700 }}>Pendapatan</div>
              <div>Bulan | Tahun</div>
              <div>Admin</div>
            </Stack>
            <Stack>
              <div>:</div>
              <div>:</div>
              <div>:</div>
            </Stack>
            <Stack>
              <div style={{ fontWeight: 700 }}>Bulanan</div>
              <div>
                {getMonthName(bulan)} {tahun}
              </div>
              <div>
                <b>{usercode}</b> {name}
                {/* {name.slice(0, 8)}
                {name.length > 8 && '...'} */}
              </div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <table
        width={"100%"}
        style={{ paddingTop: 5, paddingBottom: 8, borderCollapse: "collapse" }}
      >
        <thead
          style={{
            backgroundColor: "#EEF2F7",
            borderBottom: "2px solid #ADA4A4",
            paddingTop: 10,
          }}
        >
          <tr style={{ fontWeight: "bold" }}>
            {mainHeaders.map((header, index) => (
              <th
                key={index}
                align={header.align}
                rowSpan={header.rowSpan}
                colSpan={header.colSpan}
                width={header.width}
                style={
                  header.colSpan
                    ? { ...customHead, ...groupBorder }
                    : customHead
                }
              >
                {header.label}
              </th>
            ))}
          </tr>
          {role === "owner" && (
            <tr>
              {subHeaders.map((subHeader, index) => (
                <td key={index} align={subHeader.align} style={customHead}>
                  {subHeader.label}
                </td>
              ))}
            </tr>
          )}
        </thead>

        <tbody>
          {Data.map((e, index) => {
            const fields = [
              { value: index + 1, align: "center", style: customBody },
              { value: e.nota, suffix: " Nota", style: customBody },
              { value: e.jumlah_order, suffix: " Pcs", style: customBody },
              {
                value: e.brutto.harga_jual,
                color: "orange",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              role === "owner" && {
                value: e.brutto.hpp,
                color: "orange",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              {
                value: e.retur.harga_jual,
                color: "red",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              role === "owner" && {
                value: e.retur.hpp,
                color: "red",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              {
                value: e.netto.harga_jual,
                color: "blue",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              role === "owner" && {
                value: e.netto.hpp,
                color: "blue",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              role === "owner" && {
                value: e.gross_profit,
                color: "blue",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              {
                value: e.reject,
                color: "red",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              role === "owner" && {
                value: e.profit,
                color: "green",
                thousandSeparator: true,
                prefix: true,
                style: customBody,
              },
              role === "owner" && {
                value: e.profit_percent,
                color: "green",
                thousandSeparator: true,
                suffix: " %",
                style: customBody,
              },
            ].filter((field) => field);

            return (
              <tr key={index} style={{ borderBottom: "0.1px solid #cfcfcf" }}>
                {fields.map((field, idx) => (
                  <td
                    key={idx}
                    align={field.align || "right"}
                    style={{ ...field.style, color: field.color || "" }}
                  >
                    <NumericText
                      value={field.value}
                      suffix={field.suffix || ""}
                      displayType="text"
                      thousandSeparator={field.thousandSeparator || false}
                      prefix={field.prefix || ""}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>

        <tfoot
          style={{
            backgroundColor: "#EEF2F7",
            fontWeight: 700,
            borderTop: "2px solid #ADA4A4",
          }}
        >
          <tr>
            {[
              { value: "Total", align: "center", style: customBody },
              { value: totalNota, suffix: " Nota", style: customBody },
              { value: totalOrder, suffix: " Pcs", style: customBody },
              {
                value: totalBrutoJual,
                color: "orange",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              role === "owner" && {
                value: totalBrutoHpp,
                color: "orange",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              {
                value: totalReturJual,
                color: "red",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              role !== "customer_service" && {
                value: totalReturHpp,
                color: "red",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              {
                value: totalNettoJual,
                color: "blue",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              role !== "customer_service" && {
                value: totalNettoHpp,
                color: "blue",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              role !== "customer_service" && {
                value: totalGrossProfit,
                color: "blue",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              {
                value: totalReject,
                color: "red",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              role !== "customer_service" && {
                value: totalProfit,
                color: "green",
                thousandSeparator: true,
                prefix: "Rp ",
                style: customBody,
              },
              role !== "customer_service" && {
                value: totalProfitPercent,
                color: "green",
                thousandSeparator: true,
                suffix: " %",
                prefix: false,
                style: customBody,
              },
            ]
              .filter((field) => field)
              .map((field, idx) =>
                idx === 0 ? (
                  <td
                    key={idx}
                    align={field.align || "right"}
                    style={{ ...field.style, color: field.color || "" }}
                  >
                    {field.value}
                  </td>
                ) : (
                  <td
                    key={idx}
                    align={field.align || "right"}
                    style={{ ...field.style, color: field.color || "" }}
                  >
                    <NumericText
                      value={field.value}
                      suffix={field.suffix || ""}
                      displayType="text"
                      thousandSeparator={field.thousandSeparator || false}
                      prefix={field.prefix || ""}
                    />
                  </td>
                )
              )}
          </tr>
        </tfoot>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0 0 0",
        }}
      >
        <Typography sx={{ fontSize: 8 }}>
          Printed on : {formattedDate}
        </Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </div>

      {/* Mengatur pemisahan halaman setelah tabel */}
      <div style={{ pageBreakAfter: "always", marginTop: "2rem" }}></div>
    </div>
  );
});
