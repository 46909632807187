import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../layout/Layout";
import ReturPage from "../features/retur/ReturPage";
import { Api, EventHandlers, useFormRetur } from "../features/retur/repository";
import { Button, Grid, Stack } from "@mui/material";
import { AddButton } from "../components/controls";
import { Print as PrintIcon } from "@mui/icons-material";
import { updateLoading } from "../features/themeSlice";

const Retur = () => {
  const dispatch = useDispatch();
  const formRetur = useFormRetur();
  const componentRefRetur = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const { tanggalReturFilter } = useSelector((state) => state.retur);
  const getReturData = Api.read({
    startDate: tanggalReturFilter[0],
    endDate: tanggalReturFilter[1],
  });

  const handlePrintRetur = useReactToPrint({
    content: () => componentRefRetur.current,
  });

  const handleLoadingPrint = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(updateLoading(isLoading));
  }, [dispatch, isLoading]);

  return (
    <Layout>
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          container
          columnSpacing={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Grid item>
            <AddButton
              title="Tambah Retur"
              onClick={() =>
                EventHandlers.handleCreateUpdateModal({
                  dispatch,
                  modal: { id: null, open: true, title: "Tambah Retur !" },
                  clearErrors: formRetur.clearErrors,
                  reset: formRetur.reset,
                })
              }
              sx={{
                borderColor: "rgba(255, 0, 0, 0.8)",
                color: "rgba(255, 0, 0, 0.8)",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: 600,
                padding: "7px 14px",
                "&:hover": {
                  backgroundColor: "rgba(255, 0, 0, 0.04) !important",
                  borderColor: "rgba(255, 0, 0, 1) !important",
                  color: "rgba(255, 0, 0, 1)",
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleLoadingPrint();
                handlePrintRetur();
              }}
              variant="outlined"
              startIcon={<PrintIcon />}
              sx={{
                position: "relative",
                padding: "7px 14px",
                borderRadius: "5px",
              }}
              disabled={getReturData?.data?.data.length === 0}
            >
              Print
            </Button>
          </Grid>
        </Grid>
      </Stack>

      {/* ReturPage */}
      <div>
        <ReturPage {...{ componentRefRetur }} />
      </div>
    </Layout>
  );
};

export default Retur;
