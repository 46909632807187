import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import { Select } from "../../../../components/controls";
import { useQueryClient } from "@tanstack/react-query";
import { Grid } from "@mui/material";

const FilterBulanTahun = ({ form }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { bulan, tahun } = useSelector(
    (state) => state.dashboardGroupPencapaian
  );

  const { default_bulan, default_tahun } = useSelector((state) => state.data);

  return (
    default_bulan.length > 0 &&
    default_tahun.length > 0 && (
      <>
        <Grid item>
          <Select
            name={`bulanan.bulan`}
            control={form.control}
            label={"Bulan"}
            options={default_bulan}
            rules={{ required: false }}
            defaultValue={bulan}
            size={"small"}
            noshrinklabel={true}
            onChange={(_, value) => {
              form.setValue(`bulanan.bulan`, value.props.value);
              EventHandlers.handleBulan({
                dispatch,
                bulan: value.props.value,
                oldBulan: bulan,
                tahun: tahun,
                queryClient,
              });
            }}
            wave
          />
        </Grid>
        <Grid item>
          <Select
            name={`bulanan.tahun`}
            control={form.control}
            label={"Tahun"}
            options={default_tahun}
            rules={{ required: false }}
            defaultValue={tahun}
            size={"small"}
            noshrinklabel={true}
            onChange={(_, value) => {
              form.setValue(`bulanan.tahun`, value.props.value);
              EventHandlers.handleTahun({
                dispatch,
                tahun: value.props.value,
                oldTahun: tahun,
                bulan: bulan,
                queryClient,
              });
            }}
            wave
          />
        </Grid>
      </>
    )
  );
};

export default FilterBulanTahun;
