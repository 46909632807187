import { useForm } from "react-hook-form";
import { Config } from "./Config";

export const useFormRekapNota = () => {
  const defaultValues = Config.defaultValue;
  const form = useForm({
    mode: "all",
    defaultValues,
  });

  return form;
};
