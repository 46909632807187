import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog, DataForm } from "../../../../components/Layout/Content";
import SelesaiAntrianLayout from "../../../../components/Layout/Content/Dikerjakan/SelesaiAntrianLayout";
import { Api, EventHandlers, useFormDikerjakan } from "../repository";

const ModalSelesai = () => {
  const dispatch = useDispatch();
  const formDikerjakan = useFormDikerjakan();
  const { socket } = useSelector((state) => state.data);
  const { role } = useSelector((state) => state.auth);
  const { selesaiModal } = useSelector((state) => state.dikerjakanNota);
  const { tanggalPencapaianFilter } = useSelector(
    (state) => state.pencapaianNota
  );
  const { data, isError, isLoading } = Api.detailAntrian({
    modal: selesaiModal,
  });

  const handleClose = useCallback(() => {
    if (role === "admin_produksi") {
      formDikerjakan.reset({
        keterangan: "",
        reject: false,
        nominal_reject: 0,
        keterangan_reject: "",
        confirmation: false,
      });
    } else {
      formDikerjakan.reset({ keterangan: "", confirmation: false });
    }
    EventHandlers.handleSelesaiModal({
      dispatch,
      modal: { id: null, open: false, title: null },
    });
  }, [dispatch, formDikerjakan, role]);

  const { mutate: mutateDesainer, isPending: isPendingDesainer } =
    Api.selesaiAntrianDesainer({
      handleClose,
      dispatch,
      socket,
      tanggal: tanggalPencapaianFilter,
    });
  const { mutate: mutateProduksi, isPending: isPendingProduksi } =
    Api.selesaiAntrianProduksi({
      handleClose,
      dispatch,
      socket,
      tanggal: tanggalPencapaianFilter,
    });

  const onSubmit = (values) => {
    if (role === "desainer") {
      mutateDesainer({ body: values, id: selesaiModal.id });
    } else if (role === "admin_produksi") {
      const data = values;
      if (!data.reject) {
        data.nominal_reject = 0;
      }
      mutateProduksi({ body: values, id: selesaiModal.id });
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={selesaiModal.open}
      handleClose={handleClose}
      title={selesaiModal.title}
    >
      <DataForm data={data ? data.data : {}} isLoading={isLoading} />
      <SelesaiAntrianLayout
        isPending={isPendingDesainer || isPendingProduksi}
        onSubmit={onSubmit}
        handleClose={handleClose}
        form={formDikerjakan}
      />
    </BlankDialog>
  );
};

export default ModalSelesai;
