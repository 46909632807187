import React, { useState } from "react";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/controls/CustomButton";
import {
  BlankDialog,
  UploadImageLayout,
} from "../../../../components/Layout/Content";
import { Api, EventHandlers } from "../repository";
import { ActionGrid } from "../../../../components/common";

const ModalUploadAntrian = ({ data, isLoading }) => {
  const { socket } = useSelector((state) => state.data);
  const { uploadPreviewModal } = useSelector((state) => state.dikerjakanNota);
  const dispatch = useDispatch();
  const formUpload = useForm();
  const [progress, setProgress] = useState(0);

  const handleClose = () => {
    setProgress(0);
    formUpload.reset({ gambar: null, confirmation: false });
    EventHandlers.handleUploadPreviewModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const uploadGambar = Api.uploadGambarAntrian({
    dispatch,
    setProgress,
    id: uploadPreviewModal.id,
    handleClose,
    socket,
  });

  const deleteGambar = Api.deleteGambarAntrian({
    modal: uploadPreviewModal,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    const data = values;
    uploadGambar.mutate({
      ...data,
    });
  };

  const onDeleteImageHandler = () => {
    deleteGambar.mutate(uploadPreviewModal.id);
  };

  return (
    <BlankDialog
      open={uploadPreviewModal.open}
      handleClose={handleClose}
      title={uploadPreviewModal.title}
      outsideClose={false}
      isLoading={isLoading}
      maxWidth={"xs"}
    >
      <form onSubmit={formUpload.handleSubmit(onSubmit)}>
        <UploadImageLayout
          form={formUpload}
          progress={progress}
          gambar={data.gambar}
          onDeleteImage={onDeleteImageHandler}
          isPending={deleteGambar.isPending}
        />
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={uploadGambar.isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={uploadGambar.isPending}
              warna={"next"}
              disabled={formUpload.watch("confirmation") ? false : true}
              onClick={formUpload.handleSubmit(onSubmit)}
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

ModalUploadAntrian.propTypes = {
  data: PropTypes.shape({
    gambar: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ModalUploadAntrian;
