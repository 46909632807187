import React, { useEffect } from "react";
import { Grid, Divider, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog } from "../../../../components/Layout/Content";
import { Api, EventHandlers, useFormRekapNota } from "../repository";
import {
  CheckboxInput,
  SwitchInput,
  NumberInput,
  CustomButton,
  Input,
} from "../../../../components/controls";
import { NotaReject } from ".";
import { ActionGrid } from "../../../../components/common";

const ModalEditReject = () => {
  const dispatch = useDispatch();
  const form = useFormRekapNota();
  const { editReject, tanggalRekapFilter } = useSelector(
    (state) => state.rekapNota
  );

  const { data, isLoading } = Api.detailReject({
    modal: editReject,
  });

  const { mutate, isPending } = Api.updateReject({
    onSuccess: () => handleClose(),
    tanggal: tanggalRekapFilter,
  });

  const handleClose = () => {
    form.setValue("reject", false);
    form.setValue("nominal_reject", "");
    form.setValue("keterangan_reject", "");
    form.setValue("confirmation", false);
    EventHandlers.handleEditReject({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  useEffect(() => {
    if (editReject.id && data) {
      if (data.data.nominal_reject > 0) {
        form.setValue("reject", true);
      }
      form.setValue("nominal_reject", data.data.nominal_reject);
      form.setValue("keterangan_reject", data.data.keterangan_reject);
      form.setValue("confirmation", false);
    }
  }, [data, editReject.id, form]);

  const onSubmit = (values) => {
    if (editReject.id) {
      mutate({ body: values, id: editReject.id });
    }
  };

  return (
    <BlankDialog
      open={editReject.open}
      handleClose={handleClose}
      title={<span style={{ color: "#FF0707" }}>{editReject.title}</span>}
      outsideClose={false}
      isLoading={isLoading}
      maxWidth={"sm"}
    >
      <Stack spacing={2}>
        <NotaReject data={data ? data.data : {}} isLoading={isLoading} />
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3} textAlign={"end"}>
              <SwitchInput
                name={`reject`}
                control={form.control}
                label={"Reject"}
                defaultValue={false}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                rules={{
                  required: form.watch(`reject`)
                    ? "Nominal Reject Harus di isi!"
                    : false,
                  min: {
                    value: form.watch(`reject`) ? 1 : 0,
                    message: "Harga Harus di isi!",
                  },
                }}
                name={`nominal_reject`}
                control={form.control}
                label={"Nominal"}
                defaultValue={0}
                disabled={!form.watch(`reject`)}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                rules={{
                  required: form.watch(`reject`)
                    ? "Keterangan Reject Harus di isi!!"
                    : false,
                }}
                name="keterangan_reject"
                control={form.control}
                label="Keterangan Reject"
                defaultValue={""}
                disabled={!form.watch(`reject`)}
              />
            </Grid>
          </Grid>

          {/* Validation Check */}
          <Grid
            container
            justifyContent={"end"}
            alignItems={"center"}
            marginBottom={"-1rem"}
          >
            <CheckboxInput
              name={"confirmation"}
              control={form.control}
              label={"Pastikan data sudah benar!"}
              rules={{ required: false }}
              defaultValue={false}
            />
          </Grid>

          {/* Action Button */}
          <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
          <ActionGrid
            isLoading={isPending}
            actionStart={
              <CustomButton
                onClick={handleClose}
                warna={"clear"}
                widthbtn={"100%"}
              >
                Batal
              </CustomButton>
            }
            actionEnd={
              <CustomButton
                loading={isPending}
                warna={"next"}
                widthbtn={"100%"}
                disabled={form.watch("confirmation") ? false : true}
                type="submit"
              >
                Simpan
              </CustomButton>
            }
          />
        </form>
      </Stack>
    </BlankDialog>
  );
};

export default ModalEditReject;
