import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "../config/api";
import { setNotification } from "../features/notificationSlice";

const useDeletingData = (props) => {
  const { endpoint, onSuccess, invalidated = [] } = props;

  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) => {
      try {
        const productsResponse = await axiosInstance.delete(endpoint, {
          data: { id: id },
        });
        return productsResponse;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: (response) => {
      if (invalidated.length) {
        for (const key of invalidated) {
          queryClient.invalidateQueries({ queryKey: [`${key}`] });
        }
      }

      dispatch({
        type: setNotification,
        payload: {
          snackbarOpen: true,
          snackbarType: response.data.type,
          snackbarMessage: response.data.message,
        },
      });
      onSuccess();
    },
    onError: (error) => {
      dispatch({
        type: setNotification,
        payload: {
          snackbarOpen: true,
          snackbarType: error?.type,
          snackbarMessage: error?.message,
        },
      });
    },
    gcTime: 0,
  });
};

useDeletingData.propTypes = {
  endpoint: PropTypes.string,
  onSuccess: PropTypes.func,
  invalidated: PropTypes.object,
};

export default useDeletingData;
