import config from "../../../../config";
import {
  SET_PRINT_MODAL,
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_TANGGAL_PENCAPAIAN_FILTER,
} from "../domain/pencapaianNotaSlice";
import { UPDATE_IMAGE } from "../../../../features/imageDetailSlice";
import dayjs from "dayjs";
import { Config } from "./Config";

export class EventHandlers {
  static handleChangeFilterTanggal = ({ dispatch, queryClient, tanggal }) => {
    const areAllDates = tanggal.every((item) => dayjs(item).isValid());
    if (areAllDates) {
      const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
      const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
      queryClient.removeQueries({
        queryKey: [`${Config.variable.endpoint}/${startDate}|${endDate}`],
      });
      dispatch(SET_TANGGAL_PENCAPAIAN_FILTER({ tanggal }));
    }
  };

  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/antrian/${file}`,
        title: title,
      })
    );
  };

  static handlePrintModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PRINT_MODAL({ modal }));
  };

  static handleDetailModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_DETAIL_MODAL({ modal }));
  };

  static handleDetailProgressModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_PROGRESS_MODAL({ modal }));
  };

  static handlePembayaranModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PEMBAYARAN_MODAL({ modal }));
  };
}
