import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
  editProduct: { id: null, open: false, title: null },
};

const PengaturanProduk = createSlice({
  name: "pengaturanProduk",
  initialState,
  reducers: {
    SET_EDIT_PRODUCT: (state, action) => {
      state.editProduct = action.payload.modal;
    },
  },
});

export const { SET_EDIT_PRODUCT } = PengaturanProduk.actions;

export default PengaturanProduk.reducer;
