import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  DoneOutline as SelesaiIcon,
  Create as ProduksiIcon,
} from "@mui/icons-material";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/dikerjakanNotaSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardDikerjakan from "./TabelDikerjakan/FooterCardDikerjakan";
import ActionButton from "./TabelDikerjakan/ActionButton";

const TabelDikerjakan = ({ data, isLoading, jenisOrder, sourceOrder }) => {
  const dispatch = useDispatch();

  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.dikerjakanNota);

  const { role } = useSelector((state) => state.auth);

  const statusOptions = Array.from(
    new Set(data.map((item) => item.status.status))
  );

  const jenisOrderOptions = Array.from(
    new Set(jenisOrder.filter((jenis) => jenis.status).map((item) => item.name))
  );

  const sourceOrderOptions = Array.from(
    new Set(
      sourceOrder.filter((source) => source.status).map((item) => item.name)
    )
  );

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "jenis_order") {
      return {
        ...e,
        valueOptions: jenisOrderOptions,
      };
    }
    if (e.field === "source_order") {
      return {
        ...e,
        valueOptions: sourceOrderOptions,
      };
    }
    if (e.field === "preview") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            return ActionButton.getActionPreview({
              role,
              params,
              dispatch,
            });
          }
          return [];
        },
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            return ActionButton.getActionOpsi({ role, params, dispatch });
          }
          return [];
        },
      };
    }
    return e;
  });

  if (role === "desainer" || role === "admin_produksi") {
    const indexToInsertAfter = columns.findIndex(
      (e) => e.field === "jenis_order"
    );

    if (indexToInsertAfter !== -1) {
      columns.splice(indexToInsertAfter + 1, 0, {
        field: "selesai",
        minWidth: 150,
        hideable: false,
        filterable: false,
        sortable: false,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        renderHeader: () => (
          <SelesaiIcon
            sx={{
              color: "white",
              backgroundColor: "#797979",
              borderRadius: 50,
              padding: "2px",
            }}
          />
        ),
        renderCell: (params) => {
          if (typeof params.id === "number") {
            return (
              <Button
                size="small"
                startIcon={<SelesaiIcon color="white" />}
                variant="contained"
                sx={{
                  borderRadius: 15,
                  textTransform: "capitalize",
                  padding: "2px 15px",
                  backgroundColor: "#B8B8B8",
                  fontSize: "10px",
                  "&:hover": {
                    backgroundColor: "#2FA9EE",
                  },
                }}
                onClick={() => {
                  EventHandlers.handleSelesaiModal({
                    dispatch,
                    modal: {
                      id: params.id,
                      open: true,
                      title: "Selesai Antrian",
                    },
                  });
                }}
              >
                Selesai
              </Button>
            );
          }
          return [];
        },
      });

      if (role === "admin_produksi") {
        columns.splice(indexToInsertAfter + 1, 0, {
          field: "progress",
          minWidth: 150,
          hideable: false,
          filterable: false,
          sortable: false,
          align: "center",
          headerAlign: "center",
          disableColumnMenu: true,
          renderHeader: () => (
            <ProduksiIcon
              sx={{
                color: "white",
                backgroundColor: "#797979",
                borderRadius: 50,
                padding: "2px",
              }}
            />
          ),
          renderCell: (params) => {
            if (typeof params.id === "number") {
              return (
                <Button
                  size="small"
                  startIcon={
                    <ProduksiIcon
                      sx={{
                        color: "white",
                        backgroundColor: "#D86F23",
                        borderRadius: 50,
                        padding: "2px",
                      }}
                    />
                  }
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: 15,
                    textTransform: "capitalize",
                    borderWidth: "2px",
                    padding: "2px 15px",
                    fontSize: "10px",
                    "&:hover": {
                      borderWidth: "2px",
                      backgroundColor: "rgba(47, 169, 238, 0.04)",
                      borderColor: "#2FA9EE",
                    },
                  }}
                  onClick={() => {
                    EventHandlers.handleProgressProduksiModal({
                      dispatch,
                      modal: {
                        id: params.id,
                        open: true,
                        title: "Antrian Progress Produksi",
                      },
                    });
                  }}
                >
                  Progress
                </Button>
              );
            }
            return [];
          },
        });
      }
    }
  }

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
      footerComponent={({ finalData }) => {
        return <FooterCardDikerjakan data={finalData} isLoading={isLoading} />;
      }}
      withNumberColumn={true}
    />
  );
};

TabelDikerjakan.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
  statusList: PropTypes.array,
};

export default TabelDikerjakan;
