import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { Divider, Grid, Typography } from "@mui/material";
import { CheckboxInput } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { BlankDialog } from "../../../../components/Layout/Content";
import { DataDeleteGroup } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";
import { useForm } from "react-hook-form";
import { ActionGrid } from "../../../../components/common";

const PopupDeleteGroup = () => {
  const form = useForm();
  const dispatch = useDispatch();
  const { deleteGroup } = useSelector((state) => state.manageGroup);
  const handleClose = () => {
    form.setValue("confirmation", false);
    EventHandlers.handleDeleteGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
        data: { nota: null, antrian: [], size: 0, additional: 0 },
      },
    });
  };

  const { mutate, isPending } = Api.deleteGroup({
    handleClose,
  });

  const onHapusGroupClick = () => {
    mutate(deleteGroup.id);
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={deleteGroup.open}
      handleClose={handleClose}
      isLoading={isPending}
      title={
        <Typography color={"#FF0707"} fontWeight={700} fontSize={12}>
          {deleteGroup.title}
        </Typography>
      }
    >
      <DataDeleteGroup data={deleteGroup.data} />

      <Grid
        container
        justifyContent={"end"}
        alignItems={"center"}
        marginTop={1}
        marginBottom={"-1rem"}
      >
        <CheckboxInput
          name={"confirmation"}
          control={form.control}
          label={"Pastikan data sudah benar!"}
          rules={{ required: false }}
          defaultValue={false}
        />
      </Grid>
      <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
      <ActionGrid
        isLoading={isPending}
        actionStart={
          <CustomButton onClick={handleClose} warna={"next"}>
            Batal
          </CustomButton>
        }
        actionEnd={
          <CustomButton
            loading={isPending}
            warna={"clear"}
            disabled={form.watch("confirmation") ? false : true}
            onClick={onHapusGroupClick}
          >
            Simpan
          </CustomButton>
        }
      />
    </BlankDialog>
  );
};

export default PopupDeleteGroup;
