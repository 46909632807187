import React from "react";
import { BlankDialog } from "../";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../../Content/Scanner/repository";
import Barcode from "../../../../assets/scan/gx2MOoN1Ep.gif";

const ScanBarcode = (props) => {
  const dispatch = useDispatch();
  const { form } = props;
  const { scanOrderGroup } = useSelector((state) => state.scanner);

  const handleClose = () => {
    EventHandlers.handleScanOrderGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  return (
    <BlankDialog
      maxWidth={"xs"}
      open={scanOrderGroup.open}
      handleClose={handleClose}
      title={scanOrderGroup.title}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        paddingY={2}
      >
        <img
          src={Barcode}
          alt="Barcode"
          style={{ width: 120, marginBottom: "1rem" }}
        />
        <Typography fontWeight={700} color={"#797979"}>
          {scanOrderGroup.ket}
        </Typography>
      </Stack>
    </BlankDialog>
  );
};

export default ScanBarcode;
