import {
  SET_COLLAPSE,
  SET_BULAN_PENCAPAIAN,
  SET_TAHUN_PENCAPAIAN,
} from "../domain/dashboardGroupPencapaianSlice";
import { Config } from "./Config";

export class EventHandlers {
  static handleCollapse = ({ dispatch, collapse }) => {
    dispatch(SET_COLLAPSE({ collapse }));
  };
  static handleBulan = ({ dispatch, bulan, oldBulan, tahun, queryClient }) => {
    dispatch(SET_BULAN_PENCAPAIAN(bulan));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}?bulan=${oldBulan}&tahun=${tahun}`,
      ],
    });
  };
  static handleTahun = ({ dispatch, tahun, oldTahun, bulan, queryClient }) => {
    dispatch(SET_TAHUN_PENCAPAIAN(tahun));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}?bulan=${bulan}&tahun=${oldTahun}`,
      ],
    });
  };
}
