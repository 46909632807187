import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository";
import { BlankDialog } from "../../../../components/Layout/Content";
import { GroupDetailAntrian } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";

const PopupDetailAntrianGroup = () => {
  const dispatch = useDispatch();
  const { detailAntrianGroup } = useSelector((state) => state.dikerjakanGroup);
  const handleClose = () => {
    EventHandlers.handleDetailAntrianGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
        data: {
          nota: null,
          antrian: 0,
          size: 0,
          additional: 0,
          status: { warna: "white", progress: 0, status: "" },
        },
      },
    });
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={detailAntrianGroup.open}
      handleClose={handleClose}
      title={detailAntrianGroup.title}
    >
      <GroupDetailAntrian data={detailAntrianGroup.data} />
    </BlankDialog>
  );
};

export default PopupDetailAntrianGroup;
