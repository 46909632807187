import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import TabelAntrianGroup from "../TabelAntrianGroup";
import { checkDate, dateFormat } from "../../../../controls";

// column antrian
const totalAntrian = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    filterable: false,
    minWidth: 50,
    maxWidth: 50,
    align: "center",
    headerAlign: "center",
    display: "flex",
    renderHeader: () => <strong style={{ textAlign: "center" }}>No</strong>,
  },
  {
    field: "nota",
    flex: 1,
    minWidth: 50,
    hideable: false,
    headerName: "No. Nota",
    display: "flex",
    renderHeader: () => <strong>No. Nota</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography>{params.value}</Typography>
        </>
      );
    },
  },
  {
    field: "check_in",
    type: "date",
    flex: 1,
    minWidth: 100,
    headerName: "Check-In",
    display: "flex",
    valueGetter: (value) => new Date(value),
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      if (params.value instanceof Date && !isNaN(params.value)) {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {dateFormat(params.value)}
          </span>
        );
      }
      return "";
    },
  },
  {
    field: "nama",
    flex: 1,
    sortable: false,
    minWidth: 200,
    display: "flex",
    renderHeader: () => <strong>Leads / Nama</strong>,
    renderCell: (params) => {
      return (
        <>
          <Typography>{params.value}</Typography>
        </>
      );
    },
  },
];

const ModalDeleteGroup = ({ data }) => {
  const rowsAntrianData = data.antrian.map((row, index) => ({
    ...row,
    no: index + 1, // Menambahkan properti id untuk mendapatkan nomor otomatis
  }));

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#E0F0F9",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid container item xs={7}>
            <Grid item xs={5} fontWeight={700}>
              No. Faktur
            </Grid>
            <Grid item xs={0.5} fontWeight={700}>
              :
            </Grid>
            <Grid item xs={6.5} fontWeight={700}>
              {data.nota}
            </Grid>
            <Grid item xs={5}>
              Total Antrian
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={6.5}>
              {data.antrian.length} Nota
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item xs={8.5}>
              Total Order
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.size} Pcs
            </Grid>
            <Grid item xs={8.5}>
              Total Tambahan
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.additional} Pcs
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* tabel data antrian */}
      <Grid container item marginBottom={1}>
        <TabelAntrianGroup
          rows={rowsAntrianData}
          column={totalAntrian}
          getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
        />
      </Grid>
    </Box>
  );
};

export default ModalDeleteGroup;
