import {
  SET_TAB,
  SET_TOGGLE_VIEW,
  SET_BULAN_HARIAN,
  SET_TAHUN_HARIAN,
  SET_TAHUN_BULANAN,
  SET_TAHUN_START,
  SET_TAHUN_END,
} from "../domain/dashboardCardSourceOrderSlice";
import { Config } from "./Config";

export class EventHandlers {
  static handleTab = ({ dispatch, tab }) => {
    dispatch(SET_TAB({ tab }));
  };
  static handleToggleView = ({ dispatch, view }) => {
    dispatch(SET_TOGGLE_VIEW(view));
  };
  static handleHarianBulan = ({
    dispatch,
    harianBulan,
    harianTahun,
    oldHarianBulan,
    queryClient,
  }) => {
    dispatch(SET_BULAN_HARIAN(harianBulan));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}/harian?bulan=${oldHarianBulan}&tahun=${harianTahun}`,
      ],
    });
  };
  static handleHarianTahun = ({
    dispatch,
    harianTahun,
    harianBulan,
    oldHarianTahun,
    queryClient,
  }) => {
    dispatch(SET_TAHUN_HARIAN(harianTahun));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}/harian?bulan=${harianBulan}&tahun=${oldHarianTahun}`,
      ],
    });
  };
  static handleBulananBulan = ({
    dispatch,
    bulananTahun,
    oldBulananTahun,
    queryClient,
  }) => {
    dispatch(SET_TAHUN_BULANAN(bulananTahun));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}/bulanan?tahun=${oldBulananTahun}`,
      ],
    });
  };
  static handleStartTahun = ({
    dispatch,
    tahunanTahun1,
    oldTahunanTahun1,
    tahunanTahun2,
    queryClient,
  }) => {
    dispatch(SET_TAHUN_START(tahunanTahun1));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}/tahunan?tahun1=${oldTahunanTahun1}&tahun2=${tahunanTahun2}`,
      ],
    });
  };
  static handleEndBulan = ({
    dispatch,
    tahunanTahun2,
    tahunanTahun1,
    oldTahunanTahun2,
    queryClient,
  }) => {
    dispatch(SET_TAHUN_END(tahunanTahun2));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}/tahunan?tahun1=${tahunanTahun1}&tahun2=${oldTahunanTahun2}`,
      ],
    });
  };
}
