import { Input } from "@mui/material";
import { Controller } from "react-hook-form";
const FileInput = (props) => {
  const {
    name,
    control,
    rules,
    inputProps = { accept: "application/image*" },
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { value, onChange, ...field },
        fieldState: { error },
      }) => (
        <Input
          {...field}
          value={value?.fileName}
          onChange={(event) => {
            const files = event.target.files;
            onChange(files?.[0]);
          }}
          type="file"
          fullWidth
          error={error !== undefined}
          inputProps={inputProps}
          {...rest}
        />
      )}
    />
  );
};

export default FileInput;
