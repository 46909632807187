import { Config, EventHandlers } from ".";
import { EventHandlers as EventHandlersGroup } from "../../group/repository";
import {
  useCreatingData,
  useFetchingData,
  useDeletingData,
  useUpdatingData,
} from "../../../../hooks";
export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}`,
    });
  };

  static searchKecamatan = (props) => {
    const { search } = props;
    return useFetchingData({
      endpoint: "kecamatan",
      params: { params: { search } },
      enabled: search.length > 2 ? true : false,
      gcTime: 0,
    });
  };

  static create = ({ dispatch, form, socket }) => {
    return useCreatingData({
      endpoint: Config.variable.endpoint,
      invalidated: [`${Config.variable.endpoint}`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        EventHandlers.handleCreateUpdateModal({
          dispatch,
          modal: { id: null, open: false },
          shouldReset: true,
          clearErrors: form.clearErrors,
          reset: form.reset,
        });
        if (socket) {
          socket.emit("antrian");
          socket.emit("dashboard_jenis_order");
          socket.emit("dashboard_source_order");
        }
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static updateDetail = ({ dispatch, handleClose, form, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/edit/detail`,
      invalidated: [`antrian`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("dashboard_jenis_order");
          socket.emit("dashboard_source_order");
        }
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static updateOrder = ({ dispatch, handleClose, form, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/edit/order`,
      invalidated: [`antrian`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("dashboard_jenis_order");
          socket.emit("dashboard_source_order");
        }
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static deleteAntrian = ({ handleClose, dispatch, socket }) => {
    return useDeletingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [`antrian`, `group/antrian`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        EventHandlersGroup.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
          socket.emit("dikerjakan");
          socket.emit("dikerjakan_group");
        }
      },
    });
  };

  static uploadGambarAntrian = ({
    setProgress,
    id,
    handleClose,
    dispatch,
    socket,
  }) => {
    return useCreatingData({
      endpoint: `antrian/gambar/${id}`,
      invalidated: [`antrian`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { _method: "PUT" },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static deleteGambarAntrian = ({ modal, dispatch, socket }) => {
    return useDeletingData({
      endpoint: Config.variable.endpoint + "/gambar",
      invalidated: [`antrian`, `antrian/gambar/${modal.id ? modal.id : ""}`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        if (socket) {
          socket.emit("antrian");
        }
        return;
      },
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianEdit = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/edit/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianSize = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/size/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static progressAntrian = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `antrian/progress`,
      invalidated: [`${Config.variable.endpoint}`, `group/antrian`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        EventHandlersGroup.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
          socket.emit("dikerjakan");
          socket.emit("dikerjakan_group");
        }
      },
    });
  };

  static printAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/print/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/progress/${
        modal.id ? modal.id : ""
      }`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailPembayaran = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/pembayaran/${
        modal.id ? modal.id : ""
      }`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static updatePembayaran = ({ id, onSuccess, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/create/pembayaran`,
      invalidated: [`antrian`, `${Config.variable.endpoint}/pembayaran/${id}`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        onSuccess();
        if (socket) {
          socket.emit("antrian");
          socket.emit("dashboard_last_pembayaran");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static deletePembayaran = ({ id, handleClose, socket, dispatch }) => {
    return useDeletingData({
      endpoint: `${Config.variable.endpoint}/pembayaran`,
      invalidated: [`antrian`, `${Config.variable.endpoint}/pembayaran/${id}`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
        }
      },
    });
  };

  static detailAntrianGambar = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/gambar/${
        modal.id ? modal.id : ""
      }`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static ambilAntrian = ({ handleClose, socket, dispatch }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/ambil`,
      invalidated: [`${Config.variable.endpoint}`, "dikerjakan"],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
    });
  };

  static personAntrian = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/person`,
      invalidated: [`${Config.variable.endpoint}`, `group/antrian`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        EventHandlersGroup.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
          socket.emit("dikerjakan");
          socket.emit("dikerjakan_group");
        }
      },
    });
  };

  static skipDesainer = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/desainer`,
      invalidated: [`${Config.variable.endpoint}`],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
        }
      },
    });
  };
}
