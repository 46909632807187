import {
  IconButton,
  InputAdornment,
  styled,
  Tooltip,
  Zoom,
} from "@mui/material";
import { DateRangeIcon, LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef } from "react";

const CustomDateRangePicker = styled(DateRangePicker)(() => ({
  maxWidth: 210,
  marginBottom: 0.3,
  "& .MuiInputBase-root": {
    paddingLeft: 0,
    paddingRight: 0.5,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    // border: "none",
    border: "1px solid rgba(25, 118, 210, 0.5)",
  },
  "& .MuiInputBase-input": {
    fontSize: "11px",
    padding: "4px 4px",
    color: "#6C6C6C",
  },
  "& .MuiTextField-root": {
    marginLeft: 0,
  },
  "& .MuiMultiInputDateRangeField-separator": {
    color: "#1976d2",
    marginLeft: "8px",
    marginRight: "8px",
  },
  "& .MuiInputAdornment-outlined": {
    height: "100%",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    // backgroundColor: "rgba(0, 0, 0, 0.08)",
    marginRight: 2,
  },
  "& .MuiTouchRipple-root": {
    borderRadius: 0,
  },
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
}));

const CustomDateRange = ({ onChange, defaultValue, title }) => {
  const dateRangePickerRef = useRef(null);

  const handleIconClick = () => {
    if (dateRangePickerRef.current) {
      dateRangePickerRef.current.focus();
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomDateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        defaultValue={defaultValue}
        onChange={onChange}
        ref={dateRangePickerRef}
        slotProps={{
          textField: {
            size: "small",
            InputProps: {
              startAdornment: (
                <Tooltip
                  title={title}
                  placement="left"
                  TransitionComponent={Zoom}
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -5],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <InputAdornment position="start">
                    <IconButton size="small" onClick={handleIconClick}>
                      <DateRangeIcon sx={{ color: "#1976d2" }} />
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDateRange;
