import { createSlice } from "@reduxjs/toolkit";

const groupAntrianNota = JSON.parse(localStorage.getItem("antrianNota"));

const initialState = {
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {
    jenis_order: false,
    source_order: false,
    alamat: false,
    desainer: false,
    produksi: false,
  },
  rowGroup: groupAntrianNota ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["edit", "actions"] },
  printModal: { id: null, open: false, title: null },
  createUpdateModal: { id: null, open: false, title: null },
  createModalPage: 1,
  ambilAntrianModal: { id: null, open: false, title: null },
  uploadPreviewModal: { id: null, open: false, title: null },
  detailModal: { id: null, open: false, title: null },
  detailProgressModal: { id: null, open: false, title: null },
  progressModal: { id: null, open: false, title: null },
  pembayaranModal: { id: null, open: false, title: null },
  deletePembayaranModal: { id: null, open: false, title: null },
  changePersonModal: { id: null, open: false, title: null },
  hapusAntrian: { id: null, open: false, title: null },
  refetch_status: false,
  editDetailOrder: { id: null, open: false, title: null },
  editSizeQty: { id: null, open: false, title: null },
  changeDesignerModal: { id: null, open: false, title: null },
};

const AntrianNota = createSlice({
  name: "antrianNota",
  initialState,
  reducers: {
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem("antrianNota", JSON.stringify(action.payload.group));
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_PRINT_MODAL: (state, action) => {
      state.printModal = action.payload.modal;
    },
    SET_CREATE_UPDATE_MODAL: (state, action) => {
      state.createUpdateModal = action.payload.modal;
    },
    SET_CREATE_MODAL_PAGE: (state, action) => {
      state.createModalPage = action.payload;
    },
    SET_AMBIL_ANTRIAN_MODAL: (state, action) => {
      state.ambilAntrianModal = action.payload.modal;
    },
    SET_UPLOAD_PREVIEW_MODAL: (state, action) => {
      state.uploadPreviewModal = action.payload.modal;
    },
    SET_DETAIL_MODAL: (state, action) => {
      state.detailModal = action.payload.modal;
    },
    SET_DETAIL_PROGRESS_MODAL: (state, action) => {
      state.detailProgressModal = action.payload.modal;
    },
    SET_PROGRESS_MODAL: (state, action) => {
      state.progressModal = action.payload.modal;
    },
    SET_PEMBAYARAN_MODAL: (state, action) => {
      state.pembayaranModal = action.payload.modal;
    },
    SET_DELETE_PEMBAYARAN_MODAL: (state, action) => {
      state.deletePembayaranModal = action.payload.modal;
    },
    SET_CHANGE_PERSON_MODAL: (state, action) => {
      state.changePersonModal = action.payload.modal;
    },
    SET_HAPUS_ANTRIAN: (state, action) => {
      state.hapusAntrian = action.payload.modal;
    },
    SET_REFETCH_STATUS: (state, action) => {
      state.refetch_status = action.payload.refetch_status;
    },
    SET_EDIT_DETAIL_ORDER: (state, action) => {
      state.editDetailOrder = action.payload.modal;
    },
    SET_EDIT_SIZE_QTY: (state, action) => {
      state.editSizeQty = action.payload.modal;
    },
    SET_CHANGE_DESIGNER_MODAL: (state, action) => {
      state.changeDesignerModal = action.payload.modal;
    },
  },
});

export const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_PRINT_MODAL,
  SET_CREATE_UPDATE_MODAL,
  SET_CREATE_MODAL_PAGE,
  SET_AMBIL_ANTRIAN_MODAL,
  SET_UPLOAD_PREVIEW_MODAL,
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_PROGRESS_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_DELETE_PEMBAYARAN_MODAL,
  SET_CHANGE_PERSON_MODAL,
  SET_HAPUS_ANTRIAN,
  SET_REFETCH_STATUS,
  SET_EDIT_DETAIL_ORDER,
  SET_EDIT_SIZE_QTY,
  SET_CHANGE_DESIGNER_MODAL,
} = AntrianNota.actions;

export default AntrianNota.reducer;
