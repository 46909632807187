import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info as InfoIcon,
  Print as PrintIcon,
  PendingActions as ProgressIcon,
  Payments as PaymentsIcon,
  PeopleAlt as PersonIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { EventHandlers } from "../../repository";
class ActionButton {
  static getActionOpsi = ({ role, params, dispatch, statusDeleteAntrian }) => {
    const commonActions = [
      <GridActionsCellItem
        icon={<InfoIcon sx={{ color: "#2FA9EE" }} />}
        label="Detail"
        onClick={() =>
          EventHandlers.handleDetailModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<PrintIcon sx={{ color: "#2FA9EE" }} />}
        label="Print"
        onClick={() =>
          EventHandlers.handlePrintModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Print Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ProgressIcon sx={{ color: "#24BC85" }} />}
        label="Progress"
        onClick={() => {
          EventHandlers.handleDetailProgressModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Progress",
            },
          });
        }}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<PaymentsIcon sx={{ color: "#24BC85" }} />}
        label="Pembayaran"
        onClick={() =>
          EventHandlers.handlePembayaranModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Pembayaran",
            },
          })
        }
        showInMenu
      />,
    ];

    // Tambahkan tombol-tombol berdasarkan role
    let additionalActions = [];

    if (role === "root" || role === "customer_service") {
      additionalActions = [
        <GridActionsCellItem
          icon={<PersonIcon sx={{ color: "#B265D6" }} />}
          label="Person"
          onClick={() =>
            EventHandlers.handleChangePersonModal({
              dispatch,
              modal: {
                id: params.id,
                open: true,
                title: "Edit Person !",
              },
            })
          }
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon sx={{ color: "#F44336" }} />}
          label="Hapus"
          onClick={() => {
            EventHandlers.handleHapusAntrian({
              dispatch,
              modal: {
                id: params.id,
                open: true,
                title: "Delete Antrian !",
              },
            });
          }}
          showInMenu
          disabled={role !== "root" ? statusDeleteAntrian : false}
        />,
      ];
    }

    return [...commonActions, ...additionalActions];
  };

  static getActionEdit = ({
    params,
    dispatch,
    statusEditAntrian,
    statusEditSize,
  }) => {
    return [
      <GridActionsCellItem
        icon={<InfoIcon sx={{ color: "#2FA9EE" }} />}
        label="Detail Order"
        onClick={() =>
          EventHandlers.handleEditDetailOrderModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Edit Nota : ",
            },
          })
        }
        showInMenu
        disabled={statusEditAntrian}
      />,
      <GridActionsCellItem
        icon={<ProgressIcon sx={{ color: "#24BC85" }} />}
        label="Size & Qty"
        onClick={() => {
          EventHandlers.handleEditSizeQtyModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Edit Size & Qty",
            },
          });
        }}
        showInMenu
        disabled={statusEditSize}
      />,
    ];
  };
}

export default ActionButton;
