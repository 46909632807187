import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Skeleton,
} from "@mui/material";

const FooterCard = (props) => {
  const { title, value, isLoading, iconCard, color = "#3D85AE" } = props;
  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: "5px",
        borderBottom: `0.3rem solid ${color}`,
      }}
    >
      <CardContent sx={{ padding: "1rem !important", color: "#797979" }}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
          }}
          gutterBottom
        >
          {isLoading ? <Skeleton width={"50%"} /> : <span>{title}</span>}
          <span style={{ color: color }}>{iconCard}</span>
        </Typography>
        <Divider sx={{ borderWidth: "1px" }} />
        <Typography
          variant="h5"
          component="div"
          sx={{
            marginTop: "0.5rem",
            fontWeight: 700,
          }}
        >
          {isLoading ? <Skeleton /> : value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default FooterCard;
