import React, { useCallback, useEffect } from "react";
import { BlankDialog } from "../../../../components/Layout/Content";
import { FormSizeQty } from "../../../../components/Layout/Content/Antrian/EditAntrian";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { Divider, Grid } from "@mui/material";
import { CustomButton } from "../../../../components/controls";
import { useForm } from "react-hook-form";

const ModalEditSizeQty = ({ size, additional, keterangan }) => {
  const formAntrian = useForm({
    defaultValues: {
      size: [],
      confirmation: false,
    },
    mode: "all",
  });
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.data);
  const { editSizeQty } = useSelector((state) => state.antrianNota);

  const { data, isLoading, isError } = Api.detailAntrianSize({
    modal: editSizeQty,
  });

  useEffect(() => {
    if (data) {
      if (data.data) {
        const newSize = size.map((sizeItem) => {
          const getHarga = sizeItem.harga.find(
            (hargaItem) => hargaItem.jenisorder_id === data.data.jenisorder_id
          );
          const dataSize = data.data.size.find(
            (dataSize) => dataSize.size.id === sizeItem.id
          );
          if (dataSize) {
            const dataHarga = dataSize.harga;
            const dataHpp = dataSize.hpp;

            return {
              size_id: sizeItem.id,
              label: sizeItem.name,
              isActive: true,
              harga: dataHarga,
              hpp: dataHpp,
              detail_order: dataSize.order.map((orderItem) => ({
                id: orderItem.id,
                keterangan: orderItem.antrian_keterangan.map((keterangan) => ({
                  id: keterangan.id,
                  name: keterangan.keterangan.name,
                  deskripsi: keterangan.deskripsi ?? "",
                  keterangan_id: keterangan.keterangan.id,
                })),
                additional: orderItem.antrian_additional.map(
                  (additional) => additional.additional.id
                ),
              })),
              status: sizeItem.status,
            };
          }

          return {
            size_id: sizeItem.id,
            label: sizeItem.name,
            isActive: false,
            harga: getHarga ? getHarga.harga : 0,
            hpp: getHarga ? getHarga.hpp : 0,
            detail_order: [],
            status: sizeItem.status,
          };
        });

        formAntrian.setValue("size", newSize);
      }
    }
  }, [data, formAntrian, size]);

  const handleClose = useCallback(() => {
    formAntrian.reset({ size: [], confirmation: false });
    EventHandlers.handleEditSizeQtyModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch, formAntrian]);

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  const isAtLeastOneDetailOrder = () => {
    const getSize = formAntrian.watch("size");
    const activeSizes = getSize.filter((size) => size.isActive); // Filter size yang aktif
    if (activeSizes.length > 0) {
      return activeSizes.every((size) => size.detail_order.length > 0); // Periksa setiap size aktif, apakah ada detail_order
    }
    return false;
  };

  const isAtLeastOneActiveSize = () => {
    const getSize = formAntrian.watch("size");
    return getSize && getSize.some((size) => size.isActive);
  };

  const { mutate, isPending } = Api.updateOrder({
    dispatch,
    form: formAntrian,
    handleClose,
    socket,
  });

  const onSubmit = (values) => {
    const data = values;
    const size = data.size.filter((e) => e.isActive);
    data["size"] = size.map((e) => ({
      size_id: parseInt(e.size_id),
      harga: e.harga,
      hpp: e.hpp,
      detail_order: e.detail_order,
    }));

    mutate({ body: data, id: editSizeQty.id });
  };

  return (
    <BlankDialog
      maxWidth={"md"}
      open={editSizeQty.open}
      handleClose={handleClose}
      title={`${editSizeQty.title} | Nota : ${data ? data.data.nota : "-"}`}
      isLoading={isLoading || isPending}
    >
      <form onSubmit={formAntrian.handleSubmit(onSubmit)}>
        {editSizeQty.open && (
          <FormSizeQty
            {...{
              formAntrian,
              additional,
              keterangan,
              isLoading,
            }}
          />
        )}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={3}>
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          </Grid>
          <Grid item xs={3}>
            <CustomButton
              loading={isPending}
              warna={"next"}
              disabled={
                !isAtLeastOneDetailOrder() ||
                !isAtLeastOneActiveSize() ||
                !formAntrian.watch("confirmation") ||
                isLoading ||
                isPending
              }
              onClick={formAntrian.handleSubmit(onSubmit)}
            >
              Simpan
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </BlankDialog>
  );
};

export default ModalEditSizeQty;
