import React from "react";
import { Box, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

const Company = () => {
  const { setting } = useSelector((state) => state.data);
  const { name, usercode } = useSelector((state) => state.auth);

  const namaToko = setting.find((item) => item.rule === "NAMA_TOKO");
  // const alamatPerusahaan = setting.find((item) => item.rule === "ALAMAT");

  const pengaturan = {
    nama: namaToko ? (
      namaToko.value
    ) : (
      <Skeleton variant="text" width={140} sx={{ bgcolor: "#b0bec5" }} />
    ),
    name:
      name && usercode ? (
        `${usercode} | ${
          name.length > 15 ? name.substring(0, 15) + "..." : name
        }`
      ) : (
        <Skeleton variant="text" width={140} sx={{ bgcolor: "#b0bec5" }} />
      ),
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginRight: "1rem",
          textAlign: "end",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "15px",
          }}
        >
          {pengaturan.nama}
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "10px",
            lineHeight: "12.5px",
          }}
        >
          {pengaturan.name}
        </Typography>
      </Box>
    </>
  );
};

export default Company;
