import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import {
  Input,
  checkDate,
  dateFormat,
  handleFocus,
} from "../../../../controls";
import TabelAntrianGroup from "../TabelAntrianGroup";
import { ChipItems, NumericText } from "../../../../common";

// column antrian
const totalAntrian = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    filterable: false,
    minWidth: 50,
    maxWidth: 50,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <span
          style={{
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          {params.value}
        </span>
      );
    },
  },
  {
    field: "nota",
    flex: 1,
    minWidth: 100,
    hideable: false,
    headerName: "No. Nota",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          {params.value}
          {jarak.interval >= 2 && (
            <sup
              style={{
                color: jarak.warna,
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              {" "}
              {jarak.interval > 0 && jarak.interval}
            </sup>
          )}
        </span>
      );
    },
  },
  {
    field: "check_in",
    type: "date",
    flex: 1,
    minWidth: 100,
    headerName: "Check-In",
    valueGetter: (value) => new Date(value),
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      if (params.value instanceof Date && !isNaN(params.value)) {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {dateFormat(params.value)}
          </span>
        );
      }
      return "";
    },
  },
  {
    field: "nama",
    flex: 1,
    sortable: false,
    minWidth: 100,
    headerName: "Leads / Nama",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          {params.value}
        </span>
      );
    },
  },
  {
    field: "order",
    flex: 1,
    type: "number",
    sortable: false,
    minWidth: 70,
    headerName: "Order",
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <span
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(jarak.interval > 2 && {
              color: jarak.warna,
              ...(jarak.interval > 4 && { fontWeight: "bold" }),
            }),
          }}
        >
          <NumericText
            value={params.value}
            thousandSeparator
            prefix={false}
            suffix={" Pcs"}
            displayType="text"
          />
        </span>
      );
    },
  },
  {
    field: "status",
    headerName: "Progress",
    minWidth: 120,
    renderHeader: () => <strong>Progress</strong>,
    valueGetter: (value) => value.nama,
    renderCell: (params) => {
      return (
        <ChipItems
          size="small"
          label={params.value}
          backgroundcolor={params.row.status.warna}
          borderradius={"3px"}
          sx={{ height: "20px" }}
        />
      );
    },
  },
];

const ModalProduksiGroup = ({ form, data }) => {
  const rowsAntrianData = data.list.map((row, index) => ({
    ...row,
    no: index + 1, // Menambahkan properti id untuk mendapatkan nomor otomatis
  }));
  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#E0F0F9",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid container item xs={7}>
            <Grid item xs={5} fontWeight={700}>
              No. Faktur
            </Grid>
            <Grid item xs={0.5} fontWeight={700}>
              :
            </Grid>
            <Grid item xs={6.5} fontWeight={700}>
              {data.nota}
            </Grid>
            <Grid item xs={5}>
              Total Antrian
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={6.5}>
              {data.antrian} Nota
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item xs={8.5}>
              Total Order
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.size} Pcs
            </Grid>
            <Grid item xs={8.5}>
              Total Tambahan
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.additional} Pcs
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* tabel data antrian */}
      <Grid container item marginBottom={2}>
        <TabelAntrianGroup
          rows={rowsAntrianData}
          column={totalAntrian}
          // getRowId={(row) => row.nota}
          // padding={"0 1rem"}
        />
      </Grid>
      <Grid container item>
        <Input
          rules={{ required: false }}
          name={"keterangan"}
          control={form.control}
          label={"Keterangan"}
          defaultValue={""}
          multiline
          onFocus={handleFocus}
        />
      </Grid>
    </Box>
  );
};

export default ModalProduksiGroup;
