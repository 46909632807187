import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/tabelGrafikRejectSlice";
import { Config } from "../repository";

const TabelRejectTerbaru = ({ data, isLoading }) => {
  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.tabelGrafikReject);

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns: Config.column,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      setDensity={"compact"}
      showQuickFilter={false}
      withNumberColumn={true}
      getRowClassName={(e) => e.row.prioritas && `prioritas`}
      sx={{
        borderWidth: "0.5px 0 0 0",
        borderColor: "#797979",
        boxShadow: "none",
        borderRadius: 0,
      }}
    />
  );
};

TabelRejectTerbaru.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
  statusList: PropTypes.array,
};

export default TabelRejectTerbaru;
