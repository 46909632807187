import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { HashRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LicenseInfo } from "@mui/x-license";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
    fontSize: 12,
  },
});

const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(
  "e056381e3496d9846e75065fc5d6ba04Tz1BUk0sRT0zMTU3NDEyODQxNzAxNDQ5LFM9cHJlbWl1bSxMTT1wZXJwZXR1YWwsS1Y9Mg=="
);

root.render(
  <React.StrictMode>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </HashRouter>
  </React.StrictMode>
);
