import React from "react";
import { Collapse, Grid, Divider, Typography } from "@mui/material";
import LensIcon from "@mui/icons-material/Lens";
import { ChipItems } from "../../../../common";

const ChipItem = ({
  icon,
  label,
  backgroundcolor,
  iconmarginleft,
  sx,
  ...rest
}) => {
  return (
    <ChipItems
      icon={icon}
      label={label}
      backgroundcolor={backgroundcolor}
      borderradius="3px"
      iconmarginleft={iconmarginleft}
      sx={{
        fontSize: "9px",
        padding: "1px !important",
        height: "100%",
        ...sx,
      }}
      {...rest}
    />
  );
};

const CollapseDetailOrder = ({
  in: open,
  dataAntrianSize,
  keteranganNota,
  reject,
}) => {
  return (
    <Collapse in={open}>
      <Grid container mt={2} sx={{ backgroundColor: "#F3F7F8" }}>
        {dataAntrianSize.length > 0 &&
          dataAntrianSize.map((antrianSize, index) => (
            <Grid item xs={12} padding={1} fontSize={10} key={index}>
              <Grid container>
                <Grid item xs={9}>
                  <ChipItem
                    label={`${antrianSize.size.name} : ${antrianSize.qty} PCS`}
                    backgroundcolor="#105174"
                  />
                </Grid>
                <Grid item xs={3}>
                  <ChipItem
                    label={`Additional : ${antrianSize.qty_additional} Pcs`}
                    backgroundcolor="#101010"
                  />
                </Grid>
              </Grid>
              <Divider
                sx={{ margin: "0.5rem -0.5rem", borderColor: "#797979" }}
              />
              {antrianSize.order.map((orders, itemIndex) => (
                <Grid item key={itemIndex}>
                  <Grid container alignItems="start" direction={"row"}>
                    <Grid item xs={0.2}>
                      <LensIcon
                        sx={{
                          fontSize: "6px",
                          marginRight: "5px",
                          marginBottom: "0.1em",
                        }}
                      />
                    </Grid>
                    {orders.antrian_keterangan.map(
                      (keterangan, keteranganIndex) => (
                        <React.Fragment key={keteranganIndex}>
                          <Grid item xs={3}>
                            <b>{keterangan.keterangan.name}</b> :{" "}
                            {keterangan.deskripsi}
                          </Grid>
                        </React.Fragment>
                      ),
                    )}
                    <Grid container item xs={2.8} spacing={0.5}>
                      {orders.antrian_additional.length > 0 ? (
                        orders.antrian_additional.map(
                          (additional, additionalIndex) => {
                            return (
                              <Grid item key={additionalIndex}>
                                <ChipItem label={additional.additional.name} />
                              </Grid>
                            );
                          },
                        )
                      ) : (
                        <div>-</div>
                      )}
                    </Grid>
                  </Grid>
                  <Divider sx={{ margin: "0.5rem 0" }} />
                </Grid>
              ))}
            </Grid>
          ))}
      </Grid>
      {keteranganNota && (
        <Grid item xs={12} mt={2.5}>
          <Typography
            fontSize={10}
            paddingX={2}
            paddingY={1}
            backgroundColor="#FFEBE9"
            borderRadius="5px"
          >
            <b>Keterangan Order</b> : <br />
            {keteranganNota}
          </Typography>
        </Grid>
      )}

      {reject?.nominal > 0 && (
        <Grid
          container
          item
          mt={1.5}
          fontSize={10}
          justifyContent={"space-between"}
          paddingX={2}
          paddingY={1}
          backgroundColor="#F1503A"
          color={"#fff"}
          borderRadius="5px"
        >
          <Grid item>Reject : {reject.nominal}</Grid>
          <Grid item>{reject.keterangan}</Grid>
        </Grid>
      )}
    </Collapse>
  );
};

export default CollapseDetailOrder;
