import config from "../../../../config";
import {
  SET_DETAIL_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_DELETE_PEMBAYARAN_MODAL,
  SET_PRINT_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_EDIT_REJECT,
  SET_HAPUS_REJECT,
  SET_TANGGAL_REJECT_FILTER,
  SET_REFETCH_STATUS,
} from "../domain/rekapRejectSlice";
import { UPDATE_IMAGE } from "../../../imageDetailSlice";
import dayjs from "dayjs";
import { Config } from "./Config";

export class EventHandlers {
  static handleChangeFilterTanggal = ({ dispatch, queryClient, tanggal }) => {
    const areAllDates = tanggal.every((item) => dayjs(item).isValid());
    if (areAllDates) {
      const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
      const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
      queryClient.removeQueries({
        queryKey: [`${Config.variable.endpoint}/${startDate}|${endDate}`],
      });
      dispatch(SET_TANGGAL_REJECT_FILTER({ tanggal }));
    }
  };

  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/antrian/${file}`,
        title: title,
      })
    );
  };

  static handleDetailModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_MODAL({ modal }));
  };

  static handlePrintModal = ({ dispatch, modal }) => {
    dispatch(SET_PRINT_MODAL({ modal }));
  };

  static handleDetailProgressModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_PROGRESS_MODAL({ modal }));
  };

  static handlePembayaranModal = ({ dispatch, modal }) => {
    dispatch(SET_PEMBAYARAN_MODAL({ modal }));
  };

  static handleDeletePembayaran = ({ dispatch, modal }) => {
    dispatch(SET_DELETE_PEMBAYARAN_MODAL({ modal }));
  };

  static handleEditReject = ({ dispatch, modal }) => {
    dispatch(SET_EDIT_REJECT({ modal }));
  };

  static handleHapusReject = ({ dispatch, modal }) => {
    dispatch(SET_HAPUS_REJECT({ modal }));
  };

  static handleRefetchStatus = ({ dispatch, refetch_status }) => {
    dispatch(SET_REFETCH_STATUS({ refetch_status }));
  };
}
