import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { BlankDialog } from "../../../../components/Layout/Content";
import { Divider, Grid } from "@mui/material";
import { CheckboxInput } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { FormProgressGroup } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";
import { ActionGrid } from "../../../../components/common";
import { useForm } from "react-hook-form";

const ModalProgressGroup = () => {
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.data);
  const { progressGroup } = useSelector((state) => state.dikerjakanGroup);
  const form = useForm();
  const handleClose = () => {
    form.reset({ confirmation: false, keterangan: "", status_id: "" });
    EventHandlers.handleProgressGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
        data: {
          nota: null,
          keterangan: null,
          antrian: 0,
          size: 0,
          additional: 0,
          status: { id: null, nama: "", warna: "white" },
          list: [],
        },
      },
    });
  };

  const { mutate, isPending } = Api.progressAntrainGroup({
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    const data = {
      status_id: values.status_id,
      keterangan: values.keterangan,
    };

    mutate({ body: data, id: progressGroup.id });
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={progressGroup.open}
      handleClose={handleClose}
      title={progressGroup.title}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormProgressGroup form={form} data={progressGroup.data} />

        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              type="submit"
              disabled={form.watch("confirmation") ? false : true}
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalProgressGroup;
