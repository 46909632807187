import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalCreateUpdateTarget, TabelTarget } from "./component";
import { Api, EventHandlers, useFormPengaturanTarget } from "./repository";

const TargetPage = () => {
  const form = useFormPengaturanTarget();

  const dispatch = useDispatch();

  const { createUpdateModal, hapusTarget } = useSelector(
    (state) => state.pengaturanTarget
  );

  const handleClose = () => {
    form.setValue("bulan", "");
    form.setValue("tahun", "");
    form.setValue("jumlah", 0);
    form.setValue("confirmation", false);
    if (createUpdateModal) {
      EventHandlers.handleCreateUpdate({
        dispatch,
        modal: { id: null, open: false, title: null },
      });
    }
    if (hapusTarget) {
      EventHandlers.handleHapusTarget({
        dispatch,
        modal: { id: null, open: false, title: null },
      });
    }
  };

  const { data } = Api.read();
  const { mutate: createMutate, isPending: isPendingCreate } = Api.create({
    form,
    handleClose,
  });
  const { mutate: updateMutate, isPending: isPendingUpdate } = Api.update({
    form,
    handleClose,
  });
  const { mutate: deleteMutate, isPending: isPendingDelete } = Api.delete({
    form,
    handleClose,
  });

  const onSubmit = (value) => {
    if (createUpdateModal.id) {
      updateMutate({ body: value, id: createUpdateModal.id });
    } else {
      if (hapusTarget.id) {
        deleteMutate(hapusTarget.id);
      } else {
        createMutate(value);
      }
    }
  };

  return (
    <>
      <TabelTarget
        data={data ? data.data : []}
        isLoading={false}
        onSubmit={onSubmit}
        form={form}
      />

      <ModalCreateUpdateTarget
        {...{
          data,
          form,
          dispatch,
          onSubmit,
          handleClose,
          isPendingCreate,
          isPendingUpdate,
          isPendingDelete,
        }}
      />
    </>
  );
};

export default TargetPage;
