import React, { useRef } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import { CustomDateRange } from "../../../../components/controls";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/rekapRejectSlice";
import { Config, EventHandlers } from "../repository";
import ActionButton from "./TabelRekapReject/ActionButton";
import FooterCardRekapReject from "./TabelRekapReject/FooterCardRekapReject";

const TabelRekapReject = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    tanggalRejectFilter,
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.rekapReject);

  const { role } = useSelector((state) => state.auth);

  const columns = Config.column.map((e) => {
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            return ActionButton.getActionOpsi({
              role,
              params,
              dispatch,
              handlePrint,
            });
          }

          return [];
        },
      };
    }
    return e;
  });

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
      footerComponent={({ finalData }) => {
        return <FooterCardRekapReject data={finalData} isLoading={isLoading} />;
      }}
      customToolbar={
        <Box sx={{ p: 1 }}>
          <CustomDateRange
            title="Filter Tanggal Reject"
            defaultValue={tanggalRejectFilter}
            onChange={(values) =>
              EventHandlers.handleChangeFilterTanggal({
                dispatch,
                queryClient,
                tanggal: values,
              })
            }
          />
        </Box>
      }
    />
  );
};

TabelRekapReject.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TabelRekapReject;
