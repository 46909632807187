import React from "react";
import { Controller } from "react-hook-form";
import { FormControlLabel } from "@mui/material";
import SwitchButton from "../common/SwitchButton";

const SwitchInput = (props) => {
  const {
    name,
    control,
    label,
    defaultValue = false,
    labelPlacement = "start",
    labelsize,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange } }) => (
        <FormControlLabel
          control={
            <SwitchButton
              name={name}
              value={value}
              onChange={onChange}
              checked={value}
              sx={{
                "& .MuiSwitch-track": {
                  border:
                    value === true ? "none" : "3px solid #797979 !important",
                },
                "& .Mui-checked + .MuiSwitch-track": {
                  border: "none",
                  backgroundColor: "#35AAFF !important",
                  opacity: "1 !important",
                  transition:
                    "background-color 250ms ease-in-out, border 150ms ease-in-out",
                },
                "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                  backgroundColor:
                    value === true
                      ? "rgba(53, 170, 255,0.5) !important"
                      : "none",
                },
                "& .MuiSwitch-thumb": {
                  backgroundColor: "#797979",
                  boxShadow: "none",
                  width: 12,
                  height: 12,
                  margin: "4px",
                  transition: "background-color 300ms ease-in-out",
                },
                "& .Mui-disabled": {
                  "& .MuiSwitch-thumb": {
                    backgroundColor:
                      value === true ? "none" : "rgba(121, 121, 121, 0.5)",
                  },
                },
              }}
            />
          }
          label={label}
          labelPlacement={labelPlacement}
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: labelsize,
              color: "#797979",
              fontWeight: 700,
            },
          }}
          {...rest}
        />
      )}
    />
  );
};

export default SwitchInput;
