import React from "react";
import { Grid, Typography } from "@mui/material";
import LoadingProgress from "../../../../common/LoadingProgress";
import QRCode from "react-qr-code";

const GroupDetailAntrian = ({ data }) => {
  return (
    <Grid
      container
      sx={{
        justifyContent: "space-between",
        fontSize: "12px",
        fontWeight: "400",
        marginBottom: "0.5rem",
      }}
    >
      <Grid container item borderRadius="5px">
        <Grid item md={10}>
          <Grid
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <Grid item xs={3}>
              No. Faktur
            </Grid>
            <Grid item xs={0.2}>
              :
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {data.nota}
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              Total Antrian
            </Grid>
            <Grid item xs={0.2}>
              :
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {data.antrian} Nota
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              Total Order
            </Grid>
            <Grid item xs={0.2}>
              :
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {data.size} Pcs
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              Total Additional
            </Grid>
            <Grid item xs={0.2}>
              :
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {data.additional} Pcs
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          md={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
            height: "100%",
          }}
        >
          {data.nota && <QRCode value={data.nota} size={100} />}
        </Grid>
      </Grid>

      {/* loadaing progress */}
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={1}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2px",
          }}
        >
          <Typography variant="subtitle2" marginRight={0.5}>
            Progress :{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            color={data.status.warna}
            fontWeight={700}
          >
            {data.status.status}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingProgress
            progress={data.status.progress}
            barWidth={"100%"}
            barcolor={data.status.warna}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GroupDetailAntrian;
