import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {
  Assignment as AntrianIcon,
  StickyNote2 as FakturIcon,
} from "@mui/icons-material";
import { FooterCard, NumericText } from "../../../../components/common";

const FooterCardAntrianGroup = ({ data, isLoading }) => {
  const total_antrian = data ? data.length : 0;
  const total_nota = data
    ? data.reduce((total, note) => {
        return total + note.group.nota.length;
      }, 0)
    : 0;

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <FooterCard
          title={"Total Antrian Group"}
          value={<NumericText value={total_antrian} suffix={" Faktur"} />}
          isLoading={isLoading}
          iconCard={<FakturIcon />}
        />
      </Grid>
      <Grid item xs={6}>
        <FooterCard
          title={"Total In Group"}
          value={<NumericText value={total_nota} suffix={" Nota"} />}
          isLoading={isLoading}
          iconCard={<AntrianIcon />}
        />
      </Grid>
    </Grid>
  );
};

FooterCardAntrianGroup.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default FooterCardAntrianGroup;
