import { createSlice } from "@reduxjs/toolkit";
import {
  penata_router,
  admin_produksi_router,
  customer_service_router,
  desainer_router,
  owner_router,
  root_router,
} from "../config/router";

const initialState = {
  user_id: null,
  name: null,
  username: null,
  usercode: null,
  role: null,
  photo: null,
  menuList: [],
};

const AuthSlicer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_USER: (state, action) => {
      state.user_id = action.payload.user.id;
      state.name = action.payload.user.name;
      state.username = action.payload.user.username;
      state.usercode = action.payload.user.usercode;
      state.role = action.payload.user.role;
      state.photo = action.payload.user.photo;
      if (action.payload.user.role === "root") {
        state.menuList = root_router;
      }
      if (action.payload.user.role === "customer_service") {
        state.menuList = customer_service_router;
      }
      if (action.payload.user.role === "desainer") {
        state.menuList = desainer_router;
      }
      if (action.payload.user.role === "admin_produksi") {
        state.menuList = admin_produksi_router;
      }
      if (action.payload.user.role === "penata") {
        state.menuList = penata_router;
      }
      if (action.payload.user.role === "owner") {
        state.menuList = owner_router;
      }
    },
    RESET_USER: () => initialState,
  },
});

export const { SET_USER, RESET_USER } = AuthSlicer.actions;

export default AuthSlicer.reducer;
