import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import Login from "../../../pages/Login";
import Initial from "../../../pages/Initial";
import DataUser from "../../../pages/DataUser";
import Dashboard from "../../../pages/Dashboard";
import Rekap from "../../../pages/Rekap";
import Antrian from "../../../pages/Antrian";
import Pendapatan from "../../../pages/Pendapatan";
import SedangDikerjakan from "../../../pages/SedangDikerjakan";
import Pencapaian from "../../../pages/Pencapaian";
import Pengaturan from "../../../pages/Pengaturan";
import Checkout from "../../../pages/Checkout";
import CashFlow from "../../../pages/CashFlow";
import Retur from "../../../pages/Retur";
import ManageGroup from "../../../pages/ManageGroup";
import ToLogin from "../../../pages/ToLogin";
import RequireAuth from "../../../components/RequireAuth";
import { NotFound, Stole } from "../../../pages/ErrorPage";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* Public */}
        <Route path="/login" element={<Login />} />
        <Route path="/locked" element={<Stole />} />
        {/* Protected Routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Initial />} />

          {/* ROLE BASED */}
          {/* Owner, Customer Service */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/pendapatan" element={<Pendapatan />} />
          <Route path="/cashflow" element={<CashFlow />} />
          {/* add Root */}
          <Route path="/rekap" element={<Rekap />} />
          <Route path="/retur" element={<Retur />} />

          {/* Customer Service, Desainer, Admin Produksi, Root */}
          <Route path="/antrian" element={<Antrian />} />

          {/* Customer Service, Desainer, Admin Produksi, Root */}
          <Route path="/manage-group" element={<ManageGroup />} />

          {/* Desainer & Admin Produksi */}
          <Route path="/my-project" element={<SedangDikerjakan />} />
          {/* Add Owner */}
          <Route path="/pencapaian" element={<Pencapaian />} />

          {/* Owner only */}
          <Route path="/pengaturan" element={<Pengaturan />} />
          <Route path="/data_user" element={<DataUser />} />

          {/* CS only */}
          <Route path="/checkout" element={<Checkout />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<ToLogin />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
