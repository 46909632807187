import dayjs from "dayjs";
import { Config } from ".";
import {
  useFetchingData,
  useDeletingData,
  useUpdatingData,
} from "../../../hooks";

export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = ({ startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const endpoint = `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`;
    return useFetchingData({
      endpoint,
    });
  };

  static searchKecamatan = (props) => {
    const { search } = props;
    return useFetchingData({
      endpoint: "kecamatan",
      params: { params: { search } },
      enabled: search.length > 2 ? true : false,
      gcTime: 0,
    });
  };

  static updateDetail = ({ handleClose, form, tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `antrian/edit/detail`,
      invalidated: [`${Config.variable.endpoint}/${startDate}|${endDate}`],
      onSuccess: () => {
        handleClose();
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static updateOrder = ({ handleClose, form, socket, tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `antrian/edit/order`,
      invalidated: [`${Config.variable.endpoint}/${startDate}|${endDate}`],
      onSuccess: () => {
        handleClose();
        if (socket) {
          socket.emit("dashboard_business_progress");
          socket.emit("pendapatan");
        }
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static detailAntrianEdit = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/edit/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianSize = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/size/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static searchAntrianSelesai = ({ search }) => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/antrian/selesai`,
      params: { params: { search } },
      enabled: search.length > 2 ? true : false,
      gcTime: 0,
    });
  };

  static returAntrian = ({ onSuccess, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
      ],
      onSuccess,
      onError: () => {
        return;
      },
    });
  };

  static kirimUlang = ({ onSuccess, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/kirim_ulang`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
      ],
      onSuccess,
      onError: () => {
        return;
      },
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/progress/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static printAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/print/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static deleteAntrian = ({ handleClose, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useDeletingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
      ],
      onSuccess: () => {
        handleClose();
      },
    });
  };

  static detailPembayaran = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/pembayaran/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static updatePembayaran = ({ id, onSuccess, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `antrian/create/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
        `antrian/pembayaran/${id}`,
      ],
      onSuccess,
      onError: () => {
        return;
      },
    });
  };

  static deletePembayaran = ({ id, handleClose, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useDeletingData({
      endpoint: `antrian/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
        `antrian/pembayaran/${id}`,
      ],
      onSuccess: () => {
        handleClose();
      },
    });
  };

  static personAntrian = ({ handleClose, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `antrian/person`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
      ],
      onSuccess: () => {
        handleClose();
      },
    });
  };
}
