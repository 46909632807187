import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ViewImage } from "../../../components/Layout/Content/Image";
import { RESET_IMAGE } from "../../imageDetailSlice";

const PopupImage = () => {
  const dispatch = useDispatch();

  const { url, title } = useSelector((state) => state.imageDetail);

  const onCloseImage = () => {
    dispatch(RESET_IMAGE());
  };

  return <ViewImage onClose={onCloseImage} url={url} title={title} />;
};

export default PopupImage;
