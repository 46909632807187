import { useCreatingData } from "../../../../hooks";

export class Api {
  static updateSystem = ({ onSuccess, socket }) => {
    return useCreatingData({
      endpoint: "/pengaturan/system",
      invalidated: [
        "jenis_order",
        "source_order",
        "ekspedisi",
        "size",
        "additional",
        "status",
        "keterangan",
      ],
      onSuccess: () => {
        onSuccess();
        socket.emit("setting_system");
      },
    });
  };
}
