import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { BlankDialog } from "../../../../components/Layout/Content";
import { Divider, Grid } from "@mui/material";
import { CheckboxInput } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { FormAddNotaGroup } from "../../../../components/Layout/Content/DataGroup/FormDataGroup";
import { useForm } from "react-hook-form";
import { ActionGrid } from "../../../../components/common";

const ModalAddNotaGroup = ({ dataAntrian }) => {
  const form = useForm();
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.data);
  const { addNotaGroup } = useSelector((state) => state.antrianGroup);
  const handleClose = () => {
    EventHandlers.handleAddNotaGroup({
      dispatch,
      modal: { id: null, open: false, title: null, data: [] },
    });
  };

  const { mutate, isPending } = Api.updateNota({
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = () => {
    const data = {
      antrian_id: addNotaGroup.data.map((item) => item.id),
    };
    mutate({ body: data, id: addNotaGroup.id });
  };

  const onAddNota = (data) => {
    EventHandlers.handleAddDataNotaOnNotaGroup({
      dispatch,
      data,
    });
    form.setValue("confirmation", false);
  };

  const onRemoveNota = (id) => {
    EventHandlers.handleRemoveDataNotaOnNotaGroup({
      dispatch,
      id,
    });
    form.setValue("confirmation", false);
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={addNotaGroup.open}
      handleClose={handleClose}
      title={addNotaGroup.title}
      outsideClose={false}
    >
      <FormAddNotaGroup
        dataAntrian={dataAntrian}
        antrian={addNotaGroup.data}
        onRemoveNota={onRemoveNota}
        onAddNota={onAddNota}
      />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              type="submit"
              disabled={form.watch("confirmation") ? false : true}
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalAddNotaGroup;
