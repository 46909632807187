import { SET_TAHUN_PENDAPATAN } from "../domain/pendapatanTahunanSlice";
import { Config } from "./Config";

export class EventHandlers {
  static handleTahun = ({ dispatch, tahun, oldTahun, queryClient }) => {
    dispatch(SET_TAHUN_PENDAPATAN(tahun));
    queryClient.removeQueries({
      queryKey: [`${Config.variable.endpoint}?tahun=${oldTahun}`],
    });
  };
}
