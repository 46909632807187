import {
  SET_BULAN_PENDAPATAN,
  SET_TAHUN_PENDAPATAN,
} from "../domain/pendapatanBulananSlice";
import { Config } from "./Config";

export class EventHandlers {
  static handleBulan = ({ dispatch, bulan, oldBulan, tahun, queryClient }) => {
    dispatch(SET_BULAN_PENDAPATAN(bulan));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}?bulan=${oldBulan}&tahun=${tahun}`,
      ],
    });
  };
  static handleTahun = ({ dispatch, bulan, oldTahun, tahun, queryClient }) => {
    dispatch(SET_TAHUN_PENDAPATAN(tahun));
    queryClient.removeQueries({
      queryKey: [
        `${Config.variable.endpoint}?bulan=${bulan}&tahun=${oldTahun}`,
      ],
    });
  };
}
