import React from 'react';
import { Api } from './repository';
import { useSelector } from 'react-redux';
import { TabelPendapatanTahunan } from './components';
import { PrintPendapatanTahunan } from './components/PrintPendapatanTahunan';

const PendapatanTahunanPage = ({ componentRefPT }) => {
  const { tahun } = useSelector((state) => state.pendapatanTahunan);
  const dataTahunan = Api.pendapatanTahunan({
    tahun: tahun,
  });

  return (
    <>
      <TabelPendapatanTahunan
        data={dataTahunan.data ? dataTahunan.data.data : []}
        isLoading={dataTahunan.isLoading}
      />
      <div style={{ display: 'none' }}>
        <PrintPendapatanTahunan
          ref={componentRefPT}
          Data={dataTahunan.data ? dataTahunan.data.data : []}
          tahun={tahun}
        />
      </div>
    </>
  );
};

export default PendapatanTahunanPage;
