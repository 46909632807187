import React, { useRef, useState } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Skeleton,
  Typography,
  Autocomplete,
  TextField,
  ListItem,
} from "@mui/material";
import TabelAntrianGroup from "../TabelAntrianGroup";
import {
  ContentPasteSearch as DataSearch,
  Search as SearchIcon,
  Delete as DeleteIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { ChipItems } from "../../../../common";

const FormAddNotaGroup = ({
  dataAntrian,
  antrian,
  onRemoveNota,
  onAddNota,
}) => {
  const totalAntrian = [
    {
      field: "nota",
      flex: 1,
      minWidth: 100,
      hideable: false,
      headerName: "No. Nota",
      display: "flex",
      renderHeader: () => <strong>No. Nota</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.value}</Typography>
          </>
        );
      },
    },
    {
      field: "nama",
      flex: 1,
      sortable: false,
      minWidth: 130,
      display: "flex",
      renderHeader: () => <strong>Leads / Nama</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.value}</Typography>
          </>
        );
      },
    },
    {
      field: "order",
      flex: 1,
      minWidth: 70,
      hideable: false,
      headerName: "Order",
      display: "flex",
      renderHeader: () => <strong>Order</strong>,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.value} Pcs</Typography>
          </>
        );
      },
    },
    {
      field: "status",
      flex: 1,
      minWidth: 130,
      hideable: false,
      headerName: "Progress",
      display: "flex",
      renderHeader: () => <strong>Progress</strong>,
      valueGetter: (value) => value.nama,
      renderCell: (params) => {
        return (
          <ChipItems
            size="small"
            label={params.value}
            backgroundcolor={params.row.status.warna}
            borderradius={"3px"}
            sx={{ height: "20px" }}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      hideable: false,
      renderHeader: () => <DeleteForeverIcon />,
      renderCell: (params) => {
        return (
          <>
            <IconButton onClick={() => onRemoveNota(params.id)}>
              <DeleteIcon
                sx={{
                  fontSize: "21px",
                  color: "#fff",
                  backgroundColor: "rgba(147, 147, 147, 1)",
                  padding: "4px",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor: "rgba(255, 0, 0, 1)",
                  },
                }}
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const inputRef = useRef(null);

  const handleInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
  };

  const filteredOptions =
    inputValue.length >= 3
      ? dataAntrian.map((item) => ({
          id: item.id,
          label: item.nota + " | " + item.nama,
          disabled:
            antrian.some((disabled) => disabled.id === item.id) ||
            item.groupped,
          data: item,
        }))
      : [];

  const autocompleteRef = useRef(null);

  const handleSelectChange = (_, newInputValue) => {
    setSelectedValue(newInputValue);
    if (newInputValue) {
      const data = {
        id: newInputValue.data.id,
        nota: newInputValue.data.nota,
        nama: newInputValue.data.nama,
        order: newInputValue.data.total_qty,
        prioritas: newInputValue.data.prioritas,
        status: {
          warna: newInputValue.data.status.warna,
          nama: newInputValue.data.status.status,
        },
      };
      onAddNota(data);
      handleInputChange(_, "");
      setSelectedValue(null);
    }
  };

  return (
    <Stack>
      <Grid container marginBottom={2}>
        <Paper
          elevation={0}
          component="form"
          sx={{
            border: "0.5px solid rgba(180, 180, 180, 0.5)",
            borderRadius: "5px",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onInputChange={handleInputChange}
            getOptionDisabled={(option) => option.disabled}
            options={filteredOptions}
            inputValue={inputValue}
            value={selectedValue}
            sx={{
              flex: 1,
              "& .MuiAutocomplete-inputRoot": { padding: 0 },
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <ListItem
                  key={key}
                  {...optionProps}
                  sx={{
                    position: "relative",
                    backgroundColor:
                      option.data.prioritas === true
                        ? "rgba(255, 251, 175, 0.53)"
                        : "",
                  }}
                >
                  {option.label}
                  <ChipItems
                    size="small"
                    label={option.data.status.status}
                    backgroundcolor={option.data.status.warna}
                    borderradius={"3px"}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: 5,
                      transform: "translateY(-50%)",
                      height: "20px",
                    }}
                  />
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search NOTA | Nama min 3 char"
                inputRef={inputRef}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <IconButton
                      type="button"
                      sx={{ p: "8px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
            freeSolo
            onChange={handleSelectChange}
            ref={autocompleteRef}
          />
        </Paper>
      </Grid>
      {antrian.length > 0 ? (
        <Grid container>
          <TabelAntrianGroup
            rows={antrian}
            column={totalAntrian}
            getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
          />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} position={"relative"}>
            <Skeleton variant="rounded" width={"100%"} height={100} />
            <DataSearch
              sx={{
                color: "rgba(201, 201, 201, 0.69)",
                fontSize: "70px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Grid>
        </Grid>
      )}
      {/* tabel data antrian */}
    </Stack>
  );
};

export default FormAddNotaGroup;
