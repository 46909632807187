import { Config } from ".";
import { useFetchingData } from "../../../../hooks";

export class Api {
  // READ DATA STATUS PEMBAYARAN
  static pendapatanTahunan = ({ tahun }) => {
    const endpoint = `${Config.variable.endpoint}?tahun=${tahun}`;
    return useFetchingData({
      endpoint,
    });
  };
}
