import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCachedData } from "../../../hooks";
import {
  DetailAntrian,
  DetailPembayaran,
  DetailProgress,
  ModalAmbilAntrian,
  ModalAntrian,
  ModalUpload,
  ModalHapusAntrian,
  TabelAntrian,
  PrintAntrian,
  ModalChangePerson,
  ModalProgress,
  ModalEditDetailOrder,
  ModalEditSizeQty,
  ModalEditDesigner,
} from "./components";
import { Api, useFormAntrian } from "./repository";

const NotaPage = () => {
  const dispatch = useDispatch();

  const { socket } = useSelector((state) => state.data);

  const formAntrian = useFormAntrian();

  const { data, isLoading } = Api.read();

  const createAntrian = Api.create({ dispatch, form: formAntrian, socket });

  const getDataStatus = useGetCachedData({ queryKey: "status" });
  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });
  const getDataSize = useGetCachedData({ queryKey: "size" });
  const getDataAdditional = useGetCachedData({ queryKey: "additional" });
  const getDataEkspedisi = useGetCachedData({ queryKey: "ekspedisi" });
  const getDataKeterangan = useGetCachedData({ queryKey: "keterangan" });
  const getDataMetodePembayaran = useGetCachedData({
    queryKey: "metode_pembayaran/cashless",
  });

  const onSubmit = (values) => {
    const data = values;
    const size = data.size.filter((e) => e.isActive);
    const totalOrderSize = size.reduce(
      (partialSize, size) =>
        partialSize + size.harga * size.detail_order.length,
      0
    );

    // Hitung total order tambahan
    const totalOrderAdditional = size.reduce((partialOrder, size) => {
      const additionalTotal = size.detail_order.reduce(
        (partialAdditional, order) => {
          const additionalHarga = order.additional.reduce(
            (partialAdditional, addId) => {
              // Cari harga berdasarkan ID pada array additional
              const cariAdditional = getDataAdditional.data.find(
                (item) => item.id === addId
              );
              const cariHargaAdditional = cariAdditional.harga.find(
                (item) =>
                  item.jenisorder_id ===
                  formAntrian.watch("antrian.jenisorder_id")
              );
              return (
                partialAdditional +
                (cariHargaAdditional ? cariHargaAdditional.harga : 0)
              ); // Tambahkan harga jika ditemukan
            },
            0
          );
          return partialAdditional + additionalHarga;
        },
        0
      );
      return partialOrder + additionalTotal;
    }, 0);
    data["size"] = size.map((e) => ({
      size_id: parseInt(e.size_id),
      harga: e.harga,
      detail_order: e.detail_order,
    }));
    data["detail"]["kecamatan_id"] = data["detail"]["kecamatan_id"]["id"];
    data["antrian"]["total_pembelian"] = totalOrderSize + totalOrderAdditional;
    createAntrian.mutate({ ...data });
  };

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelAntrian
        data={data ? data.data : []}
        isLoading={isLoading}
        sourceOrder={getDataSourceOrder ? getDataSourceOrder.data : []}
        jenisOrder={getDataJenisOrder ? getDataJenisOrder.data : []}
        statusList={getDataStatus ? getDataStatus.data.default : []}
      />

      {/* POPUPS */}
      <ModalAntrian
        {...{
          formAntrian,
          isLoading: createAntrian.isPending,
          onSubmit: formAntrian.handleSubmit(onSubmit),
          sourceOrder: getDataSourceOrder ? getDataSourceOrder.data : [],
          jenisOrder: getDataJenisOrder ? getDataJenisOrder.data : [],
          size: getDataSize ? getDataSize.data : [],
          additional: getDataAdditional ? getDataAdditional.data : [],
          ekspedisi: getDataEkspedisi ? getDataEkspedisi.data : [],
          keterangan: getDataKeterangan ? getDataKeterangan.data : [],
          metodePembayaran: getDataMetodePembayaran
            ? getDataMetodePembayaran.data
            : [],
        }}
      />

      <ModalUpload />

      <DetailAntrian />

      <DetailProgress />

      <DetailPembayaran />

      <ModalAmbilAntrian />

      <ModalHapusAntrian />

      <ModalProgress />

      <ModalChangePerson />

      <ModalEditDesigner />

      {/* editAntrian */}
      <ModalEditDetailOrder
        {...{
          sourceOrder: getDataSourceOrder ? getDataSourceOrder.data : [],
          jenisOrder: getDataJenisOrder ? getDataJenisOrder.data : [],
          ekspedisi: getDataEkspedisi ? getDataEkspedisi.data : [],
        }}
      />
      <ModalEditSizeQty
        {...{
          size: getDataSize ? getDataSize.data : [],
          additional: getDataAdditional ? getDataAdditional.data : [],
          keterangan: getDataKeterangan ? getDataKeterangan.data : [],
        }}
      />

      <PrintAntrian />
    </>
  );
};

export default NotaPage;
