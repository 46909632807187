import React from "react";
import { Lightbox } from "react-modal-image";

const ViewImage = ({ url, onClose, title }) => {
  return (
    <div>
      {url && (
        <Lightbox small={url} large={url} alt={title} onClose={onClose} />
      )}
    </div>
  );
};

export default ViewImage;
