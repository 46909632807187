import React, { useEffect, useState } from "react";
import { Grid, Divider, TextField } from "@mui/material";
import { useDebounce } from "use-debounce";
import {
  AutocompleteDynamic,
  CheckboxInput,
  Input,
  Select,
} from "../../../../controls";

const FormDetailOrder = (props) => {
  const {
    formAntrian,
    jenisOrder,
    sourceOrder,
    ekspedisi,
    Api,
    data,
    isLoading,
  } = props;

  const [search, setSearch] = useState("");

  const [debounceSearch] = useDebounce(search, 1000);

  const getKecamatan = Api.searchKecamatan({ search: debounceSearch });
  const checkEkspedisi = (type) => {
    const getEkspedisiID = formAntrian.watch("ekspedisi_id");
    if (getEkspedisiID) {
      const filterEkpedisi = ekspedisi.find((e) => e.id === getEkspedisiID);

      if (filterEkpedisi.tipe === "online") {
        if (type === "rules") {
          return "No. Pengiriman Wajib di isi!";
        }
        return undefined;
      } else {
        if (type === "display") {
          return "none";
        }
        return undefined;
      }
    } else {
      return "none";
    }
  };

  const handleEkspedisiChange = (event) => {
    formAntrian.clearErrors("keterangan_ekspedisi");
    formAntrian.setValue("ekspedisi_id", event.target.value);
    const selectedEkspedisi = ekspedisi.find(
      (e) => e.id === event.target.value
    );
    if (selectedEkspedisi && selectedEkspedisi.tipe !== "online") {
      formAntrian.setValue("keterangan_ekspedisi", "");
    }
  };

  const handleChangeJenisOrder = (value) => {
    formAntrian.setValue("jenisorder_id", "");
    formAntrian.setValue("jenisorder_id", value);
  };

  useEffect(() => {
    if (data) {
      formAntrian.setValue("prioritas", data?.prioritas);
      formAntrian.setValue("nama", data?.nama);
      formAntrian.setValue("no_hp", data?.no_hp);
      formAntrian.setValue("alamat", data?.alamat);
      formAntrian.setValue("kecamatan_id", data?.kecamatan_id);
      formAntrian.setValue("jenisorder_id", data?.jenisorder_id);
      formAntrian.setValue("sourceorder_id", data?.sourceorder_id);
      formAntrian.setValue("ekspedisi_id", data?.ekspedisi_id);
      formAntrian.setValue(
        "keterangan_ekspedisi",
        data?.keterangan_ekspedisi ?? ""
      );
      formAntrian.setValue("keterangan", data?.keterangan ?? "");
    }
  }, [data, formAntrian]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Input
            rules={{ required: "Nama Harus di isi!" }}
            name={"nama"}
            control={formAntrian.control}
            label={"Leads / Nama"}
            defaultValue={""}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            rules={{ required: "No HP Harus di isi!" }}
            name={"no_hp"}
            control={formAntrian.control}
            label={"No. HP"}
            defaultValue={""}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <AutocompleteDynamic
            rules={{ required: "Kecamatan Harus di isi!" }}
            name={"kecamatan_id"}
            control={formAntrian.control}
            label={"Kecamatan"}
            defaultValue={null}
            options={
              getKecamatan.data
                ? getKecamatan.data.data.map((e) => {
                    return {
                      id: e.id,
                      label: e.kecamatan,
                      kabupaten: e.kabupaten,
                      provinsi: e.provinsi,
                      render: `${e.kecamatan} | ${e.kabupaten} | ${e.provinsi}`,
                    };
                  })
                : []
            }
            isLoading={getKecamatan.isLoading}
            inputValue={search}
            onInputChange={(event, newInputValue) => {
              if (event?.type === "change") {
                setSearch(newInputValue);
              }
            }}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant={"outlined"}
            label={`Kabupaten`}
            size="small"
            sx={{
              "& .MuiInputBase-root": {
                color: "#797979",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& .Mui-disabled": {
                  fontWeight: 700,
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                marginTop: "5px",
                padding: "8.7px 14px",
              },
              "& .MuiInputLabel-shrink": {
                fontWeight: 500,
                top: "-5px",
              },
              "& legend": {
                display: "none",
              },
              "& .MuiFormHelperText-root": {
                fontWeight: 500,
                backgroundColor: "#fff",
                paddingX: "5px",
                position: "absolute",
                top: 23,
                right: 0,
              },
              "& .Mui-disabled + .MuiInputBase-root": {
                borderRadius: "6px",
                backgroundColor: "#ECECEC",
                "& .MuiInputAdornment-root": {
                  "& .MuiTypography-root": {
                    fontWeight: 700,
                  },
                },
              },
              "& .MuiInputAdornment-root": {
                "& .MuiTypography-root": {
                  color: "rgb(121, 121, 121, 0.9)",
                },
              },
            }}
            value={formAntrian.watch("kecamatan_id")?.kabupaten || ""}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant={"outlined"}
            label={`Provinsi`}
            size="small"
            sx={{
              "& .MuiInputBase-root": {
                color: "#797979",
                borderRadius: "5px",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& .Mui-disabled": {
                  fontWeight: 700,
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                marginTop: "5px",
                padding: "8.7px 14px",
              },
              "& .MuiInputLabel-shrink": {
                fontWeight: 500,
                top: "-5px",
              },
              "& legend": {
                display: "none",
              },
              "& .MuiFormHelperText-root": {
                fontWeight: 500,
                backgroundColor: "#fff",
                paddingX: "5px",
                position: "absolute",
                top: 23,
                right: 0,
              },
              "& .Mui-disabled + .MuiInputBase-root": {
                borderRadius: "6px",
                backgroundColor: "#ECECEC",
                "& .MuiInputAdornment-root": {
                  "& .MuiTypography-root": {
                    fontWeight: 700,
                  },
                },
              },
              "& .MuiInputAdornment-root": {
                "& .MuiTypography-root": {
                  color: "rgb(121, 121, 121, 0.9)",
                },
              },
            }}
            value={formAntrian.watch("kecamatan_id")?.provinsi || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            rules={{ required: false }}
            name={"alamat"}
            control={formAntrian.control}
            label={"Alamat Lengkap"}
            defaultValue={""}
            rows={3}
            multiline
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Select
            disabled={jenisOrder.length ? false : true}
            name={"jenisorder_id"}
            control={formAntrian.control}
            label={"Jenis Order"}
            options={jenisOrder.filter((jenis_order) => jenis_order.status)}
            rules={{ required: true }}
            defaultValue={""}
            onChange={(event) => handleChangeJenisOrder(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            disabled={sourceOrder.length ? false : true}
            name={"sourceorder_id"}
            control={formAntrian.control}
            label={"Source Order"}
            options={sourceOrder.filter((source_order) => source_order.status)}
            rules={{ required: true }}
            defaultValue={""}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            disabled={ekspedisi.length ? false : true}
            name={"ekspedisi_id"}
            control={formAntrian.control}
            label={"Expedisi"}
            options={ekspedisi.filter((ekspedisi) => ekspedisi.status)}
            rules={{ required: true }}
            defaultValue={""}
            onChange={handleEkspedisiChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            rules={{ required: checkEkspedisi("rules") }}
            name={"keterangan_ekspedisi"}
            control={formAntrian.control}
            label={"No. Pengiriman"}
            defaultValue={""}
            disabled={checkEkspedisi("display") ? true : false}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            rules={{ required: false }}
            name={"keterangan"}
            control={formAntrian.control}
            label={"Keterangan Nota"}
            defaultValue={""}
            rows={3}
            multiline
            disabled={isLoading}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"end"}
        alignItems={"center"}
        marginBottom={"-1rem"}
      >
        <CheckboxInput
          name={"confirmation"}
          control={formAntrian.control}
          label={"Pastikan data sudah benar!"}
          rules={{ required: false }}
          defaultValue={false}
        />
      </Grid>
    </>
  );
};

export default FormDetailOrder;
