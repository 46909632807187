import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers, useFormCardSourceOrder } from "./repository";
import { ChartPencapaianSourceOrder, FilterBulanTahun } from "./components";
import {
  AppBar,
  Divider,
  Grid,
  Paper,
  Typography,
  Tab,
  Tabs,
  IconButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { a11yProps } from "../../../components/common";
import { ViewList as ListIcon } from "@mui/icons-material";

const bulan = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const CardGrafikSourceOrder = () => {
  const dispatch = useDispatch();
  const form = useFormCardSourceOrder();
  const {
    tab,
    toggleView,
    harianBulan,
    harianTahun,
    bulananTahun,
    tahunanTahun1,
    tahunanTahun2,
  } = useSelector((state) => state.dashboardCardSourceOrder);
  const [loading, setLoading] = useState(false);

  // HARIAN
  const [categoryHarian, setCategoryHarian] = useState([]);
  const [dataHarianNominal, setDataHarianNominal] = useState([]);
  const [dataHarianQty, setDataHarianQty] = useState([]);

  // BULANAN
  const categoryBulanan = bulan;
  const [dataBulananNominal, setDataBulananNominal] = useState([]);
  const [dataBulananQty, setDataBulananQty] = useState([]);

  // TAHUN
  const [categoryTahunan, setCategoryTahunan] = useState([]);
  const [dataTahunanNominal, setDataTahunanNominal] = useState([]);
  const [dataTahunanQty, setDataTahunanQty] = useState([]);

  const { data: dataSourceOrderHarian } = Api.sourceOrderHarian({
    bulan: harianBulan,
    tahun: harianTahun,
  });
  const { data: dataSourceOrderBulanan } = Api.sourceOrderBulanan({
    tahun: bulananTahun,
  });
  const { data: dataSourceOrderTahunan } = Api.sourceOrderTahunan({
    tahun1: tahunanTahun1,
    tahun2: tahunanTahun2,
  });

  useEffect(() => {
    if (dataSourceOrderHarian) {
      const newDataNominal = dataSourceOrderHarian.data.series.map((e) => {
        return {
          name: e.name,
          data: e.nominal,
        };
      });
      const newDataQty = dataSourceOrderHarian.data.series.map((e) => {
        return {
          name: e.name,
          data: e.qty,
        };
      });

      setCategoryHarian(dataSourceOrderHarian.data.category);
      setDataHarianNominal(newDataNominal);
      setDataHarianQty(newDataQty);
    }
  }, [dataSourceOrderHarian]);

  useEffect(() => {
    if (dataSourceOrderBulanan) {
      const newDataNominal = dataSourceOrderBulanan.data.series.map((e) => {
        return {
          name: e.name,
          data: e.nominal,
        };
      });
      const newDataQty = dataSourceOrderBulanan.data.series.map((e) => {
        return {
          name: e.name,
          data: e.qty,
        };
      });

      setDataBulananNominal(newDataNominal);
      setDataBulananQty(newDataQty);
    }
  }, [dataSourceOrderBulanan]);

  useEffect(() => {
    if (dataSourceOrderTahunan) {
      const newDataNominal = dataSourceOrderTahunan.data.series.map((e) => {
        return {
          name: e.name,
          data: e.nominal,
        };
      });
      const newDataQty = dataSourceOrderTahunan.data.series.map((e) => {
        return {
          name: e.name,
          data: e.qty,
        };
      });

      setCategoryTahunan(dataSourceOrderTahunan.data.category);
      setDataTahunanNominal(newDataNominal);
      setDataTahunanQty(newDataQty);
    }
  }, [dataSourceOrderTahunan]);

  useEffect(() => {
    setLoading(false);
  }, [toggleView]);

  return (
    <>
      <Paper sx={{ padding: 2 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <IconButton
                  disableRipple
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    EventHandlers.handleToggleView({
                      dispatch,
                      view: !toggleView,
                    });
                  }}
                  sx={{
                    backgroundColor: "rgba(47, 169, 238, 0.8)",
                    color: "#fff",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "rgba(47, 169, 238, 1)",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size={20.5}
                      disableShrink
                      sx={{ "& .MuiCircularProgress-svg": { color: "white" } }}
                    />
                  ) : (
                    <ListIcon />
                  )}
                </IconButton>
              </Grid>
              <Grid item alignContent={"center"}>
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      width={150}
                      variant="text"
                      sx={{ fontSize: 14 }}
                    />
                    <Skeleton
                      animation="wave"
                      width={90}
                      variant="text"
                      sx={{ fontSize: 12 }}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      color={"#797979"}
                      fontWeight={700}
                      fontSize={14}
                    >
                      {toggleView === false
                        ? "Pencapaian Nominal"
                        : "Pencapaian Kuantitas"}
                    </Typography>
                    <Typography color={"#797979"} fontSize={12}>
                      By Source Order
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {/* Filter Bulan Tahun */}
              <FilterBulanTahun form={form} tab={tab} />

              {/* Tabs Nav */}
              <Grid item>
                <AppBar
                  position="static"
                  elevation={0}
                  sx={{
                    backgroundColor: "#F2F2F2",
                    width: "325px",
                  }}
                >
                  <Tabs
                    variant="fullWidth"
                    value={tab}
                    onChange={(event, value) =>
                      EventHandlers.handleTab({ dispatch, tab: value })
                    }
                    centered
                  >
                    <Tab {...a11yProps(0)} label="HARIAN" />
                    <Tab {...a11yProps(1)} label="BULANAN" />
                    <Tab {...a11yProps(2)} label="TAHUNAN" />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 2, border: "0.5px solid #ABABAB" }} />

        {/* <CardGrafikPencapaian /> */}
        <ChartPencapaianSourceOrder
          toggleView={toggleView}
          tab={tab}
          nominalSourceOrderHarian={dataHarianNominal}
          nominalSourceOrderBulan={dataBulananNominal}
          nominalSourceOrderTahun={dataTahunanNominal}
          qtySourceOrderHarian={dataHarianQty}
          qtySourceOrderBulan={dataBulananQty}
          qtySourceOrderTahun={dataTahunanQty}
          tanggal={categoryHarian}
          bulan={categoryBulanan}
          tahun={categoryTahunan}
        />
      </Paper>
    </>
  );
};

export default CardGrafikSourceOrder;
