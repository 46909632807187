import React, { useEffect } from "react";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CustomizedSnackbars from "./components/Layout/Notification";
import {
  AppRouter,
  AutoRefresh,
  LoadingWithBasicData,
  PopupImage,
} from "./features/app/components";
import { useBasicData, useSocket } from "./hooks";

function App() {
  const { connectSocket } = useSocket();
  const { connectBasicData } = useBasicData();

  useEffect(() => {
    connectSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    connectBasicData();
  }, [connectBasicData]);

  return (
    <>
      <AutoRefresh />
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
      <CustomizedSnackbars />
      <LoadingWithBasicData />
      <PopupImage />
      <AppRouter />
    </>
  );
}

export default App;
