import React from "react";
import { Api } from "./repository";
import { useSelector } from "react-redux";
import { ModalDeleteRiwayat, TabelRiwayatBayar } from "./components";
import { PrintRiwayarBayar } from "./components/PrintRiwayarBayar";

const RiwayatBayarPage = (props) => {
  const { componentRefRB } = props;
  const { tanggalRiwayatBayarFilter } = useSelector(
    (state) => state.riwayatBayar
  );

  const getRiwayatBayar = Api.read({
    startDate: tanggalRiwayatBayarFilter[0],
    endDate: tanggalRiwayatBayarFilter[1],
  });

  return (
    <>
      <TabelRiwayatBayar
        data={getRiwayatBayar.data ? getRiwayatBayar.data.data : []}
        isLoading={getRiwayatBayar.isLoading}
      />
      <ModalDeleteRiwayat />
      <div style={{ display: "none" }}>
        <PrintRiwayarBayar
          ref={componentRefRB}
          data={getRiwayatBayar.data ? getRiwayatBayar.data.data : []}
          tanggal={tanggalRiwayatBayarFilter}
        />
      </div>
    </>
  );
};

export default RiwayatBayarPage;
