import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/cashflowBulananSlice";
import { Config } from "../repository";

const TabelPendapatanBulanan = ({ data, isLoading }) => {
  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.cashflowBulanan);

  const columns = Config.column.map((e) => {
    return e;
  });

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel: !isLoading ? aggregationModel : {},
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      setDensity={"compact"}
      disableColumnMenu={true}
      hideFooter={true}
      sx={{
        "& .MuiDataGrid-aggregationColumnHeaderLabel": {
          display: "none",
        },
      }}
    />
  );
};

TabelPendapatanBulanan.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TabelPendapatanBulanan;
