import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutGroup from "../../../components/Layout/Content/LayoutGroup";
import { Api, EventHandlers } from "./repository/";
import {
  FooterCardManageGroup,
  PopupTotalAntrian,
  PopupDetailAntrianGroup,
  PopupDeleteGroup,
  ModalCreateUpdate,
  PrintGroup,
  ModalAddNotaGroup,
} from "./components";

const GroupPage = () => {
  const dispatch = useDispatch();

  const { page, pageSize, search, filterStatus, filterPriority } = useSelector(
    (state) => state.manageGroup
  );

  const { data, isLoading } = Api.read();

  const { data: dataAntrian } = Api.readAntrian();

  return (
    <>
      <LayoutGroup
        isLoading={isLoading}
        currentPage={page}
        pageSize={pageSize}
        search={search}
        filterStatus={filterStatus}
        filterPriority={filterPriority}
        handlePageChange={(page) =>
          EventHandlers.handleChangePage({ dispatch, page })
        }
        page={"manage_group"}
        onClickInfoGroup={(group) =>
          EventHandlers.handleDetailAntrianGroup({
            dispatch,
            modal: {
              id: group.id,
              open: true,
              title: "Detail Antrian Group !",
              data: {
                nota: group.nota,
                antrian: group.group.nota.length,
                size: group.group.size.reduce(
                  (acc, currentValue) => acc + currentValue.qty,
                  0
                ),
                additional: group.group.additional.reduce(
                  (acc, currentValue) => acc + currentValue.qty,
                  0
                ),
                status: {
                  warna: group.status.warna,
                  progress: group.status.progress,
                  status: group.status.status,
                },
              },
            },
          })
        }
        onClickInfoAntrian={(group) => {
          EventHandlers.handleCardTotalAntrian({
            dispatch,
            modal: {
              id: group.id,
              open: true,
              title: "Total Antrian Group : " + group.nota,
              data: group.group.nota,
            },
          });
        }}
        onClickEditGroup={(group) =>
          EventHandlers.handleCreateUpdateGroup({
            dispatch,
            modal: {
              id: group.id,
              open: true,
              title: `Edit Group ${group.nota}`,
              data: {
                gambar: group.gambar,
                keterangan: group.keterangan,
                prioritas: group.prioritas,
              },
            },
          })
        }
        onClickDeleteGroup={(group) =>
          EventHandlers.handleDeleteGroup({
            dispatch,
            modal: {
              id: group.id,
              open: true,
              title: "Delete Group !",
              data: {
                nota: group.nota,
                size: group.group.size.reduce(
                  (acc, currentValue) => acc + currentValue.qty,
                  0
                ),
                additional: group.group.additional.reduce(
                  (acc, currentValue) => acc + currentValue.qty,
                  0
                ),
                antrian: group.group.nota,
              },
            },
          })
        }
        onClickAddNota={(group) =>
          EventHandlers.handleAddNotaGroup({
            dispatch,
            modal: {
              id: group.id,
              open: true,
              title: `Tambah Nota Group : ${group.nota}`,
              data: group.group.nota,
            },
          })
        }
        onClickPreview={(group) =>
          EventHandlers.handlePreview({
            dispatch,
            file: group.gambar,
            title: group.nota,
          })
        }
        onClickPrintFaktur={(group) =>
          EventHandlers.handlePrintGroup({
            dispatch,
            modal: {
              id: group.id,
              open: true,
              title: "Print Group !",
              data: {
                nota: group.nota,
                keterangan: group.keterangan,
                antrian: group.group.nota.length,
                size: group.group.size.reduce(
                  (acc, currentValue) => acc + currentValue.qty,
                  0
                ),
                additional: group.group.additional.reduce(
                  (acc, currentValue) => acc + currentValue.qty,
                  0
                ),
                list: group.group.nota,
              },
            },
          })
        }
        footerComponent={() => {
          return (
            <FooterCardManageGroup
              data={data ? data.data : []}
              isLoading={isLoading}
            />
          );
        }}
        data={data ? data.data : []}
      />

      {/* Modals */}
      <ModalCreateUpdate />
      <ModalAddNotaGroup dataAntrian={dataAntrian ? dataAntrian.data : []} />

      {/* Pop-up */}
      <PopupDeleteGroup />
      <PopupDetailAntrianGroup />
      <PopupTotalAntrian />

      {/* PrintFaktur */}
      <PrintGroup />
    </>
  );
};

export default GroupPage;
