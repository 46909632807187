import React, { useEffect, useState } from "react";
import { Input, NumberInput, Select, handleFocus } from "../controls";
import { styled, Grid, Button, Paper } from "@mui/material";
import NumericText from "./NumericText";

const ButtonPayment = styled(Button)(() => ({
  color: "#fff",
  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset",
  borderRadius: "5px",
  width: "100%",
}));

const FormCashLess = ({ formAntrian, metodePembayaran, total }) => {
  //tab button
  const [activeTab, setActiveTab] = useState(1);

  const [grandTotal, setGrandTotal] = useState(0);

  const handleTabChange = (tabValue) => {
    setActiveTab(tabValue);
  };

  useEffect(() => {
    if (activeTab === 1) {
      formAntrian.setValue("pembayaran.metode_pembayaran_id", "");
      formAntrian.setValue("pembayaran.keterangan", "");
    }
  }, [activeTab, formAntrian]);

  useEffect(() => {
    setGrandTotal(
      Math.abs(total.pembayaran - total.pembelian) -
        parseInt(formAntrian.watch("pembayaran.jumlah_bayar")) ?? 0
    );
  }, [formAntrian, total]);

  return (
    <>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={0}
        spacing={2}
      >
        <Grid item xs={6}>
          <ButtonPayment
            onClick={() => handleTabChange(1)}
            sx={{
              backgroundColor: activeTab === 1 ? "#008DD2" : "#B1B1B1",
              "&:not(:disabled):hover": {
                backgroundColor: activeTab === 1 ? "#008DD2" : "#B1B1B1",
              },
            }}
          >
            CASH
          </ButtonPayment>
        </Grid>
        <Grid item xs={6}>
          <ButtonPayment
            onClick={() => handleTabChange(2)}
            sx={{
              backgroundColor: activeTab === 2 ? "#008DD2" : "#B1B1B1",
              "&:not(:disabled):hover": {
                backgroundColor: activeTab === 2 ? "#008DD2" : "#B1B1B1",
              },
            }}
          >
            CASHLESS
          </ButtonPayment>
        </Grid>
      </Grid>
      {/* isi tab */}
      <Grid
        container
        item
        marginTop={0}
        marginBottom={2}
        spacing={2}
        alignItems="center"
        textAlign="end"
      >
        {activeTab === 1 && null}
        {activeTab === 2 && (
          <>
            <Grid item xs={6}>
              <Select
                disabled={metodePembayaran.length ? false : true}
                name={"pembayaran.metode_pembayaran_id"}
                control={formAntrian.control}
                label={"Pilih Metode Pembayaran"}
                options={metodePembayaran}
                rules={{
                  required:
                    activeTab === 2 ? "Metode Pembayaran Harus di isi" : false,
                }}
                defaultValue={""}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                className="formket"
                rules={{ required: false }}
                name={"pembayaran.keterangan"}
                control={formAntrian.control}
                label={"Keterangan Pembayaran"}
                defaultValue={""}
                multiline
                onFocus={handleFocus}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          Bayar
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            rules={{ required: true }}
            name={"pembayaran.jumlah_bayar"}
            control={formAntrian.control}
            label={""}
            defaultValue={0}
            onFocus={handleFocus}
          />
        </Grid>
        {grandTotal > 0 && (
          <>
            <Grid item xs={6} color={"#FF4040"} fontWeight={600}>
              Kekurangan
            </Grid>
            <Grid item xs={6}>
              <Paper
                elevation={0}
                sx={{
                  fontWeight: 600,
                  color: "#FF4040",
                  backgroundColor: "#E3E3E3",
                  padding: "8.5px 14px",
                  boxShadow: "0px 2px 4px 0px #00000040 inset",
                  border: "1px solid #9A9A9A",
                  borderRadius: "5px",
                }}
              >
                <NumericText value={grandTotal} prefix={true} />
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default FormCashLess;
