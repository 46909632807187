import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "../config/api";
import { useDispatch } from "react-redux";
import { setNotification } from "../features/notificationSlice";
import { RESET_USER } from "../features/authSlice";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body) => {
      try {
        const productsResponse = await axiosInstance.post("logout");
        return productsResponse;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: (response) => {
      localStorage.removeItem("token");
      navigate("/login", { replace: true });
      dispatch({
        type: RESET_USER,
      });
      dispatch({
        type: setNotification,
        payload: {
          open: true,
          type: response.data.type,
          message: response.data.message,
        },
      });
      dispatch(
        setNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Berhasil Logout!",
        })
      );

      // Mendapatkan semua keys dari cache
      const cacheKeys = queryClient
        .getQueryCache()
        .getAll()
        .map((query) => query.queryKey);

      // Membersihkan cache yang memiliki staleTime bukan Infinity
      cacheKeys.forEach((key) => {
        const query = queryClient.getQueryCache().find({ queryKey: key });
        if (query.options.gcTime !== Infinity) {
          queryClient.removeQueries({ queryKey: key, exact: true });
        }
      });
    },
    onError: (error) => {
      dispatch({
        type: setNotification,
        payload: {
          open: true,
          type: error?.type,
          message: error?.message,
        },
      });
    },
  });
};

export default useLogout;
