import { createSlice } from "@reduxjs/toolkit";

const manageGroupNota = JSON.parse(localStorage.getItem("manageGroup"));

const initialState = {
  page: 0,
  pageSize: 10,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {
    jenis_order: false,
    source_order: false,
    alamat: false,
  },
  rowGroup: manageGroupNota ?? [],
  aggregationModel: {},
  pinnedModel: { left: [], right: ["actions"] },
  detailModal: { id: null, open: false, title: null },
  detailProgressModal: { id: null, open: false, title: null },
  manageGroupModal: { id: null, open: false, title: null },
  printModal: { id: null, open: false, title: null },
};

const ManageGroupNota = createSlice({
  name: "manageGroupNota",
  initialState,
  reducers: {
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem("manageGroup", JSON.stringify(action.payload.group));
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
    SET_DETAIL_MODAL: (state, action) => {
      state.detailModal = action.payload.modal;
    },
    SET_DETAIL_PROGRESS_MODAL: (state, action) => {
      state.detailProgressModal = action.payload.modal;
    },
    SET_MANAGE_GROUP_MODAL: (state, action) => {
      state.manageGroupModal = action.payload.modal;
    },
    SET_PRINT_MODAL: (state, action) => {
      state.printModal = action.payload.modal;
    },
  },
});

export const {
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  SET_DETAIL_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_MANAGE_GROUP_MODAL,
  SET_PRINT_MODAL,
} = ManageGroupNota.actions;

export default ManageGroupNota.reducer;
