import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { FileInput, Input, handleFocus } from "../../../../components/controls";
import { AddPhotoAlternate as AddImage } from "@mui/icons-material";
import LoadingProgress from "../../../../components/common/LoadingProgress";
import config from "../../../../config";

const PengaturanProfile = ({ form, dataPengaturan }) => {
  const pengaturan = Array.from(new Set(dataPengaturan));

  const renderInputField = (
    index,
    id,
    label,
    nameInput,
    defaultValueInput,
    xsLabel,
    xsInput
  ) => (
    <React.Fragment key={index}>
      {id}
      <Grid item xs={xsLabel} textAlign={"right"}>
        {label}
      </Grid>
      <Grid item xs={xsInput}>
        <Input
          rules={{ required: false }}
          name={nameInput}
          control={form.control}
          label={""}
          onFocus={handleFocus}
          shrinklabel={false}
          defaultValue={defaultValueInput}
        />
      </Grid>
      {xsInput !== 9 && <Grid item xs={6} />}
    </React.Fragment>
  );

  const renderInputImg = (index, id, fileInputName, imgValue) => (
    <React.Fragment key={index}>
      {id}
      <Grid item xs={3} textAlign={"right"}>
        Logo Toko
      </Grid>
      <Grid item xs={3}>
        <Button
          component="label"
          variant="outlined"
          startIcon={<AddImage />}
          fullWidth
        >
          Upload Logo
          <FileInput
            name={`logo`}
            control={form.control}
            rules={{ required: false }}
            sx={{ display: "none" }}
            inputProps={{ accept: "image/*" }}
          />
        </Button>
      </Grid>
      <Grid item xs={6} position={"relative"}>
        <Box
          sx={{
            position: "absolute",
            bottom: -17,
            width: "87px",
            height: "87px",
            borderRadius: "5px",
            border: "0.5px solid #B4B4B4",
            boxShadow: "0px 2px 4px 0px #00000040 inset",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0.5,
          }}
        >
          <Box
            sx={{
              borderRadius: "4px",
              backgroundColor: "#eeeeee",
            }}
          >
            {form.watch(fileInputName) ? (
              <img
                src={URL.createObjectURL(form.watch(`logo`))}
                height={80}
                width={80}
                style={{ borderRadius: "4px" }}
                alt="logo"
              />
            ) : imgValue ? (
              <img
                src={`${config.apiUrl}/storage/logo/${imgValue}`}
                height={80}
                width={80}
                style={{ borderRadius: "4px" }}
                alt="logo"
              />
            ) : (
              <AddImage sx={{ fontSize: "40px", color: "#DBDBDB" }} />
            )}
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  );

  return (
    <Grid container spacing={2} alignItems={"center"} marginBottom={2}>
      <Grid item xs={12}>
        <Typography fontWeight={700} color={"#797979"}>
          Profil Toko
        </Typography>
        <Divider />
      </Grid>
      {!pengaturan.length > 0 && (
        <>
          {renderInputField(0, "", "Nama Toko", "", "", 3, 3)}
          {renderInputField(1, "", "Alamat Toko", "", "", 3, 9)}
          {renderInputField(2, "", "No. Telepon", "", "", 3, 3)}
          {renderInputImg(3, "", "", "")}
        </>
      )}
      {pengaturan.length > 0 &&
        pengaturan.map((e, index) => {
          if (e.rule === "NAMA_TOKO")
            return renderInputField(
              index,
              <input
                type="hidden"
                {...form.register(`setting.${index}.id`, {
                  valueAsNumber: true,
                })}
                value={e.id}
              />,
              "Nama Toko",
              `setting.${index}.value`,
              e.value,
              3,
              3
            );
          if (e.rule === "ALAMAT")
            return renderInputField(
              index,
              <input
                type="hidden"
                {...form.register(`setting.${index}.id`, {
                  valueAsNumber: true,
                })}
                value={e.id}
              />,
              "Alamat Toko",
              `setting.${index}.value`,
              e.value,
              3,
              9
            );
          if (e.rule === "NO_HP")
            return renderInputField(
              index,
              <input
                type="hidden"
                {...form.register(`setting.${index}.id`, {
                  valueAsNumber: true,
                })}
                value={e.id}
              />,
              "No. Telepon",
              `setting.${index}.value`,
              e.value,
              3,
              3
            );
          if (e.rule === "LOGO_NOTA") {
            return renderInputImg(index, "", `logo`, e.value);
          }
          return null;
        })}
    </Grid>
  );
};

PengaturanProfile.propTypes = {
  form: PropTypes.object.isRequired,
  dataPengaturan: PropTypes.array.isRequired,
};

export default PengaturanProfile;
