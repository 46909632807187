import { useCallback } from "react";
import { useDispatch } from "react-redux";
import socketClient from "socket.io-client";
import {
  UPDATE_APP_VERSION,
  UPDATE_DESCRIPTION,
  UPDATE_SERIAL_NUMBER,
  UPDATE_SOCKET,
} from "../features/app/domain/dataSlice";
import { setNotification } from "../features/notificationSlice";

const useSocket = () => {
  const dispatch = useDispatch();

  const fetchSocketConfig = async () => {
    try {
      const response = await fetch("setting.json");
      const config = await response.json();
      return config;
    } catch (error) {
      console.error("Error fetching socket configuration:", error);
      return null;
    }
  };

  const initSocket = useCallback(async () => {
    const config = await fetchSocketConfig();

    if (config) {
      const {
        online_host,
        offline_host,
        serial_number,
        app_version,
        description,
      } = config;
      if (serial_number) {
        dispatch(UPDATE_SERIAL_NUMBER(serial_number));
      }
      if (app_version) {
        dispatch(UPDATE_APP_VERSION(app_version));
      }
      if (description) {
        dispatch(UPDATE_DESCRIPTION(description));
      }
      const currentHost = window.location.hostname;

      const socketUrl = offline_host.includes(currentHost)
        ? `http://${currentHost}:3001`
        : online_host;

      const socketReq = socketClient.connect(socketUrl);

      socketReq.on("connect", () => {
        console.log("Socket.IO connected successfully!");
        dispatch(UPDATE_SOCKET(socketReq));
      });

      socketReq.on("connect_error", (_) => {
        dispatch(
          setNotification({
            snackbarOpen: true,
            snackbarType: "warning",
            snackbarMessage:
              "Socket Gagal di Sambungkan! Coba untuk Reload Page!",
          })
        );
        socketReq.close();
        dispatch(UPDATE_SOCKET(null));
      });
    } else {
      console.error("Socket configuration not available");
    }
  }, [dispatch]);

  const connectSocket = () => {
    initSocket();
  };

  return {
    connectSocket,
  };
};

export default useSocket;
