import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  CheckboxInput,
  handleFocus,
} from "../../../components/controls";
import { Grid, Divider, Stack } from "@mui/material";
import { Api, EventHandlers } from "../repository";
import { ActionGrid } from "../../../components/common";
import { BlankDialog, DataForm } from "../../../components/Layout/Content";
import CustomButton from "../../../components/controls/CustomButton";
import { useFormRetur } from "../repository";

const ModalChangeKirim = () => {
  const dispatch = useDispatch();
  const form = useFormRetur();
  const { changeKirim, tanggalReturFilter } = useSelector(
    (state) => state.retur
  );
  const handleClose = useCallback(() => {
    form.setValue("keterangan", "");
    form.setValue("confirmation", false);
    EventHandlers.handleChangeKirimModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch, form]);

  const { data, isError, isLoading } = Api.detailAntrian({
    modal: changeKirim,
  });

  const { mutate, isPending } = Api.kirimUlang({
    onSuccess: () => {
      handleClose();
    },
    startDate: tanggalReturFilter[0],
    endDate: tanggalReturFilter[1],
  });

  const onSubmit = (values) => {
    mutate({
      body: values,
      id: changeKirim.id,
    });
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={changeKirim.open}
      handleClose={handleClose}
      title={changeKirim.title}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <DataForm data={data ? data.data : {}} isLoading={isLoading} />
          <Input
            rules={{ required: "Keterangan Harus di isi!" }}
            name={"keterangan"}
            control={form.control}
            label={"Keterangan Progress"}
            defaultValue={""}
            multiline
            onFocus={handleFocus}
          />
        </Stack>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: true }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"#B0B0B0"} fullWidth>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"clear"}
              fullWidth
              disabled={form.watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalChangeKirim;
