import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { Api, EventHandlers } from "../repository";
import { useDispatch, useSelector } from "react-redux";
import {
  ColorInput,
  Input,
  Select,
  SwitchInput,
} from "../../../components/controls";
import {
  BlankDialog,
  UploadImageLayout,
} from "../../../components/Layout/Content";
import CustomButton from "../../../components/controls/CustomButton";
import { ActionGrid } from "../../../components/common";

const validation = {
  name: {
    required: "Nama harus di isi!",
    minLength: {
      value: 3,
      message: "Minimal 3 Karakter!",
    },
    maxLength: {
      value: 50,
      message: "Nama terlalu panjang!",
    },
  },
  username: {
    required: "Username harus di isi!",
    minLength: {
      value: 3,
      message: "Minimal 3 Karakter!",
    },
    maxLength: {
      value: 50,
      message: "Username terlalu panjang!",
    },
  },
  password: {
    required: "Password harus di isi!",
    minLength: {
      value: 8,
      message: "Minimal 8 Karakter!",
    },
    maxLength: {
      value: 50,
      message: "Password terlalu panjang!",
    },
  },
  role: {
    required: "Role harus di isi!",
  },
  false: {
    required: false,
  },
};

const ModalCreateUpdateUser = ({ form }) => {
  const { createUpdateUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);

  const [gambar, setGambar] = useState(null);

  const handleClose = () => {
    setProgress(0);
    setGambar(null);
    form.setValue("clear_image", true);
    form.setValue("name", "");
    form.setValue("role", "");
    form.setValue("username", "");
    form.setValue("password", "");
    form.setValue("color", "#000000");
    form.setValue("photo", null);
    form.setValue("status", false);
    form.setValue("confirmation", false);
    form.clearErrors();
    EventHandlers.handleCreateUpdate({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const onDeleteImageHandler = () => {
    setGambar(null);
    form.setValue("clear_image", true);
  };

  const roleItems = [
    { id: "customer_service", name: "Customer Service" },
    { id: "owner", name: "Owner" },
    { id: "admin_produksi", name: "Produksi" },
    { id: "desainer", name: "Desain" },
    { id: "penata", name: "Penata" },
  ];

  const { mutate, isPending } = Api.create({
    setProgress,
    handleClose,
    form,
  });

  const { mutate: mutateUpdate, isPending: isPendingUpdate } = Api.update({
    setProgress,
    id: createUpdateUser.id,
    handleClose,
  });

  const onSubmit = (values) => {
    const data = values;
    if (createUpdateUser.id) {
      if (data.status) {
        data.status = "active";
      } else {
        data.status = "inactive";
      }
      mutateUpdate({ ...data });
    } else {
      data.status = "active";
      mutate({ ...data });
    }
  };

  useEffect(() => {
    if (createUpdateUser.photo) {
      setGambar(createUpdateUser.photo);
    }
  }, [createUpdateUser.photo]);

  return (
    <BlankDialog
      maxWidth={"xs"}
      open={createUpdateUser.open}
      handleClose={handleClose}
      hideCloseIcon={true}
      title={
        createUpdateUser.id ? (
          <div
            style={{
              display: "flex",
              padding: "0 1rem",
            }}
          >
            <span>{createUpdateUser.title}</span>
            <span style={{ position: "absolute", right: 32, top: 5 }}>
              <SwitchInput
                name={"status"}
                control={form.control}
                label={"Aktif"}
                defaultValue={false}
                labelsize={12}
              />
            </span>
          </div>
        ) : (
          <span>{createUpdateUser.title}</span>
        )
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...form.register("clear_image", { value: false })}
        />
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={6}>
            <Input
              rules={validation.name}
              name={"name"}
              control={form.control}
              label={"Nama Singkat"}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              name={"role"}
              control={form.control}
              label={"Role"}
              options={roleItems}
              rules={validation.role}
              disabled={createUpdateUser.id === 1}
              defaultValue={""}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              rules={validation.username}
              name={"username"}
              control={form.control}
              label={"Username"}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              rules={
                createUpdateUser.id ? { required: false } : validation.password
              }
              name={"password"}
              control={form.control}
              label={"Password"}
              type={"password"}
            />
          </Grid>
          <Grid
            container
            item
            xs={6}
            display={"flex"}
            flexDirection={"rows"}
            rowSpacing={2}
          >
            <Grid item xs={12}>
              <ColorInput
                name={"color"}
                control={form.control}
                defaultValue={"#000000"}
              />
            </Grid>
          </Grid>
        </Grid>
        <UploadImageLayout
          form={form}
          progress={progress}
          gambar={gambar}
          onDeleteImage={onDeleteImageHandler}
          required={false}
          path="user"
          name="photo"
        />

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />

        <ActionGrid
          isLoading={isPending || isPendingUpdate}
          actionStart={
            <CustomButton
              onClick={handleClose}
              warna={"clear"}
              widthbtn={"100%"}
            >
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending || isPendingUpdate}
              warna={"next"}
              widthbtn={"100%"}
              disabled={!form.watch("confirmation")}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalCreateUpdateUser;
