import React from "react";
import { Button } from "@mui/material";
import iconCollapse from "../../assets/icon/arrowCol.svg";

const CollapseButton = ({
  onClick,
  checked,
  expandLabel,
  collapseLabel,
  sx,
  ...rest
}) => {
  return (
    <Button
      size="small"
      startIcon={<img src={iconCollapse} alt="info" />}
      variant="contained"
      onClick={onClick}
      sx={{
        alignItems: "center",
        fontSize: "10px",
        borderRadius: "20px",
        backgroundColor: "#9FA8AD",
        padding: "4px 20px",
        textTransform: "capitalize",
        "& .MuiButton-startIcon": {
          marginRight: "5px",
          transition: "transform 0.3s ease",
          transform: `rotate(${checked ? "0deg" : "180deg"})`,
        },
        "&:hover": { backgroundColor: "#7397AB" },
        ...sx,
      }}
      {...rest}
    >
      {checked ? collapseLabel : expandLabel}
    </Button>
  );
};

export default CollapseButton;
