import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import { TabelRejectTerbaru } from "./components";
import { Api } from "./repository";

const CardTabelReject = () => {
  const { data, isLoading } = Api.read();

  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item>
          <PaymentsIcon sx={{ color: "#FC001E" }} />
        </Grid>
        <Grid item>
          <Typography color={"#797979"} fontWeight={700}>
            Reject Terbaru
          </Typography>
        </Grid>
      </Grid>

      <TabelRejectTerbaru data={data ? data.data : []} isLoading={isLoading} />
    </Paper>
  );
};

export default CardTabelReject;
