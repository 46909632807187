import { useEffect } from "react";
import PropTypes from "prop-types";
import { useEventCallback } from "@mui/material";
import {
  gridDensitySelector,
  useGridApiContext,
} from "@mui/x-data-grid-premium";

function DensitySubscriber({ onDensityChange }) {
  const apiRef = useGridApiContext();
  const density = gridDensitySelector(apiRef.current.state).value;
  const onChange = useEventCallback((value) => onDensityChange(value));
  useEffect(() => {
    onChange(density);
  }, [density, onChange]);
  return null;
}

DensitySubscriber.propTypes = {
  onDensityChange: PropTypes.func.isRequired,
};
export default DensitySubscriber;
