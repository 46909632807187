import React from "react";
import { Api, useFormUser } from "./repository";
import {
  GridDataUser,
  TabelUser,
  ModalCreateUpdateUser,
  ModalDeleteUser,
} from "./components";
const UsersGridPage = () => {
  const form = useFormUser();
  const { data, isLoading } = Api.read();

  return (
    <>
      <GridDataUser {...{ form, isLoading, data: data ? data.data : [] }} />
      <ModalCreateUpdateUser {...{ form }} />
      <ModalDeleteUser />
    </>
  );
};

const UsersTabelPage = () => {
  const form = useFormUser();
  const { data, isLoading } = Api.read();

  return (
    <>
      <TabelUser {...{ form, isLoading, data: data ? data.data : [] }} />
      <ModalCreateUpdateUser {...{ form }} />
      <ModalDeleteUser />
    </>
  );
};

export { UsersGridPage, UsersTabelPage };
