import { Config } from ".";
import {
  useCreatingData,
  useDeletingData,
  useFetchingData,
} from "../../../hooks";

export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}`,
    });
  };

  static create = ({ setProgress, handleClose, form }) => {
    return useCreatingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [`${Config.variable.endpoint}`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        handleClose();
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static update = ({ setProgress, id, handleClose, form }) => {
    return useCreatingData({
      endpoint: `${Config.variable.endpoint}/${id}`,
      invalidated: [`${Config.variable.endpoint}`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { _method: "PUT" },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        handleClose();
      },
      onError: (error) => {
        if (error.errors) {
          for (const key in error.errors) {
            form.setError(key, {
              type: "manual",
              message: error.errors[key].join(" | "),
            });
          }
        }
      },
    });
  };

  static delete = ({ handleClose }) => {
    return useDeletingData({
      endpoint: `${Config.variable.endpoint}`,
      invalidated: [`${Config.variable.endpoint}`],
      onSuccess: () => {
        handleClose();
      },
    });
  };
}
