import React from "react";
import Barcode from "react-barcode";
import { Grid, Divider, Typography, Card, CardContent } from "@mui/material";
import { Payments as PaymentsIcon } from "@mui/icons-material";
import iconShirt from "../../../../../assets/icon/detail/shirt.svg";
import iconAdditional from "../../../../../assets/icon/detail/additional.svg";
import iconOffline from "../../../../../assets/icon/detail/offline.svg";
import iconEkspedisi from "../../../../../assets/icon/detail/expedisi.svg";
import NumericText from "../../../../common/NumericText";
import { ChipItems } from "../../../../common";

const ChipItem = ({
  icon,
  label,
  backgroundcolor,
  iconmarginleft,
  sx,
  ...rest
}) => {
  return (
    <ChipItems
      icon={icon}
      label={label}
      backgroundcolor={backgroundcolor}
      borderradius="3px"
      iconmarginleft={iconmarginleft}
      sx={{
        fontSize: "9px",
        padding: "1px !important",
        height: "100%",
        ...sx,
      }}
      {...rest}
    />
  );
};

const CardTemplate = (props) => {
  const { header, children } = props;
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        borderRadius: "5px",
        color: "#797979",
        backgroundColor: "#F3F7F8",
      }}
    >
      <CardContent sx={{ padding: "0.5rem 1rem !important", color: "#797979" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#797979",
          }}
        >
          {header}
        </div>
        <Divider
          sx={{
            margin: "0.5rem -1rem 0.5rem -1rem",
            borderWidth: "1px",
            borderColor: "#797979",
          }}
        />
        {children}
      </CardContent>
    </Card>
  );
};

const CardOrder = (props) => {
  const { ekspedisi, total } = props;

  const totalSize = total?.size?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.qty;
  }, 0);

  const totalAdditional = total?.additional?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.qty;
    },
    0
  );

  const pembayaran = total?.pembayaran?.total - total?.pembelian?.total;
  return (
    <>
      <Grid item xs={6}>
        <CardTemplate
          header={
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Total Order : <b>{totalSize ?? 0} Pcs</b>
              </Typography>
              <img
                src={iconShirt}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
            </>
          }
        >
          <Grid container spacing={1}>
            {total?.size?.map((e, index) => (
              <Grid item key={index}>
                <ChipItem
                  label={`${e.nama} : ${e.qty}`}
                  backgroundcolor="#105174"
                />
              </Grid>
            ))}
          </Grid>
        </CardTemplate>
      </Grid>

      <Grid item xs={6}>
        <CardTemplate
          header={
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Total Additional : <b>{totalAdditional ?? 0} Pcs</b>
              </Typography>
              <img
                src={iconAdditional}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
            </>
          }
        >
          <Grid container spacing={1}>
            {total?.additional?.map((e, index) => (
              <Grid item key={index}>
                <ChipItem
                  label={`${e.nama} : ${e.qty}`}
                  backgroundcolor="#105174"
                />
              </Grid>
            ))}
          </Grid>
        </CardTemplate>
      </Grid>

      <Grid item xs={6}>
        <CardTemplate
          header={
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                Ekspedisi : <b>{ekspedisi?.nama ?? "-"}</b>
              </Typography>
              <img
                src={iconEkspedisi}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
            </>
          }
        >
          <Grid container justifyContent={"center"} textAlign={"center"}>
            <Grid item>
              {ekspedisi?.keterangan ? (
                <Barcode
                  height={40}
                  width={2}
                  value={ekspedisi.keterangan}
                  background="transparent"
                  fontSize={10}
                  marginTop={-0.5}
                  marginBottom={-0.4}
                />
              ) : (
                <img
                  src={iconOffline}
                  alt="offline"
                  width={"50"}
                  height={"50"}
                />
              )}
            </Grid>
          </Grid>
        </CardTemplate>
      </Grid>

      <Grid item xs={6}>
        <CardTemplate
          header={
            <>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                Status Pembayaran
              </Typography>
              {total?.pembayaran?.status && (
                <ChipItem
                  icon={<PaymentsIcon />}
                  label={total.pembayaran.status}
                  iconmarginleft="8px"
                  backgroundcolor={
                    total.pembayaran.status === "Lunas" ? "green" : "#FF4040"
                  }
                  sx={{
                    "& .MuiChip-label": {
                      marginBottom: "2px",
                    },
                  }}
                />
              )}
            </>
          }
        >
          <Grid container item justifyContent="space-between">
            <Grid item xs={7.3}>
              Total Beli
            </Grid>
            <Grid item xs={0.2}>
              Rp.
            </Grid>
            <Grid item xs={4.5} textAlign="end">
              <NumericText prefix={false} value={total?.pembelian?.total} />
            </Grid>
          </Grid>
          <Grid container item justifyContent="space-between">
            <Grid item xs={7.3}>
              Total Bayar
            </Grid>
            <Grid item xs={0.2}>
              Rp.
            </Grid>
            <Grid item xs={4.5} textAlign="end">
              <NumericText prefix={false} value={total?.pembayaran?.total} />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            item
            sx={{
              color:
                total?.pembayaran?.status === "Lunas" ? "#39C236" : "#FF4040",
            }}
          >
            <Grid item xs={7.3}>
              {total?.pembayaran?.status === "Lunas"
                ? "Kembalian"
                : "Kekurangan"}
            </Grid>
            <Grid item xs={0.2}>
              Rp.
            </Grid>
            <Grid item xs={4.5} textAlign="end">
              <NumericText value={Math.abs(pembayaran ?? 0)} prefix={false} />
            </Grid>
          </Grid>
        </CardTemplate>
      </Grid>
    </>
  );
};

export default CardOrder;
