import React from "react";
import { styled } from "@mui/material/styles";
import {
  LinearProgress,
  linearProgressClasses,
  Typography,
  Box,
} from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, barcolor, barheight }) => ({
    height: barheight || 18,
    borderRadius: 50,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#00000033",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: barcolor || "#FFFFFF66",
    },
  }),
);

const LoadingProgress = ({
  progress,
  barcolor,
  barWidth,
  barheight,
  displayLabel,
}) => {
  return (
    <Box width={barWidth} position={"relative"}>
      <BorderLinearProgress
        variant="determinate"
        value={progress}
        barcolor={barcolor}
        barheight={barheight}
      />
      <Typography
        display={displayLabel}
        sx={{
          fontSize: 9,
          fontWeight: "700",
          letterSpacing: "0.9px",
          position: "absolute",
          top: "50%",
          left: "50%",
          translate: "middle",
          transform: "translate(-50%, -50%)",
          color: "rgba(255, 255, 255, 0.7)",
        }}
      >
        {`${Math.round(progress)}%`}
      </Typography>
    </Box>
  );
};

export default LoadingProgress;
