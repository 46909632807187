import React from "react";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, useTheme } from "@mui/material";

const WaveButton = styled(Button)(({ warna, marginleft, marginright }) => ({
  borderRadius: "5px",
  color: "#fff",
  // padding: "10px 0",
  backgroundColor: warna.warna,
  marginLeft: marginleft,
  marginRight: marginright,
  "&:hover": {
    backgroundColor: warna.hover,
  },
}));

const CustomButton = ({
  loading,
  warna = "",
  onClick,
  children,
  marginleft,
  marginright,
  starticon,
  endicon,
  sx,
  size = "large",
  ...rest
}) => {
  const theme = useTheme();

  const checkWarna = (props) => {
    if (props === "clear") {
      return { warna: "#FF0707", hover: "#b90202" };
    } else if (props === "next") {
      return { warna: "#008DD2", hover: "#0070a8" };
    } else if (props === "back") {
      return { warna: "#105174", hover: "#0d3f5a" };
    } else if (props === "simpan") {
      return { warna: "#1de9b6", hover: "#00bfa5" };
    } else {
      return { warna: props, hover: props };
    }
  };

  return (
    <>
      <WaveButton
        theme={theme}
        startIcon={starticon}
        endIcon={endicon}
        warna={checkWarna(warna)}
        onClick={onClick}
        color={"primary"}
        variant="contained"
        marginleft={marginleft}
        marginright={marginright}
        sx={{
          cursor: loading && "default",
          pointerEvents: loading && "none",
          ...sx,
        }}
        fullWidth
        size={size}
        disabled={loading}
        {...rest}
      >
        {loading ? (
          <CircularProgress
            color="inherit"
            size={size === "small" ? 19 : size === "medium" ? 21 : 22}
          />
        ) : (
          children
        )}
      </WaveButton>
    </>
  );
};

export default CustomButton;
