import React, { useCallback, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog, DataForm } from "../../../../components/Layout/Content";
import {
  CheckboxInput,
  CustomButton,
  Select,
} from "../../../../components/controls";
import { Api, EventHandlers, useFormManageGroupNota } from "../repository";
import { ActionGrid } from "../../../../components/common";

const ModalManageGroup = () => {
  const { role } = useSelector((state) => state.auth);
  const { control, reset, watch, handleSubmit, setValue } =
    useFormManageGroupNota();
  const { manageGroupModal } = useSelector((state) => state.manageGroupNota);

  const {
    data: dataDetailAntrian,
    isError: isErrorDetailAntrian,
    isLoading: isLoadingDetailAntrian,
  } = Api.detailAntrian({ modal: manageGroupModal });

  const { data: dataGroup } = Api.readGroup();

  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    reset({
      group_id: "",
      confirmation: false,
    });
    EventHandlers.handleManageGroupModal({
      dispatch,
      modal: { id: null, open: false, title: null },
    });
  }, [dispatch, reset]);

  const { mutate: mutatePindahGroup, isPending } = Api.pindahAntrianGroup({
    handleClose,
  });

  const onSubmit = (values) => {
    if (role === "penata") {
      mutatePindahGroup({ body: values, id: manageGroupModal.id });
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isErrorDetailAntrian) {
      handleClose();
    }
  }, [handleClose, isErrorDetailAntrian]);

  useEffect(() => {
    if (dataDetailAntrian) {
      if (dataDetailAntrian.data) {
        if (dataDetailAntrian.data.group_id) {
          setValue("group_id", dataDetailAntrian.data.group_id);
        }
      }
    }
  }, [dataDetailAntrian, setValue]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={manageGroupModal.open}
      handleClose={handleClose}
      title={manageGroupModal.title}
    >
      <DataForm
        data={dataDetailAntrian ? dataDetailAntrian.data : {}}
        isLoading={isLoadingDetailAntrian}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems={"center"} marginTop={0}>
          <Grid item xs={12}>
            <Select
              name={"group_id"}
              control={control}
              label={"Pindah Group"}
              options={[
                { id: "ungroup", name: "Ungroup" }, // Tambahkan opsi ini di awal
                ...(dataGroup
                  ? dataGroup.data.map((e) => ({ id: e.id, name: e.nota }))
                  : []),
              ]}
              rules={{ required: false }}
              defaultValue={""}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"} fullWidth>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              fullWidth
              disabled={watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalManageGroup;
