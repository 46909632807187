import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
};

const Checkout = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { SET_TAB } = Checkout.actions;

export default Checkout.reducer;
