import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PengaturanProduk } from "./component";
import { useGetCachedData } from "../../../hooks";
import { useFormPengaturanProduk } from "./repository/useFormPengaturanProduk";
import { Api, EventHandlers } from "./repository";
import { updateLoading } from "../../themeSlice";

const ProdukPage = () => {
  const dispatch = useDispatch();
  const form = useFormPengaturanProduk();
  const { socket } = useSelector((state) => state.data);
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });
  const getDataSize = useGetCachedData({ queryKey: "size" });
  const getDataAdditional = useGetCachedData({ queryKey: "additional" });

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const { mutate, isPending } = Api.updateHargaProduct({
    socket,
    onSuccess: () => {
      setIsSubmitSuccessful(true);
      EventHandlers.handleEditProduct({
        dispatch,
        modal: {
          id: null,
          open: false,
          title: null,
        },
      });
    },
  });

  const onSubmit = (value) => {
    mutate(value);
  };

  useEffect(() => {
    dispatch(updateLoading(isSubmitSuccessful));
    setTimeout(() => {
      setIsSubmitSuccessful(false);
    }, 1000);
  }, [dispatch, isSubmitSuccessful, setIsSubmitSuccessful]);

  return (
    <PengaturanProduk
      form={form}
      dispatch={dispatch}
      onSubmit={form.handleSubmit(onSubmit)}
      getDataJenisOrder={getDataJenisOrder ? getDataJenisOrder.data : []}
      getDataSize={getDataSize ? getDataSize.data : []}
      getDataAdditional={getDataAdditional ? getDataAdditional.data : []}
      isLoading={isPending}
    />
  );
};

export default ProdukPage;
