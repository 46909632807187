import config from "../../../../config";
import {
  SET_DETAIL_ANTRIAN_GROUP,
  SET_CARD_TOTAL_ANTRIAN,
  SET_PROGRESS_GROUP,
  SET_SELESAI_GROUP,
  SET_PRINT_GROUP,
  SET_PAGE,
  SET_SEARCH,
  SET_PAGE_SIZE,
  SET_FILTER_STATUS,
  SET_FILTER_PRIORITY,
  SET_REFETCH_STATUS,
} from "../domain/dikerjakanGroupSlice";
import { UPDATE_IMAGE } from "../../../../features/imageDetailSlice";

export class EventHandlers {
  static handleChangePage = ({ dispatch, page }) => {
    dispatch(SET_PAGE({ page }));
  };

  static handleSearch = ({ dispatch, search }) => {
    dispatch(SET_SEARCH({ search }));
  };

  static handlePageSize = ({ dispatch, pageSize }) => {
    dispatch(SET_PAGE_SIZE({ pageSize }));
  };

  static handleFilterStatus = ({ dispatch, filterStatus }) => {
    dispatch(SET_FILTER_STATUS({ filterStatus }));
  };

  static handleFilterPriority = ({ dispatch, filterPriority }) => {
    dispatch(SET_FILTER_PRIORITY({ filterPriority }));
  };

  static handleDetailAntrianGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_DETAIL_ANTRIAN_GROUP({ modal }));
  };

  static handleCardTotalAntrian = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_CARD_TOTAL_ANTRIAN({ modal }));
  };

  static handleProgressGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PROGRESS_GROUP({ modal }));
  };

  static handleSelesaiGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_SELESAI_GROUP({ modal }));
  };

  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/group/${file}`,
        title: title,
      })
    );
  };

  static handlePrintGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PRINT_GROUP({ modal }));
  };

  static handleRefetchStatus = ({ dispatch, refetch_status }) => {
    dispatch(SET_REFETCH_STATUS({ refetch_status }));
  };
}
