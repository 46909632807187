import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { EventHandlers as EventHandlersAntrianNota } from "../../antrian/nota/repository";
import { EventHandlers as EventHandlersAntrianGroup } from "../../antrian/group/repository";
import { EventHandlers as EventHandlersDikerjakanNota } from "../../antrian/nota/repository";
import { EventHandlers as EventHandlersDikerjakanGroup } from "../../dikerjakan/group/repository";
import { EventHandlers as EventHandlersCheckoutSiapKirim } from "../../checkout/siapkirim/repository";
import { EventHandlers as EventHandlersCheckoutDikirim } from "../../checkout/dikirim/repository";
import { EventHandlers as EventHandlersRekapNota } from "../../rekap/nota/repository";
import { EventHandlers as EventHandlersRekapReject } from "../../rekap/reject/repository";
import dayjs from "dayjs";
const AutoRefresh = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { socket } = useSelector((state) => state.data);

  const { refetch_status: refetch_status_antrian } = useSelector(
    (state) => state.antrianNota
  );

  const { refetch_status: refetch_status_antrian_group } = useSelector(
    (state) => state.antrianGroup
  );

  const { refetch_status: refetch_status_dikerjakan } = useSelector(
    (state) => state.dikerjakanNota
  );

  const { refetch_status: refetch_status_dikerjakan_group } = useSelector(
    (state) => state.dikerjakanGroup
  );

  const { refetch_status: refetch_status_checkout_siap_kirim } = useSelector(
    (state) => state.checkoutSiapKirim
  );

  const { refetch_status: refetch_status_checkout_dikirim } = useSelector(
    (state) => state.checkoutDikirim
  );

  const { refetch_status: refetch_status_rekap_nota, tanggalRekapFilter } =
    useSelector((state) => state.rekapNota);

  const { refetch_status: refetch_status_rekap_reject, tanggalRejectFilter } =
    useSelector((state) => state.rekapReject);

  const {
    harianBulan: jenisOrderHarianBulan,
    harianTahun: jenisOrderHarianTahun,
    bulananTahun: jenisOrderBulananTahun,
    tahunanTahun1: jenisOrderTahunanTahun1,
    tahunanTahun2: jenisOrderTahunanTahun2,
  } = useSelector((state) => state.dashboardCardJenisOrder);

  const {
    harianBulan: sourceOrderHarianBulan,
    harianTahun: sourceOrderHarianTahun,
    bulananTahun: sourceOrderBulananTahun,
    tahunanTahun1: sourceOrderTahunanTahun1,
    tahunanTahun2: sourceOrderTahunanTahun2,
  } = useSelector((state) => state.dashboardCardSourceOrder);

  const { bulan: bulanBusinessProgress, tahun: tahunBusinessProgress } =
    useSelector((state) => state.dashboardGroupPencapaian);

  const { bulan: pendapatanBulananBulan, tahun: pendapatanBulananTahun } =
    useSelector((state) => state.pendapatanBulanan);

  const { tahun: pendapatanTahunanTahun } = useSelector(
    (state) => state.pendapatanTahunan
  );

  // ANTRIAN
  useEffect(() => {
    if (socket) {
      const handleAntrianFired = () => {
        if (!refetch_status_antrian) {
          queryClient.invalidateQueries({ queryKey: ["antrian"], type: "all" });
        }
        EventHandlersAntrianNota.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onAntrianFired", handleAntrianFired);

      return () => {
        socket.off("onAntrianFired", handleAntrianFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_antrian, socket]);

  // ANTRIAN GROUP
  useEffect(() => {
    if (socket) {
      const handleAntrianFired = () => {
        if (!refetch_status_antrian_group) {
          queryClient.invalidateQueries({
            queryKey: ["group/antrian"],
            type: "all",
          });
        }
        EventHandlersAntrianGroup.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onAntrianGroupFired", handleAntrianFired);

      return () => {
        socket.off("onAntrianGroupFired", handleAntrianFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_antrian_group, socket]);

  // DIKERJAKAN
  useEffect(() => {
    if (socket) {
      const handleDikerjakanFired = () => {
        if (!refetch_status_dikerjakan) {
          queryClient.invalidateQueries({
            queryKey: ["dikerjakan"],
            type: "all",
          });
        }
        EventHandlersDikerjakanNota.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onDikerjakanFired", handleDikerjakanFired);

      return () => {
        socket.off("onDikerjakanFired", handleDikerjakanFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_dikerjakan, socket]);

  // DIKERJAKAN GROUP
  useEffect(() => {
    if (socket) {
      const handleDikerjakanGroupFired = () => {
        if (!refetch_status_dikerjakan_group) {
          queryClient.invalidateQueries({
            queryKey: ["group/dikerjakan"],
            type: "all",
          });
        }
        EventHandlersDikerjakanGroup.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onDikerjakanGroupFired", handleDikerjakanGroupFired);

      return () => {
        socket.off("onDikerjakanGroupFired", handleDikerjakanGroupFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_dikerjakan_group, socket]);

  // CHECKOUT SIAP KIRIM
  useEffect(() => {
    if (socket) {
      const handleCheckoutFired = () => {
        if (!refetch_status_checkout_siap_kirim) {
          queryClient.invalidateQueries({
            queryKey: ["checkout/siap_kirim"],
            type: "all",
          });
        }
        EventHandlersCheckoutSiapKirim.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onCheckoutSiapKirimFired", handleCheckoutFired);

      return () => {
        socket.off("onCheckoutSiapKirimFired", handleCheckoutFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_checkout_siap_kirim, socket]);

  // CHECKOUT DIKIRIM
  useEffect(() => {
    if (socket) {
      const handleCheckoutFired = () => {
        if (!refetch_status_checkout_dikirim) {
          queryClient.invalidateQueries({
            queryKey: ["checkout/dikirim"],
            type: "all",
          });
        }
        EventHandlersCheckoutDikirim.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onCheckoutDikirimFired", handleCheckoutFired);

      return () => {
        socket.off("onCheckoutDikirimFired", handleCheckoutFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_checkout_dikirim, socket]);

  // REKAP NOTA
  useEffect(() => {
    if (socket) {
      const handleRekapNotaFired = () => {
        const startDate = dayjs(tanggalRekapFilter[0]).format("YYYY-MM-DD");
        const endDate = dayjs(tanggalRekapFilter[1]).format("YYYY-MM-DD");
        if (!refetch_status_rekap_nota) {
          queryClient.invalidateQueries({
            queryKey: [`rekap/${startDate}|${endDate}`],
            type: "all",
          });
        }
        EventHandlersRekapNota.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onRekapNotaFired", handleRekapNotaFired);

      return () => {
        socket.off("onRekapNotaFired", handleRekapNotaFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_rekap_nota, socket]);

  // REKAP REJECT
  useEffect(() => {
    if (socket) {
      const handleRekapRejectFired = () => {
        const startDate = dayjs(tanggalRejectFilter[0]).format("YYYY-MM-DD");
        const endDate = dayjs(tanggalRejectFilter[1]).format("YYYY-MM-DD");
        if (!refetch_status_rekap_reject) {
          queryClient.invalidateQueries({
            queryKey: [`reject/${startDate}|${endDate}`],
            type: "all",
          });
        }
        EventHandlersRekapReject.handleRefetchStatus({
          dispatch,
          refetch_status: false,
        });
      };

      socket.on("onRekapRejectFired", handleRekapRejectFired);

      return () => {
        socket.off("onRekapRejectFired", handleRekapRejectFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch_status_rekap_reject, socket]);

  // DASHBAORD
  //~jenis order
  useEffect(() => {
    if (socket) {
      const handleDashboardJenisOrderFired = () => {
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/pencapaian/jenis_order/harian?bulan=${jenisOrderHarianBulan}&tahun=${jenisOrderHarianTahun}`,
          ],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/pencapaian/jenis_order/bulanan?tahun=${jenisOrderBulananTahun}`,
          ],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/pencapaian/jenis_order/tahunan?tahun1=${jenisOrderTahunanTahun1}&tahun2=${jenisOrderTahunanTahun2}`,
          ],
          type: "all",
        });
      };

      socket.on("onDashboardJenisOrderFired", handleDashboardJenisOrderFired);

      return () => {
        socket.off(
          "onDashboardJenisOrderFired",
          handleDashboardJenisOrderFired
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  //~source order
  useEffect(() => {
    if (socket) {
      const handleDashboardSourceOrderFired = () => {
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/pencapaian/source_order/harian?bulan=${sourceOrderHarianBulan}&tahun=${sourceOrderHarianTahun}`,
          ],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/pencapaian/source_order/bulanan?tahun=${sourceOrderBulananTahun}`,
          ],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/pencapaian/source_order/tahunan?tahun1=${sourceOrderTahunanTahun1}&tahun2=${sourceOrderTahunanTahun2}`,
          ],
          type: "all",
        });
      };

      socket.on("onDashboardSourceOrderFired", handleDashboardSourceOrderFired);

      return () => {
        socket.off(
          "onDashboardSourceOrderFired",
          handleDashboardSourceOrderFired
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  //~bussiness progress
  useEffect(() => {
    if (socket) {
      const handleDashboardBusinessProgressFired = () => {
        queryClient.invalidateQueries({
          queryKey: [
            `dashboard/business_progress?bulan=${bulanBusinessProgress}&tahun=${tahunBusinessProgress}`,
          ],
          type: "all",
        });
      };

      socket.on(
        "onDashboardBusinessProgressFired",
        handleDashboardBusinessProgressFired
      );

      return () => {
        socket.off(
          "onDashboardBusinessProgressFired",
          handleDashboardBusinessProgressFired
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  //~last reject
  useEffect(() => {
    if (socket) {
      const handleDashboardLastRejectFired = () => {
        queryClient.invalidateQueries({
          queryKey: [`dashboard/last_reject`],
          type: "all",
        });
      };

      socket.on("onDashboardLastRejectFired", handleDashboardLastRejectFired);

      return () => {
        socket.off(
          "onDashboardLastRejectFired",
          handleDashboardLastRejectFired
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  //~last pemabayaran
  useEffect(() => {
    if (socket) {
      const handleDashboardLastPembayaranFired = () => {
        queryClient.invalidateQueries({
          queryKey: [`dashboard/last_pembayaran`],
          type: "all",
        });
      };

      socket.on(
        "onDashboardLastPembayaranFired",
        handleDashboardLastPembayaranFired
      );

      return () => {
        socket.off(
          "onDashboardLastPembayaranFired",
          handleDashboardLastPembayaranFired
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // PENDAPATAN
  useEffect(() => {
    if (socket) {
      const handlePendapatanFired = () => {
        queryClient.invalidateQueries({
          queryKey: [
            `pendapatan/bulanan?bulan=${pendapatanBulananBulan}&tahun=${pendapatanBulananTahun}`,
          ],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`pendapatan/tahunan?tahun=${pendapatanTahunanTahun}`],
          type: "all",
        });
      };

      socket.on("onPendapatanFired", handlePendapatanFired);

      return () => {
        socket.off("onPendapatanFired", handlePendapatanFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  // SETTING
  // # TOKO
  useEffect(() => {
    if (socket) {
      const handleSettingTokoFired = () => {
        queryClient.invalidateQueries({
          queryKey: [`setting`],
          type: "all",
        });
      };

      socket.on("onSettingTokoFired", handleSettingTokoFired);

      return () => {
        socket.off("onSettingTokoFired", handleSettingTokoFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  // # SYSTEM
  useEffect(() => {
    if (socket) {
      const handleSettingSystemFired = () => {
        queryClient.invalidateQueries({
          queryKey: [`jenis_order`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`source_order`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`ekspedisi`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`size`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`additional`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`status`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`keterangan`],
          type: "all",
        });
      };

      socket.on("onSettingSystemFired", handleSettingSystemFired);

      return () => {
        socket.off("onSettingSystemFired", handleSettingSystemFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  // # PRODUK
  useEffect(() => {
    if (socket) {
      const handleSettingProdukFired = () => {
        queryClient.invalidateQueries({
          queryKey: [`size`],
          type: "all",
        });
        queryClient.invalidateQueries({
          queryKey: [`additional`],
          type: "all",
        });
      };

      socket.on("onSettingProdukFired", handleSettingProdukFired);

      return () => {
        socket.off("onSettingProdukFired", handleSettingProdukFired);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return <div></div>;
};

export default AutoRefresh;
