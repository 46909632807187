import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog } from "../../../../components/Layout/Content";
import { DetailAntrianLayout } from "../../../../components/Layout/Content/Antrian";
import { Api, EventHandlers } from "../repository";

const DetailAntrian = () => {
  const dispatch = useDispatch();
  const { detailModal } = useSelector((state) => state.rekapNota);
  const { data, isLoading, isError } = Api.detailAntrian({
    modal: detailModal,
  });

  const handleClose = useCallback(() => {
    EventHandlers.handleDetailModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <BlankDialog
      open={detailModal.open}
      title={detailModal.title}
      handleClose={handleClose}
      isLoading={isLoading}
      maxWidth={"md"}
    >
      <DetailAntrianLayout data={data ? data.data : {}} isLoading={isLoading} />
    </BlankDialog>
  );
};

export default DetailAntrian;
