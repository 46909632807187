import config from "../../../../config";
import {
  SET_PAGE,
  SET_SEARCH,
  SET_PAGE_SIZE,
  SET_FILTER_STATUS,
  SET_FILTER_PRIORITY,
  SET_CREATE_UPDATE_MODAL_GROUP,
  SET_DETAIL_ANTRIAN_GROUP,
  SET_CARD_TOTAL_ANTRIAN,
  SET_ADD_NOTA_GROUP,
  SET_DELETE_GROUP,
  PUSH_TO_ADD_NOTA_GROUP_DATA,
  REMOVE_FROM_ADD_NOTA_GROUP_DATA,
  SET_PRINT_GROUP,
} from "../domain/manageGroupSlice";
import { UPDATE_IMAGE } from "../../../../features/imageDetailSlice";

export class EventHandlers {
  static handleChangePage = ({ dispatch, page }) => {
    dispatch(SET_PAGE({ page }));
  };

  static handleSearch = ({ dispatch, search }) => {
    dispatch(SET_SEARCH({ search }));
  };

  static handlePageSize = ({ dispatch, pageSize }) => {
    dispatch(SET_PAGE_SIZE({ pageSize }));
  };

  static handleFilterStatus = ({ dispatch, filterStatus }) => {
    dispatch(SET_FILTER_STATUS({ filterStatus }));
  };

  static handleFilterPriority = ({ dispatch, filterPriority }) => {
    dispatch(SET_FILTER_PRIORITY({ filterPriority }));
  };

  static handleCreateUpdateGroup = ({ dispatch, modal }) => {
    dispatch(SET_CREATE_UPDATE_MODAL_GROUP({ modal }));
  };

  static handleDetailAntrianGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_DETAIL_ANTRIAN_GROUP({ modal }));
  };

  static handleCardTotalAntrian = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_CARD_TOTAL_ANTRIAN({ modal }));
  };

  static handleAddNotaGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_ADD_NOTA_GROUP({ modal }));
  };

  static handleAddDataNotaOnNotaGroup = ({ dispatch, data }) => {
    dispatch(PUSH_TO_ADD_NOTA_GROUP_DATA({ data }));
  };

  static handleRemoveDataNotaOnNotaGroup = ({ dispatch, id }) => {
    dispatch(REMOVE_FROM_ADD_NOTA_GROUP_DATA({ id }));
  };

  static handleDeleteGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_DELETE_GROUP({ modal }));
  };

  static handlePreview = ({ dispatch, file, title }) => {
    dispatch(
      UPDATE_IMAGE({
        url: `${config.apiUrl}/storage/group/${file}`,
        title: title,
      })
    );
  };

  static handlePrintGroup = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PRINT_GROUP({ modal }));
  };
}
