import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
};

const Rekap = createSlice({
  name: "rekap",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { SET_TAB } = Rekap.actions;

export default Rekap.reducer;
