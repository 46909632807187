import React from "react";
import { Controller } from "react-hook-form";
import { TextField, Autocomplete, CircularProgress, Box } from "@mui/material";

const AutocompleteDynamic = (props) => {
  const {
    name,
    control,
    label,
    rules,
    options,
    isLoading,
    defaultValue = null,
    inputValue,
    onInputChange,
    size = "small",
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue ?? null}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <Autocomplete
          fullWidth
          name={name}
          value={value ?? null}
          onChange={(_, newValuee) => onChange(newValuee)}
          inputValue={value ? value.label : inputValue}
          onInputChange={onInputChange}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id}>
              {option.render ? option.render : option.label}
            </Box>
          )}
          getOptionLabel={(option) => option.label ?? ""}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          options={options}
          loading={isLoading}
          size={size}
          renderInput={(params) => (
            <TextField
              label={`${label} ${rules["required"] ? "*" : ""}`}
              error={error !== undefined}
              helperText={error ? error.message : ""}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  color: "#797979",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 4px 0px #00000040 inset",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  "& .Mui-disabled": {
                    borderRadius: "6px",
                    backgroundColor: "#ECECEC",
                    boxShadow: "0px 2px 4px 0px #00000040 inset",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  marginTop: "5px",
                },
                "& .MuiInputLabel-shrink": {
                  fontWeight: 500,
                  top: "-5px",
                },
                "& legend": {
                  display: "none",
                },
                "& .Mui-focused + .MuiOutlinedInput-root": {
                  color: "#797979 !important",
                  "& .MuiInputLabel-shrink": {
                    color: "#1976d2",
                  },
                },
                "& .Mui-disabled": {
                  color: "#bdbdbd",
                },
                "& .MuiFormHelperText-root": {
                  fontWeight: 500,
                  backgroundColor: "#fff",
                  paddingX: "5px",
                  position: "absolute",
                  top: 23,
                  right: 0,
                },
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteDynamic;
