import { NumericText } from "../../../../components/common";

export class Config {
  static column = [
    {
      field: "id",
      flex: 1,
      minWidth: 70,
      width: 70,
      maxWidth: 70,
      align: "center",
      headerAlign: "center",
      headerName: "Tanggal",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value ?? "Total";
      },
    },
    {
      field: "jumlah_pembayaran",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Jumlah Pembayaran",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        if (params.value) {
          return (
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <NumericText
                value={params.value}
                thousandSeparator
                prefix={false}
                suffix={" Transaksi"}
                displayType="text"
              />
            </span>
          );
        }
      },
    },
    {
      field: "total_nominal",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Total Nominal",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!params.value) {
          return "-";
        }

        if (params.value) {
          return (
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#0067E0",
              }}
            >
              <NumericText
                value={params.value}
                thousandSeparator
                prefix={true}
                displayType="text"
              />
            </span>
          );
        }
      },
    },
  ];

  static variable = {
    nama: "Cashflow",
    endpoint: "cashflow/bulanan",
  };
}
