import React, { useState } from "react";
import { Grid, Divider, TextField, Typography, Stack } from "@mui/material";
import { useDebounce } from "use-debounce";
import {
  AutocompleteDynamic,
  Input,
  NumberInput,
  Select,
  SwitchInput,
} from "../../../../../components/controls";
import { Api } from "../../repository";
import { ChipItems } from "../../../../../components/common";

const FormTambahAntrian = (props) => {
  const { formAntrian, size, jenisOrder, sourceOrder, ekspedisi } = props;

  const [search, setSearch] = useState("");

  const [debounceSearch] = useDebounce(search, 1000);

  const getKecamatan = Api.searchKecamatan({ search: debounceSearch });
  const checkEkspedisi = (type) => {
    const getEkspedisiID = formAntrian.watch("antrian.ekspedisi_id");
    if (getEkspedisiID) {
      const filterEkpedisi = ekspedisi.find((e) => e.id === getEkspedisiID);

      if (filterEkpedisi.tipe === "online") {
        if (type === "rules") {
          return "No. Pengiriman Wajib di isi!";
        }
        return undefined;
      } else {
        if (type === "display") {
          return "none";
        }
        return undefined;
      }
    } else {
      return "none";
    }
  };

  const handleEkspedisiChange = (event) => {
    formAntrian.clearErrors("antrian.keterangan_ekspedisi");
    formAntrian.setValue("antrian.ekspedisi_id", event.target.value);
    const selectedEkspedisi = ekspedisi.find(
      (e) => e.id === event.target.value
    );
    if (selectedEkspedisi && selectedEkspedisi.tipe !== "online") {
      formAntrian.setValue("antrian.keterangan_ekspedisi", "");
    }
  };

  const handleChangeJenisOrder = (value) => {
    formAntrian.setValue("antrian.jenisorder_id", "");
    formAntrian.setValue("antrian.jenisorder_id", value);
    const defaultSize = size.map((sizeItem) => ({
      size_id: sizeItem.id,
      label: sizeItem.name,
      isActive: false,
      harga: 0,
      detail_order: [],
      status: sizeItem.status,
    }));
    formAntrian.resetField("size", { defaultValue: defaultSize });

    const newSize = size.map((sizeItem) => {
      const getHarga = sizeItem.harga.find(
        (hargaItem) => hargaItem.jenisorder_id === value
      );
      return {
        size_id: sizeItem.id,
        label: sizeItem.name,
        isActive: false,
        harga: getHarga ? getHarga.harga : 0,
        detail_order: [],
        status: sizeItem.status,
      };
    });
    formAntrian.setValue("size", newSize);
  };

  const CheckHarga = ({ jenisorder_id, harga }) => {
    let error = [];
    const newHarga = harga.find((item) => item.jenisorder_id === jenisorder_id);

    if (newHarga.hpp === 0) {
      error.push("HPP");
    }
    if (newHarga.harga === 0) {
      error.push("Harga");
    }

    if (error.length > 0) {
      return (
        <>
          <Grid item xs={6} />
          <Grid item xs={6} sx={{ paddingLeft: 2, paddingTop: 0.5 }}>
            <Stack direction={"row"} spacing={1}>
              <Typography color={"#F60000"} fontSize={9}>
                N/A :
              </Typography>
              {error.map((item, index) => (
                <ChipItems
                  key={index}
                  label={item}
                  sx={{ fontSize: 9, height: "15px" }}
                  backgroundcolor="#F60000"
                  color="#fff"
                  borderradius={"3px"}
                />
              ))}
            </Stack>
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                rules={{ required: "Nama Harus di isi!" }}
                name={"detail.nama"}
                control={formAntrian.control}
                label={"Leads / Nama"}
                defaultValue={""}
              />
            </Grid>

            <Grid item xs={6}>
              <Input
                rules={{ required: "No HP Harus di isi!" }}
                name={"detail.no_hp"}
                control={formAntrian.control}
                label={"No. HP"}
                defaultValue={""}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteDynamic
                rules={{ required: "Kecamatan Harus di isi!" }}
                name={"detail.kecamatan_id"}
                control={formAntrian.control}
                label={"Kecamatan"}
                defaultValue={null}
                options={
                  getKecamatan.data
                    ? getKecamatan.data.data.map((e) => {
                        return {
                          id: e.id,
                          label: e.kecamatan,
                          kabupaten: e.kabupaten,
                          provinsi: e.provinsi,
                          render: `${e.kecamatan} | ${e.kabupaten} | ${e.provinsi}`,
                        };
                      })
                    : []
                }
                isLoading={getKecamatan.isLoading}
                inputValue={search}
                onInputChange={(event, newInputValue) => {
                  if (event?.type === "change") {
                    setSearch(newInputValue);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={"outlined"}
                label={`Kabupaten`}
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#797979",
                    borderRadius: "5px",
                    boxShadow: "0px 2px 4px 0px #00000040 inset",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& .Mui-disabled": {
                      fontWeight: 700,
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    marginTop: "5px",
                    padding: "8.7px 14px",
                  },
                  "& .MuiInputLabel-shrink": {
                    fontWeight: 500,
                    top: "-5px",
                  },
                  "& legend": {
                    display: "none",
                  },
                  "& .MuiFormHelperText-root": {
                    fontWeight: 500,
                    backgroundColor: "#fff",
                    paddingX: "5px",
                    position: "absolute",
                    top: 23,
                    right: 0,
                  },
                  "& .Mui-disabled + .MuiInputBase-root": {
                    borderRadius: "6px",
                    backgroundColor: "#ECECEC",
                    "& .MuiInputAdornment-root": {
                      "& .MuiTypography-root": {
                        fontWeight: 700,
                      },
                    },
                  },
                  "& .MuiInputAdornment-root": {
                    "& .MuiTypography-root": {
                      color: "rgb(121, 121, 121, 0.9)",
                    },
                  },
                }}
                value={
                  formAntrian.watch("detail.kecamatan_id")?.kabupaten || ""
                }
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={"outlined"}
                label={`Provinsi`}
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#797979",
                    borderRadius: "5px",
                    boxShadow: "0px 2px 4px 0px #00000040 inset",
                  },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    "& .Mui-disabled": {
                      fontWeight: 700,
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    marginTop: "5px",
                    padding: "8.7px 14px",
                  },
                  "& .MuiInputLabel-shrink": {
                    fontWeight: 500,
                    top: "-5px",
                  },
                  "& legend": {
                    display: "none",
                  },
                  "& .MuiFormHelperText-root": {
                    fontWeight: 500,
                    backgroundColor: "#fff",
                    paddingX: "5px",
                    position: "absolute",
                    top: 23,
                    right: 0,
                  },
                  "& .Mui-disabled + .MuiInputBase-root": {
                    borderRadius: "6px",
                    backgroundColor: "#ECECEC",
                    "& .MuiInputAdornment-root": {
                      "& .MuiTypography-root": {
                        fontWeight: 700,
                      },
                    },
                  },
                  "& .MuiInputAdornment-root": {
                    "& .MuiTypography-root": {
                      color: "rgb(121, 121, 121, 0.9)",
                    },
                  },
                }}
                value={formAntrian.watch("detail.kecamatan_id")?.provinsi || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                rules={{ required: false }}
                name={"detail.alamat"}
                control={formAntrian.control}
                label={"Alamat Lengkap"}
                defaultValue={""}
                rows={3}
                multiline
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Select
                disabled={jenisOrder.length ? false : true}
                name={"antrian.jenisorder_id"}
                control={formAntrian.control}
                label={"Jenis Order"}
                options={jenisOrder.filter((ekspedisi) => ekspedisi.status)}
                rules={{ required: true }}
                defaultValue={""}
                onChange={(event) => handleChangeJenisOrder(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                disabled={sourceOrder.length ? false : true}
                name={"antrian.sourceorder_id"}
                control={formAntrian.control}
                label={"Source Order"}
                options={sourceOrder.filter((ekspedisi) => ekspedisi.status)}
                rules={{ required: true }}
                defaultValue={""}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                disabled={ekspedisi.length ? false : true}
                name={"antrian.ekspedisi_id"}
                control={formAntrian.control}
                label={"Expedisi"}
                options={ekspedisi.filter((ekspedisi) => ekspedisi.status)}
                rules={{ required: true }}
                defaultValue={""}
                onChange={handleEkspedisiChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                rules={{ required: checkEkspedisi("rules") }}
                name={"antrian.keterangan_ekspedisi"}
                control={formAntrian.control}
                label={"No. Pengiriman"}
                defaultValue={""}
                disabled={checkEkspedisi("display") ? true : false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {size.length > 0 &&
            size
              .filter((filterSize) => filterSize.status)
              .map((size, index) => (
                <Grid
                  container
                  key={`${formAntrian.watch("antrian.jenisorder_id")}-${
                    size.id
                  }`}
                  sx={{ pb: 1 }}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: "right",
                      paddingRight: 2,
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="hidden"
                      {...formAntrian.register(`size.${index}.size_id`)}
                      value={parseInt(size.id)}
                    />
                    <input
                      type="hidden"
                      {...formAntrian.register(`size.${index}.label`)}
                      value={size.name}
                    />
                    <SwitchInput
                      key={`switch-${formAntrian.watch(
                        "antrian.jenisorder_id"
                      )}-${size.id}`}
                      name={`size.${index}.isActive`}
                      control={formAntrian.control}
                      label={size.name}
                      defaultValue={
                        formAntrian.watch("antrian.jenisorder_id")
                          ? true
                          : false
                      }
                      sx={{ marginLeft: "0.5rem" }}
                      disabled={!formAntrian.watch(`antrian.jenisorder_id`)}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingLeft: 2 }}>
                    <NumberInput
                      rules={{
                        required: formAntrian.watch(`size.${index}.isActive`)
                          ? "Harga Harus di isi!"
                          : false,
                        min: {
                          value: formAntrian.watch(`size.${index}.isActive`)
                            ? 0
                            : 0,
                          message: "Harga Harus di isi!",
                        },
                      }}
                      name={`size.${index}.harga`}
                      control={formAntrian.control}
                      label={
                        formAntrian.watch(`size.${index}.isActive`)
                          ? `Harga Satuan (${size.name})`
                          : ""
                      }
                      defaultValue={0}
                      variant={"filled"}
                      disabled={!formAntrian.watch(`size.${index}.isActive`)}
                      value={
                        formAntrian.watch(`size.${index}.reset`)
                          ? 0
                          : formAntrian.watch(`size.${index}.harga`)
                      }
                    />
                  </Grid>
                  {formAntrian.watch(`antrian.jenisorder_id`) && (
                    <CheckHarga
                      jenisorder_id={formAntrian.watch(`antrian.jenisorder_id`)}
                      harga={size.harga}
                    />
                  )}
                </Grid>
              ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FormTambahAntrian;
