import React from "react";
import Layout from "../layout/Layout";
import { TabPanel, a11yProps, SortingLength } from "../components/common";
import { useDispatch, useSelector } from "react-redux";
import { AddGroup } from "../components/Layout/Content/LayoutGroup";
import { EventHandlers } from "../features/manageGroup/page/repository";
import {
  Api,
  EventHandlers as EventHandlersGroup,
} from "../features/manageGroup/group/repository";
import {
  AppBar,
  Stack,
  Grid,
  Tab,
  Tabs,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import InputSearch from "../components/common/InputSearch";
import GroupPage from "../features/manageGroup/group/GroupPage";
import NotaPage from "../features/manageGroup/nota/NotaPage";

const ManageGroup = () => {
  const dispatch = useDispatch();

  const { tab } = useSelector((state) => state.manageGroupPage);

  const { pageSize, search, filterStatus, filterPriority } = useSelector(
    (state) => state.manageGroup
  );

  const { data } = Api.read();
  const statusOptions = Array.from(
    new Map(
      data
        ? data.data
            .filter((item) => item.status.id)
            .sort((a, b) => a.status.status.localeCompare(b.status.status))
            .map((item) => [item.status.id, item.status])
        : []
    ).values()
  );

  return (
    <Layout>
      {/* Title Container */}
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent={tab === 0 ? "space-between" : "end"}
        alignItems="center"
      >
        {tab === 0 && (
          <>
            <Grid
              container
              columnSpacing={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Grid item>
                <AddGroup
                  onClickAddGroup={() =>
                    EventHandlersGroup.handleCreateUpdateGroup({
                      dispatch,
                      modal: {
                        id: null,
                        open: true,
                        title: "Tambah Group !",
                      },
                    })
                  }
                />
              </Grid>
              <Grid item>
                <InputSearch
                  value={search}
                  handleChange={(search) =>
                    EventHandlersGroup.handleSearch({ dispatch, search })
                  }
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth sx={SelectStyle}>
                  <InputLabel id="demo-simple-select-label">
                    Filter Status
                  </InputLabel>
                  <Select
                    value={filterStatus}
                    label="Filter Status"
                    onChange={(event) =>
                      EventHandlersGroup.handleFilterStatus({
                        dispatch,
                        filterStatus: event.target.value,
                      })
                    }
                    size="small"
                  >
                    <MenuItem value="All">Semua Status</MenuItem>
                    {statusOptions.map((status, index) => (
                      <MenuItem value={status.id} key={index}>
                        {status.status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth sx={SelectStyle}>
                  <InputLabel id="demo-simple-select-label">
                    Filter Priority
                  </InputLabel>
                  <Select
                    value={filterPriority}
                    label="Filter Priority"
                    onChange={(event) =>
                      EventHandlersGroup.handleFilterPriority({
                        dispatch,
                        filterPriority: event.target.value,
                      })
                    }
                    size="small"
                  >
                    <MenuItem value="All">Semua Priority</MenuItem>
                    <MenuItem value={true}>Prioritas</MenuItem>
                    <MenuItem value={false}>Regular</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <SortingLength
              value={pageSize}
              handleChange={(pageSize) =>
                EventHandlersGroup.handlePageSize({ dispatch, pageSize })
              }
            />
          </>
        )}
        <AppBar
          position="static"
          elevation={0}
          sx={{ backgroundColor: "#fff", width: "250px" }}
        >
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(_, value) =>
              EventHandlers.handleTab({ dispatch, tab: value })
            }
            centered
          >
            <Tab {...a11yProps(0)} label="GROUP" />
            <Tab {...a11yProps(1)} label="ANTRIAN" />
          </Tabs>
        </AppBar>
      </Stack>

      {/* Content */}
      <TabPanel value={tab} index={0}>
        <GroupPage />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <NotaPage />
      </TabPanel>
    </Layout>
  );
};

const SelectStyle = {
  "& .MuiInputBase-root": {
    color: "#1976d2",
    borderRadius: "5px",
    fontSize: "13px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& .Mui-disabled": {
      borderRadius: "6px",
      backgroundColor: "#ECECEC",
      fontWeight: 700,
    },
    "& .MuiSvgIcon-root": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(25, 118, 210, 0.5)",
    marginTop: "5px",
  },
  "& .MuiInputLabel-shrink": {
    display: "none",
    color: "#1976d2",
    fontWeight: 500,
    top: "-5px",
  },
  "& legend": {
    display: "none",
  },
  "& .Mui-focused + .MuiOutlinedInput-root": {
    "& .MuiInputLabel-shrink": {
      color: "#1976d2",
    },
  },
  "& .Mui-disabled": {
    color: "#bdbdbd",
  },
  "& .MuiFormLabel-root": {
    color: "#1976d2",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 500,
    backgroundColor: "#fff",
    paddingX: "5px",
    position: "absolute",
    top: 23,
    right: 0,
  },
  "& .MuiSvgIcon-root": {
    color: "#1976d2",
  },
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
};

export default ManageGroup;
