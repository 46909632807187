import { Avatar, Chip } from "@mui/material";
import config from "../../../config";

export class Config {
  static column = [
    {
      field: "usercode",
      flex: 1,
      minWidth: 100,
      hideable: false,
      headerName: "Kode",
      renderCell: (params) => {
        return <span style={{ fontWeight: 700 }}>{params.value}</span>;
      },
    },
    {
      field: "role",
      flex: 1,
      minWidth: 100,
      headerName: "Role",
      renderCell: (params) => {
        return (
          <span>
            {params.value === "admin_produksi"
              ? "Produksi"
              : params.value
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
          </span>
        );
      },
    },
    {
      field: "name",
      flex: 1,
      minWidth: 150,
      headerName: "Nama",
    },
    {
      field: "username",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      headerName: "Username",
    },
    {
      field: "status",
      type: "singleSelect",
      flex: 1,
      minWidth: 70,
      headerName: "Status",
      renderCell: (params) => {
        if (params.value === "active") {
          return (
            <Chip
              size="small"
              label={"Active"}
              sx={{
                borderRadius: "3px",
                backgroundColor: "#39c236",
                color: "white",
                fontWeight: 500,
                "& .MuiSvgIcon-root": {
                  marginLeft: "8px",
                  color: "#fff",
                },
              }}
              variant="filled"
            />
          );
        } else {
          return (
            <Chip
              size="small"
              label={"Banned"}
              sx={{
                borderRadius: "3px",
                backgroundColor: "#ff4040",
                color: "white",
                fontWeight: 500,
                "& .MuiSvgIcon-root": {
                  marginLeft: "8px",
                  color: "#fff",
                },
              }}
              variant="filled"
            />
          );
        }
      },
    },
    {
      field: "color",
      type: "singleSelect",
      flex: 1,
      minWidth: 70,
      headerName: "Color",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value) {
          return (
            <Chip
              size="small"
              label={params.value}
              sx={{
                borderRadius: "3px",
                backgroundColor: params.value,
                color: "white",
                fontWeight: 500,
                "& .MuiSvgIcon-root": {
                  marginLeft: "8px",
                  color: "#fff",
                },
              }}
              variant="filled"
            />
          );
        }
        return "";
      },
    },
    {
      field: "photo",
      flex: 1,
      minWidth: 150,
      headerName: "Photo",
      headerAlign: "center",
      align: "center",
      display: "flex",
      renderCell: (params) => {
        const src =
          config.apiUrl + "/storage/user/" + params.value ||
          "/broken-image.jpg";
        return (
          <>
            <Avatar
              src={src}
              sx={{ boxShadow: "0px 2px 4px 0px #00000040 inset" }}
            />
          </>
        );
      },
    },
    {
      headerName: "Opsi",
      disableClickEventBubbling: true,
      field: "actions",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      disableColumnResize: true,
      disableColumnReorder: true,
      width: 60,
    },
  ];

  static variable = {
    nama: "User",
    endpoint: "user",
  };
}
