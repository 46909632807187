import React, { useState } from "react";
import { Grid, Divider, Typography, Card } from "@mui/material";
import { BlankDialog } from "../../../../components/Layout/Content";
import CollapseButton from "../../../../components/common/CollapseButton";
import { CardOrder } from "./DetailAntrian";
import { CollapseDetailOrderPembayaran, RiwayatPembayaran } from "./Pembayaran";
import { NumericText } from "../../../common";
import FormCashLess from "../../../common/FormCashLess";
import { useGetCachedData } from "../../../../hooks";
import { useSelector } from "react-redux";
import { CustomButton } from "../../../controls";

const PembayaranLayout = ({
  title,
  handleClose,
  open,
  data,
  onSubmit,
  isLoading,
  formAntrian,
  handleDeletePembayaran,
}) => {
  const { role } = useSelector((state) => state.auth);
  const dataAntrian = {
    nota: data?.antrian?.nota ?? "-",
    keterangan: data?.antrian?.keterangan ?? "-",
    reject: {
      keterangan: data?.antrian?.reject?.keterangan ?? "-",
      nominal: data?.antrian?.reject?.nominal ?? 0,
    },
    total: {
      pembelian: data?.total?.pembelian?.total ?? 0,
      pembayaran: data?.total?.pembayaran?.total ?? 0,
    },
  };
  const getDataMetodePembayaran = useGetCachedData({
    queryKey: "metode_pembayaran/cashless",
  });
  const [openRiwayat, setOpenRiwayat] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const handleRiwayat = () => {
    setOpenRiwayat(!openRiwayat);
  };

  const handleDetail = () => {
    setOpenDetail(!openDetail);
  };

  return (
    <BlankDialog
      title={title + " Nota " + dataAntrian.nota}
      handleClose={handleClose}
      open={open}
      isLoading={isLoading}
    >
      <form onSubmit={formAntrian.handleSubmit(onSubmit)}>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            fontSize: "12px",
            color: "#797979",
            fontWeight: "400",
          }}
        >
          <Grid item xs={12} textAlign="center" marginBottom={2}>
            <Card
              sx={{
                backgroundColor: "#105174",
                color: "#FFFFFF",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: "600",
                  marginTop: "0.5rem",
                  marginBottom: "-0.5rem",
                }}
              >
                Total Beli
              </Typography>
              <Typography
                sx={{
                  fontSize: 48,
                  fontWeight: "700",
                  marginBottom: "0.5rem",
                }}
              >
                <NumericText
                  prefix={true}
                  value={dataAntrian.total.pembelian}
                />
              </Typography>
            </Card>
          </Grid>
          {dataAntrian.total.pembayaran < dataAntrian.total.pembelian &&
            role === "customer_service" && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <FormCashLess
                  formAntrian={formAntrian}
                  metodePembayaran={
                    getDataMetodePembayaran ? getDataMetodePembayaran.data : []
                  }
                  total={dataAntrian.total}
                />

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid container item marginTop={2}>
                  <CustomButton
                    disabled={false}
                    loading={isLoading}
                    type="submit"
                    sx={{
                      backgroundColor: "#39C236",
                      "&:hover": {
                        backgroundColor: "#35af33",
                      },
                      "&:disabled:": {
                        backgroundColor: "#35af33",
                      },
                    }}
                  >
                    BAYAR
                  </CustomButton>
                </Grid>
              </>
            )}

          {/* order detail dkk */}
          <Grid item xs={12} pt={1.5}>
            <Divider>
              <CollapseButton
                onClick={handleRiwayat}
                checked={openRiwayat}
                expandLabel="Riwayat Pembayaran"
                collapseLabel="Riwayat Pembayaran"
                disabled={isLoading}
              />
            </Divider>
            <RiwayatPembayaran
              in={openRiwayat}
              dataPembayaran={data?.pembayaran ?? []}
              handleDeletePembayaran={handleDeletePembayaran}
            />
          </Grid>
          <Grid item xs={12} pt={1.5}>
            <Divider>
              <CollapseButton
                onClick={handleDetail}
                checked={openDetail}
                expandLabel="Order Detail"
                collapseLabel="Order Detail"
                disabled={isLoading}
              />
            </Divider>
            <CollapseDetailOrderPembayaran
              in={openDetail}
              dataAntrianSize={data?.total?.size ?? []}
              keteranganNota={data?.antrian?.keterangan ?? null}
              reject={data?.antrian?.reject ?? null}
              totalPembelian={data?.total?.pembelian.total ?? 0}
            />
          </Grid>

          {/* CardOrder */}
          <Grid container item mt={0} spacing={2}>
            <CardOrder
              ekspedisi={data?.antrian?.ekspedisi ?? {}}
              total={data?.total ?? {}}
            />
          </Grid>
        </Grid>
      </form>
    </BlankDialog>
  );
};

export default PembayaranLayout;
