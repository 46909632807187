import React, { useCallback, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/controls/CustomButton";
import {
  BlankDialog,
  UploadImageLayout,
} from "../../../../components/Layout/Content";
import { Api, EventHandlers } from "../repository";
import { Input, SwitchInput } from "../../../../components/controls";
import { ActionGrid } from "../../../../components/common";

const ModalUpload = () => {
  const dispatch = useDispatch();
  const formUpload = useForm();
  const [progress, setProgress] = useState(0);

  const [gambar, setGambar] = useState(null);

  const { socket } = useSelector((state) => state.data);
  const { createUpdateModal } = useSelector((state) => state.antrianGroup);

  const handleClose = useCallback(() => {
    setProgress(0);
    formUpload.reset({ gambar: null, confirmation: false });
    EventHandlers.handleCreateUpdateGroup({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch, formUpload]);

  const updateGroup = Api.updateGroup({
    setProgress,
    id: createUpdateModal.id,
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    const data = values;
    updateGroup.mutate({
      ...data,
    });
  };

  const onDeleteImageHandler = () => {
    setGambar(null);
    formUpload.setValue("clear_image", true);
  };

  useEffect(() => {
    if (createUpdateModal.data) {
      formUpload.setValue("keterangan", createUpdateModal.data.keterangan);
      formUpload.setValue("prioritas", createUpdateModal.data.prioritas);
      setGambar(createUpdateModal.data.gambar);
    }
  }, [createUpdateModal.data, formUpload]);

  return (
    <BlankDialog
      open={createUpdateModal.open}
      handleClose={handleClose}
      hideCloseIcon={true}
      title={
        <Stack direction={"row"} alignItems={"center"} position={"relative"}>
          <span>{createUpdateModal.title}</span>
          <span
            style={{
              position: "absolute",
              top: "50%",
              right: 5,
              transform: "translateY(-50%)",
            }}
          >
            <SwitchInput
              name="prioritas"
              control={formUpload.control}
              label="Prioritas"
              defaultValue={false}
            />
          </span>
        </Stack>
      }
      outsideClose={false}
      isLoading={false}
      maxWidth={"xs"}
      action={
        <ActionGrid
          padding={"1rem"}
          isLoading={updateGroup.isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={updateGroup.isPending}
              warna={"next"}
              disabled={formUpload.watch("confirmation") ? false : true}
              onClick={formUpload.handleSubmit(onSubmit)}
            >
              Simpan
            </CustomButton>
          }
        />
      }
    >
      <form onSubmit={formUpload.handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...formUpload.register("clear_image", { value: false })}
        />
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={formUpload.control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              multiline={true}
            />
          </Grid>
        </Grid>
        <UploadImageLayout
          form={formUpload}
          progress={progress}
          gambar={gambar}
          onDeleteImage={onDeleteImageHandler}
          required={false}
          path="group"
        />
      </form>
    </BlankDialog>
  );
};

export default ModalUpload;
