import Wave from "../../../assets/icon/logo/wave.svg";

const Logo = () => {
  return (
    <>
      <img
        alt="logo"
        src={Wave}
        height={30}
        style={{
          mr: 2,
          display: { xs: "none", md: "flex" },
          // flexGrow: 1,
        }}
      />
    </>
  );
};

export default Logo;
