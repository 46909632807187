import { useFetchingData } from "../../../hooks";

export class Api {
  static me = ({ token }) => {
    return useFetchingData({
      endpoint: "me",
      enabled: token ? true : false,
      type: "non_protected",
    });
  };

  static user = () => {
    return useFetchingData({
      endpoint: "users/group_by_role",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
      type: "non_protected",
    });
  };

  static setting = () => {
    return useFetchingData({
      endpoint: "setting",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
      type: "non_protected",
    });
  };

  static status = () => {
    return useFetchingData({
      endpoint: "status",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
      type: "non_protected",
    });
  };

  static jenisOrder = () => {
    return useFetchingData({
      endpoint: "jenis_order",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };

  static sourceOrder = () => {
    return useFetchingData({
      endpoint: "source_order",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };

  static size = () => {
    return useFetchingData({
      endpoint: "size",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };

  static additional = () => {
    return useFetchingData({
      endpoint: "additional",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };

  static ekspedisi = () => {
    return useFetchingData({
      endpoint: "ekspedisi",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };

  static keterangan = () => {
    return useFetchingData({
      endpoint: "keterangan",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };

  static metodePembayaran = () => {
    return useFetchingData({
      endpoint: "metode_pembayaran/cashless",
      enabled: true,
      staleTime: Infinity,
      gcTime: Infinity,
    });
  };
}
