import {
  SET_CREATE_UPDATE_TARGET,
  SET_HAPUS_TARGET,
} from "../domain/pengaturanTargetSlice";

export class EventHandlers {
  static handleCreateUpdate = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_CREATE_UPDATE_TARGET({ modal }));
  };

  static handleHapusTarget = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_HAPUS_TARGET({ modal }));
  };
}
