import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";
import handleFocus from "./handleFocus";

const Input = ({
  name,
  control,
  label,
  rules,
  defaultValue = "",
  variant = "outlined",
  shrinklabel,
  InputProps,
  addorment = false,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? ""}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...rest}
          value={field.value}
          fullWidth
          variant={variant}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          label={`${label} ${rules["required"] ? "*" : ""}`}
          size="small"
          sx={{
            "& .MuiInputBase-root": {
              color: "#797979",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px 0px #00000040 inset",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& .Mui-disabled": {
                fontWeight: 700,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              marginTop: "5px",
            },
            "& .MuiInputLabel-shrink": {
              fontWeight: 500,
              top: "-5px",
            },
            "& legend": {
              display: "none",
            },
            "& .MuiFormHelperText-root": {
              fontWeight: 500,
              backgroundColor: "#fff",
              paddingX: "5px",
              position: "absolute",
              top: 23,
              right: 0,
            },
            "& .Mui-disabled + .MuiInputBase-root": {
              borderRadius: "6px",
              backgroundColor: "#ECECEC",
              "& .MuiInputAdornment-root": {
                "& .MuiTypography-root": {
                  fontWeight: 700,
                },
              },
            },
            "& .MuiInputAdornment-root": {
              "& .MuiTypography-root": {
                color: "rgb(121, 121, 121, 0.9)",
              },
            },
          }}
          InputLabelProps={{
            shrink: shrinklabel,
          }}
          InputProps={
            field.value
              ? {
                  startAdornment: addorment === true && (
                    <InputAdornment position="start">
                      {InputProps}
                    </InputAdornment>
                  ),
                }
              : null
          }
          onFocus={handleFocus}
        />
      )}
    />
  );
};

export default Input;
