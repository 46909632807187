import * as React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  Box,
  Chip,
} from "@mui/material";

const MultipleSelect = (props) => {
  const {
    name,
    control,
    label,
    options,
    rules,
    defaultValue,
    disabled = false,
    size = "small",
    ...rest
  } = props;

  const idToName = (selectedIds) => {
    return selectedIds.map((selectedId) => {
      const selectedItem = options.find((item) => item.id === selectedId);
      return selectedItem ? selectedItem.name : "";
    });
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          disabled={disabled}
          fullWidth
          size={size}
          {...(error && { error: true })}
          sx={{
            "& .MuiInputBase-root": {
              color: "#797979",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px 0px #00000040 inset",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& .Mui-disabled": {
                borderRadius: "6px",
                backgroundColor: "#ECECEC",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              marginTop: "5px",
            },
            "& .MuiInputLabel-shrink": {
              fontWeight: 500,
              top: "-5px",
            },
            "& legend": {
              display: "none",
            },
            "& .Mui-focused + .MuiOutlinedInput-root": {
              color: "#797979 !important",
              "& .MuiInputLabel-shrink": {
                color: "#1976d2",
              },
            },
            "& .Mui-disabled": {
              color: "#bdbdbd",
            },
          }}
        >
          <InputLabel>{`${label} ${rules["required"] ? "*" : ""}`}</InputLabel>
          <MuiSelect
            {...field}
            {...rest}
            label={`${label} ${rules["required"] ? "*" : ""}`}
            multiple
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "center",
                  marginTop: "2px",
                }}
              >
                {idToName(selected).map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    size="small"
                    sx={{ height: "17px" }}
                  />
                ))}
              </Box>
            )}
          >
            <MenuItem value="" disabled>
              Pilih {label}
            </MenuItem>
            {options.length &&
              options.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox checked={field.value.indexOf(item.id) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
          </MuiSelect>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default MultipleSelect;
