import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/cashflowTahunanSlice";
import { Config } from "../repository";

const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const TabelCashflowTahunan = ({ data, isLoading }) => {
  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.cashflowTahunan);

  const columns = Config.column.map((e) => {
    if (e.field === "id") {
      return {
        ...e,
        valueGetter: (params) => {
          const value = params;
          return monthNames[value - 1] || value;
        },
      };
    }
    return e;
  });

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel: !isLoading ? aggregationModel : {},
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      setDensity={"compact"}
      disableColumnMenu={true}
      hideFooter={true}
      sx={{
        "& .MuiDataGrid-aggregationColumnHeaderLabel": {
          display: "none",
        },
      }}
    />
  );
};

TabelCashflowTahunan.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TabelCashflowTahunan;
