import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/pengaturanTargetSlice";
import { Config } from "../repository";
import ActionButton from "./TabelTarget/ActionButton";

const TabelTarget = ({ data, isLoading, form }) => {
  const dispatch = useDispatch();

  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.pengaturanTarget);

  const columns = Config.column.map((e) => {
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) =>
          ActionButton.getActionOpsi({ form, params, dispatch }),
      };
    }
    return e;
  });

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
    />
  );
};

TabelTarget.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default TabelTarget;
