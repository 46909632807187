import React, { useState, useEffect } from "react";
import { Stack, Paper, Typography, IconButton, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import { useSelector } from "react-redux";
import { useSocket } from "../../../hooks";

const StatusConection = () => {
  const [clicked, setClicked] = useState(false);
  const { socket } = useSelector((state) => state.data);
  const { connectSocket } = useSocket();
  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = animasi;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const handleStatusChange = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
      connectSocket();
    }, 1000);
  };

  return (
    <Stack
      direction="row"
      spacing={0.5}
      justifyContent="start"
      alignItems="center"
    >
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          borderRadius: 50,
          animation: "fadeIn 1500ms infinite alternate",
        }}
      >
        <Tooltip
          title={
            socket ? "Auto Refresh Connected!" : "Auto Refresh Not Connected"
          }
        >
          <CircleIcon
            sx={{
              color: socket ? "rgb(84 255 2)" : "red",
              padding: "3px",
              display: "block",
            }}
          />
        </Tooltip>
        {!socket && (
          <>
            <Typography sx={{ color: "white", paddingRight: "10px" }}>
              Auto Refresh Not Connected
            </Typography>
            <IconButton
              sx={{
                padding: 0,
                animation: `${clicked ? "rotate 400ms linear infinite" : ""}`,
              }}
              onClick={handleStatusChange}
            >
              <ReplayCircleFilledIcon
                sx={{ color: "white", transform: "scaleX(-1)" }}
              />
            </IconButton>
          </>
        )}
      </Paper>
    </Stack>
  );
};

const animasi = `
  @keyframes fadeIn  {
    from { opacity: 0.3; }
  to { opacity: 1; }
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default StatusConection;
