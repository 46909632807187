import * as React from "react";
import { Avatar, Divider, Grid } from "@mui/material";
import { Api, EventHandlers } from "../repository";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog } from "../../../components/Layout/Content";
import CustomButton from "../../../components/controls/CustomButton";
import { CheckboxInput } from "../../../components/controls";
import { ActionGrid } from "../../../components/common";
import { useForm } from "react-hook-form";

const ModalDeleteUser = () => {
  const { deleteUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const form = useForm();

  const handleClose = () => {
    form.setValue("confirmation", false);
    EventHandlers.handleDelete({
      dispatch,
      modal: {
        id: null,
        name: null,
        role: null,
        photo: null,
        open: false,
        title: null,
      },
    });
  };

  const { mutate, isPending } = Api.delete({ handleClose });

  const onDelete = () => {
    mutate(deleteUser.id);
  };

  return (
    <BlankDialog
      maxWidth={"xs"}
      open={deleteUser.open}
      handleClose={handleClose}
      hideCloseIcon={true}
      title={<span style={{ color: "#FF0707" }}>{deleteUser.title}</span>}
    >
      <form onSubmit={form.handleSubmit(onDelete)}>
        <Grid
          container
          padding={2}
          sx={{ backgroundColor: "#E0F0F9", borderRadius: "5px" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid container item xs={9}>
            {[
              { label: "Kode User", value: deleteUser.usercode },
              { label: "Nama", value: deleteUser.name },
              {
                label: "Role",
                value: deleteUser.role
                  ? deleteUser.role === "admin_produksi"
                    ? "Produksi"
                    : deleteUser.role
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                  : "",
              },
            ].map((item, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={4}
                  fontWeight={item.label === "Kode User" ? 700 : 400}
                >
                  {item.label}
                </Grid>
                <Grid
                  item
                  xs={0.5}
                  fontWeight={item.label === "Kode User" ? 700 : 400}
                >
                  :
                </Grid>
                <Grid
                  item
                  xs={7.5}
                  textTransform={"capitalize"}
                  fontWeight={item.label === "Kode User" ? 700 : 400}
                >
                  {item.value}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Grid item xs={2} textAlign={"end"}>
            <Avatar
              variant="square"
              src={deleteUser.photo ? deleteUser.photo : "/broken-image.jpg"}
              sx={{
                width: deleteUser.photo ? "100%" : "60px !important",
                height: deleteUser.photo ? "100%" : "60px !important",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
                borderRadius: "5px",
              }}
            />
          </Grid>
        </Grid>

        {/* Validation Check */}
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={form.control}
            label={"Data yang dihapus tidak dapat dikembalikan !"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>

        {/* Action Button */}
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton
              onClick={handleClose}
              warna={"next"}
              widthbtn={"100%"}
            >
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"clear"}
              widthbtn={"100%"}
              disabled={!form.watch("confirmation")}
              type="submit"
            >
              Hapus
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalDeleteUser;
