import { NumericFormat } from "react-number-format";

const NumericText = (props) => {
  const { value, style, prefix = false, suffix } = props;
  return (
    <NumericFormat
      value={value}
      thousandSeparator="."
      decimalSeparator=","
      displayType="text"
      style={style}
      prefix={prefix ? "Rp " : undefined}
      suffix={suffix}
      decimalScale={2}
    />
  );
};

export default NumericText;
