import {
  SET_CREATE_UPDATE_MODAL,
  SET_HAPUS_ANTRIAN,
  SET_PRINT_MODAL,
  SET_DETAIL_MODAL,
  SET_DETAIL_PROGRESS_MODAL,
  SET_PEMBAYARAN_MODAL,
  SET_DELETE_PEMBAYARAN_MODAL,
  SET_CHANGE_PERSON_MODAL,
  SET_CHANGE_KIRIM,
  SET_PROGRESS_MODAL,
  SET_TANGGAL_RETUR_FILTER,
  SET_EDIT_DETAIL_ORDER,
  SET_EDIT_SIZE_QTY,
  SET_CHANGE_DESIGNER_MODAL,
} from "../domain/returSlice";

export class EventHandlers {
  static handleCreateUpdateModal = ({
    dispatch,
    modal,
    shouldReset = false,
    clearErrors,
    reset,
  }) => {
    dispatch(SET_CREATE_UPDATE_MODAL({ modal }));
    clearErrors();
    if (shouldReset) {
      reset();
    }
  };

  static handleHapusAntrian = ({ dispatch, modal }) => {
    dispatch(SET_HAPUS_ANTRIAN({ modal }));
  };

  static handlePrintModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PRINT_MODAL({ modal }));
  };

  static handleDetailModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_DETAIL_MODAL({ modal }));
  };

  static handleDetailProgressModal = ({ dispatch, modal }) => {
    dispatch(SET_DETAIL_PROGRESS_MODAL({ modal }));
  };

  static handlePembayaranModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PEMBAYARAN_MODAL({ modal }));
  };

  static handleDeletePembayaran = ({ dispatch, modal }) => {
    dispatch(SET_DELETE_PEMBAYARAN_MODAL({ modal }));
  };

  static handleChangePersonModal = ({ dispatch, modal }) => {
    dispatch(SET_CHANGE_PERSON_MODAL({ modal }));
  };

  static handleChangeKirimModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_CHANGE_KIRIM({ modal }));
  };

  static handleProgressModal = (props) => {
    const { dispatch, modal } = props;
    dispatch(SET_PROGRESS_MODAL({ modal }));
  };

  static handleFilterTanggal = ({ dispatch, tanggal }) => {
    dispatch(SET_TANGGAL_RETUR_FILTER({ tanggal }));
  };

  static handleEditDetailOrderModal = ({ dispatch, modal }) => {
    dispatch(SET_EDIT_DETAIL_ORDER({ modal }));
  };

  static handleEditSizeQtyModal = ({ dispatch, modal }) => {
    dispatch(SET_EDIT_SIZE_QTY({ modal }));
  };

  static handleChangeDesignerModal = ({ dispatch, modal }) => {
    dispatch(SET_CHANGE_DESIGNER_MODAL({ modal }));
  };
}
