import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataForm, DeleteDialog } from "../../../../components/Layout/Content";
import { Api, EventHandlers } from "../repository";

const ModalHapusAntrian = () => {
  const dispatch = useDispatch();
  const { tanggalRekapFilter, hapusAntrian } = useSelector(
    (state) => state.rekapNota
  );

  const handleClose = useCallback(() => {
    EventHandlers.handleHapusAntrian({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch]);

  const { data, isError, isLoading } = Api.detailAntrian({
    modal: hapusAntrian,
  });

  const { mutate, isPending } = Api.deleteAntrian({
    handleClose,
    tanggal: tanggalRekapFilter,
  });

  const onDelete = () => {
    mutate(hapusAntrian.id);
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <DeleteDialog
      handleClose={handleClose}
      onDelete={onDelete}
      title={"Delete Antrian"}
      open={hapusAntrian.open}
      isLoading={isLoading || isPending}
    >
      <DataForm data={data ? data.data : {}} isLoading={isLoading} />
    </DeleteDialog>
  );
};

export default ModalHapusAntrian;
