import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "../config/api";
import { setNotification } from "../features/notificationSlice";

const useCreatingData = (props) => {
  const {
    endpoint,
    onSuccess,
    onError,
    invalidated = [],
    request = undefined,
  } = props;

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body) => {
      try {
        const productsResponse = await axiosInstance.post(
          endpoint,
          body,
          request
        );
        return productsResponse;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: (response) => {
      if (invalidated.length) {
        for (const key of invalidated) {
          queryClient.invalidateQueries({ queryKey: [`${key}`] });
        }
      }

      dispatch({
        type: setNotification,
        payload: {
          snackbarOpen: true,
          snackbarType: response.data.type,
          snackbarMessage: response.data.message,
        },
      });
      onSuccess();
    },
    onError: (error) => {
      dispatch({
        type: setNotification,
        payload: {
          snackbarOpen: true,
          snackbarType: error?.type,
          snackbarMessage: error?.message,
        },
      });
      if (onError) {
        onError(error);
      }
    },
    gcTime: 0,
  });
};

export default useCreatingData;
