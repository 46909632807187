import * as React from "react";
import Layout from "../layout/Layout";
import { AddButton } from "../components/controls";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { EventHandlers, useFormUser } from "../features/users/repository";
import {
  GridView as GridIcon,
  ViewList as ListIcon,
} from "@mui/icons-material";
import SortingLength from "../components/common/SortingLength";
import InputSearch from "../components/common/InputSearch";
import { UsersGridPage, UsersTabelPage } from "../features/users/UsersPage";

const DataUser = () => {
  const dispatch = useDispatch();
  const formUser = useFormUser();
  const { search, toggleView, filterRole, filterStatus, pageSizeGrid } =
    useSelector((state) => state.user);
  const [isLoading, setIsLoading] = React.useState(false);

  const roleItems = [
    { id: "root", name: "Root" },
    { id: "customer_service", name: "Customer Service" },
    { id: "owner", name: "Owner" },
    { id: "admin_produksi", name: "Produksi" },
    { id: "desainer", name: "Desain" },
    { id: "penata", name: "Penata" },
  ];

  return (
    <Layout>
      <Grid
        container
        sx={{ pb: 1.5 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <AddButton
                title="User"
                variant="contained"
                onClick={() =>
                  EventHandlers.handleCreateUpdate({
                    dispatch,
                    modal: { id: null, open: true, title: "Tambah User" },
                    clearErrors: formUser.clearErrors,
                    reset: formUser.reset,
                  })
                }
              />
            </Grid>
            {toggleView === 1 && (
              <>
                <Grid item>
                  <FormControl fullWidth sx={SelectStyle}>
                    <InputLabel id="demo-simple-select-label">
                      Filter Role
                    </InputLabel>
                    <Select
                      value={filterRole}
                      label="Filter Role"
                      onChange={(event) => {
                        EventHandlers.handleRole({
                          dispatch,
                          filterRole: event.target.value,
                        });
                      }}
                      size="small"
                    >
                      <MenuItem value="All">Semua Role</MenuItem>
                      {roleItems.map((role, index) => (
                        <MenuItem value={role.id} key={index}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth sx={SelectStyle}>
                    <InputLabel id="demo-simple-select-label">
                      Filter Status
                    </InputLabel>
                    <Select
                      value={filterStatus}
                      label="Filter Role"
                      onChange={(event) =>
                        EventHandlers.handleStatus({
                          dispatch,
                          filterStatus: event.target.value,
                        })
                      }
                      size="small"
                    >
                      <MenuItem value="All">Semua Status</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Banned</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            justifyContent="end"
            alignItems="center"
            columnSpacing={1}
          >
            {toggleView === 1 ? (
              <>
                <Grid item marginRight={-1}>
                  <SortingLength
                    value={pageSizeGrid}
                    options={[30, 50, 100]}
                    handleChange={(pageSizeGrid) => {
                      EventHandlers.handlePageSizeGrid({
                        dispatch,
                        pageSizeGrid,
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <InputSearch
                    value={search}
                    handleChange={(search) =>
                      EventHandlers.handleSearch({ dispatch, search })
                    }
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    disableRipple
                    onClick={() => {
                      setIsLoading(true);
                      setTimeout(() => {
                        EventHandlers.handleToggleView({ dispatch, view: 2 });
                        setIsLoading(false);
                      }, [500]);
                    }}
                    sx={{
                      backgroundColor: "rgba(25, 118, 210, 0.8)",
                      color: "#fff",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "rgba(25, 118, 210, 1)",
                      },
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size={21} />
                    ) : (
                      <ListIcon />
                    )}
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid item>
                <IconButton
                  disableRipple
                  onClick={() => {
                    setIsLoading(true);
                    setTimeout(() => {
                      EventHandlers.handleToggleView({ dispatch, view: 1 });
                      setIsLoading(false);
                    }, [500]);
                  }}
                  sx={{
                    backgroundColor: "rgba(25, 118, 210, 0.8)",
                    color: "#fff",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "rgba(25, 118, 210, 1)",
                    },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress color="inherit" size={21} />
                  ) : (
                    <GridIcon />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {toggleView === 1 ? <UsersGridPage /> : <UsersTabelPage />}
    </Layout>
  );
};

const SelectStyle = {
  "& .MuiInputBase-root": {
    color: "#1976d2",
    borderRadius: "5px",
    fontSize: "13px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    "& .Mui-disabled": {
      borderRadius: "6px",
      backgroundColor: "#ECECEC",
      fontWeight: 700,
    },
    "& .MuiSvgIcon-root": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(25, 118, 210, 0.5)",
    marginTop: "5px",
  },
  "& .MuiInputLabel-shrink": {
    display: "none",
    color: "#1976d2",
    fontWeight: 500,
    top: "-5px",
  },
  "& legend": {
    display: "none",
  },
  "& .Mui-focused + .MuiOutlinedInput-root": {
    "& .MuiInputLabel-shrink": {
      color: "#1976d2",
    },
  },
  "& .Mui-disabled": {
    color: "#bdbdbd",
  },
  "& .MuiFormLabel-root": {
    color: "#1976d2",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 500,
    backgroundColor: "#fff",
    paddingX: "5px",
    position: "absolute",
    top: 23,
    right: 0,
  },
  "& .MuiSvgIcon-root": {
    color: "#1976d2",
  },
  "&:hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
};

export default DataUser;
