import { Config } from ".";
import { useFetchingData, useDeletingData } from "../../../../hooks";
import dayjs from "dayjs";

export class Api {
  static read = ({ startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    const endpoint = `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`;
    return useFetchingData({
      endpoint,
      gcTime: 0,
    });
  };

  static deletePembayaran = ({ id, handleClose, startDate, endDate }) => {
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
    return useDeletingData({
      endpoint: `antrian/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/${formattedStartDate}|${formattedEndDate}`,
        `${Config.variable.endpoint}/${id}`,
      ],
      onSuccess: () => {
        handleClose();
      },
    });
  };
}
