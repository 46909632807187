import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { EventHandlers } from "../repository";
import UserCard from "../../../components/common/UserCard";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

const GridDataUser = (props) => {
  const { form, data, isLoading } = props;
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState(data);
  const { search, filterRole, filterStatus, pageSizeGrid, pageGrid } =
    useSelector((state) => state.user);

  useEffect(() => {
    const filterData = data.filter((item) => {
      const matchesStatus =
        filterStatus === "All" || item.status === filterStatus;
      const matchesRole = filterRole === "All" || item.role === filterRole;
      const matchesSearch =
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.username.toLowerCase().includes(search.toLowerCase()) ||
        item.usercode.toLowerCase().includes(search.toLowerCase()) ||
        item.role.toLowerCase().includes(search.toLowerCase());
      return matchesStatus && matchesRole && matchesSearch;
    });
    setFilteredData(filterData);
  }, [filterStatus, filterRole, data, search]);

  const offset = (pageGrid - 1) * pageSizeGrid;
  const currentPageData = filteredData.slice(offset, offset + pageSizeGrid);

  const handlePageChange = (pageGrid) => {
    EventHandlers.handlePageGridChange({ dispatch, pageGrid });
  };

  return (
    <Box
      sx={{
        padding: 3,
        boxShadow: "2px 2px 5px #cbcbcb",
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "4px",
        backgroundColor: "white",
      }}
    >
      <Grid container spacing={3}>
        {isLoading &&
          Array.from({ length: 12 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <Skeleton variant="rounded" width={"100%"} height={130} />
            </Grid>
          ))}
        {currentPageData.length > 0 ? (
          currentPageData.map((e, index) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <UserCard
                userID={e.usercode}
                userType={e.role}
                username={e.username}
                nama={e.name}
                status={e.status}
                photo={e.photo}
                color={e.color}
                onEdit={() => {
                  form.setValue("name", e.name);
                  form.setValue("role", e.role);
                  form.setValue("username", e.username);
                  form.setValue("password", e.password);
                  form.setValue("color", e.color);
                  form.setValue("status", e.status === "active" ? true : false);
                  EventHandlers.handleCreateUpdate({
                    dispatch,
                    modal: {
                      id: e.id,
                      photo: e.photo,
                      open: true,
                      title: `Edit User !`,
                    },
                  });
                }}
                onDelete={() => {
                  EventHandlers.handleDelete({
                    dispatch,
                    modal: {
                      id: e.id,
                      usercode: e.usercode,
                      name: e.name,
                      role: e.role,
                      photo: e.photo,
                      open: true,
                      title: `Delete User !`,
                    },
                  });
                }}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} textAlign={"center"}>
            <Stack
              sx={{
                color: "#adadad !important",
                padding: 10,
                alignItems: "center",
              }}
            >
              <NoAccountsIcon sx={{ fontSize: 100 }} />
              <Typography fontWeight={500}>
                Data User Tidak Ditemukan !
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
      {Math.ceil(filteredData.length / pageSizeGrid) > 1 && (
        <Box marginTop={2}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              background:
                "linear-gradient(90deg, rgba(238,174,202,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)",
              flexGrow: 1,
              justifyContent: "center",
              padding: 1,
              boxShadow: "0px 0px 40px -27px #f1f6f8 inset",
            }}
          >
            <Pagination
              count={Math.ceil(filteredData.length / pageSizeGrid)}
              page={pageGrid}
              onChange={(_, value) => handlePageChange(value)}
              shape="rounded"
              // variant="outlined"
              color="primary"
              size="small"
              sx={{
                "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#3D85AE",
                },
              }}
            />
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default GridDataUser;
