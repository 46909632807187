import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const SwitchButton = styled(Switch)(() => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "#fff",
    opacity: 0.8,
    transition: "background-color 500ms ease-in-out, border 200ms ease-in-out",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .Mui-checked": {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#fff",
      opacity: 1,
    },
  },
  "& .MuiSwitch-switchBase": {
    transition: "transform 300ms ease-in-out",
  },
}));

export default SwitchButton;
