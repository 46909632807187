import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detailAntrianGroup: { id: null, open: false, title: null },
  cardTotalAntrian: { id: null, open: false, title: null },
};

const PencapaianGroup = createSlice({
  name: "pencapaianGroup",
  initialState,
  reducers: {
    SET_DETAIL_ANTRIAN_GROUP: (state, action) => {
      state.detailAntrianGroup = action.payload.modal;
    },
    SET_CARD_TOTAL_ANTRIAN: (state, action) => {
      state.cardTotalAntrian = action.payload.modal;
    },
  },
});

export const { SET_DETAIL_ANTRIAN_GROUP, SET_CARD_TOTAL_ANTRIAN } =
  PencapaianGroup.actions;

export default PencapaianGroup.reducer;
