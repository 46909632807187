import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import handleFocus from "./handleFocus";

const NumberInput = (props) => {
  const {
    name,
    control,
    label,
    rules,
    defaultValue,
    variant = "outlined",
    size = "small",
    prefix = true,
    ...rest
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <NumericFormat
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              color: "#797979",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px 0px #00000040 inset",
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              "& .Mui-disabled": {
                borderRadius: "6px",
                backgroundColor: "#ECECEC",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              marginTop: "5px",
            },
            "& .MuiInputLabel-shrink": {
              fontWeight: 500,
              top: "-5px",
            },
            "& legend": {
              display: "none",
            },
            "& .MuiFormHelperText-root": {
              fontWeight: 500,
              backgroundColor: "#fff",
              paddingX: "5px",
              position: "absolute",
              top: 23,
              right: 0,
            },
          }}
          fullWidth
          thousandSeparator="."
          decimalSeparator=","
          onValueChange={(value) => onChange(value.floatValue ?? 0)}
          allowNegative={false}
          onFocus={handleFocus}
          name={name}
          value={value}
          prefix={prefix ? "Rp. " : undefined}
          variant={variant}
          customInput={TextField}
          size={size}
          // focused
          label={`${label} ${rules["required"] ? "*" : ""}`}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          {...rest}
        />
      )}
    />
  );
};

export default NumberInput;
