import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailProgressLayout } from "../../../components/Layout/Content";
import { Api, EventHandlers } from "../repository";

const DetailProgress = () => {
  const dispatch = useDispatch();
  const { detailProgressModal } = useSelector((state) => state.retur);
  const { data, isLoading, isError } = Api.detailProgress({
    modal: detailProgressModal,
  });

  const handleClose = useCallback(() => {
    EventHandlers.handleDetailProgressModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <DetailProgressLayout
      open={detailProgressModal.open}
      handleClose={handleClose}
      title={detailProgressModal.title}
      isLoading={isLoading}
      data={data ? data.data : {}}
    />
  );
};

export default DetailProgress;
