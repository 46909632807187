import React from "react";
import { useSelector } from "react-redux";
import { useGetCachedData } from "../../../hooks";
import {
  TabelRekapNota,
  DetailPembayaran,
  DetailProgress,
  ModalHapusAntrian,
  PrintAntrian,
  DetailAntrian,
  ModalUpload,
  ModalChangePerson,
  ModalEditReject,
} from "./components";
import { Api } from "./repository";
import ModalEditDetailOrder from "./components/ModalEditDetailOrder";
import ModalEditSizeQty from "./components/ModalEditSizeQty";

const RekapNotaPage = () => {
  const { tanggalRekapFilter } = useSelector((state) => state.rekapNota);

  const getDataAntrian = Api.read({ tanggal: tanggalRekapFilter });

  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });
  const getDataEkspedisi = useGetCachedData({ queryKey: "ekspedisi" });
  const getDataSize = useGetCachedData({ queryKey: "size" });
  const getDataAdditional = useGetCachedData({ queryKey: "additional" });
  const getDataKeterangan = useGetCachedData({ queryKey: "keterangan" });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelRekapNota
        data={getDataAntrian.data ? getDataAntrian.data.data : []}
        isLoading={getDataAntrian.isLoading}
        sourceOrder={getDataSourceOrder ? getDataSourceOrder.data : []}
        jenisOrder={getDataJenisOrder ? getDataJenisOrder.data : []}
      />

      {/* POPUPS */}
      <ModalChangePerson />

      {/* <ModalProgress /> */}
      <ModalEditReject />

      <ModalUpload />

      <ModalHapusAntrian />

      <DetailAntrian />

      <DetailProgress />

      <DetailPembayaran />

      <PrintAntrian />

      {/* editAntrian */}
      <ModalEditDetailOrder
        {...{
          sourceOrder: getDataSourceOrder ? getDataSourceOrder.data : [],
          jenisOrder: getDataJenisOrder ? getDataJenisOrder.data : [],
          ekspedisi: getDataEkspedisi ? getDataEkspedisi.data : [],
        }}
      />
      <ModalEditSizeQty
        {...{
          size: getDataSize ? getDataSize.data : [],
          additional: getDataAdditional ? getDataAdditional.data : [],
          keterangan: getDataKeterangan ? getDataKeterangan.data : [],
        }}
      />
    </>
  );
};

export default RekapNotaPage;
