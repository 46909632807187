import React from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const InputSearch = ({ fullwidth, handleChange, value }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        border: fullwidth ? "0.5px solid rgba(180, 180, 180, 0.5)" : "",
        borderRadius: fullwidth ? "5px" : "5px 5px 0 0",
        boxShadow: fullwidth
          ? "0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset"
          : "inset 0 -1px 0 #797979",
        width: fullwidth && "100%",
      }}
    >
      <IconButton type="button" sx={{ p: "8px" }} aria-label="Search">
        <SearchIcon />
      </IconButton>
      <InputBase
        value={value}
        fullWidth
        sx={{ ml: 0, flex: 1 }}
        placeholder="Search..."
        inputProps={{ "aria-label": "Search" }}
        onChange={(event) => handleChange(event.target.value)}
      />
    </Paper>
  );
};

export default InputSearch;
