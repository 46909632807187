import { RESET_USER, SET_USER } from "../../authSlice";
import { UPDATE_SETTING } from "../domain/dataSlice";

export class EventHandlers {
  static handleSuccessLogin = ({ dispatch, data }) => {
    dispatch({
      type: SET_USER,
      payload: {
        ...data,
      },
    });
  };

  static handleErrorLogin = ({ navigate, dispatch }) => {
    navigate("/login", { replace: true });
    dispatch(RESET_USER);
    localStorage.removeItem("token");
  };

  static handlePengaturan = ({ dispatch, data }) => {
    dispatch(UPDATE_SETTING(data));
  };
}
