import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/Layout/Loading";
import { Api } from "../repository/Api";
import { EventHandlers } from "../repository";

const LoadingBasicData = () => {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isLoading: isLoadingGetMe,
    data: dataGetMe,
    isError: isErrorGetMe,
  } = Api.me({ token });
  const { isLoading: isLoadingSetting, data: dataSetting } = Api.setting();
  const { isLoading: isLoadingUser } = Api.user();
  const { isLoading: isLoadingStatus } = Api.status();
  const { isLoading: isLoadingJenisOrder } = Api.jenisOrder({
    isActive: true,
  });
  const { isLoading: isLoadingSourceOrder } = Api.sourceOrder({
    isActive: true,
  });
  const { isLoading: isLoadingSize } = Api.size();
  const { isLoading: isLoadingAdditional } = Api.additional();
  const { isLoading: isLoadingEkspedisi } = Api.ekspedisi();
  const { isLoading: isLoadingKeterangan } = Api.keterangan();
  const { isLoading: isLoadingMetodePembayaran } = Api.metodePembayaran();

  useEffect(() => {
    if (dataGetMe) {
      EventHandlers.handleSuccessLogin({ dispatch, data: dataGetMe.data });
    }
  }, [dataGetMe, dispatch]);

  useEffect(() => {
    if (isErrorGetMe) {
      EventHandlers.handleErrorLogin({ navigate, dispatch });
    }
  }, [dispatch, isErrorGetMe, navigate]);

  useEffect(() => {
    if (dataSetting) {
      EventHandlers.handlePengaturan({ dispatch, data: dataSetting.data });
    }
  }, [dataSetting, dispatch]);

  return (
    <Loading
      isLoading={
        isLoadingGetMe ||
        isLoadingUser ||
        isLoadingSetting ||
        isLoadingStatus ||
        isLoadingJenisOrder ||
        isLoadingSourceOrder ||
        isLoadingSize ||
        isLoadingAdditional ||
        isLoadingEkspedisi ||
        isLoadingKeterangan ||
        isLoadingMetodePembayaran
      }
    />
  );
};

export default LoadingBasicData;
