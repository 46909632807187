import React from "react";
import { Grid } from "@mui/material";

const ActionGrid = ({
  isLoading,
  actionStart,
  actionEnd,
  spacing = 2,
  ...props
}) => {
  return (
    <Grid container spacing={spacing} justifyContent={"end"} {...props}>
      <Grid
        item
        xs={6}
        sx={{
          transition: "all 0.5s ease",
          display: isLoading ? "none" : "",
          flexBasis: isLoading ? "0%" : "50%",
          minWidth: isLoading ? "0%" : "50%",
        }}
      >
        {actionStart}
      </Grid>
      <Grid
        item
        xs={isLoading ? 12 : 6}
        sx={{
          transition: isLoading && "flex-basis 0.5s ease, min-width 0.5s ease",
          flexBasis: isLoading ? "100%" : "50%",
          minWidth: isLoading ? "100%" : "50%",
        }}
      >
        {actionEnd}
      </Grid>
    </Grid>
  );
};

export default ActionGrid;
