import React from "react";
import { Grid } from "@mui/material";

const FormAdditional = (props) => {
  const { form, Input, handleFocus, SwitchInput, additionalData, isLoading } =
    props;
  const additional = Array.from(new Set(additionalData));

  const midPoint = Math.ceil(additional.length / 2);

  return (
    <Grid container spacing={2} marginBottom={3} sx={{ color: "#797979" }}>
      {/* input 1-5 */}
      <Grid container item xs={6} spacing={2}>
        {additional.slice(0, midPoint).map((data, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              {...form.register(`additional.${index}.id`, {
                valueAsNumber: true,
              })}
              value={data.id}
            />
            <Grid item xs={6} textAlign="right">
              <SwitchInput
                name={`additional.${index}.status`}
                control={form.control}
                label={`${index + 1}`}
                defaultValue={data ? data.status : false}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                rules={{
                  required: form.watch(`additional.${index}.status`)
                    ? true
                    : false,
                }}
                name={`additional.${index}.name`}
                control={form.control}
                label={`Additional ${index + 1}`}
                onFocus={handleFocus}
                disabled={
                  !form.watch(`additional.${index}.status`, data.status) ||
                  isLoading
                }
                defaultValue={data.name}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {/* input 6-10 */}
      <Grid container item xs={6} spacing={2}>
        {additional.slice(midPoint).map((data, index) => {
          const adjustedIndex = index + midPoint;
          return (
            <React.Fragment key={adjustedIndex}>
              <input
                type="hidden"
                {...form.register(`additional.${adjustedIndex}.id`, {
                  valueAsNumber: true,
                })}
                value={data.id}
              />
              <Grid item xs={6} textAlign="right">
                <SwitchInput
                  name={`additional.${adjustedIndex}.status`}
                  control={form.control}
                  label={`${adjustedIndex + 1}`}
                  defaultValue={data ? data.status : false}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rules={{
                    required: form.watch(`additional.${adjustedIndex}.status`)
                      ? true
                      : false,
                  }}
                  name={`additional.${adjustedIndex}.name`}
                  control={form.control}
                  label={`Additional ${adjustedIndex + 1}`}
                  onFocus={handleFocus}
                  disabled={
                    !form.watch(
                      `additional.${adjustedIndex}.status`,
                      data.status
                    ) || isLoading
                  }
                  defaultValue={data.name}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FormAdditional;
