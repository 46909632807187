import React from "react";
import { Collapse, Chip, Grid, Divider, Typography } from "@mui/material";
import { NumericText } from "../../../../common";

const CollapseDetailOrderPembayaran = ({
  in: open,
  dataAntrianSize,
  keteranganNota,
  reject,
  totalPembelian,
}) => {
  const totalQty = dataAntrianSize?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.qty;
  }, 0);
  return (
    <Collapse in={open}>
      <Grid container mt={1.5} sx={{ backgroundColor: "#F3F7F8" }}>
        <Grid item xs={12} padding={1} fontSize={10}>
          <Grid container fontWeight={700}>
            <Grid item xs={3}>
              Size
            </Grid>
            <Grid item xs={2} textAlign={"center"}>
              Qty
            </Grid>
            <Grid item xs={3} textAlign={"end"}>
              Harga
            </Grid>
            <Grid item xs={4} textAlign={"end"}>
              Sub Total
            </Grid>
          </Grid>
          <Divider sx={{ margin: "0.5rem -0.5rem", borderColor: "#797979" }} />
          {dataAntrianSize.map((size, indexSize) => (
            <Grid item key={indexSize}>
              <Grid container alignItems="start">
                <Grid item xs={3} sx={{ marginBottom: "3px" }}>
                  <Chip
                    label={size.nama}
                    size="small"
                    sx={{
                      padding: "1px 0 !important",
                      borderRadius: "3px",
                      color: "white",
                      fontWeight: 400,
                      margin: "0 0.3rem 0 0",
                      backgroundColor: "#105174",
                      fontSize: "9px",
                      height: "100%",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  textAlign={"center"}
                  fontSize={11}
                  fontWeight={700}
                >
                  <NumericText prefix={false} value={size.qty} />
                </Grid>
                <Grid
                  item
                  xs={3}
                  textAlign={"end"}
                  fontSize={11}
                  fontWeight={700}
                >
                  <NumericText prefix={false} value={size.harga} />
                </Grid>
                <Grid
                  item
                  xs={4}
                  textAlign={"end"}
                  fontSize={11}
                  fontWeight={700}
                >
                  <NumericText prefix={false} value={size.qty * size.harga} />
                </Grid>
              </Grid>
              {size.additional.map((additional, indexAdditional) => (
                <Grid container alignItems="start" key={indexAdditional}>
                  <Grid item xs={3} sx={{ marginBottom: "3px" }}>
                    <Chip
                      label={additional.nama}
                      size="small"
                      sx={{
                        borderRadius: "3px",
                        color: "white",
                        fontWeight: 400,
                        margin: "0 0.3rem 0 1rem",
                        backgroundColor: "#A4A4A4",
                        fontSize: "9px",
                        height: "100%",

                        "& .MuiChip-label": {
                          padding: "0 5px",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} textAlign={"center"}>
                    <NumericText prefix={false} value={additional.qty} />
                  </Grid>
                  <Grid item xs={3} textAlign={"end"}>
                    <NumericText prefix={false} value={additional.harga} />
                  </Grid>
                  <Grid item xs={4} textAlign={"end"}>
                    <NumericText
                      prefix={false}
                      value={additional.qty * additional.harga}
                    />
                  </Grid>
                </Grid>
              ))}
              <Divider sx={{ margin: "0.5rem 0" }} />
            </Grid>
          ))}
          <Grid container fontWeight={700} fontSize={12}>
            <Grid item xs={3}>
              Total
            </Grid>
            <Grid item xs={2} textAlign={"center"}>
              <NumericText prefix={false} value={totalQty} /> Pcs
            </Grid>
            <Grid item xs={3} textAlign={"end"}></Grid>
            <Grid item xs={4} textAlign={"end"}>
              <NumericText prefix={true} value={totalPembelian} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {keteranganNota && (
        <Grid item xs={12} mt={2.5}>
          <Typography
            fontSize={10}
            paddingX={2}
            paddingY={1}
            backgroundColor="#FFEBE9"
            borderRadius="5px"
          >
            <b>Keterangan Order</b> : <br />
            {keteranganNota}
          </Typography>
        </Grid>
      )}

      {reject?.nominal > 0 && (
        <Grid
          container
          item
          mt={1.5}
          fontSize={10}
          justifyContent={"space-between"}
          paddingX={2}
          paddingY={1}
          backgroundColor="#F1503A"
          color={"#fff"}
          borderRadius="5px"
        >
          <Grid item>Reject : {reject.nominal}</Grid>
          <Grid item>{reject.keterangan}</Grid>
        </Grid>
      )}
    </Collapse>
  );
};

export default CollapseDetailOrderPembayaran;
