import React from 'react';
import { Api } from './repository';
import { useSelector } from 'react-redux';
import { TabelPendapatanBulanan } from './components';
import { PrintPendapatanBulanan } from './components/PrintPendapatanBulanan';

const PendapatanBulananPage = ({ componentRefPB }) => {
  const { bulan, tahun } = useSelector((state) => state.pendapatanBulanan);
  const dataBulanan = Api.pendapatanBulanan({
    bulan: bulan,
    tahun: tahun,
  });

  return (
    <>
      <TabelPendapatanBulanan
        data={dataBulanan.data ? dataBulanan.data.data : []}
        isLoading={dataBulanan.isLoading}
      />
      <div style={{ display: 'none' }}>
        <PrintPendapatanBulanan
          ref={componentRefPB}
          Data={dataBulanan.data ? dataBulanan.data.data : []}
          bulan={bulan}
          tahun={tahun}
        />
      </div>
    </>
  );
};

export default PendapatanBulananPage;
