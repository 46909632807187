import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  CheckboxInput,
  handleFocus,
  AutocompleteDynamic,
} from "../../../components/controls";
import { Grid, Skeleton, Divider, Stack } from "@mui/material";
import { Api, EventHandlers } from "../repository";
import { ActionGrid } from "../../../components/common";
import { BlankDialog, DataForm } from "../../../components/Layout/Content";
import CustomButton from "../../../components/controls/CustomButton";
import { ContentPasteSearch as DataSearch } from "@mui/icons-material";
import { useFormRetur } from "../repository";
import { useDebounce } from "use-debounce";

const ModalCreateUpdateRetur = () => {
  const dispatch = useDispatch();
  const form = useFormRetur();
  const { createUpdateModal, tanggalReturFilter } = useSelector(
    (state) => state.retur
  );

  const [search, setSearch] = useState("");

  const [debounceSearch] = useDebounce(search, 1000);

  const getAntrianSelesai = Api.searchAntrianSelesai({
    search: debounceSearch,
  });

  const handleClose = () => {
    setSearch("");
    form.setValue("nota", null);
    form.setValue("confirmation", false);
    form.setValue("keterangan", "");
    EventHandlers.handleCreateUpdateModal({
      dispatch,
      modal: { id: null, open: false, title: null },
      clearErrors: form.clearErrors,
      reset: form.reset,
    });
  };

  const { mutate, isPending } = Api.returAntrian({
    onSuccess: () => {
      handleClose();
    },
    startDate: tanggalReturFilter[0],
    endDate: tanggalReturFilter[1],
  });

  const onSubmit = (values) => {
    const id = values.nota.id;
    const data = {
      keterangan: values.keterangan,
    };
    mutate({
      body: data,
      id,
    });
  };

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={createUpdateModal.open}
      handleClose={handleClose}
      title={
        <span style={{ fontSize: "14px", color: "red" }}>
          {createUpdateModal.title}
        </span>
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container marginBottom={2}>
          <AutocompleteDynamic
            rules={{ required: "Nota Harus di isi!" }}
            name={"nota"}
            control={form.control}
            label={"Nota"}
            defaultValue={null}
            options={
              getAntrianSelesai.data
                ? getAntrianSelesai.data.data.map((e) => {
                    return {
                      id: e.id,
                      label: `${e.nota} | ${e.nama}`,
                      render: `${e.nota} | ${e.nama}`,
                      value: {
                        antrian: {
                          nota: e.nota,
                          jenis_order: {
                            nama: e.jenis_order,
                          },
                          source_order: {
                            nama: e.source_order,
                          },
                        },
                        detail: {
                          nama: e.nama,
                          no_hp: e.no_hp,
                          wilayah: e.wilayah,
                          alamat: e.alamat,
                        },
                      },
                    };
                  })
                : []
            }
            isLoading={getAntrianSelesai.isLoading}
            inputValue={search}
            onInputChange={(event, newInputValue) => {
              if (event?.type === "change") {
                setSearch(newInputValue);
              }
            }}
          />
        </Grid>
        {!form.watch("nota") ? (
          <Grid container>
            <Grid item xs={12} position={"relative"}>
              <Skeleton variant="rounded" width={"100%"} height={120} />
              <DataSearch
                sx={{
                  color: "rgba(201, 201, 201, 0.69)",
                  fontSize: "70px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Stack spacing={2}>
              <DataForm isLoading={false} data={form.watch("nota.value")} />
              <Input
                rules={{ required: "Keterangan Harus di isi!" }}
                name={"keterangan"}
                control={form.control}
                label={"Keterangan Retur"}
                defaultValue={""}
                multiline
                onFocus={handleFocus}
              />
            </Stack>
            <Grid
              container
              justifyContent={"end"}
              alignItems={"center"}
              marginTop={1}
              marginBottom={"-1rem"}
            >
              <CheckboxInput
                name={"confirmation"}
                control={form.control}
                label={"Pastikan data sudah benar!"}
                rules={{ required: true }}
                defaultValue={false}
              />
            </Grid>
            <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
            <ActionGrid
              isLoading={isPending}
              actionStart={
                <CustomButton onClick={handleClose} warna={"#B0B0B0"} fullWidth>
                  Batal
                </CustomButton>
              }
              actionEnd={
                <CustomButton
                  loading={isPending}
                  warna={"clear"}
                  fullWidth
                  disabled={form.watch("confirmation") ? false : true}
                  type="submit"
                >
                  Simpan
                </CustomButton>
              }
            />
          </>
        )}
      </form>
    </BlankDialog>
  );
};

export default ModalCreateUpdateRetur;
