import React from "react";
import { Grid } from "@mui/material";

const FormPilihUkuran = (props) => {
  const { form, Input, handleFocus, SwitchInput, sizeData, isLoading } = props;
  const size = Array.from(new Set(sizeData));

  const midPoint = Math.ceil(size.length / 2);

  return (
    <Grid container spacing={2} marginBottom={3} sx={{ color: "#797979" }}>
      {/* input 1-5 */}
      <Grid container item xs={6} spacing={2}>
        {size.slice(0, midPoint).map((data, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              {...form.register(`size.${index}.id`, {
                valueAsNumber: true,
              })}
              value={data.id}
            />
            <Grid item xs={6} textAlign="right">
              <SwitchInput
                name={`size.${index}.status`}
                control={form.control}
                label={`${index + 1}`}
                defaultValue={data ? data.status : false}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                rules={{
                  required: form.watch(`size.${index}.status`) ? true : false,
                }}
                name={`size.${index}.name`}
                control={form.control}
                label={`Name Size ${index + 1}`}
                onFocus={handleFocus}
                disabled={
                  !form.watch(`size.${index}.status`, data.status) || isLoading
                }
                defaultValue={data.name}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {/* input 6-10 */}
      <Grid container item xs={6} spacing={2}>
        {size.slice(midPoint).map((data, index) => {
          const adjustedIndex = index + midPoint;
          return (
            <React.Fragment key={adjustedIndex}>
              <input
                type="hidden"
                {...form.register(`size.${adjustedIndex}.id`, {
                  valueAsNumber: true,
                })}
                value={data.id}
              />
              <Grid item xs={6} textAlign="right">
                <SwitchInput
                  name={`size.${adjustedIndex}.status`}
                  control={form.control}
                  label={`${adjustedIndex + 1}`}
                  defaultValue={data ? data.status : false}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rules={{
                    required: form.watch(`size.${adjustedIndex}.status`)
                      ? true
                      : false,
                  }}
                  name={`size.${adjustedIndex}.name`}
                  control={form.control}
                  label={`Name Size ${adjustedIndex + 1}`}
                  onFocus={handleFocus}
                  disabled={
                    !form.watch(
                      `size.${adjustedIndex}.status`,
                      data ? data.status : false
                    ) || isLoading
                  }
                  defaultValue={data.name}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FormPilihUkuran;
