import React from "react";
import { Api } from "./repository";
import { useSelector } from "react-redux";
import { TabelCashflowBulanan } from "./components";
import { PrintCashflowBulanan } from "./components/PrintCashflowBulanan";

const BulananPage = (props) => {
  const { componentRefPB } = props;
  const { bulan, tahun } = useSelector((state) => state.cashflowBulanan);
  const getCashflowBulanan = Api.pendapatanBulanan({
    bulan: bulan,
    tahun: tahun,
  });

  return (
    <>
      <TabelCashflowBulanan
        data={getCashflowBulanan.data ? getCashflowBulanan.data.data : []}
        isLoading={getCashflowBulanan.isLoading}
      />
      <div style={{ display: "none" }}>
        <PrintCashflowBulanan
          ref={componentRefPB}
          data={getCashflowBulanan.data ? getCashflowBulanan.data.data : []}
          bulan={bulan}
          tahun={tahun}
        />
      </div>
    </>
  );
};

export default BulananPage;
