import * as React from "react";
import { Box, CssBaseline, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InsightsIcon from "@mui/icons-material/Insights";

export const NotFound = () => {
  return (
    <>
      <Typography component={"h1"}>Go Back! Not Found Page.</Typography>
    </>
  );
};

export const Restricted = () => {
  return (
    <>
      <Typography component={"h1"}>
        You are not Allowed Here. Please go back.
      </Typography>
    </>
  );
};

export const ComingSoon = () => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <InsightsIcon sx={{ fontSize: "200px", color: "grey !important" }} />
      </div>
      <div style={{ textAlign: "center" }}>
        <p>Coming Soon</p>
      </div>
    </>
  );
};

export const Stole = () => {
  const { uuid } = useSelector((state) => state.data);
  const { serial_number } = useSelector((state) => state.data);
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = `Application Error`;
    if (uuid !== "NOT") {
      navigate("/login", { replace: true });
    }
  }, [uuid, navigate]);
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={1}>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 10,
            }}
          >
            <img
              src="denied.svg"
              alt="Logo Bintang Sablon"
              width={350}
              height={350}
            />
          </Box>
          <div style={{ textAlign: "center" }}>
            Aplikasi belum terdaftar di perangkat anda!
            <br /> Silahkan Hubungi Administrator
          </div>

          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ mt: 10 }}
          >
            {`Registered ID : ${
              serial_number ? serial_number : "INVALID SERIAL NUMBER!"
            }`}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              color: "gray",
              textDecoration: "none",
              fontSize: 10,
              textAlign: "center",
            }}
          >
            Copyright © APPAREL MANAGEMENT V.1.2.0 - All Right Reserved 2022 -{" "}
            {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
