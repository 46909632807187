import { createSlice } from "@reduxjs/toolkit";
const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const initialState = {
  collapse: false,
  bulan: currentMonth,
  tahun: currentYear,
};

const DashboardGroupPencapaian = createSlice({
  name: "dashboardGroupPencapaian",
  initialState,
  reducers: {
    SET_COLLAPSE: (state, action) => {
      state.collapse = action.payload.collapse;
    },
    SET_BULAN_PENCAPAIAN: (state, action) => {
      state.bulan = action.payload;
    },
    SET_TAHUN_PENCAPAIAN: (state, action) => {
      state.tahun = action.payload;
    },
  },
});

export const { SET_COLLAPSE, SET_BULAN_PENCAPAIAN, SET_TAHUN_PENCAPAIAN } =
  DashboardGroupPencapaian.actions;

export default DashboardGroupPencapaian.reducer;
