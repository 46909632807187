import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import config from "../../config";

const UserCard = ({
  userID,
  color,
  userType,
  status,
  username,
  nama,
  onEdit,
  onDelete,
  photo,
}) => {
  let chipColor = "rgba(0, 0, 0, 0.08)";

  if (status === "active") {
    chipColor = "rgba(57, 194, 54, 1)";
  } else {
    chipColor = "rgba(255, 64, 64, 1)";
  }

  return (
    <Card
      elevation={2}
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
        borderBottomWidth: "3px",
        borderBottomStyle: "solid",
        borderBottomColor: color,
      }}
    >
      <CardHeader
        style={{
          padding: "0.5rem 1rem",
          backgroundColor: color,
          display: "flex",
          alignItems: "center",
        }}
        title={
          <div style={{ display: "flex" }}>
            <Typography color="#fff" marginRight={0.5} fontSize={12}>
              {userID}
            </Typography>
            <Typography
              color="#fff"
              fontSize={12}
              fontWeight={700}
              textTransform={"capitalize"}
            >
              {userType === "admin_produksi"
                ? "Produksi"
                : userType
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
            </Typography>
          </div>
        }
        action={
          userType !== "root" && (
            <div style={{ display: "flex" }}>
              <IconButton
                aria-label="edit"
                size="small"
                sx={{ padding: "3px" }}
                onClick={onEdit}
              >
                <EditIcon
                  style={{
                    fontSize: "18px",
                    padding: "3px",
                    color: color,
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>

              <IconButton
                aria-label="delete"
                size="small"
                sx={{ padding: "3px" }}
                onClick={onDelete}
              >
                <DeleteIcon
                  style={{
                    fontSize: "18px",
                    padding: "3px",
                    color: color,
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </div>
          )
        }
      />
      <CardContent style={{ p: 2.25, fontSize: "12px" }}>
        <Stack direction={"row"} spacing={1}>
          <Box>
            <Avatar
              variant="square"
              src={
                photo === null
                  ? "/broken-image.jpg"
                  : config.apiUrl + "/storage/user/" + photo
              }
              sx={{
                width: "55px",
                height: "55px",
                boxShadow: "0px 2px 4px 0px #00000040 inset",
                borderRadius: "5px",
              }}
            />
          </Box>
          <Box>
            <Grid container>
              {[
                { label: "Username", value: username },
                { label: "Name", value: nama, fontWeight: 700 },
                {
                  label: "Status",
                  value: (
                    <Chip
                      label={status === "active" ? "Active" : "Banned"}
                      sx={{
                        height: "15px",
                        fontWeight: 700,
                        color: "#fff",
                        backgroundColor: chipColor,
                        borderRadius: "3px",
                      }}
                    />
                  ),
                },
              ].map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={5} md={5.5} lg={4} xl={3.5}>
                    {item.label}
                  </Grid>
                  <Grid item xs={0.5}>
                    :
                  </Grid>
                  <Grid
                    item
                    xs={6.5}
                    md={6}
                    lg={7.5}
                    xl={8}
                    fontWeight={item.fontWeight}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.value}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default UserCard;
