import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/pendapatanBulananSlice";
import { Config } from "../repository";

const getOwnerStyles = (role) => {
  if (role !== "owner") {
    return {};
  }

  return {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: 12,
      fontWeight: 700,
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      fontSize: 12,
    },

    "& .MuiDataGrid-columnHeader": {
      height: "25px !important",
    },
    "& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeader--filledGroup":
      {
        height: "25px !important",
      },
    "& .MuiDataGrid-columnHeader[data-field='brutto_jual'], & .MuiDataGrid-columnHeader[data-field='brutto_hpp'], & .MuiDataGrid-columnHeader[data-field='retur_jual'], & .MuiDataGrid-columnHeader[data-field='retur_hpp'], & .MuiDataGrid-columnHeader[data-field='netto_jual'], & .MuiDataGrid-columnHeader[data-field='netto_hpp']":
      {
        height: "19px !important",
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: 10,
          fontWeight: 500,
        },
      },
    "& .MuiDataGrid-columnHeader[data-field='id'], & .MuiDataGrid-columnHeader[data-field='nota'], & .MuiDataGrid-columnHeader[data-field='jumlah_order'], & .MuiDataGrid-columnHeader[data-field='gross_profit'], & .MuiDataGrid-columnHeader[data-field='reject'], & .MuiDataGrid-columnHeader[data-field='profit'], & .MuiDataGrid-columnHeader[data-field='profit_percent']":
      {
        height: "45px !important",
        marginTop: -3,
      },
    "& .MuiDataGrid-columnHeader[data-field='jumlah_order']": {
      "& .MuiDataGrid-columnSeparator": {
        marginTop: "22px",
      },
    },
  };
};

const TabelPendapatanBulanan = ({ data, isLoading }) => {
  const {
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.pendapatanBulanan);
  const { role } = useSelector((state) => state.auth);
  const ownerStyles = getOwnerStyles(role);

  const columns = Config.column.map((e) => {
    return e;
  });

  const columnsCS = Config.columnCS.map((e) => {
    return e;
  });

  const columnGroup = [
    {
      groupId: "Bruto",
      headerName: "Bruto",
      headerAlign: "center",
      description: "",
      children: [{ field: "brutto_jual" }, { field: "brutto_hpp" }],
    },
    {
      groupId: "Retur",
      headerName: "Retur",
      headerAlign: "center",
      description: "",
      children: [{ field: "retur_jual" }, { field: "retur_hpp" }],
    },
    {
      groupId: "Netto",
      headerName: "Netto",
      headerAlign: "center",
      description: "",
      children: [{ field: "netto_jual" }, { field: "netto_hpp" }],
    },
  ];

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns: role === "owner" ? columns : columnsCS,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel: !isLoading ? aggregationModel : {},
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      setDensity={"compact"}
      disableColumnMenu={true}
      hideFooter={true}
      columnGroup={role === "owner" ? columnGroup : []}
      sx={{
        ...ownerStyles,
        "& .MuiDataGrid-aggregationColumnHeaderLabel": {
          display: "none",
        },
      }}
    />
  );
};

TabelPendapatanBulanan.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
  statusList: PropTypes.array,
};

export default TabelPendapatanBulanan;
