import { Config } from ".";
import { useFetchingData } from "../../../../hooks";

export class Api {
  static sourceOrderHarian = ({ bulan, tahun }) => {
    const endpoint = `${Config.variable.endpoint}/harian?bulan=${bulan}&tahun=${tahun}`;
    return useFetchingData({
      endpoint,
    });
  };
  static sourceOrderBulanan = ({ tahun }) => {
    const endpoint = `${Config.variable.endpoint}/bulanan?tahun=${tahun}`;
    return useFetchingData({
      endpoint,
    });
  };
  static sourceOrderTahunan = ({ tahun1, tahun2 }) => {
    const endpoint = `${Config.variable.endpoint}/tahunan?tahun1=${tahun1}&tahun2=${tahun2}`;
    return useFetchingData({
      endpoint,
    });
  };
}
