import { Chip } from "@mui/material";
import React from "react";

const ChipItems = ({
  icon,
  size = "small",
  label = "ChipItems",
  backgroundcolor = "#A4A4A4",
  color = "white",
  fontweight = 700,
  padding,
  borderradius,
  iconmarginleft,
  sx,
  ...rest
}) => {
  return (
    <Chip
      variant="filled"
      icon={icon}
      size={size}
      label={label}
      sx={{
        backgroundColor: backgroundcolor,
        color: color,
        fontWeight: fontweight,
        padding: padding,
        borderRadius: borderradius,
        "& .MuiSvgIcon-root": {
          marginLeft: iconmarginleft,
          color: color,
        },
        ...sx,
      }}
      {...rest}
    />
  );
};

export default ChipItems;
