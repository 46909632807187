import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setNotification } from "../features/notificationSlice";
import { axiosInstance } from "../config/api";

const useAuth = (props) => {
  const { onSuccess } = props;
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async (body) => {
      try {
        const productsResponse = await axiosInstance.post("login", {
          ...body,
        });
        return productsResponse;
      } catch (error) {
        throw error;
      }
    },
    onSuccess: (response) => {
      dispatch({
        type: setNotification,
        payload: {
          snackbarOpen: true,
          snackbarType: response.data.type,
          snackbarMessage: response.data.message,
        },
      });
      onSuccess(response);
    },
    onError: (error) => {
      dispatch({
        type: setNotification,
        payload: {
          snackbarOpen: true,
          snackbarType: error?.type,
          snackbarMessage: error?.message,
        },
      });
    },
  });
};

export default useAuth;
