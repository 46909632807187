import React, { useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import QRCode from "react-qr-code";
import { dateFormatter } from "../../../../controls";

const detailItems = [
  { label: "No. Nota", property: "nota" },
  { label: "Nama", property: "nama" },
  { label: "Jenis Order", property: "jenisorder" },
  { label: "Check In", property: "checkin" },
  { label: "Check Out", property: "checkout" },
];

const NotaProgress = (props) => {
  const { data, isLoading } = props;
  const [dataDetail, setDataDetail] = useState({
    nota: "-",
    nama: "-",
    jenisorder: "-",
    checkin: "-",
    checkout: "-",
  });

  useEffect(() => {
    if (data) {
      if (data.detail) {
        setDataDetail({
          nota: data.detail.nota,
          nama: data.detail.nama,
          jenisorder: data.detail.jenis_order,
          checkin: data.detail.check_in.tanggal,
          checkout: data.detail.check_out.tanggal,
        });
      }
    }
  }, [data]);

  return (
    <>
      <Grid item md={10}>
        {detailItems.map((item, index) => (
          <Grid
            key={index}
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              {item.property === "nota" ? (
                <span style={{ fontWeight: 700, color: "#000000" }}>
                  {item.label}
                </span>
              ) : (
                item.label
              )}
            </Grid>
            <Grid item xs={0.2}>
              :
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {isLoading ? (
                <Skeleton />
              ) : item.property === "nota" ? (
                <span style={{ fontWeight: 700, color: "#000000" }}>
                  {dataDetail[item.property]}
                </span>
              ) : item.property === "nama" ? (
                <span>
                  {dataDetail[item.property].length > 15
                    ? `${dataDetail[item.property].slice(0, 15)}...`
                    : dataDetail[item.property]}
                </span>
              ) : (item.property === "checkin" &&
                  dataDetail[item.property].length - 1) ||
                (item.property === "checkout" &&
                  dataDetail[item.property].length - 1) ? (
                dateFormatter({
                  date: new Date(dataDetail[item.property]),
                  withHari: true,
                  withMonth: "simple",
                  withTimes: true,
                  yearLength: "full",
                })
              ) : (
                dataDetail[item.property]
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        md={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          width: "100%",
          height: "100%",
        }}
      >
        {isLoading ? (
          <Skeleton variant="rounded" width={90} height={90} />
        ) : (
          <QRCode value={dataDetail.nota} size={100} />
        )}
      </Grid>
    </>
  );
};

export default NotaProgress;
