import React from "react";
import { Grid, Skeleton } from "@mui/material";
import { ChipItems, NumericText } from "../../../../components/common";

const NotaReject = ({ data, isLoading }) => {
  return (
    <Grid
      container
      position={"relative"}
      paddingY={"1rem"}
      paddingX={"1.5rem"}
      borderRadius={"5px"}
      sx={{
        justifyContent: "space-between",
        fontSize: "12px",
        backgroundColor: "#F3F7F8",
        color: "#797979",
        fontWeight: "400",
      }}
    >
      {[
        { label: "No. Nota", value: data ? data.nota : "" },
        { label: "Leads / Nama", value: data ? data.nama : "" },
        { label: "Nominal", value: data ? data.nominal_reject : "" },
        { label: "Keterangan", value: data ? data.keterangan_reject : "" },
      ].map((item, index) => (
        <Grid
          container
          key={index}
          fontWeight={index === 0 ? "bold" : "normal"}
        >
          <Grid item xs={item.label === "" ? 3 : 2.5}>
            {item.label}
          </Grid>
          <Grid item xs={0.5} display={item.label === "" ? "none" : "block"}>
            :
          </Grid>
          <Grid
            item
            xs={8}
            fontWeight={index === 0 || index === 2 ? "bold" : "normal"}
          >
            {isLoading ? (
              <Skeleton variant="text" />
            ) : item.label === "Nominal" ? (
              <NumericText
                value={item.value}
                thousandSeparator
                prefix
                displayType="text"
              />
            ) : (
              item.value
            )}
          </Grid>
        </Grid>
      ))}

      <ChipItems
        label={
          isLoading ? (
            <Skeleton variant="rounded" />
          ) : (
            data?.status?.status ?? "-"
          )
        }
        backgroundcolor={data?.status?.warna ?? "black"}
        borderradius={"3px"}
        sx={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
          height: "20px",
          padding: "0 1rem",
        }}
      />
    </Grid>
  );
};

export default NotaReject;
