import { dateFormat } from "../../../../components/controls";
import { NumericText } from "../../../../components/common";

export class Config {
  static column = [
    {
      field: "nominal",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Nominal Bayar",
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "tanggal",
      type: "date",
      flex: 1,
      minWidth: 150,
      headerName: "Tanggal Bayar",
      valueGetter: (params) => new Date(params),
      renderCell: (params) => {
        if (params.value instanceof Date && !isNaN(params.value)) {
          return (
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {dateFormat(params.value, "", "-")}
            </span>
          );
        }
        return "";
      },
      groupingValueGetter: (params) => {
        var date = new Date(params);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "nota",
      flex: 1,
      minWidth: 100,
      hideable: false,
      headerName: "Nota",
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#007FF3",
              fontWeight: 700,
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "user",
      flex: 1,
      minWidth: 150,
      headerName: "Customer Service",
      align: "center",
      headerAlign: "center",
      filterable: false,
      sortable: false,
      display: "flex",
      renderCell: (params) => {
        if (params.value && typeof params.value === "string") {
          const [userID, userName] = params.value.split("|");
          return (
            <span>
              {userID} <strong>{userName}</strong>
            </span>
          );
        }
        return "";
      },
    },
    {
      field: "metode_pembayaran",
      type: "singleSelect",
      flex: 1,
      minWidth: 100,
      headerName: "Metode Bayar",
    },
    {
      field: "keterangan",
      type: "singleSelect",
      flex: 1,
      minWidth: 200,
      headerName: "Keterangan",
      renderCell: (params) => {
        if (!params.value && params.rowNode.type !== "group") {
          return "-";
        }

        if (params.value) {
          return params.value;
        }
      },
    },
  ];

  static variable = {
    nama: "RiwayatBayar",
    endpoint: "riwayat_pembayaran",
  };
}
