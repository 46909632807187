import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
  editProduct: { id: null, open: false, title: null },
};

const Pengaturan = createSlice({
  name: "pengaturan",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { SET_TAB } = Pengaturan.actions;

export default Pengaturan.reducer;
