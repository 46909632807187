import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { EventHandlers } from "../../repository";
class ActionButton {
  static getActionOpsi = ({ form, params, dispatch }) => {
    return [
      <GridActionsCellItem
        icon={
          <EditIcon
            sx={{
              fontSize: "21px",
              color: "#fff",
              backgroundColor: "rgba(159, 159, 159, 1)",
              padding: "4px",
              borderRadius: "50%",
            }}
          />
        }
        sx={{
          "&:hover svg": {
            backgroundColor: "#FF9800",
          },
        }}
        label="Edit"
        onClick={() => {
          form.setValue("bulan", params.row.bulan);
          form.setValue("tahun", params.row.tahun);
          form.setValue("jumlah", params.row.nominal);
          EventHandlers.handleCreateUpdate({
            dispatch,
            modal: {
              id: params.row.id,
              open: true,
              title: `Edit Target`,
            },
          });
        }}
      />,
      <GridActionsCellItem
        icon={
          <DeleteIcon
            sx={{
              fontSize: "21px",
              color: "#fff",
              backgroundColor: "rgba(159, 159, 159, 1)",
              padding: "4px",
              borderRadius: "50%",
            }}
          />
        }
        sx={{
          "&:hover svg": {
            backgroundColor: "#ff0000",
          },
        }}
        label="Hapus"
        onClick={() => {
          form.setValue("bulan", params.row.bulan);
          form.setValue("tahun", params.row.tahun);
          form.setValue("jumlah", params.row.nominal);
          EventHandlers.handleHapusTarget({
            dispatch,
            modal: {
              id: params.row.id,
              open: true,
              title: `Delete Target !`,
            },
          });
        }}
      />,
    ];
  };
}

export default ActionButton;
