import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BlankDialog, DataForm } from "../../../../components/Layout/Content";
import SelesaiAntrianLayout from "../../../../components/Layout/Content/Checkout/SelesaiAntrianLayout";
import { Api, EventHandlers } from "../repository";

const ModalKirim = () => {
  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.data);
  const { kirimModal } = useSelector((state) => state.checkoutSiapKirim);
  const { data, isError, isLoading } = Api.detailAntrian({ modal: kirimModal });
  const handleClose = useCallback(() => {
    EventHandlers.handleKirimModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch]);

  const { mutate, isPending } = Api.checkoutKirim({
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    mutate({ body: values, id: kirimModal.id });
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={kirimModal.open}
      handleClose={handleClose}
      title={kirimModal.title}
    >
      <DataForm data={data ? data.data : {}} isLoading={isLoading} />
      <SelesaiAntrianLayout
        isPending={isPending}
        handleClose={handleClose}
        onSubmit={onSubmit}
        title="Kirim"
      />
    </BlankDialog>
  );
};

export default ModalKirim;
