import dayjs from "dayjs";
import * as React from "react";
import { useSelector } from "react-redux";
import { Grid, Stack, Typography } from "@mui/material";
import { NumericText } from "../../../../components/common";
import logoWave from "../../../../assets/icon/logo/waveprint.svg";
import config from "../../../../config";

const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const DatePrint = dayjs();
const formattedDate = DatePrint.format("DD-MM-YYYY HH:mm");

export const PrintCashflowBulanan = React.forwardRef((props, ref) => {
  const { data, bulan, tahun } = props;
  const { setting } = useSelector((state) => state.data);
  const { name, usercode } = useSelector((state) => state.auth);
  const [pengaturan, setPengaturan] = React.useState({
    nama: "",
    alamat: "",
    logo: "",
  });

  const customHead = {
    padding: "0.7rem",
  };

  const customBody = {
    padding: "0.3rem 0.7rem",
  };

  // Hitung total pembayaran
  const totalPembayaran = data.reduce(
    (total, item) => total + (item.jumlah_pembayaran || 0),
    0
  );
  const totalNominal = data.reduce(
    (total, item) => total + (item.total_nominal || 0),
    0
  );

  React.useEffect(() => {
    if (setting.length) {
      const nama = setting.find((e) => e.rule === "NAMA_TOKO");
      const alamat = setting.find((e) => e.rule === "ALAMAT");
      const logo = setting.find((e) => e.rule === "LOGO_NOTA");
      if (nama) {
        setPengaturan((old) => ({ ...old, nama: nama.value }));
      }
      if (alamat) {
        setPengaturan((old) => ({ ...old, alamat: alamat.value }));
      }
      if (logo) {
        setPengaturan((old) => ({ ...old, logo: logo.value }));
      }
    }
  }, [setting]);

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={1}
      >
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <div style={{ marginRight: "1rem" }}>
            <img
              src={config.apiUrl + "/storage/logo/" + pengaturan.logo}
              alt="Logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
              Laporan Keuangan
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{pengaturan.nama}</Typography>
            <Typography sx={{ fontSize: 12 }}>{pengaturan.alamat}</Typography>
          </div>
        </Grid>
        <Grid item sx={{ fontSize: 12 }}>
          <Stack direction="row" spacing={1}>
            <Stack>
              <div style={{ fontWeight: 700 }}>Pendapatan</div>
              <div>Bulan | Tahun</div>
              <div>Admin</div>
            </Stack>
            <Stack>
              <div>:</div>
              <div>:</div>
              <div>:</div>
            </Stack>
            <Stack>
              <div style={{ fontWeight: 700 }}>Bulanan</div>
              <div>
                {monthNames[bulan - 1]} {tahun}
              </div>
              <div>
                {usercode} {name}
                {/* {name.slice(0, 8)}
                {name.length > 8 && '...'} */}
              </div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <table
        width={"100%"}
        style={{ paddingTop: 5, paddingBottom: 8, borderCollapse: "collapse" }}
      >
        <thead
          style={{
            backgroundColor: "#EEF2F7",
            borderBottom: "2px solid #ADA4A4",
            paddingTop: 10,
          }}
        >
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <th align="center" width={"10%"} style={customHead}>
              Tanggal
            </th>
            <th align="right" width={"45%"} style={customHead}>
              Jumlah Pembayaran
            </th>
            <th align="right" width={"45%"} style={customHead}>
              Total Nominal
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((e, index) => {
            const { jumlah_pembayaran, total_nominal } = e;

            return (
              <tr key={index} style={{ borderBottom: "0.1px solid #cfcfcf" }}>
                <td align="center" style={customBody}>
                  {index + 1}
                </td>
                <td align="right" style={customBody}>
                  {jumlah_pembayaran ? (
                    <NumericText
                      value={jumlah_pembayaran}
                      suffix=" Transaksi"
                      displayType="text"
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td align="right" style={{ ...customBody, color: "#007FF3" }}>
                  {total_nominal ? (
                    <NumericText
                      value={total_nominal}
                      prefix={true}
                      displayType="text"
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>

        <tfoot
          style={{
            backgroundColor: "#EEF2F7",
            fontWeight: 700,
            borderBottom: "2px solid #ADA4A4",
          }}
        >
          <tr>
            <td align="center" style={customBody}>
              Total
            </td>
            <td align="right" style={customBody}>
              <NumericText
                value={totalPembayaran}
                suffix=" Transaksi"
                displayType="text"
              />
            </td>
            <td align="right" style={customBody}>
              <NumericText
                value={totalNominal}
                prefix={true}
                displayType="text"
              />
            </td>
          </tr>
        </tfoot>
      </table>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0 0 0",
        }}
      >
        <Typography sx={{ fontSize: 8 }}>
          Printed on : {formattedDate}
        </Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </div>

      {/* Mengatur pemisahan halaman setelah tabel */}
      <div style={{ pageBreakAfter: "always", marginTop: "2rem" }}></div>
    </div>
  );
});
