import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { CustomDatagrid } from "../../../../components/Layout/Content";
import { CustomDateRange } from "../../../../components/controls";
import {
  SET_COLUMN,
  SET_FILTER,
  SET_SORT,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} from "../domain/rekapNotaSlice";
import { Config, EventHandlers } from "../repository";
import FooterCardRekapNota from "./TabelRekapNota/FooterCardRekapNota";
import ActionButton from "./TabelRekapNota/ActionButton";

const TabelRekapNota = ({ data, isLoading, jenisOrder, sourceOrder }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    tanggalRekapFilter,
    page,
    pageSize,
    filterModel,
    sortModel,
    columnModel,
    rowGroup,
    aggregationModel,
    pinnedModel,
  } = useSelector((state) => state.rekapNota);

  const { role } = useSelector((state) => state.auth);

  const { setting } = useSelector((state) => state.data);

  const statusOptions = Array.from(
    new Set(data.map((item) => item.status.status))
  );

  const jenisOrderOptions = Array.from(
    new Set(jenisOrder.filter((item) => item.status).map((item) => item.name))
  );

  const sourceOrderOptions = Array.from(
    new Set(sourceOrder.filter((item) => item.status).map((item) => item.name))
  );

  const columns = Config.column.map((e) => {
    if (e.field === "status") {
      return {
        ...e,
        valueOptions: statusOptions,
      };
    }
    if (e.field === "jenis_order") {
      return {
        ...e,
        valueOptions: jenisOrderOptions,
      };
    }
    if (e.field === "source_order") {
      return {
        ...e,
        valueOptions: sourceOrderOptions,
      };
    }
    if (e.field === "actions") {
      return {
        ...e,
        getActions: (params) => {
          if (typeof params.id === "number") {
            const statusDeleteAntrian = setting.find(
              (item) => item.rule === "DELETE_ANTRIAN"
            );
            return ActionButton.getActionOpsi({
              role,
              params,
              dispatch,
              statusDeleteAntrian: statusDeleteAntrian.value === "false",
            });
          }

          return [];
        },
      };
    }
    return e;
  });

  if (role === "root" || role === "customer_service") {
    const indexToInsertAfter = columns.findIndex((e) => e.field === "actions");
    const statusEditAntrian = setting.find(
      (item) => item.rule === "EDIT_ANTRIAN"
    );

    const statusEditSize = setting.find((item) => item.rule === "EDIT_SIZE");

    columns.splice(indexToInsertAfter, 0, {
      headerName: "Edit",
      disableClickEventBubbling: true,
      field: "edit",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      width: 60,
      getActions: (params) => {
        if (typeof params.id === "number") {
          return ActionButton.getActionEdit({
            role,
            params,
            dispatch,
            statusEditAntrian:
              role !== "root" ? statusEditAntrian.value === "false" : false,
            statusEditSize:
              role !== "root" ? statusEditSize.value === "false" : false,
          });
        }
        return [];
      },
    });
  }

  return (
    <CustomDatagrid
      {...{
        rows: data,
        columns,
        isLoading: isLoading,
        columnModel,
        page,
        pageSize,
        filterModel,
        sortModel,
        rowGroup,
        aggregationModel,
        pinnedModel,
        SET_COLUMN: SET_COLUMN,
        SET_PAGE: SET_PAGE,
        SET_PAGE_SIZE: SET_PAGE_SIZE,
        SET_FILTER: SET_FILTER,
        SET_SORT: SET_SORT,
        SET_ROW_GROUP,
        SET_AGGREGATION,
        SET_PINNED_COLUMN,
      }}
      withNumberColumn={true}
      getRowClassName={(e) => e.row.prioritas && `datagrid-prioritas`}
      footerComponent={({ finalData }) => {
        return <FooterCardRekapNota data={finalData} isLoading={isLoading} />;
      }}
      customToolbar={
        <Box sx={{ p: 1 }}>
          <CustomDateRange
            title="Filter Tanggal Selesai"
            defaultValue={tanggalRekapFilter}
            onChange={(values) =>
              EventHandlers.handleChangeFilterTanggal({
                dispatch,
                queryClient,
                tanggal: values,
              })
            }
          />
        </Box>
      }
    />
  );
};

TabelRekapNota.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  jenisOrder: PropTypes.array,
  sourceOrder: PropTypes.array,
};

export default TabelRekapNota;
