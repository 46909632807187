import React from "react";
import { Grid } from "@mui/material";

const FormKeteranganSatuan = (props) => {
  const { form, Input, handleFocus, SwitchInput, keteranganData, isLoading } =
    props;
  const keterangan = Array.from(new Set(keteranganData));

  return (
    <Grid container spacing={2} marginBottom={3} sx={{ color: "#797979" }}>
      {/* input 1-3 */}
      <Grid container item xs={6} spacing={2}>
        {keterangan.map((data, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              {...form.register(`keterangan.${index}.id`, {
                valueAsNumber: true,
              })}
              value={data.id}
            />
            <Grid item xs={6} textAlign="right">
              <SwitchInput
                name={`keterangan.${index}.status`}
                control={form.control}
                label={`${index + 1}`}
                defaultValue={data ? data.status : false}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                rules={{
                  required: form.watch(`keterangan.${index}.status`)
                    ? true
                    : false,
                }}
                name={`keterangan.${index}.name`}
                control={form.control}
                label={`Judul Keterangan ${index + 1}`}
                defaultValue={data.name}
                onFocus={handleFocus}
                disabled={
                  !form.watch(`keterangan.${index}.status`, data.status) ||
                  isLoading
                }
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default FormKeteranganSatuan;
