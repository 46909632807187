import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Typography } from "@mui/material";
import { Input, handleFocus } from "../../../../components/controls";

const PengaturanKodeNota = ({ form, dataPengaturan }) => {
  const pengaturan = Array.from(new Set(dataPengaturan));

  const renderInputField = (
    index,
    id,
    label,
    nameInput,
    defaultValueInput,
    xsLabel,
    xsInput
  ) => (
    <React.Fragment key={index}>
      {id}
      <Grid item xs={xsLabel} textAlign={"right"}>
        {label}
      </Grid>
      <Grid item xs={xsInput}>
        <Input
          rules={{ required: false }}
          name={nameInput}
          control={form.control}
          label={""}
          onFocus={handleFocus}
          shrinklabel={false}
          defaultValue={defaultValueInput}
        />
      </Grid>
      {xsInput !== 9 && <Grid item xs={6} />}
    </React.Fragment>
  );

  return (
    <Grid container spacing={2} alignItems={"center"} marginBottom={2}>
      <Grid item xs={12}>
        <Typography fontWeight={700} color={"#797979"}>
          Kode Nota
        </Typography>
        <Divider />
      </Grid>
      {!pengaturan.length > 0 && (
        <>
          {renderInputField(0, "", "Nota Order", "", "", 3, 3)}
          {renderInputField(1, "", "Faktur Group", "", "", 3, 3)}
        </>
      )}
      {pengaturan.length > 0 &&
        pengaturan.map((e, index) => {
          if (e.rule === "ANTRIAN") {
            return renderInputField(
              index,
              <input
                type="hidden"
                {...form.register(`setting.${index}.id`, {
                  valueAsNumber: true,
                })}
                value={e.id}
              />,
              "Nota Order",
              `setting.${index}.value`,
              e.value,
              3,
              3
            );
          }
          if (e.rule === "GROUP") {
            return renderInputField(
              index,
              <input
                type="hidden"
                {...form.register(`setting.${index}.id`, {
                  valueAsNumber: true,
                })}
                value={e.id}
              />,
              "Faktur Group",
              `setting.${index}.value`,
              e.value,
              3,
              3
            );
          }
          return null;
        })}
    </Grid>
  );
};

PengaturanKodeNota.propTypes = {
  form: PropTypes.object.isRequired,
  dataPengaturan: PropTypes.array.isRequired,
};

export default PengaturanKodeNota;
