import React, { useEffect, useState } from "react";
import { DoneOutline as SimpanIcon } from "@mui/icons-material";
import { Collapse, Divider, Grid, Paper } from "@mui/material";
import { useGetCachedData } from "../../../hooks";
import CollapseButton from "../../../components/common/CollapseButton";
import {
  Input,
  handleFocus,
  SwitchInput,
  CustomButton,
} from "../../../components/controls";
import {
  FormAdditional,
  FormExpedisi,
  FormJenisOrder,
  FormKeteranganSatuan,
  FormPilihUkuran,
  FormSourceOrder,
  FormStatusProgress,
} from "./component";
import { Api, useFormPengaturanSystem } from "./repository";
import { updateLoading } from "../../themeSlice";
import { useDispatch, useSelector } from "react-redux";

const SystemPage = () => {
  const dispatch = useDispatch();
  const form = useFormPengaturanSystem();
  const { socket } = useSelector((state) => state.data);
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });
  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataEkspedisi = useGetCachedData({ queryKey: "ekspedisi" });
  const getDataSize = useGetCachedData({ queryKey: "size" });
  const getDataAdditional = useGetCachedData({ queryKey: "additional" });
  const getDataStatus = useGetCachedData({ queryKey: "status" });
  const getDataKeterangan = useGetCachedData({ queryKey: "keterangan" });

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const { mutate, isPending } = Api.updateSystem({
    socket,
    onSuccess: () => {
      setIsSubmitSuccessful(true);
    },
  });

  const onSubmit = (value) => {
    mutate(value);
  };

  useEffect(() => {
    dispatch(updateLoading(isSubmitSuccessful));
    setTimeout(() => {
      setIsSubmitSuccessful(false);
    }, 1000);
  }, [dispatch, isSubmitSuccessful, setIsSubmitSuccessful]);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(updateLoading(isPending));
  // }, [dispatch, isPending]);

  // collapse-expand
  const [jenisOrder, setJenisOrder] = useState(false);
  const [sourceOrder, setSourceOrder] = useState(false);
  const [expedisi, setExpedisi] = useState(false);
  const [pilihanUkuran, setPilihanUkuran] = useState(false);
  const [additional, setAdditional] = useState(false);
  const [statusProgress, setStatusProgress] = useState(false);
  const [ketSatuan, setKetSatuan] = useState(false);

  const handleJO = () => {
    setJenisOrder(!jenisOrder);
  };
  const handleSO = () => {
    setSourceOrder(!sourceOrder);
  };
  const handleEx = () => {
    setExpedisi(!expedisi);
  };
  const handlePU = () => {
    setPilihanUkuran(!pilihanUkuran);
  };
  const handleAd = () => {
    setAdditional(!additional);
  };
  const handleSP = () => {
    setStatusProgress(!statusProgress);
  };
  const handleKS = () => {
    setKetSatuan(!ketSatuan);
  };

  return (
    <Paper elevation={1} sx={{ padding: "1rem" }}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* jenis order */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handleJO}
            checked={jenisOrder}
            expandLabel="Jenis Order"
            collapseLabel="Jenis Order"
          />
        </Divider>
        <Collapse in={jenisOrder}>
          <FormJenisOrder
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              dataJenisOrder: getDataJenisOrder ? getDataJenisOrder.data : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        {/* source order */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handleSO}
            checked={sourceOrder}
            expandLabel="Source Order"
            collapseLabel="Source Order"
          />
        </Divider>
        <Collapse in={sourceOrder}>
          <FormSourceOrder
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              dataSourceOrder: getDataSourceOrder
                ? getDataSourceOrder.data
                : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        {/* ekspedisi */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handleEx}
            checked={expedisi}
            expandLabel="Ekspedisi"
            collapseLabel="Ekspedisi"
          />
        </Divider>
        <Collapse in={expedisi}>
          <FormExpedisi
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              expedisiData: getDataEkspedisi ? getDataEkspedisi.data : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        {/* Pilihan Ukuran */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handlePU}
            checked={pilihanUkuran}
            expandLabel="Pilihan Ukuran"
            collapseLabel="Pilihan Ukuran"
          />
        </Divider>
        <Collapse in={pilihanUkuran}>
          <FormPilihUkuran
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              sizeData: getDataSize ? getDataSize.data : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        {/* Additional */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handleAd}
            checked={additional}
            expandLabel="Additional"
            collapseLabel="Additional"
          />
        </Divider>
        <Collapse in={additional}>
          <FormAdditional
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              additionalData: getDataAdditional ? getDataAdditional.data : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        {/* Status Progress */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handleSP}
            checked={statusProgress}
            expandLabel="Status Progress"
            collapseLabel="Status Progress"
          />
        </Divider>
        <Collapse in={statusProgress}>
          <FormStatusProgress
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              statusDataDefault: getDataStatus
                ? getDataStatus.data.default
                : [],
              statusDataProduksi: getDataStatus
                ? getDataStatus.data.produksi
                : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        {/* Keterangan Satuan */}
        <Divider sx={{ marginBottom: 3 }}>
          <CollapseButton
            onClick={handleKS}
            checked={ketSatuan}
            expandLabel="Keterangan Satuan"
            collapseLabel="Keterangan Satuan"
          />
        </Divider>
        <Collapse in={ketSatuan}>
          <FormKeteranganSatuan
            {...{
              form,
              Input,
              handleFocus,
              SwitchInput,
              keteranganData: getDataKeterangan ? getDataKeterangan.data : [],
              isLoading: isPending,
            }}
          />
        </Collapse>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 2 }} />
            <CustomButton
              loading={isPending}
              type="submit"
              variant="contained"
              startIcon={!isPending && <SimpanIcon />}
              disabled={false}
            >
              Simpan Pengaturan Sistem
            </CustomButton>
            {/* <Button
              type="submit"
              variant="contained"
              startIcon={<SimpanIcon />}
              fullWidth
              disabled={isPending}
            >
              SIMPAN PENGATURAN SISTEM
            </Button> */}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SystemPage;
