import { Button, Fab, useTheme, useMediaQuery } from "@mui/material";
import QueueIcon from "@mui/icons-material/Queue";
import { Add as AddIcon } from "@mui/icons-material";

const AddButton = ({
  title,
  variant = "outlined",
  iconcolor,
  fontcolor,
  ...rest
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  if (isSmall) {
    return (
      <Fab
        sx={{
          display: isSmall ? "" : "none",
          position: "fixed",
          bottom: 20,
          right: 10,
        }}
        variant="extended"
        size="large"
        color="primary"
        aria-label="add"
        {...rest}
      >
        <AddIcon sx={{ mr: 1 }} />
        {title}
      </Fab>
    );
  } else {
    return (
      <Button
        sx={{
          display: isSmall ? "none" : "",
          color: variant === "outlined" ? "#1976d2" : "",
          backgroundColor: variant === "contained" ? "#1976d2" : "",
          borderRadius: "5px",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "15px",
          letterSpacing: "0em",
          padding: "8.5px 14px",
          boxShadow:
            variant === "contained"
              ? "0px 0px 5px -2px rgba(0,0,0,0.4)"
              : "none",
          border:
            variant === "outlined"
              ? "1px solid rgba(25, 118, 210, 0.5)"
              : "1px solid #1976d2",
          "&:hover": {
            boxShadow:
              variant === "contained"
                ? "0px 0px 5px -1px rgba(0,0,0,1)"
                : "none",
            backgroundColor: variant === "contained" ? "#1976d2" : "",
            border:
              variant === "outlined"
                ? "1px solid #1976d2"
                : "1px solid #1976d2",
          },
        }}
        fullWidth
        size="small"
        variant={variant}
        startIcon={<QueueIcon sx={{ color: iconcolor }} />}
        {...rest}
      >
        {title}
      </Button>
    );
  }
};

export default AddButton;
