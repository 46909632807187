import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { BlankDialog, DataForm } from "../../../../components/Layout/Content";
import { Divider, Grid } from "@mui/material";
import { CheckboxInput, Input, Select } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { useGetCachedData } from "../../../../hooks";
import { ActionGrid } from "../../../../components/common";

const ModalEditDesigner = () => {
  const { control, watch, reset, handleSubmit } = useForm();
  const getUser = useGetCachedData({ queryKey: "users/group_by_role" });
  const { changeDesignerModal } = useSelector((state) => state.antrianNota);
  const { socket } = useSelector((state) => state.data);
  const getAntrianDetail = Api.detailAntrian({ modal: changeDesignerModal });
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    reset({ desainer: "", keterangan: "", confirmation: false });
    EventHandlers.handleChangeDesignerModal({
      dispatch,
      modal: { id: null, open: false, title: null },
    });
  }, [dispatch, reset]);

  const { mutate, isPending, isError } = Api.skipDesainer({
    handleClose,
    dispatch,
    socket,
  });

  const onSubmit = (values) => {
    mutate({ body: values, id: changeDesignerModal.id });
  };

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={changeDesignerModal.open}
      handleClose={handleClose}
      title={changeDesignerModal.title}
    >
      <DataForm
        data={getAntrianDetail.data ? getAntrianDetail.data.data : {}}
        isLoading={getAntrianDetail.isLoading}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container paddingBottom={1} spacing={2}>
          <Grid item xs={4}>
            <Select
              rules={{ required: "Desainer Wajib di isi!" }}
              name={"desainer"}
              control={control}
              label={"Desainer"}
              options={getUser?.data?.desainer ?? []}
              defaultValue={""}
            />
          </Grid>
          <Grid item xs={8}>
            <Input
              name="keterangan"
              control={control}
              label="Keterangan Progress"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              type="submit"
              disabled={watch("confirmation") ? false : true}
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalEditDesigner;
