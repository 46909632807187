import dayjs from "dayjs";
import * as React from "react";
import { useSelector } from "react-redux";
import { useGetCachedData } from "../../../hooks";
import { Grid, Stack, Typography } from "@mui/material";
import { NumericText } from "../../../components/common";
import logoWave from "../../../assets/icon/logo/waveprint.svg";
import config from "../../../config";

const monthNames = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const DatePrint = dayjs();
const formattedDate = DatePrint.format("DD-MM-YYYY HH:mm");

export const PrintRetur = React.forwardRef((props, ref) => {
  const { data, tanggal } = props;
  const getDataPengaturan = useGetCachedData({ queryKey: "setting" });
  const { name, usercode } = useSelector((state) => state.auth);
  const [logoNota, setLogoNota] = React.useState("");
  const [namaToko, setNamaToko] = React.useState("");
  const [alamatToko, setAlamatToko] = React.useState("");

  React.useEffect(() => {
    getDataPengaturan?.data.forEach((item) => {
      switch (item.rule) {
        case "LOGO_NOTA":
          setLogoNota(item.value);
          break;
        case "NAMA_TOKO":
          setNamaToko(item.value);
          break;
        case "ALAMAT":
          setAlamatToko(item.value);
          break;
        default:
          break;
      }
    });
  }, [getDataPengaturan]);

  const formattedStartDate =
    dayjs(tanggal[0]).format("DD") +
    " " +
    monthNames[dayjs(tanggal[0]).month()] +
    " " +
    dayjs(tanggal[0]).format("YYYY");
  const formattedEndDate =
    dayjs(tanggal[1]).format("DD") +
    " " +
    monthNames[dayjs(tanggal[1]).month()] +
    " " +
    dayjs(tanggal[1]).format("YYYY");

  const customHead = {
    padding: "0.7rem",
  };

  const customBody = {
    padding: "0.3rem 0.7rem",
  };

  // Hitung total pembayaran
  const totalPembayaran = data ? data.length : 0;
  const totalNominal = data.reduce(
    (total, item) => total + (item.total_pembelian || 0),
    0
  );

  return (
    <div
      ref={ref}
      style={{
        padding: "1rem",
        color: "black",
        fontSize: 12,
        fontFamily: "PrintFont",
      }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"end"}
        marginBottom={1}
      >
        <Grid item xs={4} display={"flex"} alignItems={"center"}>
          <div style={{ marginRight: "1rem" }}>
            <img
              src={`${config.apiUrl}/storage/logo/${logoNota}`}
              alt="Logo"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div>
            <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
              Laporan Retur
            </Typography>
            <Typography sx={{ fontSize: 12 }}>{namaToko}</Typography>
            <Typography sx={{ fontSize: 12 }}>{alamatToko}</Typography>
          </div>
        </Grid>
        <Grid item sx={{ fontSize: 12 }}>
          <Stack direction="row" spacing={1}>
            <Stack>
              <div>Admin</div>
              <div>Jangka Waktu</div>
            </Stack>
            <Stack>
              <div>:</div>
              <div>:</div>
            </Stack>
            <Stack>
              <div>
                {usercode} {name}
                {/* {name.slice(0, 8)}
                {name.length > 8 && '...'} */}
              </div>
              <div>
                {formattedStartDate} - {formattedEndDate}
              </div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <table
        width={"100%"}
        style={{ paddingTop: 5, paddingBottom: 8, borderCollapse: "collapse" }}
      >
        <thead
          style={{
            backgroundColor: "#EEF2F7",
            borderBottom: "2px solid #ADA4A4",
            paddingTop: 10,
          }}
        >
          <tr
            style={{
              fontWeight: "bold",
            }}
          >
            <th align="center" width={"1%"} style={customHead}>
              No
            </th>
            <th align="right" width={"15%"} style={customHead}>
              Nominal Order
            </th>
            <th align="left" width={"20%"} style={customHead}>
              Tanggal Retur
            </th>
            <th align="left" width={"10%"} style={customHead}>
              Nota
            </th>
            <th align="left" width={"15%"} style={customHead}>
              Lead/Nama
            </th>
            <th align="right" width={"10%"} style={customHead}>
              Qty
            </th>
            <th align="left" width={"20%"} style={customHead}>
              Keterangan
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((e, index) => {
            return (
              <tr
                key={index}
                style={{
                  borderBottom: "0.1px solid #cfcfcf",
                }}
              >
                <td align="center" style={customBody}>
                  {index + 1}
                </td>
                <td align="right" style={customBody}>
                  {e.total_pembelian ? (
                    <NumericText
                      thousandSeparator
                      value={e.total_pembelian}
                      prefix={true}
                      displayType="text"
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td style={customBody}>{e.status.tanggal}</td>
                <td style={{ ...customBody, fontWeight: 700 }}>
                  <b>{e.nota}</b>
                </td>
                <td style={customBody}>{e.nama}</td>
                <td align="right" style={customBody}>
                  <NumericText
                    thousandSeparator
                    value={e.total_qty}
                    suffix={" Pcs"}
                    displayType="text"
                  />
                </td>
                <td style={customBody}>
                  {e.status.keterangan.length > 24
                    ? e.status.keterangan.substring(0, 24) + "..."
                    : e.status.keterangan}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "#EEF2F7",
          padding: "0.5rem 1rem",
          fontWeight: 700,
          borderBottom: "2px solid #ADA4A4",
          textAlign: "center",
        }}
      >
        <Grid item xs={4}>
          Total Pembayaran :{" "}
          <NumericText
            value={totalPembayaran}
            suffix=" Transaksi"
            displayType="text"
          />
        </Grid>
        <Grid item xs={4}>
          Total Nominal :{" "}
          <NumericText
            thousandSeparator
            value={totalNominal}
            prefix={true}
            displayType="text"
          />
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem 0 0 0",
        }}
      >
        <Typography sx={{ fontSize: 8 }}>
          Printed on : {formattedDate}
        </Typography>
        <Typography sx={{ fontSize: 7 }}>
          Powered by{" "}
          <img src={logoWave} alt="logoWave" style={{ width: "30px" }} />
        </Typography>
      </div>

      {/* Mengatur pemisahan halaman setelah tabel */}
      <div style={{ pageBreakAfter: "always", marginTop: "2rem" }}></div>
    </div>
  );
});
