import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateDensity } from "../../../../features/themeSlice";
import Toolbar from "./Toolbar";
import LoadingData from "./LoadingData";
import EmptyDataOverlay from "./EmptyDataOverlay";
import NumberCell from "./NumberCell";

function arraysEqual(array1, array2) {
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
}

const CustomDatagrid = ({
  rows,
  columns,
  isLoading = false,
  columnModel,
  page,
  pageSize,
  filterModel,
  sortModel,
  rowGroup,
  aggregationModel,
  pinnedModel,
  SET_COLUMN,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
  footerComponent,
  customToolbar = undefined,
  getRowClassName = undefined,
  withNumberColumn = false,
  showQuickFilter = true,
  disableColumnMenu = false,
  hideFooter = false,
  columnGroup,
  setDensity,
  sx = {},
}) => {
  const [, setState] = useState({});
  const forceUpdate = () => setState({});
  const apiRef = useGridApiRef();

  const dispatch = useDispatch();
  const [finalData, setFinalData] = useState([]);
  const prevVisibleItems = useRef([]);
  const { density, pageSizeOptions } = useSelector((state) => state.theme);

  const columnNumber = {
    field: "_nomor",
    headerName: "No",
    maxWidth: 70,
    headerAlign: "center",
    align: "center",
    sortable: false,
    filterable: false,
    type: "actions",
    renderCell: (params) => {
      return <NumberCell {...params} />;
    },
  };

  const updatedColumns = [...columns];

  if (withNumberColumn && rowGroup.length === 0) {
    updatedColumns.unshift(columnNumber);
  }

  return (
    <>
      <Box sx={{ width: "100%", height: !rows.length && 500 }}>
        <DataGridPremium
          apiRef={apiRef}
          /**
           * SETTING DEFAULT
           */
          disableRowSelectionOnClick
          pagination={true}
          pageSizeOptions={pageSizeOptions}
          density={setDensity || density}
          autoHeight={rows.length ? true : false}
          loading={isLoading}
          sx={{
            backgroundColor: "white",
            boxShadow: "2px 2px 5px #cbcbcb",
            color: "#606f7b",
            "& .MuiDataGrid-cellContent": {
              fontSize: "0.85rem",
            },
            "& .MuiDataGrid-columnHeaders": {
              borderBottom: "2px solid #ADA4A4",
              "& [role=row]": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: "#eef2f7",
              },
            },
            "& .MuiDataGrid-bottomContainer [role=row]": {
              borderTop: "2px solid #ADA4A4",
              backgroundColor: "#eef2f7",
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-bottomContainer": {
              display: rows.length === 0 && "none",
            },
            "& .MuiDataGrid-columnHeader--pinnedRight, & .MuiDataGrid-cell--pinnedRight":
              {
                borderLeftWidth: "2px",
              },
            ...sx,
          }}
          rows={rows}
          columns={updatedColumns}
          /**
           * SLOTS COMPONENT TOOLBAR & DENSITY
           */
          slots={{
            loadingOverlay: LoadingData,
            toolbar: disableColumnMenu === false && Toolbar,
            noRowsOverlay: EmptyDataOverlay,
          }}
          slotProps={{
            toolbar: disableColumnMenu === false && {
              onDensityChange: (density) => {
                dispatch(updateDensity({ density }));
              },
              showQuickFilter: showQuickFilter,
              customToolbar: customToolbar,
            },
          }}
          /**
           * SORTING
           */
          sortModel={sortModel}
          onSortModelChange={(sort) => {
            dispatch(SET_SORT({ sort }));
            forceUpdate();
          }}
          /**
           * FILTERING
           */
          filterModel={filterModel}
          onFilterModelChange={(filter) => {
            dispatch(SET_FILTER({ filter }));
            forceUpdate();
          }}
          /**
           * COLUMN VISIBILITY
           */
          columnVisibilityModel={columnModel}
          onColumnVisibilityModelChange={(model) => {
            dispatch(SET_COLUMN({ model }));
          }}
          /**
           * PAGINATION
           */
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(page) => {
            dispatch(SET_PAGE({ page: page.page }));
            dispatch(SET_PAGE_SIZE({ pageSize: page.pageSize }));
            forceUpdate();
          }}
          /**
           * GROUPING ROWS
           */
          rowGroupingModel={rowGroup}
          onRowGroupingModelChange={(groups) => {
            dispatch(SET_ROW_GROUP({ group: groups }));
          }}
          groupingColDef={{
            hideable: false,
            aggregable: false,
            filterable: false,
            width: 150,
            headerAlign: "center",
          }}
          /**
           * AGGREGATION
           */
          aggregationModel={aggregationModel}
          onAggregationModelChange={(aggregates) =>
            dispatch(SET_AGGREGATION({ aggregates }))
          }
          /**
           * PIN COLUMN
           */
          pinnedColumns={pinnedModel}
          onPinnedColumnsChange={(pinned) =>
            dispatch(SET_PINNED_COLUMN({ pinned }))
          }
          /**
           * FOOTER COMPONENT, USING onStateChange for get real data (FILTERED)
           */
          onStateChange={(state) => {
            if (footerComponent) {
              const visibleRows = state.filter.filteredRowsLookup;
              let visibleItems = [];
              for (const [id, value] of Object.entries(visibleRows)) {
                if (value === true) {
                  visibleItems.push(id);
                }
              }

              // Compare current visible items with previous ones
              if (!arraysEqual(visibleItems, prevVisibleItems.current)) {
                prevVisibleItems.current = visibleItems;
                const res = rows.filter((item) =>
                  visibleItems.includes(item.id.toString())
                );
                setFinalData(res);
              }
            }
          }}
          /**
           * CLASSES ROW
           */
          columnGroupingModel={columnGroup}
          getRowClassName={getRowClassName}
          disableColumnMenu={disableColumnMenu}
          hideFooter={hideFooter}
        />
      </Box>

      {footerComponent && footerComponent({ finalData })}
    </>
  );
};

CustomDatagrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  columnModel: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  filterModel: PropTypes.object.isRequired,
  sortModel: PropTypes.array.isRequired,
  SET_COLUMN: PropTypes.func.isRequired,
  SET_PAGE: PropTypes.func.isRequired,
  SET_PAGE_SIZE: PropTypes.func.isRequired,
  SET_FILTER: PropTypes.func.isRequired,
  SET_SORT: PropTypes.func.isRequired,
  footerComponent: PropTypes.func,
  getRowClassName: PropTypes.func,
  withNumberColumn: PropTypes.bool,
  sx: PropTypes.object,
};

export default CustomDatagrid;
