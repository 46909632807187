import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { NumericText } from "../../components/common";
import { Edit as EditIcon } from "@mui/icons-material";

const CardTabel = ({
  isLoading,
  produkID,
  produkTitle,
  rowsSize,
  rowsAdditional,
  onEdit,
}) => {
  let cardColor = "#008DD2";

  return (
    <Card
      elevation={3}
      sx={{
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
        width: "100%",
      }}
    >
      <CardHeader
        style={{
          padding: "0.5rem 1rem",
          backgroundColor: cardColor,
          display: "flex",
          alignItems: "center",
        }}
        title={
          <div style={{ display: "flex" }}>
            <Typography
              color="#fff"
              fontSize={12}
              fontWeight={700}
              textTransform={"capitalize"}
            >
              {produkTitle}
            </Typography>
          </div>
        }
        action={
          <div style={{ display: "flex", padding: 3 }}>
            <Button
              variant="outlined"
              startIcon={
                <EditIcon
                  style={{
                    fontSize: "15px",
                    padding: "3px",
                    color: cardColor,
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              }
              size="small"
              sx={{
                color: "white",
                borderColor: "white",
                padding: "3px 10px",
                textTransform: "capitalize",
                "&:hover": {
                  borderColor: "white",
                  boxShadow: "0px 0px 8px 1px #00000020 inset",
                },
              }}
              onClick={onEdit}
            >
              Edit Harga
            </Button>
          </div>
        }
      />
      <CardContent style={{ padding: "0.2rem 1rem 1rem 1rem" }}>
        <Grid
          container
          sx={{
            "& .MuiTableCell-root": {
              color: "#797979",
              fontSize: "10px",
              padding: "3px 6px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              border: "none",
            },
            "& .MuiTableCell-head": {
              fontWeight: 700,
              borderBottom: "2px solid rgba(224, 224, 224, 1)",
            },
          }}
        >
          <Grid item xs={6}>
            <Table size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell>HPP</TableCell>
                  <TableCell width={"35%"}>Harga Jual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsSize
                  .filter((row) => row.status)
                  .map((row, index) => (
                    <React.Fragment key={index}>
                      {row.harga
                        .filter((harga) => harga.jenisorder_id === produkID)
                        .map((harga, hargaIndex) => (
                          <TableRow
                            key={hargaIndex}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <NumericText
                                thousandSeparator
                                value={harga.hpp}
                                prefix={true}
                                displayType="text"
                              />
                            </TableCell>
                            <TableCell>
                              <NumericText
                                thousandSeparator
                                value={harga.harga}
                                prefix={true}
                                displayType="text"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={6} color={"#797979"}>
            <Table size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>Additional</TableCell>
                  <TableCell>HPP</TableCell>
                  <TableCell width={"35%"}>Harga Jual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsAdditional
                  .filter((row) => row.status)
                  .map((row, index) => (
                    <React.Fragment key={index}>
                      {row.harga
                        .filter((harga) => harga.jenisorder_id === produkID)
                        .map((harga, hargaIndex) => (
                          <TableRow
                            key={hargaIndex}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <NumericText
                                thousandSeparator
                                value={harga.hpp}
                                prefix={true}
                                displayType="text"
                              />
                            </TableCell>
                            <TableCell>
                              <NumericText
                                thousandSeparator
                                value={harga.harga}
                                prefix={true}
                                displayType="text"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardTabel;
