import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../repository";
import { BlankDialog, DataForm } from "../../../../components/Layout/Content";
import { Divider, Grid } from "@mui/material";
import { CheckboxInput, Select } from "../../../../components/controls";
import CustomButton from "../../../../components/controls/CustomButton";
import { useGetCachedData } from "../../../../hooks";
import { ActionGrid } from "../../../../components/common";

const ModalChangePerson = () => {
  const { control, setValue, watch, reset, handleSubmit } = useForm();
  const [defaultDesainer, setDefaultDesainer] = useState(null);
  const [defaultProduksi, setDefaultProduksi] = useState(null);
  const getUser = useGetCachedData({ queryKey: "users/group_by_role" });
  const { changePersonModal } = useSelector((state) => state.checkoutSiapKirim);
  const getAntrianDetail = Api.detailAntrian({ modal: changePersonModal });
  const dispatch = useDispatch();
  const handleClose = () => {
    reset({ desainer: "", produksi: "", confirmation: false });
    EventHandlers.handleChangePersonModal({
      dispatch,
      modal: { id: null, open: false, title: null },
    });
  };

  const { mutate, isPending } = Api.personAntrian({ handleClose });

  const onSubmit = (values) => {
    mutate({ body: values, id: changePersonModal.id });
  };

  useEffect(() => {
    if (getAntrianDetail.data) {
      const desainer = getAntrianDetail.data.data.progress.desainer;
      const produksi = getAntrianDetail.data.data.progress.produksi;

      if (desainer.id) {
        setValue("desainer", desainer.id);
        setDefaultDesainer(desainer.id);
      }

      if (produksi.id) {
        setValue("produksi", produksi.id);
        setDefaultProduksi(produksi.id);
      }
    }
  }, [getAntrianDetail.data, setValue]);

  return (
    <BlankDialog
      maxWidth={"sm"}
      open={changePersonModal.open}
      handleClose={handleClose}
      title={changePersonModal.title}
    >
      <DataForm
        data={getAntrianDetail.data ? getAntrianDetail.data.data : {}}
        isLoading={getAntrianDetail.isLoading}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container paddingBottom={1} spacing={2}>
          <Grid item xs={6}>
            <Select
              rules={{ required: defaultDesainer ? true : false }}
              disabled={defaultDesainer ? false : true}
              name={"desainer"}
              control={control}
              label={"Desainer"}
              options={getUser?.data?.desainer ?? []}
              defaultValue={defaultDesainer ?? ""}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              rules={{ required: defaultProduksi ? true : false }}
              disabled={defaultProduksi ? false : true}
              name={"produksi"}
              control={control}
              label={"Produksi"}
              options={getUser?.data?.admin_produksi ?? []}
              defaultValue={defaultProduksi ?? ""}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: false }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton
              onClick={handleClose}
              warna={"clear"}
              widthbtn={"100%"}
            >
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              widthbtn={"100%"}
              disabled={watch("confirmation") ? false : true}
              type="submit"
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalChangePerson;
