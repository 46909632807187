import React from "react";
import { Divider, Grid, Paper, Stack } from "@mui/material";
import { NumericText, WaveChart } from "../../../../components/common";

const CardGrafikPencapaian = ({
  targetPencapaian,
  nominalJenisOrderHarian,
  qtyJenisOrderHarian,
  nominalSourceOrderHarian,
  qtySourceOrderHarian,
  tanggal,
  totalNominalJenisOrder,
  totalNominalSourceOrder,
  totalNotaJenisOrder,
  totalNotaSourceOrder,
}) => {
  return (
    <Stack spacing={2}>
      {/* lineGraph */}
      <WaveChart
        type={"area"}
        series={targetPencapaian}
        xaxis={tanggal}
        nominal
      />

      {/* Card Total Pesanan & Nominal*/}
      <Stack direction={"row"} spacing={2}>
        {/*Total Pesanan */}
        <Paper
          sx={{
            width: "50%",
            padding: 2,
            color: "#797979",
            borderBottom: "3px solid #787878",
            boxShadow: "1px 2px 4px 0px #00000040",
            backgroundColor: "#F2F2F2",
          }}
        >
          <Grid container justifyContent={"space-between"} fontWeight={700}>
            <Grid item>Total Pesanan</Grid>
            <Grid item>
              <NumericText
                value={totalNotaJenisOrder}
                thousandSeparator
                suffix={" Pesanan"}
                displayType="text"
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 1 }} />
          {qtyJenisOrderHarian.length > 0 &&
            qtyJenisOrderHarian.map((jenisOrder, index) => (
              <Grid key={index} container justifyContent={"space-between"}>
                <Grid item xs={5}>
                  {jenisOrder.name}
                </Grid>
                <Grid item xs={2} textAlign={"end"}>
                  <NumericText
                    value={jenisOrder.data.reduce((item, a) => item + a, 0)}
                    thousandSeparator
                    suffix={" Pcs"}
                    displayType="text"
                  />
                </Grid>
                <Grid item xs={5} fontWeight={700} textAlign={"end"}>
                  <NumericText
                    value={jenisOrder.nota.reduce((item, a) => item + a, 0)}
                    thousandSeparator
                    suffix={" Nota"}
                    displayType="text"
                  />
                </Grid>
              </Grid>
            ))}
        </Paper>

        {/* Total Nominal */}
        <Paper
          sx={{
            width: "50%",
            padding: 2,
            color: "#797979",
            borderBottom: "3px solid #787878",
            boxShadow: "1px 2px 4px 0px #00000040",
            backgroundColor: "#F2F2F2",
          }}
        >
          <Grid container justifyContent={"space-between"} fontWeight={700}>
            <Grid item>Total Nominal</Grid>
            <Grid item>
              <NumericText
                value={totalNominalJenisOrder}
                thousandSeparator
                prefix={true}
                displayType="text"
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 1 }} />
          {nominalJenisOrderHarian.length > 0 &&
            nominalJenisOrderHarian.map((jenisOrder, index) => (
              <Grid key={index} container justifyContent={"space-between"}>
                <Grid item>{jenisOrder.name}</Grid>
                <Grid item fontWeight={700}>
                  <NumericText
                    value={jenisOrder.data.reduce((item, a) => item + a, 0)}
                    thousandSeparator
                    prefix={true}
                    displayType="text"
                  />
                </Grid>
              </Grid>
            ))}
        </Paper>
      </Stack>

      <Paper elevation={0}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <WaveChart
              type={"line"}
              series={qtyJenisOrderHarian}
              width={250}
              title={"Total Pesanan"}
              xaxis={tanggal}
              showXaxis={false}
              showYaxis={false}
              pcs
            />
          </Grid>
          <Grid item xs={6}>
            <WaveChart
              type={"line"}
              series={nominalJenisOrderHarian}
              width={250}
              title={"Total Nominal"}
              xaxis={tanggal}
              showXaxis={false}
              showYaxis={false}
              nominal
            />
          </Grid>
        </Grid>
      </Paper>

      <Divider />
      <Stack direction={"row"} spacing={2}>
        {/*Total Pesanan */}
        <Paper
          sx={{
            width: "50%",
            padding: 2,
            color: "#797979",
            borderBottom: "3px solid #787878",
            boxShadow: "1px 2px 4px 0px #00000040",
            backgroundColor: "#F2F2F2",
          }}
        >
          <Grid container justifyContent={"space-between"} fontWeight={700}>
            <Grid item>Total Pesanan</Grid>
            <Grid item>
              <NumericText
                value={totalNotaSourceOrder}
                thousandSeparator
                suffix={" Pesanan"}
                displayType="text"
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 1 }} />
          {qtySourceOrderHarian.length > 0 &&
            qtySourceOrderHarian.map((sourceOrder, index) => (
              <Grid key={index} container justifyContent={"space-between"}>
                <Grid item xs={5}>
                  {sourceOrder.name}
                </Grid>
                <Grid item xs={2} textAlign={"end"}>
                  <NumericText
                    value={sourceOrder.data.reduce((item, a) => item + a, 0)}
                    thousandSeparator
                    suffix={" Pcs"}
                    displayType="text"
                  />
                </Grid>
                <Grid item xs={5} fontWeight={700} textAlign={"end"}>
                  <NumericText
                    value={sourceOrder.nota.reduce((item, a) => item + a, 0)}
                    thousandSeparator
                    suffix={" Nota"}
                    displayType="text"
                  />
                </Grid>
              </Grid>
            ))}
        </Paper>

        {/* Total Nominal */}
        <Paper
          sx={{
            width: "50%",
            padding: 2,
            color: "#797979",
            borderBottom: "3px solid #787878",
            boxShadow: "1px 2px 4px 0px #00000040",
            backgroundColor: "#F2F2F2",
          }}
        >
          <Grid container justifyContent={"space-between"} fontWeight={700}>
            <Grid item>Total Nominal</Grid>
            <Grid item>
              <NumericText
                value={totalNominalSourceOrder}
                thousandSeparator
                prefix={true}
                displayType="text"
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 1 }} />
          {nominalSourceOrderHarian.length > 0 &&
            nominalSourceOrderHarian.map((sourceOrder, index) => (
              <Grid key={index} container justifyContent={"space-between"}>
                <Grid item>{sourceOrder.name}</Grid>
                <Grid item fontWeight={700}>
                  <NumericText
                    value={sourceOrder.data.reduce((item, a) => item + a, 0)}
                    thousandSeparator
                    prefix={true}
                    displayType="text"
                  />
                </Grid>
              </Grid>
            ))}
        </Paper>
      </Stack>

      <Paper elevation={0}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <WaveChart
              type={"line"}
              color={["#3cbef1", "#d71149", "#ED5635", "#15D14A"]}
              series={qtySourceOrderHarian}
              width={250}
              title={"Total Pesanan"}
              xaxis={tanggal}
              showXaxis={false}
              showYaxis={false}
              pcs
            />
          </Grid>
          <Grid item xs={6}>
            <WaveChart
              type={"line"}
              color={["#3cbef1", "#d71149", "#ED5635", "#15D14A"]}
              series={nominalSourceOrderHarian}
              width={250}
              title={"Total Nominal"}
              xaxis={tanggal}
              showXaxis={false}
              showYaxis={false}
              nominal
            />
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default CardGrafikPencapaian;
