import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import {
  Payments as PaymentsIcon,
  Assignment as AntrianIcon,
  ShoppingCart as QtyIcon,
} from "@mui/icons-material";
import { NumericText, FooterCard } from "../../../../../components/common";

const FooterCardDikerjakan = ({ data, isLoading }) => {
  const [totalAntrian, setTotalAntrian] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [totalNominal, setTotalNominal] = useState(0);
  useEffect(() => {
    if (data) {
      setTotalAntrian(data.length);
      setTotalNominal(
        data.map((item) => item.total_pembelian).reduce((a, b) => a + b, 0)
      );
      setTotalQty(
        data.map((item) => item.total_qty).reduce((a, b) => a + b, 0)
      );
    }
  }, [data]);

  return (
    <Grid container direction="row" spacing={2} sx={{ marginTop: "0.1rem" }}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FooterCard
          title={"Total Antrian"}
          value={<NumericText value={totalAntrian} suffix={" Antrian"} />}
          isLoading={isLoading}
          iconCard={<AntrianIcon />}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FooterCard
          title={"Total Qty"}
          value={<NumericText value={totalQty} suffix={" Pcs"} />}
          isLoading={isLoading}
          iconCard={<QtyIcon />}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FooterCard
          title={"Total Nominal"}
          value={<NumericText value={totalNominal} prefix={true} />}
          isLoading={isLoading}
          iconCard={<PaymentsIcon />}
        />
      </Grid>
    </Grid>
  );
};

FooterCardDikerjakan.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default FooterCardDikerjakan;
