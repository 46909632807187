import React, { useCallback, useEffect } from "react";
import { BlankDialog } from "../../../../components/Layout/Content";
import { FormDetailOrder } from "../../../../components/Layout/Content/Antrian/EditAntrian";
import { Api, EventHandlers } from "../repository";
import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { ActionGrid } from "../../../../components/common";
import { CustomButton, SwitchInput } from "../../../../components/controls";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const ModalEditDetailOrder = (props) => {
  const { jenisOrder, sourceOrder, ekspedisi } = props;
  const dispatch = useDispatch();
  const formAntrian = useForm();
  const { socket } = useSelector((state) => state.data);
  const { editDetailOrder, tanggalRekapFilter } = useSelector(
    (state) => state.rekapNota
  );
  const { data, isLoading, isError } = Api.detailAntrianEdit({
    modal: editDetailOrder,
  });

  const handleClose = useCallback(() => {
    formAntrian.setValue("confirmation", false);
    formAntrian.setValue("prioritas", false);
    formAntrian.setValue("nama", "");
    formAntrian.setValue("no_hp", "");
    formAntrian.setValue("alamat", "");
    formAntrian.setValue("jenisorder_id", "");
    formAntrian.setValue("sourceorder_id", "");
    formAntrian.setValue("ekspedisi_id", "");
    formAntrian.setValue("keterangan_ekspedisi", "");
    formAntrian.setValue("keterangan", "");
    EventHandlers.handleEditDetailOrderModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  }, [dispatch, formAntrian]);

  useEffect(() => {
    if (isError) {
      handleClose();
    }
  }, [handleClose, isError]);

  const { mutate, isPending } = Api.updateDetail({
    dispatch,
    handleClose,
    form: formAntrian,
    socket,
    tanggal: tanggalRekapFilter,
  });

  const onSubmit = (values) => {
    const data = values;
    data["kecamatan_id"] = data["kecamatan_id"]["id"];
    mutate({ body: data, id: editDetailOrder.id });
  };

  return (
    <BlankDialog
      open={editDetailOrder.open}
      handleClose={handleClose}
      isLoading={isLoading || isPending}
      title={
        <>
          <SwitchInput
            name={"prioritas"}
            control={formAntrian.control}
            label={"Antrian Prioritas"}
            defaultValue={false}
            sx={{
              position: "absolute",
              left: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
          <Stack
            spacing={0.5}
            direction={"row"}
            justifyContent={"center"}
            sx={{ fontSize: "14px !important" }}
          >
            <Typography>{editDetailOrder.title}</Typography>
            <Typography fontWeight={700}>
              {isLoading ? (
                <Skeleton variant="text" width={40} />
              ) : (
                data?.data?.nota
              )}
            </Typography>
          </Stack>
        </>
      }
    >
      <form onSubmit={formAntrian.handleSubmit(onSubmit)}>
        <FormDetailOrder
          {...{
            formAntrian,
            jenisOrder,
            sourceOrder,
            ekspedisi,
            Api,
            data: data ? data.data : {},
            isLoading,
          }}
        />
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton onClick={handleClose} warna={"clear"}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              disabled={formAntrian.watch("confirmation") ? false : true}
              onClick={formAntrian.handleSubmit(onSubmit)}
            >
              Simpan
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalEditDetailOrder;
