import {
  SET_TANGGAL_RIWAYAT_BAYAR_FILTER,
  SET_HAPUS_RIWAYAT,
} from "../domain/riwayarBayarSlice";

export class EventHandlers {
  static handleFilterTanggal = ({ dispatch, tanggal }) => {
    dispatch(SET_TANGGAL_RIWAYAT_BAYAR_FILTER({ tanggal }));
  };
  static handleHapusRiwayat = ({ dispatch, modal }) => {
    dispatch(SET_HAPUS_RIWAYAT({ modal }));
  };
}
