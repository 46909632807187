import { createSlice } from "@reduxjs/toolkit";

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const cashflowBulanan = JSON.parse(localStorage.getItem("cashflowBulanan"));

const initialState = {
  bulan: currentMonth,
  tahun: currentYear,
  page: 0,
  pageSize: 31,
  filterModel: {
    items: [],
  },
  sortModel: [],
  columnModel: {},
  rowGroup: cashflowBulanan ?? [],
  aggregationModel: {
    jumlah_pembayaran: "sum",
    total_nominal: "sum",
  },
  pinnedModel: { left: [], right: [] },
};

const CashflowBulanan = createSlice({
  name: "cashflowBulanan",
  initialState,
  reducers: {
    SET_BULAN_CASHFLOW: (state, action) => {
      state.bulan = action.payload;
    },
    SET_TAHUN_CASHFLOW: (state, action) => {
      state.tahun = action.payload;
    },
    SET_PAGE: (state, action) => {
      state.page = action.payload.page;
    },
    SET_PAGE_SIZE: (state, action) => {
      state.pageSize = action.payload.pageSize;
    },
    SET_FILTER: (state, action) => {
      state.filterModel = action.payload.filter;
    },
    SET_SORT: (state, action) => {
      state.sortModel = action.payload.sort;
    },
    SET_COLUMN: (state, action) => {
      state.columnModel = action.payload.model;
    },
    SET_ROW_GROUP: (state, action) => {
      localStorage.setItem(
        "cashflowBulanan",
        JSON.stringify(action.payload.group)
      );
      state.rowGroup = action.payload.group;
    },
    SET_AGGREGATION: (state, action) => {
      state.aggregationModel = action.payload.aggregates;
    },
    SET_PINNED_COLUMN: (state, action) => {
      state.pinnedModel = action.payload.pinned;
    },
  },
});

export const {
  SET_BULAN_CASHFLOW,
  SET_TAHUN_CASHFLOW,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_FILTER,
  SET_SORT,
  SET_COLUMN,
  SET_ROW_GROUP,
  SET_AGGREGATION,
  SET_PINNED_COLUMN,
} = CashflowBulanan.actions;

export default CashflowBulanan.reducer;
