import React from "react";
import {
  Grid,
  Divider,
  Chip,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  RemoveCircle as RemoveCircleIcon,
} from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import {
  Input,
  MultipleSelect,
  handleFocus,
} from "../../../../../components/controls";
import { useFieldArray } from "react-hook-form";

const makeID = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const FormOrderDetail = (props) => {
  const { formAntrian, additional, keterangan } = props;
  const { fields } = useFieldArray({
    control: formAntrian.control,
    name: "size",
  });

  const addRowDetail = (orderIndex) => {
    const getSize = formAntrian.getValues().size;
    const updatedFields = [...getSize]; // Salin nilai fields ke dalam array baru
    const newDetailOrder = {
      id: makeID(10),
      keterangan: keterangan.map((e) => ({
        id: e.id,
        deskripsi: "",
        name: e.name,
      })),
      additional: [],
    };
    updatedFields[orderIndex].detail_order.push(newDetailOrder); // Tambahkan data baru ke detail_order
    formAntrian.setValue(`size`, updatedFields); // Atur kembali nilai fields
  };

  const hitungTotalOrderDanAdditional = () => {
    const activeSize = fields.filter((size) => size.isActive);
    const totalOrderSize = activeSize.reduce(
      (partialSize, size) =>
        partialSize + size.harga * size.detail_order.length,
      0
    );

    // Hitung total order tambahan
    const totalOrderAdditional = activeSize.reduce((partialOrder, size) => {
      const additionalTotal = size.detail_order.reduce(
        (partialAdditional, order) => {
          const additionalHarga = order.additional.reduce(
            (partialAdditional, addId) => {
              // Cari harga berdasarkan ID pada array additional
              const cariAdditional = additional.find(
                (item) => item.id === addId
              );
              const cariHargaAdditional = cariAdditional.harga.find(
                (item) =>
                  item.jenisorder_id ===
                  formAntrian.watch("antrian.jenisorder_id")
              );
              return (
                partialAdditional +
                (cariHargaAdditional ? cariHargaAdditional.harga : 0)
              ); // Tambahkan harga jika ditemukan
            },
            0
          );
          return partialAdditional + additionalHarga;
        },
        0
      );
      return partialOrder + additionalTotal;
    }, 0);

    // Total order keseluruhan
    const totalOrder = totalOrderSize + totalOrderAdditional;

    return totalOrder;
  };

  return (
    <>
      {fields.map((order, orderIndex) => (
        <div
          key={orderIndex}
          style={{
            margin: "0 0 2rem 0",
            display: order.isActive ? undefined : "none",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "end",
              margin: "0 0 0.3rem 0",
            }}
          >
            <Chip
              label={order.label}
              sx={{
                backgroundColor: "#105174",
                color: "#fff",
                fontWeight: 700,
                fontSize: "10px",
                borderRadius: "5px",
              }}
            />
            <Stack direction="row" alignItems={"center"} spacing={1}>
              <Typography
                sx={{ color: "#105174", fontWeight: 700, fontSize: "12px" }}
              >
                {formAntrian.watch(`size.${orderIndex}.detail_order`).length ||
                  0}{" "}
                Pcs
              </Typography>
              <IconButton
                aria-label="add"
                onClick={() => addRowDetail(orderIndex)}
                sx={{
                  backgroundColor: "#35AAFF",
                  color: "white",
                  borderRadius: "5px",
                  padding: "1px",
                  "&:hover": {
                    backgroundColor: "rgba(53, 170, 255, 0.8)",
                  },
                }}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Divider sx={{ margin: "0 0 1rem 0" }} />
          <DetailRowField
            orderIndex={orderIndex}
            additional={additional}
            control={formAntrian.control}
            register={formAntrian.register}
            keterangan={keterangan}
            watch={formAntrian.watch}
          />
        </div>
      ))}
      <Grid item xs={12}>
        <Divider sx={{ margin: "0 0 1rem 0" }}>
          <Chip
            label="Pembayaran"
            sx={{
              backgroundColor: "#008DD2",
              color: "#fff",
              fontWeight: 700,
              fontSize: "11px",
              padding: "0 1.5rem",
              letterSpacing: "1px",
            }}
          />
        </Divider>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#105174",
              color: "#fff",
              fontWeight: 700,
              fontSize: "10px",
              height: "100%",
              borderRadius: "5px",
            }}
          >
            <Typography>Total Order + Additional</Typography>
            <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
              <NumericFormat
                value={hitungTotalOrderDanAdditional()}
                thousandSeparator
                prefix="Rp. "
                displayType="text"
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Input
            rules={{ required: false }}
            name={"antrian.keterangan"}
            control={formAntrian.control}
            label={"Keterangan"}
            defaultValue={""}
            rows={3}
            multiline
            onFocus={handleFocus}
          />
        </Grid>
      </Grid>
    </>
  );
};

const DetailRowField = ({
  orderIndex,
  control,
  register,
  additional,
  watch,
}) => {
  const { remove } = useFieldArray({
    control,
    name: `size.${orderIndex}.detail_order`,
  });

  const removeRowDetail = (detailOrderIndex) => {
    remove(detailOrderIndex);
  };

  return (
    <Grid container spacing={2} key={orderIndex}>
      {watch(`size.${orderIndex}.detail_order`).map(
        (detailOrder, detailOrderIndex) => (
          <React.Fragment key={detailOrder.id}>
            {detailOrder.keterangan.map((keterangan, keteranganIndex) => (
              <Grid item xs={3} key={keteranganIndex}>
                <input
                  type="hidden"
                  {...register(
                    `size.${orderIndex}.detail_order.${detailOrderIndex}.keterangan.${keteranganIndex}.keterangan_id`
                  )}
                  value={keterangan.id}
                />

                <Input
                  rules={{ required: "" }}
                  name={`size.${orderIndex}.detail_order.${detailOrderIndex}.keterangan.${keteranganIndex}.deskripsi`}
                  control={control}
                  label={keterangan.name}
                  defaultValue={keterangan.deskripsi}
                />
              </Grid>
            ))}
            <Grid item xs={3}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <MultipleSelect
                  disabled={additional.length ? false : true}
                  name={`size.${orderIndex}.detail_order.${detailOrderIndex}.additional`}
                  control={control}
                  label={"Tambahan"}
                  options={
                    additional.length
                      ? additional.filter((additional) => additional.status)
                      : []
                  }
                  rules={{ required: false }}
                  defaultValue={detailOrder.additional}
                />
                <IconButton
                  aria-label="add"
                  onClick={() => removeRowDetail(detailOrderIndex)}
                  sx={{
                    color: "#ACAFB1",
                    padding: "1px",
                    "& .MuiSvgIcon-root": {
                      fontSize: 22,
                    },
                    "&:hover": {
                      color: "rgba(255, 0, 0, 0.8)",
                    },
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Stack>
            </Grid>
            {/* Panggil fungsi removeRowDetail */}
          </React.Fragment>
        )
      )}
    </Grid>
  );
};

export default FormOrderDetail;
