import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createUpdateModal: { id: null, open: false, title: null },
  detailAntrianGroup: { id: null, open: false, title: null },
  cardTotalAntrian: { id: null, open: false, title: null },
  addNotaGroup: { id: null, open: false, title: null },
  produksiGroup: { id: null, open: false, title: null },
  progressGroup: { id: null, open: false, title: null },
  selesaiGroup: { id: null, open: false, title: null },
  editGroup: { id: null, open: false, title: null },
  deleteGroup: { id: null, open: false, title: null },
};

const Group = createSlice({
  name: "group",
  initialState,
  reducers: {
    SET_CREATE_UPDATE_MODAL_GROUP: (state, action) => {
      state.createUpdateModal = action.payload.modal;
    },
    SET_DETAIL_ANTRIAN_GROUP: (state, action) => {
      state.detailAntrianGroup = action.payload.modal;
    },
    SET_CARD_TOTAL_ANTRIAN: (state, action) => {
      state.cardTotalAntrian = action.payload.modal;
    },
    SET_ADD_NOTA_GROUP: (state, action) => {
      state.addNotaGroup = action.payload.modal;
    },
    SET_PRODUKSI_GROUP: (state, action) => {
      state.produksiGroup = action.payload.modal;
    },
    SET_PROGRESS_GROUP: (state, action) => {
      state.progressGroup = action.payload.modal;
    },
    SET_SELESAI_GROUP: (state, action) => {
      state.selesaiGroup = action.payload.modal;
    },
    SET_EDIT_GROUP: (state, action) => {
      state.editGroup = action.payload.modal;
    },
    SET_DELETE_GROUP: (state, action) => {
      state.deleteGroup = action.payload.modal;
    },
  },
});

export const {
  SET_CREATE_UPDATE_MODAL_GROUP,
  SET_DETAIL_ANTRIAN_GROUP,
  SET_CARD_TOTAL_ANTRIAN,
  SET_ADD_NOTA_GROUP,
  SET_PRODUKSI_GROUP,
  SET_PROGRESS_GROUP,
  SET_SELESAI_GROUP,
  SET_EDIT_GROUP,
  SET_DELETE_GROUP,
} = Group.actions;

export default Group.reducer;
