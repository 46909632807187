import React from "react";
import { BlankDialog } from "../../../../components/Layout/Content";
import { useDispatch, useSelector } from "react-redux";
import { EventHandlers } from "../repository/EventHandlers";
import { useForm } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import CustomButton from "../../../../components/controls/CustomButton";
import { CheckboxInput, Input } from "../../../../components/controls";
import { Api } from "../repository";
import { ActionGrid } from "../../../../components/common";

const ModalAmbilAntrian = () => {
  const dispatch = useDispatch();
  const { ambilAntrianModal } = useSelector((state) => state.antrianNota);
  const { socket } = useSelector((state) => state.data);
  const { role } = useSelector((state) => state.auth);
  const formAmbilAntrian = useForm();

  const handleClose = () => {
    formAmbilAntrian.reset({ keterangan: "", confirmation: false });
    EventHandlers.handleAmbilAntrianModal({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const { mutate, isPending } = Api.ambilAntrian({
    handleClose,
    socket,
    dispatch,
  });

  const onSubmit = (values) => {
    mutate({ body: values, id: ambilAntrianModal.id });
  };

  return (
    <BlankDialog
      open={ambilAntrianModal.open}
      handleClose={handleClose}
      title={ambilAntrianModal.title}
      outsideClose={false}
      isLoading={isPending}
      maxWidth={"xs"}
    >
      <form onSubmit={formAmbilAntrian.handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Input
              name="keterangan"
              control={formAmbilAntrian.control}
              label="Keterangan"
              rules={{ required: false }}
              defaultValue=""
              rows={5}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"end"}
          alignItems={"center"}
          // marginTop={1}
          marginBottom={"-1rem"}
        >
          <CheckboxInput
            name={"confirmation"}
            control={formAmbilAntrian.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: "Konfirmasi untuk menyimpan" }}
            defaultValue={false}
          />
        </Grid>
        <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
        <ActionGrid
          isLoading={isPending}
          actionStart={
            <CustomButton
              onClick={handleClose}
              warna={"clear"}
              fullWidth
              disabled={isPending}
            >
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"next"}
              fullWidth
              disabled={formAmbilAntrian.watch("confirmation") ? false : true}
              type="submit"
            >
              {role === "desainer" ? "Desain" : "Produksi"}
            </CustomButton>
          }
        />
      </form>
    </BlankDialog>
  );
};

export default ModalAmbilAntrian;
