import PropTypes from "prop-types";
import { Box } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import DensitySubscriber from "./DensitySubscriber";

const Toolbar = (props) => {
  const { onDensityChange, showQuickFilter, customToolbar } = props;
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <DensitySubscriber onDensityChange={onDensityChange} />

      <Box sx={{ flexGrow: 1 }} />
      {customToolbar}
      {showQuickFilter && <GridToolbarQuickFilter />}
    </GridToolbarContainer>
  );
};

Toolbar.propTypes = {
  onDensityChange: PropTypes.func.isRequired,
  showQuickFilter: PropTypes.bool.isRequired,
};

export default Toolbar;
