import React from "react";
import { useSelector } from "react-redux";
import {
  TabelRekapReject,
  DetailAntrian,
  DetailProgress,
  DetailPembayaran,
  PrintAntrian,
  ModalEditReject,
} from "./components";
import { Api } from "./repository";
import { PrintReject } from "./components/PrintReject";

const RekapRejectPage = ({ componentRef }) => {
  const { tanggalRejectFilter } = useSelector((state) => state.rekapReject);

  const { data, isLoading } = Api.read({ tanggal: tanggalRejectFilter });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelRekapReject data={data ? data.data : []} isLoading={isLoading} />

      {/* POPUPS */}
      <ModalEditReject />

      <DetailAntrian />

      <DetailProgress />

      <DetailPembayaran />

      <PrintAntrian />

      <div style={{ display: "none" }}>
        <PrintReject
          data={data ? data.data : []}
          tanggal={tanggalRejectFilter}
          ref={componentRef}
        />
      </div>
    </>
  );
};

export default RekapRejectPage;
