import React from "react";
import { Divider, Grid, Paper } from "@mui/material";
import { DoneOutline as SimpanIcon } from "@mui/icons-material";
import {
  PengaturanProfile,
  PengaturanKodeNota,
  PengaturanSwitch,
} from "./components";
import { Api, useFormPengaturanToko } from "./repository";
import { CustomButton } from "../../../components/controls";
import { useSelector } from "react-redux";

const TokoPage = () => {
  const form = useFormPengaturanToko();
  const { socket } = useSelector((state) => state.data);
  const { data, isLoading } = Api.setting();
  const { mutate, isPending } = Api.updateSetting({ socket });

  const onSubmit = (value) => {
    mutate(value);
  };

  return (
    <Paper elevation={1} sx={{ padding: "1rem", color: "#797979" }}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {/* Pengaturan Profile */}
        <PengaturanProfile
          form={form}
          dataPengaturan={data ? data.data : []}
          isLoading={isLoading}
        />

        {/* Pengaturan Kode Nota */}
        <PengaturanKodeNota
          form={form}
          dataPengaturan={data ? data.data : []}
        />

        <PengaturanSwitch form={form} dataPengaturan={data ? data.data : []} />

        {/* Pengaturan Lisensi */}
        {/* <PengaturanLisensi form={form} dataPengaturan={data ? data.data : []} /> */}

        {/* Button Simpan */}
        <Grid item xs={12}>
          <Divider sx={{ marginY: "1rem" }} />
          <CustomButton
            loading={isPending}
            type="submit"
            variant="contained"
            startIcon={!isPending && <SimpanIcon />}
            disabled={false}
          >
            Simpan Pengaturan
          </CustomButton>
        </Grid>
      </form>
    </Paper>
  );
};

export default TokoPage;
