import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import {
  CheckboxInput,
  Input,
  SwitchInput,
  NumberInput,
  CustomButton,
} from "../../../controls";
import { ActionGrid } from "../../../common";

const SelesaiAntrianLayout = ({ form, onSubmit, handleClose, isPending }) => {
  const { role } = useSelector((state) => state.auth);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ paddingBottom: 1 }}>
        <Grid item xs={12}>
          <Input
            name="keterangan"
            control={form.control}
            label="Keterangan"
            rules={{ required: false }}
            defaultValue=""
            rows={5}
          />
        </Grid>
        {role === "admin_produksi" && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={3}>
              <SwitchInput
                name={`reject`}
                control={form.control}
                label={"Reject"}
                defaultValue={false}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                rules={{
                  required: form.watch(`reject`)
                    ? "Nominal Reject Harus di isi!"
                    : false,
                  min: {
                    value: form.watch(`reject`) ? 1 : 0,
                    message: "Harga Harus di isi!",
                  },
                }}
                name={`nominal_reject`}
                control={form.control}
                label={"Nominal"}
                defaultValue={0}
                disabled={!form.watch(`reject`)}
              />
            </Grid>

            <Grid item xs={5}>
              <Input
                rules={{
                  required: form.watch(`reject`)
                    ? "Keterangan Reject Harus di isi!!"
                    : false,
                }}
                name="keterangan_reject"
                control={form.control}
                label="Keterangan Reject"
                defaultValue=""
                disabled={!form.watch(`reject`)}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"end"}
        alignItems={"center"}
        marginBottom={"-1rem"}
      >
        <CheckboxInput
          name={"confirmation"}
          control={form.control}
          label={"Pastikan data sudah benar!"}
          rules={{ required: false }}
          defaultValue={false}
        />
      </Grid>
      <Divider sx={{ marginY: 2, marginX: "-1rem" }} />
      <ActionGrid
        isLoading={isPending}
        actionStart={
          <CustomButton onClick={handleClose} warna={"clear"}>
            Batal
          </CustomButton>
        }
        actionEnd={
          <CustomButton
            loading={isPending}
            warna={"next"}
            fullWidth
            disabled={form.watch("confirmation") ? false : true}
            type="submit"
          >
            Simpan
          </CustomButton>
        }
      />
    </form>
  );
};

SelesaiAntrianLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
};
export default SelesaiAntrianLayout;
