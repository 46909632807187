import { useQueryClient } from "@tanstack/react-query";

const useGetCachedData = ({ queryKey }) => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const query = queryCache.find({ queryKey: [queryKey] });
  return query.state.data;
};

export default useGetCachedData;
