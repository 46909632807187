import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Grid,
  Chip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddImageIcon from "@mui/icons-material/AddAPhoto";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import LoadingProgress from "../../../common/LoadingProgress";
import FileInput from "../../../controls/FileInput";
import CheckboxInput from "../../../controls/CheckboxInput";
import config from "../../../../config";

const UploadImageLayout = ({
  form,
  progress,
  gambar,
  onDeleteImage,
  isPending,
  required = "Gambar Wajib di isi!",
  path = "antrian",
  name = "gambar",
}) => {
  const handleRemoveImage = () => {
    form.setValue(name, undefined);
  };

  const fileInput = form.watch(name);
  const file = fileInput?.name;

  const fileName = file ?? "Max size 15MB";

  return (
    <>
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid
          container
          spacing={2}
          item
          xs={8.5}
          justifyContent={"space-between"}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <Button
              color="primary"
              aria-label="Upload *"
              component="label"
              startIcon={<AddImageIcon />}
              variant="outlined"
              sx={{ borderColor: "rgba(53, 170, 255, 1)" }}
              fullWidth
            >
              Upload Preview
              <FileInput
                name={`${name}`}
                control={form.control}
                rules={{
                  required: required,
                  max: 1,
                }}
                sx={{ display: "none" }}
                inputProps={{ accept: "image/*" }}
              />
            </Button>
          </Grid>
          {form.watch(`${name}`) || gambar ? (
            <Grid item>
              <IconButton
                disableRipple
                onClick={gambar ? onDeleteImage : handleRemoveImage}
                sx={{
                  border: "1px solid #FF0707",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              >
                {isPending ? (
                  <CircularProgress size={21} color="error" />
                ) : (
                  <DeleteIcon sx={{ color: "#FF0707" }} />
                )}
              </IconButton>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Chip
              sx={{
                width: "100%",
                borderRadius: "5px",
                boxShadow: "inset 0px 2px 4px 0px #00000040",
                color: form.formState.errors[name] && "#FF0707",
              }}
              label={
                form.formState.errors[name]
                  ? form.formState.errors[name].message
                  : fileName
              }
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingProgress
              barheight={10}
              barcolor={"#A5CFF5"}
              progress={progress}
              displayLabel={"none"}
            />
          </Grid>
        </Grid>
        <Grid item xs={3.5}>
          <Avatar
            variant="square"
            src={
              fileInput
                ? URL.createObjectURL(form.watch(`${name}`))
                : gambar
                ? `${config.apiUrl}/storage/${path}/${gambar}`
                : null
            }
            sx={{
              width: "100%",
              height: "110px",
              borderRadius: "5px",
              objectFit: "cover",
              display: "flex",
              flexDirection: "column",
              fontSize: "10px",
              border: fileInput || gambar ? "1px solid silver" : "none",
              boxShadow: gambar
                ? "0px 0px 1px 1px #00000040"
                : "0px 2px 4px 0px #00000040 inset",
            }}
          >
            {!gambar || !fileInput ? (
              <>
                <ImageOutlinedIcon
                  sx={{
                    width: "2.5em",
                    height: "2.5em",
                    marginBottom: "-.8rem",
                  }}
                />
                <p>Preview</p>
              </>
            ) : null}
          </Avatar>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"end"}
        alignItems={"center"}
        marginTop={1}
        marginBottom={"-1rem"}
      >
        <CheckboxInput
          name={"confirmation"}
          control={form.control}
          label={"Pastikan data sudah benar!"}
          rules={{ required: true }}
          defaultValue={false}
        />
      </Grid>
    </>
  );
};

UploadImageLayout.propTypes = {
  form: PropTypes.object,
  progress: PropTypes.number,
  gambar: PropTypes.string,
  onDeleteImage: PropTypes.func,
  isPending: PropTypes.bool,
};

export default UploadImageLayout;
