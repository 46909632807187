import dayjs from "dayjs";
import { Config } from ".";
import {
  useFetchingData,
  useDeletingData,
  useUpdatingData,
} from "../../../../hooks";
export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = ({ tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/${startDate}|${endDate}`,
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailReject = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/reject/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static printAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/print/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/progress/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailPembayaran = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/pembayaran/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static updatePembayaran = ({ id, onSuccess, tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `antrian/create/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/${startDate}|${endDate}`,
        `antrian/pembayaran/${id}`,
      ],
      onSuccess,
      onError: () => {
        return;
      },
    });
  };

  static deletePembayaran = ({ id, handleClose, tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useDeletingData({
      endpoint: `antrian/pembayaran`,
      invalidated: [
        `${Config.variable.endpoint}/${startDate}|${endDate}`,
        `antrian/pembayaran/${id}`,
      ],
      onSuccess: () => {
        handleClose();
      },
    });
  };

  static updateReject = ({ onSuccess, tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `antrian/reject`,
      invalidated: [`${Config.variable.endpoint}/${startDate}|${endDate}`],
      onSuccess,
      onError: () => {
        return;
      },
    });
  };
}
