import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tab: 0,
};

const Pendapatan = createSlice({
  name: "pendapatan",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload.tab;
    },
  },
});

export const { SET_TAB } = Pendapatan.actions;

export default Pendapatan.reducer;
