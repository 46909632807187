import React, { useEffect, useState } from "react";
import { Box, Collapse, Divider, Grid, Paper, Typography } from "@mui/material";
import {
  Input,
  handleFocus,
  checkDate,
  dateFormat,
  SwitchInput,
  NumberInput,
} from "../../../../controls";
import TabelAntrianGroup from "../TabelAntrianGroup";
import CollapseButton from "../../../../common/CollapseButton";

// column antrian
const totalAntrian = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    type: "actions",
    filterable: false,
    minWidth: 50,
    maxWidth: 50,
    display: "flex",
    renderHeader: () => <strong style={{ textAlign: "center" }}>No</strong>,
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <>
          <Typography
            sx={{
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
          </Typography>
        </>
      );
    },
  },
  {
    field: "nota",
    flex: 1,
    minWidth: 100,
    hideable: false,
    headerName: "No. Nota",
    display: "flex",
    renderHeader: () => <strong>No. Nota</strong>,
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <>
          <Typography
            sx={{
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
            {jarak.interval >= 2 && (
              <sup
                style={{
                  color: jarak.warna,
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {" "}
                {jarak.interval > 0 && jarak.interval}
              </sup>
            )}
          </Typography>
        </>
      );
    },
  },
  {
    field: "check_in",
    type: "date",
    flex: 1,
    minWidth: 100,
    headerName: "Check-In",
    display: "flex",
    valueGetter: (value) => new Date(value),
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      if (params.value instanceof Date && !isNaN(params.value)) {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {dateFormat(params.value)}
          </span>
        );
      }
      return "";
    },
  },
  {
    field: "nama",
    flex: 1,
    sortable: false,
    minWidth: 200,
    display: "flex",
    renderHeader: () => <strong>Leads / Nama</strong>,
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      return (
        <>
          <Typography
            sx={{
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
          </Typography>
        </>
      );
    },
  },
];

const ModalSelesaiGroup = ({ form, data }) => {
  const rowsAntrianData = data.list.map((row, index) => ({
    ...row,
    no: index + 1,
  }));

  const [reject, setReject] = useState(true);
  const handleReject = () => {
    setReject(!reject);
  };

  useEffect(() => {
    if (data.list.length > 0) {
      data.list.map((nota, index) =>
        form.setValue(
          `nota.${index}.reject`,
          nota.nominal_reject > 0 ? true : false
        )
      );
    }
  }, [data, form]);

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#E0F0F9",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid container item xs={7}>
            <Grid item xs={5} fontWeight={700}>
              No. Faktur
            </Grid>
            <Grid item xs={0.5} fontWeight={700}>
              :
            </Grid>
            <Grid item xs={6.5} fontWeight={700}>
              {data.nota}
            </Grid>
            <Grid item xs={5}>
              Total Antrian
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={6.5}>
              {data.antrian} Nota
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item xs={8.5}>
              Total Order
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.size} Pcs
            </Grid>
            <Grid item xs={8.5}>
              Total Tambahan
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.additional} Pcs
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* tabel data antrian */}
      <Grid container marginBottom={2}>
        <TabelAntrianGroup rows={rowsAntrianData} column={totalAntrian} />
      </Grid>
      <Grid container marginBottom={2}>
        <Input
          rules={{ required: false }}
          name={"keterangan"}
          control={form.control}
          label={"Keterangan"}
          defaultValue={""}
          multiline
          onFocus={handleFocus}
        />
      </Grid>

      <Divider sx={{ marginBottom: "1rem" }}>
        <CollapseButton
          onClick={handleReject}
          checked={reject}
          expandLabel="Reject"
          collapseLabel="Reject"
        />
      </Divider>
      <Collapse in={reject}>
        <Grid container alignItems={"center"} spacing={2}>
          {data.list.map((nota, index) => (
            <React.Fragment key={index}>
              <input
                type="hidden"
                {...form.register(`nota.${index}.id`, {
                  value: nota.id,
                })}
              />
              <Grid item xs={3} textAlign={"end"}>
                <SwitchInput
                  name={`nota.${index}.reject`}
                  control={form.control}
                  label={nota.nota}
                  sx={{ marginLeft: "0.5rem" }}
                  defaultValue={nota.nominal_reject > 0 ? true : false}
                />
              </Grid>
              <Grid item xs={3}>
                <NumberInput
                  rules={{
                    required: form.watch(`nota.${index}.reject`)
                      ? "Nominal Reject Harus di isi!"
                      : false,
                    min: {
                      value: form.watch(`nota.${index}.reject`) ? 1 : 0,
                      message: "Harga Harus di isi!",
                    },
                  }}
                  name={`nota.${index}.nominal`}
                  control={form.control}
                  label={"Nominal"}
                  defaultValue={nota.nominal_reject}
                  disabled={!form.watch(`nota.${index}.reject`)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rules={{
                    required: form.watch(`nota.${index}.reject`)
                      ? "Keterangan Wajib di isi!"
                      : false,
                  }}
                  name={`nota.${index}.keterangan`}
                  control={form.control}
                  label={"Keterangan"}
                  defaultValue={nota.keterangan_reject}
                  onFocus={handleFocus}
                  disabled={!form.watch(`nota.${index}.reject`)}
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default ModalSelesaiGroup;
