import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { styled, Stack, Button } from "@mui/material";
import ScanBarcode from "../Content/Scanner/ScanBarcode";
import { EventHandlers } from "../Content/Scanner/repository";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

const ScanButton = styled(Button)(({ theme }) => ({
  borderColor: "white",
  borderRadius: "5px",
  color: "white",
  "& .MuiSvgIcon-root": { color: "white" },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.04)",
    borderColor: "rgba(255, 255, 255, 0.8)",
    color: "rgba(255, 255, 255, 0.8)",
    "& .MuiSvgIcon-root": {
      color: "rgba(255, 255, 255, 0.8)",
    },
  },
}));

const Scanner = ({ onClickOrder, onClickGroup }) => {
  const form = useForm();
  const dispatch = useDispatch();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      marginRight="1rem"
      spacing={1}
    >
      <ScanButton
        variant="outlined"
        startIcon={<QrCodeScannerIcon />}
        onClick={() => {
          EventHandlers.handleScanOrderGroup({
            dispatch,
            modal: {
              id: null,
              open: true,
              title: "Scan !",
              ket: "Scan QR-Code Pada Nota Order !",
            },
          });
        }}
      >
        ORDER
      </ScanButton>
      <ScanButton
        variant="outlined"
        startIcon={<QrCodeScannerIcon />}
        onClick={() => {
          EventHandlers.handleScanOrderGroup({
            dispatch,
            modal: {
              id: null,
              open: true,
              title: "Scan !",
              ket: "Scan QR-Code Pada Faktur Group !",
            },
          });
        }}
      >
        GROUP
      </ScanButton>
      <ScanBarcode {...{ form }} />
    </Stack>
  );
};

export default Scanner;
