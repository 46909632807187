import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import {
  Input,
  handleFocus,
  checkDate,
  dateFormat,
  Select,
} from "../../../../controls";
import TabelAntrianGroup from "../TabelAntrianGroup";
import { useGetCachedData } from "../../../../../hooks";

// column antrian
const totalAntrian = [
  {
    field: "no",
    headerName: "No",
    flex: 1,
    type: "actions",
    filterable: false,
    minWidth: 50,
    maxWidth: 50,
    display: "flex",
    renderHeader: () => <strong style={{ textAlign: "center" }}>No</strong>,
    renderCell: (params) => (
      <Typography textAlign={"center"}>{params.value} </Typography>
    ),
  },
  {
    field: "nota",
    flex: 1,
    minWidth: 100,
    hideable: false,
    headerName: "No. Nota",
    display: "flex",
    renderHeader: () => <strong>No. Nota</strong>,
    renderCell: (params) => {
      const jarak = checkDate(params.row.checkin);
      return (
        <>
          <Typography
            sx={{
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
            {jarak.interval >= 2 && (
              <sup
                style={{
                  color: jarak.warna,
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {" "}
                {jarak.interval > 0 && jarak.interval}
              </sup>
            )}
          </Typography>
        </>
      );
    },
  },
  {
    field: "check_in",
    type: "date",
    flex: 1,
    minWidth: 100,
    headerName: "Check-In",
    display: "flex",
    valueGetter: (value) => new Date(value),
    renderCell: (params) => {
      const jarak = checkDate(params.row.check_in);
      if (params.value instanceof Date && !isNaN(params.value)) {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {dateFormat(params.value)}
          </span>
        );
      }
      return "";
    },
  },
  {
    field: "nama",
    flex: 1,
    sortable: false,
    minWidth: 200,
    display: "flex",
    renderHeader: () => <strong>Leads / Nama</strong>,
    renderCell: (params) => {
      const jarak = checkDate(params.row.checkin);
      return (
        <>
          <Typography
            sx={{
              ...(jarak.interval > 2 && {
                color: jarak.warna,
                ...(jarak.interval > 4 && { fontWeight: "bold" }),
              }),
            }}
          >
            {params.value}
          </Typography>
        </>
      );
    },
  },
];

const ModalProgressGroup = ({ form, data }) => {
  const rowsAntrianData = data.list.map((row, index) => ({
    ...row,
    no: index + 1,
  }));

  const getDataStatus = useGetCachedData({ queryKey: "status" });
  const [listStatus, setListStatus] = useState([]);

  useEffect(() => {
    if (getDataStatus) {
      setListStatus(
        getDataStatus.data.produksi
          .filter((status) => status.status)
          .map((status) => ({
            id: status.id,
            name: status.nama,
          }))
      );
    }
  }, [getDataStatus]);

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#E0F0F9",
          padding: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid container item xs={7}>
            <Grid item xs={5} fontWeight={700}>
              No. Faktur
            </Grid>
            <Grid item xs={0.5} fontWeight={700}>
              :
            </Grid>
            <Grid item xs={6.5} fontWeight={700}>
              {data.nota}
            </Grid>
            <Grid item xs={5}>
              Total Antrian
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={6.5}>
              {data.antrian} Nota
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item xs={8.5}>
              Total Order
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.size} Pcs
            </Grid>
            <Grid item xs={8.5}>
              Total Tambahan
            </Grid>
            <Grid item xs={0.5}>
              :
            </Grid>
            <Grid item xs={3}>
              {data.additional} Pcs
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {/* tabel data antrian */}
      <Grid container item marginBottom={2}>
        <TabelAntrianGroup rows={rowsAntrianData} column={totalAntrian} />
      </Grid>
      <Grid container marginBottom={2} spacing={2}>
        <Grid item xs={6}>
          <Chip
            label={data.status.nama}
            sx={{
              backgroundColor: data.status.warna,
              color: "white",
              borderRadius: "5px",
              fontWeight: 500,
              width: "100%",
              height: "100%",
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            disabled={listStatus.length ? false : true}
            name={"status_id"}
            control={form.control}
            label={"Status"}
            options={
              data.status.id
                ? listStatus.filter(
                    (status) => status.id !== parseInt(data.status.id)
                  )
                : listStatus
            }
            rules={{ required: "Status Harus Dipilih!" }}
            defaultValue={""}
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Input
          rules={{ required: false }}
          name={"keterangan"}
          control={form.control}
          label={"Keterangan"}
          defaultValue={""}
          multiline
          onFocus={handleFocus}
        />
      </Grid>
    </Box>
  );
};

export default ModalProgressGroup;
