import React from "react";
import { DeleteDialog } from "../../../../../components/Layout/Content";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers } from "../../repository";
import { Grid } from "@mui/material";
import { dateFormatter } from "../../../../../components/controls";
import { NumericText } from "../../../../../components/common";

const ModalDeletePembayaran = () => {
  const dispatch = useDispatch();

  const { tanggalRejectFilter, deletePembayaranModal, pembayaranModal } =
    useSelector((state) => state.rekapReject);

  const handleClose = () => {
    EventHandlers.handleDeletePembayaran({
      dispatch,
      modal: {
        id: null,
        open: false,
        title: null,
      },
    });
  };

  const { mutate, isPending } = Api.deletePembayaran({
    id: pembayaranModal.id,
    handleClose,
    tanggal: tanggalRejectFilter,
  });

  const onDelete = () => {
    mutate(deletePembayaranModal.id);
  };

  return (
    <DeleteDialog
      handleClose={handleClose}
      onDelete={onDelete}
      title={"Delete Pembayaran"}
      open={deletePembayaranModal.open}
      isLoading={isPending}
    >
      <Grid
        container
        marginTop={2}
        borderRadius="5px"
        sx={{ backgroundColor: "#F3F7F8", padding: "0.5rem" }}
      >
        <Grid item xs={3}>
          Tanggal Bayar
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          {deletePembayaranModal.title
            ? dateFormatter({
                date: new Date(deletePembayaranModal.title?.tanggal),
                withHari: true,
                withMonth: "simple",
                withTimes: true,
                yearLength: "full",
              })
            : "-"}
        </Grid>
        <Grid item xs={3}>
          Nominal
        </Grid>
        <Grid item xs={1}>
          :
        </Grid>
        <Grid item xs={8}>
          <NumericText
            value={deletePembayaranModal.title?.jumlah_bayar ?? 0}
            prefix={true}
          />
        </Grid>
      </Grid>
    </DeleteDialog>
  );
};

export default ModalDeletePembayaran;
