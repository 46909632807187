import React from "react";
import { Grid } from "@mui/material";
import { ColorInput, NumberInput } from "../../../../components/controls";

const FormStatusProgress = (props) => {
  const {
    form,
    Input,
    handleFocus,
    SwitchInput,
    statusDataDefault,
    statusDataProduksi,
    isLoading,
  } = props;

  const statusDefault = Array.from(new Set(statusDataDefault));
  const statusProduksi = Array.from(new Set(statusDataProduksi));
  const status = [...statusDefault, ...statusProduksi];

  return (
    <Grid container spacing={2} marginBottom={3} sx={{ color: "#797979" }}>
      {status.map((data, index) => (
        <React.Fragment key={index}>
          <input
            type="hidden"
            {...form.register(`status.${index}.id`, {
              valueAsNumber: true,
            })}
            value={data.id}
          />
          <Grid item xs={3} textAlign={"right"}>
            <SwitchInput
              name={`status.${index}.status`}
              control={form.control}
              label={`${index + 1}`}
              defaultValue={data.status}
              disabled={data.kunci || isLoading}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              rules={{
                required: form.watch(`status.${index}.status`) ? true : false,
              }}
              name={`status.${index}.name`}
              control={form.control}
              label={`Progress ${index + 1}`}
              onFocus={handleFocus}
              disabled={
                !form.watch(`status.${index}.status`, data.status) ||
                data.kunci ||
                isLoading
              }
              defaultValue={data.nama}
            />
          </Grid>
          <Grid item xs={3} textAlign={"right"}>
            <ColorInput
              name={`status.${index}.warna`}
              control={form.control}
              defaultValue={data ? data.warna : "#000000"}
              onFocus={handleFocus}
              label={`Warna ${index + 1}`}
              isDisabled={
                !form.watch(`status.${index}.status`, data.status) || isLoading
              }
            />
          </Grid>
          <Grid item xs={3}>
            <NumberInput
              rules={{
                required: form.watch(`status.${index}.status`) ? true : false,
                min: form.watch(`status.${index}.status`) ? 1 : 0,
              }}
              name={`status.${index}.progress`}
              control={form.control}
              label={`Persentase ${index + 1}`}
              disabled={
                !form.watch(`status.${index}.status`, data.status) || isLoading
              }
              defaultValue={data ? data.progress : ""}
              prefix={false}
              suffix={" %"}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default FormStatusProgress;
