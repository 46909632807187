import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Tabs, Tab, AppBar } from "@mui/material";
import Layout from "../layout/Layout";
import { EventHandlers as EventHandlersCheckoutPage } from "../features/checkout/page/repository";
import { TabPanel, a11yProps } from "../components/common";
import SiapKirimPage from "../features/checkout/siapkirim/SiapKirimPage";
import DikirimPage from "../features/checkout/dikirim/DikirimPage";

const Checkout = (props) => {
  const dispatch = useDispatch();

  const { tab } = useSelector((state) => state.checkout);
  const { setting } = useSelector((state) => state.data);

  // CHANGE TITLE
  React.useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Checkout`;
      } else {
        document.title = `Checkout`;
      }
    } else {
      document.title = "Checkout";
    }
  }, [setting]);

  return (
    <Layout>
      {/* Title Container */}
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent="end"
        alignItems="center"
      >
        <AppBar
          position="static"
          elevation={0}
          sx={{ backgroundColor: "#fff", width: "250px" }}
        >
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={(event, value) =>
              EventHandlersCheckoutPage.handleTab({ dispatch, tab: value })
            }
            centered
          >
            <Tab {...a11yProps(0)} label="SIAP KIRIM" />
            <Tab {...a11yProps(1)} label="DIKIRIM" />
          </Tabs>
        </AppBar>
      </Stack>

      {/* Content */}
      <TabPanel value={tab} index={0}>
        <SiapKirimPage />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <DikirimPage />
      </TabPanel>
    </Layout>
  );
};

export default Checkout;
