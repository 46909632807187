import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Stack, Tab, Tabs, Button, Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { TabPanel, a11yProps } from "../components/common";
import Layout from "../layout/Layout";
import { EventHandlers } from "../features/pendapatan/page/repository";
import { useFormPendapatanTahunan } from "../features/pendapatan/tahunan/repository";
import {
  EventHandlers as EventHandlersBulanan,
  useFormPendapatanBulanan,
} from "../features/pendapatan/bulanan/repository";
import { EventHandlers as EventHandlersTahunan } from "../features/pendapatan/tahunan/repository";
import { Select } from "../components/controls";
import { useReactToPrint } from "react-to-print";
import { Print as PrintIcon } from "@mui/icons-material";

import PendapatanBulananPage from "../features/pendapatan/bulanan/PendapatanBulananPage";
import TabelPendapatanTahunan from "../features/pendapatan/tahunan/PendapatanTahunanPage";
import { updateLoading } from "../features/themeSlice";

const Pendapatan = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const formBulanan = useFormPendapatanBulanan();
  const formTahunan = useFormPendapatanTahunan();
  const { tab } = useSelector((state) => state.pendapatan);
  const { bulan, tahun } = useSelector((state) => state.pendapatanBulanan);
  const { tahun: tahunanTahun } = useSelector(
    (state) => state.pendapatanTahunan
  );
  const { setting } = useSelector((state) => state.data);

  const { default_bulan, default_tahun } = useSelector((state) => state.data);

  const [isLoadingPB, setIsLoadingPB] = React.useState(false);
  const [isLoadingPT, setIsLoadingPT] = React.useState(false);

  //print
  const componentRefPB = React.useRef();
  const componentRefPT = React.useRef();

  const handlePrintPB = useReactToPrint({
    content: () => componentRefPB.current,
  });

  const handlePrintPT = useReactToPrint({
    content: () => componentRefPT.current,
  });

  // CHANGE TITLE
  React.useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Pendapatan`;
      } else {
        document.title = `Pendapatan`;
      }
    } else {
      document.title = "Pendapatan";
    }
  }, [setting]);

  const handleLoadingPrint = () => {
    setIsLoadingPB(true);
    setIsLoadingPT(true);
    setTimeout(() => {
      setIsLoadingPB(false);
      setIsLoadingPT(false);
    }, 1000);
  };

  React.useEffect(() => {
    dispatch(updateLoading(isLoadingPB, isLoadingPT));
  }, [dispatch, isLoadingPB, isLoadingPT]);

  return (
    <Layout>
      {/* Title Container */}
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {default_bulan.length > 0 && default_tahun.length > 0 && (
          <Grid
            container
            columnSpacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {tab === 0 && (
              <>
                <Grid item>
                  <Select
                    name={`bulanan.bulan`}
                    control={formBulanan.control}
                    label={""}
                    options={default_bulan}
                    rules={{ required: false }}
                    defaultValue={bulan}
                    size={"small"}
                    onChange={(_, value) => {
                      formBulanan.setValue(`bulanan.bulan`, value.props.value);
                      EventHandlersBulanan.handleBulan({
                        dispatch,
                        bulan: value.props.value,
                        oldBulan: bulan,
                        tahun: tahun,
                        queryClient,
                      });
                    }}
                    wave
                  />
                </Grid>
                <Grid item>
                  <Select
                    name={`bulanan.tahun`}
                    control={formBulanan.control}
                    label={""}
                    options={default_tahun}
                    rules={{ required: false }}
                    defaultValue={tahun}
                    size={"small"}
                    onChange={(_, value) => {
                      formBulanan.setValue(`bulanan.tahun`, value.props.value);
                      EventHandlersBulanan.handleTahun({
                        dispatch,
                        bulan: bulan,
                        oldTahun: tahun,
                        tahun: value.props.value,
                        queryClient,
                      });
                    }}
                    wave
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleLoadingPrint();
                      handlePrintPB();
                    }}
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    sx={{
                      padding: "0.45rem 1rem",
                      borderRadius: "5px",
                    }}
                  >
                    Print
                  </Button>
                </Grid>
              </>
            )}
            {tab === 1 && (
              <>
                <Grid item>
                  <Select
                    name={`tahunan.tahun`}
                    control={formTahunan.control}
                    label={""}
                    options={default_tahun}
                    rules={{ required: false }}
                    defaultValue={tahunanTahun}
                    size={"small"}
                    onChange={(_, value) => {
                      formTahunan.setValue(`tahunan.tahun`, value.props.value);
                      EventHandlersTahunan.handleTahun({
                        dispatch,
                        tahun: value.props.value,
                        oldTahun: tahunanTahun,
                        queryClient,
                      });
                    }}
                    wave
                  />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleLoadingPrint();
                      handlePrintPT();
                    }}
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    sx={{
                      padding: "0.45rem 1rem",
                      borderRadius: "5px",
                    }}
                  >
                    Print
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}

        <AppBar
          position="static"
          elevation={0}
          sx={{ backgroundColor: "#fff", width: "250px" }}
        >
          <Tabs
            variant="fullWidth"
            value={tab}
            centered
            onChange={(_, value) =>
              EventHandlers.handleTab({ dispatch, tab: value })
            }
          >
            <Tab {...a11yProps(0)} label="BULANAN" />
            <Tab {...a11yProps(1)} label="TAHUNAN" />
          </Tabs>
        </AppBar>
      </Stack>
      {/* Content */}
      <TabPanel value={tab} index={0}>
        <PendapatanBulananPage {...{ componentRefPB }} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <TabelPendapatanTahunan {...{ componentRefPT }} />
      </TabPanel>
    </Layout>
  );
};

export default Pendapatan;
