import { NumericText } from "../../../../components/common";
export class Config {
  static column = [
    {
      field: "target",
      flex: 1,
      minWidth: 150,
      headerName: "Target",
      groupable: false,
    },
    {
      field: "nominal",
      flex: 1,
      minWidth: 150,
      headerName: "Nominal",
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      headerName: "Opsi",
      disableClickEventBubbling: true,
      field: "actions",
      type: "actions",
      sortable: false,
      filterable: false,
      groupable: false,
      aggregable: false,
      pinnable: false,
      disableColumnMenu: true,
      disableColumnResize: true,
      disableColumnReorder: true,
      width: 90,
    },
  ];

  static variable = {
    nama: "Pengaturan Target",
    endpoint: "pengaturan/target",
  };
}
