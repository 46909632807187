import * as React from "react";
import {
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import User from "../../../assets/icon/profile.svg";
import {
  Logout as LogoutIcon,
  ErrorOutline as ErrorOutlineIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomButton from "../../controls/CustomButton";
import { ActionGrid } from "../../common";
import useLogout from "../../../hooks/useLogout";
import config from "../../../config";

const Profile = () => {
  const { loading } = useSelector((state) => state.theme);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const { name, usercode, role, photo } = useSelector((state) => state.auth);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { mutate: logoutMutate, isPending } = useLogout();

  return (
    <>
      <Tooltip title="Profile">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {photo ? (
            <Avatar
              alt={photo}
              src={config.apiUrl + "/storage/user/" + photo}
              sx={{
                objectFit: "cover",
                boxShadow: 3,
                border: "3px solid #fff",
              }}
            />
          ) : loading ? (
            <Skeleton
              sx={{ bgcolor: "grey.300" }}
              variant="circular"
              animation="wave"
            />
          ) : (
            <Avatar alt={photo} src={User} />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem sx={{ pointerEvents: "none", cursor: "default" }}>
          <ListItemIcon>
            <PersonIcon fontSize="medium" sx={{ color: "#2FA9EE" }} />
            <Typography color={"#2FA9EE"} fontWeight={700} noWrap>
              !
            </Typography>
          </ListItemIcon>
          <Typography color={"#797979"} fontWeight={700} noWrap>
            {name
              ? `${name.length > 25 ? name.substring(0, 25) + "..." : name}`
              : "-"}
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ pointerEvents: "none", cursor: "default", marginTop: -1.5 }}
        >
          <ListItemIcon>
            {/* <PersonIcon fontSize="medium" sx={{ color: "#2FA9EE" }} /> */}
          </ListItemIcon>
          <Typography color={"#797979"} fontSize={10} noWrap>
            {usercode ? usercode : "-"}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ marginX: 0.5, marginY: 0.3, borderColor: "#ABABAB" }}
            flexItem
          />
          <Typography color={"#797979"} fontSize={10} noWrap>
            {role ? role.toUpperCase().split("_").join(" ") : "-"}
          </Typography>
        </MenuItem>
        <Divider sx={{ borderColor: "#ABABAB" }} />
        <MenuItem
          onClick={() => setOpen(true)}
          disabled={loading}
          sx={{ marginY: -1 }}
        >
          <ListItemIcon>
            <LogoutIcon
              fontSize="medium"
              sx={{ color: "#FF0000", marginLeft: 0.5 }}
            />
          </ListItemIcon>
          <Typography color={"#797979"} noWrap>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
      <Dialog
        maxWidth={"xs"}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ alignSelf: "center" }}>
          Anda yakin akan Logout?
        </DialogTitle>
        <DialogContent sx={{ alignSelf: "center" }}>
          <ErrorOutlineIcon sx={{ fontSize: 100 }} color="warning" />
        </DialogContent>
        <Divider sx={{ borderColor: "#ABABAB" }} />
        <ActionGrid
          padding={"1rem"}
          isLoading={isPending}
          actionStart={
            <CustomButton warna={"next"} onClick={() => setOpen(false)}>
              Batal
            </CustomButton>
          }
          actionEnd={
            <CustomButton
              loading={isPending}
              warna={"clear"}
              onClick={() => logoutMutate()}
              autoFocus
              disabled={false}
            >
              Logout
            </CustomButton>
          }
        />
      </Dialog>
    </>
  );
};

export default Profile;
