import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info as InfoIcon,
  Print as PrintIcon,
  PendingActions as ProgressIcon,
  Photo as PhotoIcon,
  FileUpload as FileUploadIcon,
} from "@mui/icons-material";
import { EventHandlers } from "../../repository";

class ActionButton {
  static getActionOpsi = ({ params, dispatch }) => {
    const commonActions = [
      <GridActionsCellItem
        icon={<InfoIcon sx={{ color: "#2FA9EE" }} />}
        label="Detail"
        onClick={() =>
          EventHandlers.handleDetailModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<PrintIcon sx={{ color: "#2FA9EE" }} />}
        label="Print"
        onClick={() =>
          EventHandlers.handlePrintModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Print Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ProgressIcon sx={{ color: "#24BC85" }} />}
        label="Progress"
        onClick={() => {
          EventHandlers.handleDetailProgress({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Progress",
            },
          });
        }}
        showInMenu
      />,
    ];

    return commonActions;
  };

  static getActionPreview = ({ params, dispatch }) => {
    return [
      <GridActionsCellItem
        icon={<PhotoIcon />}
        label="Preview"
        onClick={() =>
          EventHandlers.handlePreview({
            dispatch,
            file: params.row.gambar,
            title: `Detail Gambar Nota : ${params.row.nota}`,
          })
        }
        sx={{
          "& svg": {
            fontSize: "22px",
          },
          "&:hover svg": {
            color: "#2FA9EE",
          },
        }}
        disabled={params.row.gambar ? false : true}
      />,
    ];
  };
}

export default ActionButton;
