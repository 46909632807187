import React from "react";
import { useSelector } from "react-redux";
import {
  TabelRetur,
  DetailAntrian,
  DetailProgress,
  ModalCreateUpdateRetur,
  ModalChangeKirim,
  ModalHapusAntrian,
  ModalChangePerson,
  DetailPembayaran,
  PrintAntrian,
} from "./components";
import { Api } from "./repository";
import { PrintRetur } from "./components/PrintRetur";
import ModalEditDetailOrder from "./components/ModalEditDetailOrder";
import ModalEditSizeQty from "./components/ModalEditSizeQty";
import { useGetCachedData } from "../../hooks";

const ReturPage = ({ componentRefRetur }) => {
  const { tanggalReturFilter } = useSelector((state) => state.retur);

  const getReturData = Api.read({
    startDate: tanggalReturFilter[0],
    endDate: tanggalReturFilter[1],
  });

  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });
  const getDataEkspedisi = useGetCachedData({ queryKey: "ekspedisi" });
  const getDataSize = useGetCachedData({ queryKey: "size" });
  const getDataAdditional = useGetCachedData({ queryKey: "additional" });
  const getDataKeterangan = useGetCachedData({ queryKey: "keterangan" });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelRetur
        data={getReturData.data ? getReturData.data.data : []}
        isLoading={getReturData.isLoading}
      />

      {/* Modal Popup */}
      <ModalCreateUpdateRetur />

      <ModalChangeKirim />

      <ModalHapusAntrian />

      <ModalChangePerson />

      <DetailAntrian />

      <DetailProgress />

      <DetailPembayaran />

      <PrintAntrian />

      <div style={{ display: "none" }}>
        <PrintRetur
          ref={componentRefRetur}
          data={getReturData.data ? getReturData.data.data : []}
          tanggal={tanggalReturFilter}
        />
      </div>

      {/* editAntrian */}
      <ModalEditDetailOrder
        {...{
          sourceOrder: getDataSourceOrder ? getDataSourceOrder.data : [],
          jenisOrder: getDataJenisOrder ? getDataJenisOrder.data : [],
          ekspedisi: getDataEkspedisi ? getDataEkspedisi.data : [],
        }}
      />
      <ModalEditSizeQty
        {...{
          size: getDataSize ? getDataSize.data : [],
          additional: getDataAdditional ? getDataAdditional.data : [],
          keterangan: getDataKeterangan ? getDataKeterangan.data : [],
        }}
      />
    </>
  );
};

export default ReturPage;
