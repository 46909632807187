import React from "react";
import { Grid } from "@mui/material";

const FormExpedisi = (props) => {
  const { form, Input, handleFocus, SwitchInput, expedisiData, isLoading } =
    props;
  const expedisi = Array.from(new Set(expedisiData));

  const midPoint = Math.ceil(expedisi.length / 2);

  return (
    <Grid container spacing={2} marginBottom={3} sx={{ color: "#797979" }}>
      {/* input 1-5 */}
      <Grid container item xs={6} spacing={2}>
        {expedisi.slice(0, midPoint).map((exp, index) => (
          <React.Fragment key={index}>
            <input
              type="hidden"
              {...form.register(`ekspedisi.${index}.id`, {
                valueAsNumber: true,
              })}
              value={exp.id}
            />
            <Grid item xs={6} textAlign="right">
              <SwitchInput
                name={`ekspedisi.${index}.status`}
                control={form.control}
                label={`${index + 1}`}
                defaultValue={exp ? exp.status : false}
                disabled={exp.tipe === "offline" ? true : false || isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                rules={{ required: false }}
                name={`ekspedisi.${index}.name`}
                control={form.control}
                label={`Ekspedisi ${index + 1}`}
                onFocus={handleFocus}
                disabled={
                  !form.watch(`ekspedisi.${index}.status`, exp.status) ||
                  isLoading
                }
                defaultValue={exp ? exp.name : ""}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {/* input 6-10 */}
      <Grid container item xs={6} spacing={2}>
        {expedisi.slice(midPoint).map((exp, index) => {
          const adjustedIndex = index + midPoint;
          return (
            <React.Fragment key={adjustedIndex}>
              <input
                type="hidden"
                {...form.register(`ekspedisi.${adjustedIndex}.id`, {
                  valueAsNumber: true,
                })}
                value={exp.id}
              />
              <Grid item xs={6} textAlign="right">
                <SwitchInput
                  name={`ekspedisi.${adjustedIndex}.status`}
                  control={form.control}
                  label={`${adjustedIndex + 1}`}
                  defaultValue={exp ? exp.status : false}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  rules={{ required: false }}
                  name={`ekspedisi.${adjustedIndex}.name`}
                  control={form.control}
                  label={`Ekspedisi ${adjustedIndex + 1}`}
                  onFocus={handleFocus}
                  disabled={
                    !form.watch(
                      `ekspedisi.${adjustedIndex}.status`,
                      exp.status
                    ) || isLoading
                  }
                  defaultValue={exp ? exp.name : ""}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FormExpedisi;
