import React from "react";
import { WaveChart } from "../../../../components/common";

const ChartPencapaianSourceOrder = ({
  toggleView,
  tab,
  nominalSourceOrderHarian,
  nominalSourceOrderBulan,
  nominalSourceOrderTahun,
  qtySourceOrderHarian,
  qtySourceOrderBulan,
  qtySourceOrderTahun,
  tanggal,
  bulan,
  tahun,
}) => {
  return (
    <WaveChart
      key={toggleView}
      type={"bar"}
      stacked={true}
      series={
        toggleView === false && tab === 0
          ? nominalSourceOrderHarian
          : toggleView === true && tab === 0
          ? qtySourceOrderHarian
          : toggleView === false && tab === 1
          ? nominalSourceOrderBulan
          : toggleView === true && tab === 1
          ? qtySourceOrderBulan
          : toggleView === false && tab === 2
          ? nominalSourceOrderTahun
          : toggleView === true && tab === 2
          ? qtySourceOrderTahun
          : null
      }
      color={["#3cbef1", "#d71149", "#ED5635", "#15D14A"]}
      xaxis={tab === 0 ? tanggal : tab === 1 ? bulan : tab === 2 ? tahun : null}
      nominal={toggleView === false && true}
      pcs={toggleView === true && true}
    />
  );
};

export default ChartPencapaianSourceOrder;
