import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  SET_DEFAULT_BULAN,
  SET_DEFAULT_TAHUN,
} from "../features/app/domain/dataSlice";

const useBasicData = () => {
  const dispatch = useDispatch();

  const fetchDataConfig = async () => {
    try {
      const response = await fetch("data.json");
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching basic data:", error);
      return null;
    }
  };

  const getBasicData = useCallback(async () => {
    const data = await fetchDataConfig();

    if (data) {
      const { bulan, tahun } = data;
      if (bulan) {
        dispatch(SET_DEFAULT_BULAN(bulan));
      }

      if (tahun) {
        dispatch(SET_DEFAULT_TAHUN(tahun));
      }
    } else {
      console.error("Data dan tahun tidak ditemukkan!");
    }
  }, [dispatch]);

  const connectBasicData = () => {
    getBasicData();
  };

  return {
    connectBasicData,
  };
};

export default useBasicData;
