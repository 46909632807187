import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import FormCashLess from "../../../../../components/common/FormCashLess";
import {
  CheckboxInput,
  Input,
  handleFocus,
} from "../../../../../components/controls";

const inputItems = [
  { label: "Nama", property: "nama" },
  { label: "No. HP", property: "no_hp" },
  { label: "Jenis Order", property: "jenis_order" },
  { label: "Source Order", property: "source_order" },
  { label: "Alamat", property: "alamat" },
  { label: "", property: "alamat_detail" },
];

const inputTotal = [
  { label: "Total Order", property: "totalOrder" },
  { label: "Total Nominal", property: "totalNominal" },
  { label: "Ekspedisi", property: "ekspedisi" },
];

const FormConfirmation = (props) => {
  const {
    formAntrian,
    ekspedisi,
    jenisOrder,
    sourceOrder,
    metodePembayaran,
    additional,
  } = props;
  const [data, setData] = useState({
    nama: "",
    no_hp: "",
    jenis_order: "",
    source_order: "",
    alamat: "",
    alamat_detail: "",
    totalOrder: 0,
    totalNominal: 0,
    ekspedisi: "",
  });

  const [totalNominal, setTotalNominal] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);

  useEffect(() => {
    const size = formAntrian.getValues("size");
    if (size) {
      const activeSize = size.filter((size) => size.isActive);
      const totalOrderSize = activeSize.reduce(
        (partialSize, size) =>
          partialSize + size.harga * size.detail_order.length,
        0
      );

      // Hitung total order tambahan
      const totalOrderAdditional = activeSize.reduce((partialOrder, size) => {
        const additionalTotal = size.detail_order.reduce(
          (partialAdditional, order) => {
            const additionalHarga = order.additional.reduce(
              (partialAdditional, addId) => {
                // Cari harga berdasarkan ID pada array additional
                const cariAdditional = additional.find(
                  (item) => item.id === addId
                );
                const cariHargaAdditional = cariAdditional.harga.find(
                  (item) =>
                    item.jenisorder_id ===
                    formAntrian.watch("antrian.jenisorder_id")
                );
                return (
                  partialAdditional +
                  (cariHargaAdditional ? cariHargaAdditional.harga : 0)
                ); // Tambahkan harga jika ditemukan
              },
              0
            );
            return partialAdditional + additionalHarga;
          },
          0
        );
        return partialOrder + additionalTotal;
      }, 0);

      const hitungTotalOrder = size
        .filter((e) => e.isActive)
        .reduce((totalOrder, item) => totalOrder + item.detail_order.length, 0);
      setTotalNominal(totalOrderSize + totalOrderAdditional);
      setTotalOrder(hitungTotalOrder);
    }
  }, [additional, formAntrian]);

  useEffect(() => {
    const data = formAntrian.getValues();
    let ekspedisiFilter = "";
    let jenisOrderFilter = "";
    let sourceOrderFilter = "";
    if (data.antrian.ekspedisi_id) {
      const filter = ekspedisi.find((e) => e.id === data.antrian.ekspedisi_id);
      ekspedisiFilter = filter.name;
    }

    if (data.antrian.jenisorder_id) {
      const filter = jenisOrder.find(
        (e) => e.id === data.antrian.jenisorder_id
      );
      jenisOrderFilter = filter.name;
    }

    if (data.antrian.sourceorder_id) {
      const filter = sourceOrder.find(
        (e) => e.id === data.antrian.sourceorder_id
      );
      sourceOrderFilter = filter.name;
    }
    setData({
      nama: data.detail.nama,
      no_hp: data.detail.no_hp,
      jenis_order: jenisOrderFilter,
      source_order: sourceOrderFilter,
      alamat: data.detail.kecamatan_id
        ? `${data.detail.kecamatan_id.label}, ${data.detail.kecamatan_id.kabupaten}, ${data.detail.kecamatan_id.provinsi}`
        : "-",
      alamat_detail: data.detail.alamat ?? "-",
      totalOrder: totalOrder,
      totalNominal: totalNominal,
      ekspedisi: ekspedisiFilter,
    });
  }, [
    formAntrian,
    ekspedisi,
    jenisOrder,
    sourceOrder,
    totalOrder,
    totalNominal,
  ]);

  return (
    <>
      <Grid
        container
        marginTop={"-0.5rem"}
        paddingY={"1rem"}
        paddingX={"1.5rem"}
        borderRadius={"5px"}
        sx={{
          justifyContent: "space-between",
          fontSize: "12px",
          backgroundColor: "#F3F7F8",
          color: "#797979",
          fontWeight: "400",
        }}
      >
        {inputItems.map((item, index) => (
          <Grid
            key={index}
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              {item.property === "nama" ? <b>{item.label}</b> : item.label}
            </Grid>
            <Grid item xs={0.2}>
              {item.property === "alamat_detail" ? null : ":"}
            </Grid>
            <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
              {item.property === "nama" ? (
                <span>
                  <b>
                    {data[item.property].length > 15
                      ? `${data[item.property].slice(0, 15)}...`
                      : data[item.property]}
                  </b>
                </span>
              ) : item.property === "no_hp" ? (
                <span>
                  {data[item.property].replace(
                    /(\d{4})(\d{4})(\d{4})/,
                    "$1 $2 $3"
                  )}
                </span>
              ) : item.property === "jenis_order" ||
                item.property === "source_order" ||
                item.property === "alamat" ? (
                <b>{data[item.property]}</b>
              ) : item.property === "alamat_detail" ? (
                <>
                  <span style={{ fontSize: "10px" }}>
                    {data[item.property]}
                  </span>
                </>
              ) : (
                data[item.property]
              )}
              {/* <Skeleton variant="rectangular" /> */}
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12} paddingY={1.5}>
          <Divider />
        </Grid>
        {inputTotal.map((item, index) => (
          <Grid
            key={index}
            container
            item
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              {item.label}
            </Grid>
            <Grid item xs={0.2}>
              {":"}
            </Grid>
            <Grid item xs={8.5} fontWeight={700} sx={{ paddingRight: "15px" }}>
              {item.property === "totalOrder"
                ? `${data[item.property]} Pcs`
                : item.property === "totalNominal"
                ? `Rp ${new Intl.NumberFormat("id-ID").format(
                    data[item.property]
                  )}`
                : data[item.property]}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <Divider />
      </Grid>
      <FormCashLess
        {...{
          formAntrian,
          metodePembayaran,
          total: { pembayaran: 0, pembelian: totalNominal },
        }}
      />
      <Grid item xs={12} paddingBottom={2}>
        <Divider />
      </Grid>
      <Grid container paddingBottom={0}>
        <Grid container item paddingBottom={2}>
          <Input
            rules={{ required: false }}
            name={"progress.keterangan"}
            control={formAntrian.control}
            label={"Keterangan Progress"}
            defaultValue={""}
            multiline
            onFocus={handleFocus}
          />
        </Grid>
        <Grid container item justifyContent={"end"} marginBottom={"-1rem"}>
          <CheckboxInput
            name={"confirmation"}
            control={formAntrian.control}
            label={"Pastikan data sudah benar!"}
            rules={{ required: true }}
            defaultValue={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormConfirmation;
