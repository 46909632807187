import { NumericText } from "../../../../components/common";

export class Config {
  static column = [
    {
      field: "id",
      flex: 1,
      minWidth: 70,
      width: 70,
      maxWidth: 70,
      align: "center",
      headerAlign: "center",
      // headerName: "",
      headerName: "Tanggal",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value ?? "Total";
      },
    },
    {
      field: "nota",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      // headerName: "",
      headerName: "Nota Order",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={false}
              suffix={" Nota"}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "jumlah_order",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      // headerName: "",
      headerName: "Jumlah Order",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={false}
              suffix={" Pcs"}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "brutto_jual",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",
      headerName: "Jual",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.brutto?.harga_jual ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FD8900",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "brutto_hpp",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",
      headerName: "Hpp",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.brutto?.hpp ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FD8900",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "retur_jual",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",
      headerName: "Jual",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.retur?.harga_jual ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FA0000",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "retur_hpp",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",
      headerName: "Hpp",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.retur?.hpp ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FA0000",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "netto_jual",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",
      headerName: "Jual",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.netto?.harga_jual ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#0067E0",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "netto_hpp",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "center",
      headerName: "Hpp",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.netto?.hpp ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#0067E0",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "gross_profit",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      // headerName: "",
      headerName: "Gross Profit",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#0067E0",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "reject",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      // headerName: "",
      headerName: "Reject",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FA0000",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "profit",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      // headerName: "",
      headerName: "Profit",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#009D3F",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "profit_percent",
      type: "number",
      flex: 1,
      minWidth: 50,
      width: 70,
      align: "center",
      headerAlign: "center",
      // headerName: "",
      headerName: "%",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#009D3F",
            }}
          >
            <NumericText
              value={params.value}
              decimalSeparator
              decimalScale={2}
              prefix={false}
              suffix={" %"}
              displayType="text"
            />
          </span>
        );
      },
    },
  ];

  static columnCS = [
    {
      field: "id",
      flex: 1,
      minWidth: 70,
      width: 70,
      maxWidth: 70,
      align: "center",
      headerAlign: "center",
      headerName: "Tanggal",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value ?? "Total";
      },
    },
    {
      field: "nota",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Nota Order",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={false}
              suffix={" Nota"}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "jumlah_order",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Jumlah Order",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={false}
              suffix={" Pcs"}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "brutto_jual",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Bruto",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.brutto?.harga_jual ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FD8900",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "retur_jual",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Retur",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.retur?.harga_jual ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FA0000",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "netto_jual",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Netto (Omset)",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      valueGetter: (_, row) => {
        return row?.netto?.harga_jual ?? null;
      },
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#0067E0",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
    {
      field: "reject",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "right",
      headerAlign: "right",
      headerName: "Reject",
      sortable: false,
      filterable: false,
      groupable: false,
      pinnable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#FA0000",
            }}
          >
            <NumericText
              value={params.value}
              thousandSeparator
              prefix={true}
              displayType="text"
            />
          </span>
        );
      },
    },
  ];

  static variable = {
    nama: "Pendapatan",
    endpoint: "pendapatan/bulanan",
  };
}
