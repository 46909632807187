import React, { useEffect, useState } from "react";
import { CardGrafikPencapaian, FilterBulanTahun } from "./components";
import { Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Api, EventHandlers, useFormGroupPencapaian } from "./repository";
import { Speed as SpeedIcon } from "@mui/icons-material";
import {
  NumericText,
  LoadingProgress,
  CollapseButton,
} from "../../../components/common";

const CardGroupPencapaian = () => {
  const dispatch = useDispatch();
  const form = useFormGroupPencapaian();
  const { collapse, bulan, tahun } = useSelector(
    (state) => state.dashboardGroupPencapaian
  );

  const [category, setCategory] = useState([]);
  const [pencapaian, setPencapaian] = useState([]);
  const [jenisOrderNominal, setJenisOrderNominal] = useState([]);
  const [jenisOrderQty, setJenisOrderQty] = useState([]);
  const [sourceOrderNominal, setSourceOrderNominal] = useState([]);
  const [sourceOrderQty, setSourceOrderQty] = useState([]);
  const [totalData, setTotalData] = useState({
    target: 0,
    pembelian: 0,
    qty: 0,
    nota: 0,
    pencapaian: 0,
  });
  const [totalNominalJenisOrder, seTtotalNominalJenisOrder] = useState(0);
  const [totalNotaJenisOrder, seTtotalNotaJenisOrder] = useState(0);
  const [totalNominalSourceOrder, seTtotalNominalSourceOrder] = useState(0);
  const [totalNotaSourceOrder, seTtotalNotaSourceOrder] = useState(0);

  const { data: dataBusinessProgress } = Api.businessProgress({
    bulan: bulan,
    tahun: tahun,
  });

  useEffect(() => {
    if (dataBusinessProgress) {
      const newTotalNominalJenisOrder =
        dataBusinessProgress.data.jenis_order.series.reduce(
          (accumulator, currentValue) => {
            const sum = currentValue.nominal.reduce((acc, val) => acc + val, 0);
            return accumulator + sum;
          },
          0
        );

      const newTotalNotaJenisOrder =
        dataBusinessProgress.data.jenis_order.series.reduce(
          (accumulator, currentValue) => {
            const sum = currentValue.nota.reduce((acc, val) => acc + val, 0);
            return accumulator + sum;
          },
          0
        );
      const newJenisOrderNominal =
        dataBusinessProgress.data.jenis_order.series.map((e) => {
          return {
            name: e.name,
            data: e.nominal,
          };
        });
      const newJenisOrderQty = dataBusinessProgress.data.jenis_order.series.map(
        (e) => {
          return {
            name: e.name,
            data: e.qty,
            nota: e.nota,
          };
        }
      );

      const newTotalNominalSourceOrder =
        dataBusinessProgress.data.jenis_order.series.reduce(
          (accumulator, currentValue) => {
            const sum = currentValue.nominal.reduce((acc, val) => acc + val, 0);
            return accumulator + sum;
          },
          0
        );

      const newTotalNotaSourceOrder =
        dataBusinessProgress.data.jenis_order.series.reduce(
          (accumulator, currentValue) => {
            const sum = currentValue.nota.reduce((acc, val) => acc + val, 0);
            return accumulator + sum;
          },
          0
        );

      const newSourceOrderNominal =
        dataBusinessProgress.data.source_order.series.map((e) => {
          return {
            name: e.name,
            data: e.nominal,
          };
        });
      const newSourceOrderQty =
        dataBusinessProgress.data.source_order.series.map((e) => {
          return {
            name: e.name,
            data: e.qty,
            nota: e.nota,
          };
        });

      setCategory(dataBusinessProgress.data.category);
      setPencapaian(dataBusinessProgress.data.pencapaian.series);
      setJenisOrderNominal(newJenisOrderNominal);
      setJenisOrderQty(newJenisOrderQty);
      setSourceOrderNominal(newSourceOrderNominal);
      setSourceOrderQty(newSourceOrderQty);
      setTotalData({
        target: dataBusinessProgress.data.total.target,
        pembelian: dataBusinessProgress.data.total.pembelian,
        qty: dataBusinessProgress.data.total.qty,
        nota: dataBusinessProgress.data.total.nota,
        pencapaian: dataBusinessProgress.data.total.pencapaian,
      });
      seTtotalNominalJenisOrder(newTotalNominalJenisOrder);
      seTtotalNotaJenisOrder(newTotalNotaJenisOrder);
      seTtotalNominalSourceOrder(newTotalNominalSourceOrder);
      seTtotalNotaSourceOrder(newTotalNotaSourceOrder);
    }
  }, [dataBusinessProgress]);

  return (
    <Paper sx={{ padding: 2 }}>
      <Stack spacing={2}>
        {/* Filter Harian */}
        <Grid container justifyContent={"space-between"}>
          <Grid item alignSelf={"center"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <SpeedIcon sx={{ fontSize: 25, color: "#FF650E" }} />
              <Typography fontSize={14} fontWeight={700} color={"#797979"}>
                Bussines Progress
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <FilterBulanTahun form={form} />
            </Grid>
          </Grid>
        </Grid>

        {/* Pencapaian */}
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Stack direction={"row"} spacing={1}>
              <Typography color={"#797979"} fontSize={12}>
                Pencapaian :
              </Typography>
              <Typography color={"#797979"} fontSize={12} fontWeight={700}>
                <NumericText
                  value={totalData.pembelian}
                  thousandSeparator
                  prefix={true}
                  displayType="text"
                />
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction={"row"} spacing={1}>
              <Typography color={"#797979"} fontSize={12}>
                Target :
              </Typography>
              <Typography color={"#797979"} fontSize={12} fontWeight={700}>
                <NumericText
                  value={totalData.target}
                  thousandSeparator
                  prefix={true}
                  displayType="text"
                />
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        {/* Progress */}
        <LoadingProgress
          progress={totalData.pencapaian}
          barWidth={"100%"}
          barheight={"1.5rem"}
          barcolor={"#00A0E3"}
        />

        {/* Collapse */}
        <Collapse in={collapse}>
          <CardGrafikPencapaian
            targetPencapaian={pencapaian}
            qtyJenisOrderHarian={jenisOrderQty}
            nominalJenisOrderHarian={jenisOrderNominal}
            qtySourceOrderHarian={sourceOrderQty}
            nominalSourceOrderHarian={sourceOrderNominal}
            tanggal={category}
            totalNominalJenisOrder={totalNominalJenisOrder}
            totalNominalSourceOrder={totalNominalSourceOrder}
            totalNotaJenisOrder={totalNotaJenisOrder}
            totalNotaSourceOrder={totalNotaSourceOrder}
          />
        </Collapse>

        {/* CollapseButton */}
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 1 }}
        >
          <CollapseButton
            onClick={() =>
              EventHandlers.handleCollapse({
                dispatch,
                collapse: !collapse,
              })
            }
            checked={collapse}
            expandLabel="Expand"
            collapseLabel="Collapse"
          />
        </div>
      </Stack>
    </Paper>
  );
};

export default CardGroupPencapaian;
