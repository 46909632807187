import React from "react";
import { Paper } from "@mui/material";
import Chart from "react-apexcharts";

const WaveChart = ({
  color = ["#35C1ED", "#15D14A", "#ED5635"],
  type,
  series,
  width = 300,
  title,
  xaxis,
  showXaxis = true,
  showYaxis = true,
  nominal,
  stacked = false,
  pcs,
}) => {
  const options = {
    colors: color,
    chart: {
      stacked: stacked,
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      width: type === "area" || type === "line" ? 2 : type === "bar" ? 0 : 3,
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: title,
      style: {
        fontSize: "12px",
        color: "#797979",
      },
    },
    xaxis: {
      categories: xaxis,
      labels: {
        show: showXaxis,
        style: {
          colors: ["#797979"],
        },
      },
    },
    yaxis: {
      show: showYaxis,
      labels: {
        style: {
          colors: ["#797979"],
        },
        formatter: function (val) {
          if (nominal) {
            return "Rp " + val.toLocaleString("id-ID");
          } else if (pcs) {
            return val.toLocaleString("id-ID") + " Pcs";
          } else {
            return val;
          }
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      labels: {
        colors: "#797979",
      },
      markers: {
        radius: 2,
      },
    },
    ...(type === "bar" && {
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadiusApplication: "end", // 'around', 'end'
          borderRadiusWhenStacked: "last", // 'all', 'last'
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
    }),
  };

  return (
    <Paper
      sx={{
        padding: 1,
        border: "0.5px solid #CACACA",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Chart
        options={options}
        series={series}
        height={width}
        width={"100%"}
        type={type}
      />
    </Paper>
  );
};

export default WaveChart;
