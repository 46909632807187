import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Tabs, Tab, AppBar, Button } from "@mui/material";
import Layout from "../layout/Layout";
import { EventHandlers as EventHandlersRekapPage } from "../features/rekap/page/repository";
import { Api as ApiReject } from "../features/rekap/reject/repository";
import { TabPanel, a11yProps } from "../components/common";
import RekapNotaPage from "../features/rekap/nota/RekapNotaPage";
import RekapRejectPage from "../features/rekap/reject/RekapRejectPage";
import { Print as PrintIcon } from "@mui/icons-material";
import { updateLoading } from "../features/themeSlice";

const Rekap = () => {
  const dispatch = useDispatch();

  const { tab } = useSelector((state) => state.rekap);
  const { setting } = useSelector((state) => state.data);
  const { role } = useSelector((state) => state.auth);
  const { tanggalRejectFilter } = useSelector((state) => state.rekapReject);

  const [isLoading, setIsLoading] = useState(false);

  const getRejectData = ApiReject.read({ tanggal: tanggalRejectFilter });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleLoadingPrint = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    dispatch(updateLoading(isLoading));
  }, [dispatch, isLoading]);

  // CHANGE TITLE
  useEffect(() => {
    if (setting.length) {
      const judul = setting.filter((e) => e.rule === "NAMA_TOKO");
      if (judul.length) {
        document.title = `${judul[0].value} | Rekap`;
      } else {
        document.title = `Rekap`;
      }
    } else {
      document.title = "Rekap";
    }
  }, [setting]);

  return (
    <Layout>
      {/* Title Container */}
      <Stack
        sx={{ pb: 1.5 }}
        direction="row"
        justifyContent={tab === 0 ? "end" : "space-between"}
        alignItems="center"
      >
        {tab === 1 && (
          <Grid
            container
            columnSpacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Grid item>
              <Button
                onClick={() => {
                  handleLoadingPrint();
                  handlePrint();
                }}
                variant="outlined"
                startIcon={<PrintIcon />}
                sx={{
                  padding: "7px 14px",
                  borderRadius: "5px",
                }}
                disabled={getRejectData?.data?.data.length === 0}
              >
                Print
              </Button>
            </Grid>
          </Grid>
        )}
        {role !== "penata" && (
          <AppBar
            position="static"
            elevation={0}
            sx={{
              backgroundColor: "#fff",
              width: role !== "penata" ? "325px" : "250px",
            }}
          >
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, value) =>
                EventHandlersRekapPage.handleTab({ dispatch, tab: value })
              }
              centered
            >
              <Tab {...a11yProps(0)} label="BY NOTA" />
              <Tab {...a11yProps(1)} label="REJECT" />
            </Tabs>
          </AppBar>
        )}
      </Stack>

      {/* Content */}
      <TabPanel value={tab} index={0}>
        <RekapNotaPage />
      </TabPanel>
      {role !== "penata" && (
        <TabPanel value={tab} index={1}>
          <RekapRejectPage componentRef={componentRef} />
        </TabPanel>
      )}
    </Layout>
  );
};

export default Rekap;
