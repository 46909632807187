import * as React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const TabelAntrianGroup = (props) => {
  const { column, rows, fontSize, margin, getRowClassName } = props;

  return (
    <DataGridPremium
      autoHeight
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      density={"compact"}
      rows={rows}
      columns={column}
      initialState={{ pinnedColumns: { left: [], right: ["actions"] } }}
      hideFooter
      getRowClassName={getRowClassName}
      sx={{
        fontSize: fontSize,
        borderRadius: "5px",
        border: "none",
        backgroundColor: "#F3F7F8",
        margin: margin,
        color: "#797979",
        "& .MuiDataGrid-columnHeaders": {
          minWidth: "35px !important",
          borderBottom: "2px solid #ADA4A4",
          "& [role=row]": {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: "#eef2f7",
          },
        },
        "& .MuiDataGrid-columnHeader--pinnedRight[data-field='actions'], & .MuiDataGrid-cell--pinnedRight[data-field='actions']":
          {
            backgroundColor: "#eef2f7",
            boxShadow: "-6px 0px 7px -3px rgba(0,0,0,0.1);",
          },
      }}
    />
  );
};

export default TabelAntrianGroup;
