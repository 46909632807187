import dayjs from "dayjs";
import { Config, EventHandlers } from ".";
import { EventHandlers as EventHandlersGroup } from "../../group/repository";
import {
  useCreatingData,
  useDeletingData,
  useFetchingData,
  useUpdatingData,
} from "../../../../hooks";

export class Api {
  static read = () => {
    return useFetchingData({
      endpoint: `${Config.variable.endpoint}`,
    });
  };

  static selesaiAntrianDesainer = ({
    handleClose,
    dispatch,
    socket,
    tanggal,
  }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/selesai/desainer`,
      invalidated: [
        `${Config.variable.endpoint}`,
        `antrian`,
        `pencapaian/${startDate}|${endDate}`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
    });
  };

  static selesaiAntrianProduksi = ({
    handleClose,
    dispatch,
    socket,
    tanggal,
  }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/selesai/produksi`,
      invalidated: [
        `antrian`,
        `${Config.variable.endpoint}`,
        `group/dikerjakan`,
        `pencapaian/${startDate}|${endDate}`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        EventHandlersGroup.handleRefetchStatus({
          dispatch,
          refetch_status: true,
        });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("checkout_siap_kirim");
          socket.emit("dikerjakan_group");
          socket.emit("antrian_group");
          socket.emit("dashboard_last_reject");
        }
      },
    });
  };

  static progressAntrianProduksi = ({ handleClose, dispatch, socket }) => {
    return useUpdatingData({
      endpoint: `${Config.variable.endpoint}/progress/produksi`,
      invalidated: [
        `${Config.variable.endpoint}`,
        `group/dikerjakan`,
        `antrian`,
        `group/antrian`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
          socket.emit("antrian_group");
        }
      },
    });
  };

  static uploadGambarAntrian = ({
    setProgress,
    id,
    handleClose,
    dispatch,
    socket,
  }) => {
    return useCreatingData({
      endpoint: `antrian/gambar/${id}`,
      invalidated: [`antrian`, `dikerjakan`],
      request: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: { _method: "PUT" },
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
          );
          setProgress(progressPercentage);
        },
      },
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        handleClose();
        if (socket) {
          socket.emit("antrian");
        }
      },
      onError: () => {
        return;
      },
    });
  };

  static deleteGambarAntrian = ({ modal, dispatch, socket }) => {
    return useDeletingData({
      endpoint: "antrian/gambar",
      invalidated: [
        `antrian`,
        `antrian/gambar/${modal.id ? modal.id : ""}`,
        `dikerjakan`,
      ],
      onSuccess: () => {
        EventHandlers.handleRefetchStatus({ dispatch, refetch_status: true });
        if (socket) {
          socket.emit("antrian");
        }
        return;
      },
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/progress/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianGambar = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/gambar/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };
}
