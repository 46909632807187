import React from "react";
import { Grid, Chip, Skeleton } from "@mui/material";

const inputItems = [
  { label: "No. Nota", property: "nota" },
  { label: "Nama", property: "nama" },
  { label: "No. HP", property: "nohp" },
  { label: "Jenis Order", property: "jenisorder" },
  { label: "Source Order", property: "sourceorder" },
  { label: "Alamat", property: "alamat" },
  { label: "", property: "alamatDetail" },
];

const DataForm = ({ data, isLoading, withStatus = true }) => {
  const dataDetail = {
    nota: data?.antrian?.nota || "-",
    nama: data?.detail?.nama || "-",
    nohp: data?.detail?.no_hp || "-",
    jenisorder: data?.antrian?.jenis_order?.nama || "-",
    sourceorder: data?.antrian?.source_order?.nama || "-",
    alamat: data?.detail?.wilayah || "-",
    alamatDetail: data?.detail?.alamat || "-",
  };

  return (
    <Grid
      container
      position={"relative"}
      paddingY={"1rem"}
      paddingX={"1.5rem"}
      borderRadius={"5px"}
      marginBottom={2}
      sx={{
        justifyContent: "space-between",
        fontSize: "12px",
        backgroundColor: "#F3F7F8",
        color: "#797979",
        fontWeight: "400",
      }}
    >
      {inputItems.map((item, index) => (
        <Grid
          key={index}
          container
          item
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={3}>
            {item.property === "nota" ? <b>{item.label}</b> : item.label}
          </Grid>
          <Grid item xs={0.2}>
            {item.property === "alamatDetail" ? null : ":"}
          </Grid>
          <Grid item xs={8.5} sx={{ paddingRight: "15px" }}>
            {isLoading ? (
              <Skeleton />
            ) : item.property === "nota" ? (
              <b>
                {item.property === "alamat"
                  ? dataDetail[item.property]
                  : dataDetail[item.property]}
              </b>
            ) : item.property === "nama" ? (
              <span>
                {dataDetail[item.property].length > 15
                  ? `${dataDetail[item.property].slice(0, 15)}...`
                  : dataDetail[item.property]}
              </span>
            ) : item.property === "nohp" ? (
              <span>
                {dataDetail[item.property].replace(
                  /(\d{4})(\d{4})(\d{4})/,
                  "$1 $2 $3",
                )}
              </span>
            ) : item.property === "jenisorder" ||
              item.property === "sourceorder" ||
              item.property === "alamat" ? (
              <b>
                {item.property === "alamat"
                  ? dataDetail[item.property]
                  : dataDetail[item.property]}
              </b>
            ) : item.property === "alamatDetail" ? (
              <>
                <span style={{ fontSize: "10px" }}>
                  {dataDetail[item.property]}
                </span>
              </>
            ) : (
              dataDetail[item.property]
            )}
          </Grid>
        </Grid>
      ))}

      {!isLoading && withStatus && (
        <Chip
          size="small"
          label={data?.progress?.status?.nama || "-"}
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            backgroundColor: data?.progress?.status?.warna || "white",
            color: "white",
            borderRadius: "3px",
            fontWeight: 500,
            padding: "0 0.5rem",
            height: "15px",
          }}
          variant="filled"
        />
      )}
    </Grid>
  );
};

export default DataForm;
