import dayjs from "dayjs";
import { Config } from ".";
import { useFetchingData } from "../../../../hooks";

export class Api {
  // READ DATA STATUS PEMBAYARAN
  static read = ({ tanggal }) => {
    const startDate = dayjs(tanggal[0]).format("YYYY-MM-DD");
    const endDate = dayjs(tanggal[1]).format("YYYY-MM-DD");

    return useFetchingData({
      endpoint: `${Config.variable.endpoint}/${startDate}|${endDate}`,
    });
  };

  static detailAntrian = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailProgress = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/progress/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };

  static detailAntrianGambar = (props) => {
    const { modal } = props;
    return useFetchingData({
      endpoint: `antrian/gambar/${modal.id ? modal.id : ""}`,
      enabled: modal.open,
      gcTime: 0,
    });
  };
}
