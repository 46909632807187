import React from "react";
import { useSelector } from "react-redux";
import { useGetCachedData } from "../../../hooks";
import {
  DetailAntrian,
  DetailProgress,
  TabelManageGroup,
  ModalManageGroup,
  PrintAntrian,
} from "./components";
import { Api } from "./repository";

const NotaPage = () => {
  const { detailModal, detailProgressModal } = useSelector(
    (state) => state.manageGroupNota
  );

  const getDataAntrian = Api.read();
  const getAntrianDetail = Api.detailAntrian({ modal: detailModal });
  const getProgressDetail = Api.detailProgress({ modal: detailProgressModal });

  const getDataSourceOrder = useGetCachedData({ queryKey: "source_order" });
  const getDataJenisOrder = useGetCachedData({ queryKey: "jenis_order" });

  return (
    <>
      {/* TABEL ANTRIAN */}
      <TabelManageGroup
        data={getDataAntrian.data ? getDataAntrian.data.data : []}
        isLoading={getDataAntrian.isLoading}
        sourceOrder={getDataSourceOrder ? getDataSourceOrder.data : []}
        jenisOrder={getDataJenisOrder ? getDataJenisOrder.data : []}
      />

      {/* POPUPS */}
      <DetailAntrian
        data={getAntrianDetail.data ? getAntrianDetail.data.data : {}}
        isLoading={getAntrianDetail.isLoading}
        isError={getAntrianDetail.isError}
      />

      <DetailProgress
        data={getProgressDetail.data ? getProgressDetail.data.data : {}}
        isLoading={getProgressDetail.isLoading}
        isError={getProgressDetail.isError}
      />

      <ModalManageGroup />

      <PrintAntrian />
    </>
  );
};

export default NotaPage;
