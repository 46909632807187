import React from "react";
import Layout from "../layout/Layout";
import PencapaianNotaPage from "../features/pencapaian/nota/PencapaianNotaPage";

const Pencapaian = () => {
  return (
    <Layout>
      {/* Title Container */}
      {/* {role === "admin_produksi" && (
        <Stack
          direction="row"
          rowSpacing={1}
          sx={{
            pb: 1.5,
            justifyContent: "space-between",
            display: role !== "admin_produksi" ? "none" : "",
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {tab === 1 ? (
              <>
                <Grid item>
                  <InputSearch />
                </Grid>
                <Grid item>
                  <Select
                    name={"filterStatus"}
                    control={formPencapaian.control}
                    label={"Status"}
                    options={filterStatus}
                    rules={{ required: false }}
                    defaultValue={""}
                    size={"small"}
                    wave
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
          {tab === 1 && <Sortinglength />}
          <AppBar
            position="static"
            elevation={0}
            sx={{
              backgroundColor: "#fff",
              width: "250px",
            }}
          >
            <Tabs
              variant="fullWidth"
              value={tab}
              onChange={(_, value) =>
                EventHandlers.handleTab({ dispatch, tab: value })
              }
              centered
            >
              <Tab {...a11yProps(0)} label="BY NOTA" />
              <Tab {...a11yProps(1)} label="BY GROUP" />
            </Tabs>
          </AppBar>
        </Stack>
      )} */}

      {/* Content */}
      <div>
        <PencapaianNotaPage />
      </div>
      {/* {role === "admin_produksi" && ( */}
      {/* <>
          <TabPanel value={tab} index={0}>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <GroupPage />
          </TabPanel>
        </> */}
      {/* )} */}

      {/* {role === "desainer" && (
        <TabPanel value={tab} index={0}>
          <PencapaianNotaPage />
        </TabPanel>
      )} */}
      {/* <RiseLoader /> */}
    </Layout>
  );
};

export default Pencapaian;
