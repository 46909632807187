import { Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";

const CheckboxInput = (props) => {
  const { name, control, label, rules, defaultValue, disabled = false } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || false}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          disabled={disabled}
          {...(error && { error: true })}
          sx={{
            "& .MuiFormHelperText-root": {
              fontWeight: 500,
              position: "absolute",
              top: 23,
              right: 10,
            },
          }}
        >
          <FormControlLabel
            labelPlacement="start"
            label={label}
            control={<Checkbox {...field} checked={field.value || false} />}
          />
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default CheckboxInput;
