import { Config } from ".";
import { useFetchingData } from "../../../../hooks";

export class Api {
  static pendapatanTahunan = ({ tahun }) => {
    const endpoint = `${Config.variable.endpoint}?tahun=${tahun}`;
    return useFetchingData({
      endpoint,
      gcTime: 0,
    });
  };
}
