import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Info as InfoIcon,
  Print as PrintIcon,
  PendingActions as ProgressIcon,
  Payments as PaymentsIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { EventHandlers } from "../../repository";

class ActionButton {
  static getActionOpsi = ({ role, params, dispatch }) => {
    const commonActions = [
      <GridActionsCellItem
        icon={<InfoIcon sx={{ color: "#2FA9EE" }} />}
        label="Detail"
        onClick={() =>
          EventHandlers.handleDetailModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<PrintIcon sx={{ color: "#2FA9EE" }} />}
        label="Print"
        onClick={() =>
          EventHandlers.handlePrintModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Print Antrian",
            },
          })
        }
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ProgressIcon sx={{ color: "#24BC85" }} />}
        label="Progress"
        onClick={() => {
          EventHandlers.handleDetailProgressModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Detail Progress",
            },
          });
        }}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<PaymentsIcon sx={{ color: "#24BC85" }} />}
        label="Pembayaran"
        onClick={() =>
          EventHandlers.handlePembayaranModal({
            dispatch,
            modal: {
              id: params.id,
              open: true,
              title: "Pembayaran",
            },
          })
        }
        showInMenu
      />,
    ];

    let additionalActions = [];

    if (role === "root" || role === "customer_service") {
      additionalActions = [
        <GridActionsCellItem
          icon={<EditIcon sx={{ color: "#FF9800" }} />}
          label="Edit Reject"
          onClick={() =>
            EventHandlers.handleEditReject({
              dispatch,
              modal: {
                id: params.id,
                open: true,
                title: "Edit Reject !",
              },
            })
          }
          showInMenu
        />,
      ];
    }

    return [...commonActions, ...additionalActions];
  };
}

export default ActionButton;
