export function dateFormatter(props) {
  const {
    date,
    withHari = false,
    withMonth = "full",
    withTimes = false,
    yearLength = "full",
    timesOnly = false,
  } = props;

  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let hari = date.getDay();

  let labelHariText = "";
  let labelHariNumber = ("0" + date.getDate()).slice(-2);
  let labelBulanText = "";
  let labelBulanNumber = ("0" + (date.getMonth() + 1)).slice(-2);

  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  switch (hari) {
    case 0:
      labelHariText = "Minggu";
      break;
    case 1:
      labelHariText = "Senin";
      break;
    case 2:
      labelHariText = "Selasa";
      break;
    case 3:
      labelHariText = "Rabu";
      break;
    case 4:
      labelHariText = "Kamis";
      break;
    case 5:
      labelHariText = "Jum'at";
      break;
    case 6:
      labelHariText = "Sabtu";
      break;
    default:
      labelHariText = "Wrong Day";
      break;
  }

  switch (bulan) {
    case 0:
      labelBulanText = "Januari";
      break;
    case 1:
      labelBulanText = "Februari";
      break;
    case 2:
      labelBulanText = "Maret";
      break;
    case 3:
      labelBulanText = "April";
      break;
    case 4:
      labelBulanText = "Mei";
      break;
    case 5:
      labelBulanText = "Juni";
      break;
    case 6:
      labelBulanText = "Juli";
      break;
    case 7:
      labelBulanText = "Agustus";
      break;
    case 8:
      labelBulanText = "September";
      break;
    case 9:
      labelBulanText = "Oktober";
      break;
    case 10:
      labelBulanText = "November";
      break;
    case 11:
      labelBulanText = "Desember";
      break;
    default:
      labelBulanText = "Wrong Month";
      break;
  }

  let formattedDate = "";
  if (withHari) {
    formattedDate += labelHariText + ", ";
  }
  formattedDate += labelHariNumber + " ";

  if (withMonth === "full") {
    formattedDate += labelBulanText;
  } else if (withMonth === "simple") {
    formattedDate += labelBulanText.slice(0, 3);
  } else {
    formattedDate += labelBulanNumber;
  }

  if (yearLength === "full") {
    formattedDate += " " + tahun;
  } else {
    formattedDate += " " + tahun.toString().slice(-2);
  }

  if (withTimes) {
    formattedDate += " " + hours + ":" + minutes;
  }

  if (timesOnly) {
    return hours + ":" + minutes;
  }

  return formattedDate;
}
